"use strict";

const ConstructionProjectPopup = function () {
  var loadDomListeners = function () {
    $("#mapContent").off("click", ".popup-history-button");
    $("#mapContent").on("click", ".popup-history-button", inspectionHistoryButtonClick);

    $("#mapContent").on("click", ".popup-project-back-button", clickOnBackButton);

    $("#mapContent").on("click", ".open-construction-project-fact-sheet", openFactSheet);
    $("#mapContent").on("click", ".popup-construction-show-photos", clickOnPhotosButton);

    $("#mapContent").off("click", ".popup-project-delete-button", getDataForDeletionOfPopup);
    $("#mapContent").on("click", ".popup-project-delete-button", getDataForDeletionOfPopup);

    $("#mapContent").off("click", ".download-inspection");
    $("#mapContent").on("click", ".download-inspection", downloadInspectionReport);

    $("#mapContent").off("click", ".view-project-inventory");
    $("#mapContent").on("click", ".view-project-inventory", viewConstructionProjectInventory);

    $("#mapContent").off("click", ".popup-fact-sheet-button");
    $("#mapContent").on("click", ".popup-fact-sheet-button", openFactSheet);

    $("#mapContent").off("click", ".show-enforcement-action-modal");
    $("#mapContent").on(
      "click",
      ".show-enforcement-action-modal",
      onEnforcementActionModalForInspection,
    );

    $("#mapContent").off("click", ".show-enforcement-action");
    $("#mapContent").on("click", ".show-enforcement-action", showEnforcementActionModal);

    $("#mapContent").off("click", "#construction-complete-project-btn", openCompleteModal);
    $("#mapContent").on("click", "#construction-complete-project-btn", openCompleteModal);

    $("#mapContent").off("click", ".post-active-button", showPostActiveModal);
    $("#mapContent").on("click", ".post-active-button", showPostActiveModal);

    $("#mapContent").off(
      "click",
      ".js-delete-construction-project-inspection",
      deleteProjectInspection,
    );
    $("#mapContent").on(
      "click",
      ".js-delete-construction-project-inspection",
      deleteProjectInspection,
    );
  };

  var getConstructionProjectPopupData = async function (projectId) {
    const constructionProjectData = await ApiCalls.getProjectPopup(projectId);
    return constructionProjectData;
  };

  var getDataForDeletionOfPopup = async function () {
    const project = $(this).closest(".popup-general").data();
    const projectData = await getConstructionProjectPopupData(project.id);
    Analytics.sendPopupEvent("delete_project", "construction");
    deleteConstructionProjectPopup(projectData.id, projectData.projectName);
  };

  var deleteConstructionProjectPopup = function (projectId, projectName) {
    MessageModal.showConfirmWarningModal(
      `Delete Construction Project ${projectName} ? Click "Delete" to permanently delete the Construction Project. Click "Cancel" to return.`,
      async function () {
        await ApiCalls.deleteConstructionProject(projectId);
        ConstructionProjectLayer.loadLayer("main");
      },
      "Cancel",
      "Delete",
    );
  };

  var onEnforcementActionModalForInspection = function () {
    const inspectionId = getRowId($(this));
    openEnforcementActionModalForInspection(inspectionId);
  };

  var openEnforcementActionModalForInspection = function (inspectionId) {
    if (ToolSettings.getSetting("construction", "useIssueEnforcementsModal")) {
      IssueEnforcementsModal.open(inspectionId, null);
    } else {
      EnforcementActionModalController.openEnforcementActionModalForInspection(inspectionId, () => {
        // Tree.set("constructionLayerOpenOnReload", enforcementAction.constructionProjectId);
        ConstructionProjectLayer.loadLayer();
      });
    }
  };

  var openCompleteModal = function (e) {
    const $row = $(e.currentTarget).closest("div");
    const id = $row.data("id");

    ProjectCompleteModal.renderAndShow(id);
  };

  var showEnforcementActionModal = function () {
    const enforcementActionId = getRowId($(this));
    EnforcementActionModalController.getAndRenderEnforcementAction(enforcementActionId, () => {
      // Tree.set("constructionLayerOpenOnReload", enforcementAction.constructionProjectId);
      ConstructionProjectLayer.loadLayer();
    });
  };

  var showPostActiveModal = function (e) {
    const $row = $(e.currentTarget).closest(".popup-construction");
    const id = $row.data("id");

    PostActiveModal.renderAndShow(id);
  };

  var inspectionHistoryButtonClick = function () {
    var projectId = $(this).parents(".popup-general").data("id");
    showInspectionHistory(projectId);

    Analytics.sendPopupEvent("view_historyTab", "construction");
  };

  var downloadInspectionReport = function () {
    var inspectionId = getRowId($(this));
    var inspectionType = $(this).parents("tr").data("type");
    ApiCalls.downloadConstructionInspectionReport(inspectionId, null, inspectionType);
  };

  var clickOnBackButton = function () {
    $(".popup-main-pane").show();
    $(".popup-history-pane").hide();
    $(".popup-photos-pane").hide();
    $(".popup-project-back-button").hide();
    $(".popup-project-delete-button").hide();
    $(".open-construction-project-fact-sheet").show();
  };

  var clickOnPhotosButton = function () {
    var projectId = $(this).parents(".popup-general").data("id");
    PopupPhotos.load("constructionProject", projectId);
    $(".popup-main-pane").hide();
    $(".open-construction-project-fact-sheet").hide();
    $(".popup-project-back-button").show();
    $(".popup-photos-pane").show();

    Analytics.sendPopupEvent("view_photos", "construction");
  };

  var showInspectionHistory = function (projectId) {
    const isCdot = Session.isCdot();
    ApiCalls.getConstructionProjectHistory(projectId, function (inspections) {
      $(".popup-construction-history").html(
        nunjucks.render("popups/constructionInspectionHistory.njk", {
          inspections,
          isCdot,
        }),
      );
      $(".popup-main-pane").hide();
      $(".popup-history-button").hide();
      $(".popup-construction-history").show();
      $(".popup-project-back-button").show();
      $(".popup-project-delete-button").show();
      $(".open-construction-project-fact-sheet").hide();
    });
  };

  var _renderSiteVisitHistoryBarGraph = function (inspectionsArray) {
    PopupContent.setPopupConditionBar(
      $(".tr-construction-project-score"),
      inspectionsArray,
      function (datum) {
        if (datum.score >= 0.7) {
          return 5;
        } else if (
          (datum.score < 0.7 && datum.score !== null) | (datum.score >= 0 && datum.score !== null)
        ) {
          return 0;
        }
      },
    );
  };

  var getConstructionProjectPopupHtml = async function (constructionProjectData) {
    const popupData = await ApiCalls.getProjectPopup(constructionProjectData.id);
    const constructionProps = getConstructionPopupProps(popupData);
    const dataView = Tree.get("dataView");
    if (dataView === "construction-project-delivery") {
      return nunjucks.render(
        "popups/constructionProjectDeliveryPopup.njk",
        getConstructionProjectDeliveryPopupProps(popupData),
      );
    } else {
      setTimeout(() => {
        _renderSiteVisitHistoryBarGraph(popupData?.siteVisitHistoryGraphData);
      }, "250");
      return renderConstructionProject(constructionProps);
    }
  };

  var renderConstructionProject = function (props) {
    return nunjucks.render("popups/constructionProjectPopup.njk", props);
  };

  var getConstructionPopupProps = function (constructionProjectData) {
    const props = {};
    props.generalContractorInfo = constructionProjectData.generalContractorInfo;
    props.siteVisitHistory = constructionProjectData.siteVisitHistoryGraphData;
    props.id = constructionProjectData.id;
    props.subtitle = constructionProjectData.highPriority ? "Priority" : "";
    if (constructionProjectData.pauseStatus === "paused") {
      if (props.subtitle) {
        props.subtitle += ", ";
      }
      props.subtitle += "Paused";
    }

    props.title = constructionProjectData.projectIdentifier;
    props.projectName = constructionProjectData.projectName;
    props.displayAreaOfDisturbance = (
      constructionProjectData.priorityInfo || {}
    ).displayAreaOfDisturbance;
    props.prioritization = constructionProjectData.highPriority ? "High" : "Low";
    props.highPriority = constructionProjectData.highPriority;
    props.streetAddress = constructionProjectData.streetAddressShort;
    props.phase = constructionProjectData.phase;
    props.projectDeliveryCost = constructionProjectData.projectDeliveryCost;
    props.implementerType = constructionProjectData.implementerType;
    props.maintainerType = constructionProjectData.maintainerType;
    props.ownerName =
      constructionProjectData.owner !== null ? constructionProjectData.owner.name : null;
    props.ownerPhoneNumber =
      constructionProjectData.owner !== null ? constructionProjectData.owner.phoneNumber : null;
    props.nextInspectionType =
      constructionProjectData.nextInspection !== null
        ? constructionProjectData.nextInspection.type
        : null;
    props.nextInspectionDueDate =
      constructionProjectData.nextInspection !== null
        ? constructionProjectData.nextInspection.dueDate
        : null;
    addInspectionButtonProps(props, constructionProjectData);
    props.headerColorClass = ConstructionProjectStyles.getHeaderColorClass(constructionProjectData);
    props.canEditProject = constructionProjectData.canEditProject;
    props.canCertifyProject = constructionProjectData.canCertifyProject;
    props.showPostActiveButton =
      constructionProjectData.phase === "construction" &&
      ToolSettings.getSetting("construction", "enable-post-active-phase");
    props.showPostActiveCompleteProjectButton =
      constructionProjectData.phase === "post-active" &&
      ToolSettings.getSetting("construction", "enable-post-active-phase");
    props.permissions = UserPermissions.getAllPermissions();
    return props;
  };

  var getConstructionProjectDeliveryPopupProps = function (constructionProjectData) {
    const props = {};
    props.id = constructionProjectData.id;
    props.subtitle = ConstructionProjectStyles.getDisplayPhase(constructionProjectData.phase);
    props.title = constructionProjectData.projectIdentifier;
    props.projectName = constructionProjectData.projectName;
    props.streetAddress = constructionProjectData.streetAddressShort;
    props.city = constructionProjectData.city;
    props.treatmentTypes = constructionProjectData.treatmentTypes;

    props.showPostActiveButton =
      constructionProjectData.phase === "construction" &&
      ToolSettings.getSetting("construction", "enable-post-active-phase");
    props.showPostActiveCompleteProjectButton =
      constructionProjectData.phase === "post-active" &&
      ToolSettings.getSetting("construction", "enable-post-active-phase");
    props.stage = ProjectStageData.getDisplayStageData(constructionProjectData);
    if (props.stage) {
      props.capacityAcreFeet = props.stage.capacityAcreFeet;
      props.treatedImperviousAreaAcres = props.stage.treatedImperviousAreaAcres;
    }
    props.projectDeliveryCost = constructionProjectData.projectDeliveryCost;

    props.headerColorClass = ConstructionProjectStyles.getHeaderColorClass(constructionProjectData);
    props.canEditProject = constructionProjectData.canEditProject;
    props.canCertifyProject = constructionProjectData.canCertifyProject;
    return props;
  };

  var viewConstructionProjectInventory = function () {
    const id = $(this).parents(".popup-construction").data("id");
    ProjectInventoryModalController.invokeProjectInventoryModal(id);
  };

  var openFactSheet = function () {
    const dataView = Tree.get("dataView");
    FactSheet._toggleDataListIcon();
    if (dataView === "construction-project") {
      Tree.set("navToggleSelection", "list");
      Analytics.sendPopupEvent("view_details", "construction");
    }
    const id = $(this).parents(".popup-construction").data("id");
    Tree.set(["factSheet", "previousDataSort"], Tree.get(["table", "dataSort"]));
    Filters.setDataSort("projectIdentifier");
    UrlRoutes.go(`${dataView}-fact-sheet`, { id });
    if (dataView === "construction-project-delivery") {
      const marker = LayerFunctions.getLayerMarkerById(id);
      marker.closePopup();
      Tree.set("navToggleSelection", "data");
    }
    FactSheet.handleFilterFlow();
  };

  var addInspectionButtonProps = function (props, project) {
    const id = project.projectId || project.id;

    if (project.canInspectProject === undefined) {
      throw new Error(`Project inspection permission not returned for project ${id}.`);
    }
    props.canInspectProject = project.canInspectProject;
    props.projectId = id;
  };

  var deleteProjectInspection = function () {
    const inspectionId = getRowId($(this));
    MessageModal.showDeleteRecordModal(async function () {
      await ApiCalls.deleteConstructionProjectInspection(inspectionId);
      await ConstructionProjectLayer.loadLayer();
    });
  };

  var getRowId = function ($this) {
    return $this.parents("tr").data("id");
  };

  return {
    loadDomListeners,
    getConstructionProjectPopupHtml,
    getConstructionPopupProps,
    showInspectionHistory,
    addInspectionButtonProps,
    showPostActiveModal,
    _renderSiteVisitHistoryBarGraph,
    renderConstructionProject,
    downloadInspectionReport,
    openEnforcementActionModalForInspection,
    onEnforcementActionModalForInspection,
    showEnforcementActionModal,
    deleteProjectInspection,
    getRowId,
  };
};

module.exports = ConstructionProjectPopup();

const ApiCalls = require("../apiCalls");
const ConstructionProjectLayer = require("./constructionProjectLayer");
const ConstructionProjectStyles = require("./constructionProjectStyles");
const EnforcementActionModalController = require("./enforcementActionModalController");
const Filters = require("../mapFunctions/filters");
const IssueEnforcementsModal = require("./issueEnforcementsModal");
const PostActiveModal = require("../construction/postActiveModal");
const ProjectCompleteModal = require("../construction/projectCompleteModal");
const ProjectInventoryModalController = require("./projectInventoryModalController");
const ProjectStageData = require("./projectStageData");
const Session = require("../login/session");
const UserPermissions = require("../login/userPermissions");
const ToolSettings = require("../settings/toolSettings");
const Tree = require("../tree");
const LayerFunctions = require("../layerFunctions");
const FactSheet = require("../general/factSheet");
const MessageModal = require("../modals/messageModal");
const PopupPhotos = require("../mapFunctions/popupPhotos");
const Analytics = require("../general/analytics");
const PopupContent = require("../mapFunctions/popupContent");
const UrlRoutes = require("../routes/urlRoutes");
