"use strict";

const SamplingSiteInventoryGeneralInfo = function () {
  const stringKey = "sampling-site-general-info";
  const headerInformation = "General info";
  let $page;

  var getStringKey = function () {
    return stringKey;
  };

  var getHeaderInformation = function () {
    return headerInformation;
  };

  var render = async function () {
    await renderPageHtml();
  };

  var loadListeners = function () {
    Form.initializeAndLoadListeners(
      $page,
      SamplingSiteInventoryModalController.getSamplingSiteInventoryFormKey(),
      { isMultiPart: true },
    );
  };

  var removeListeners = function () {};

  var renderPageHtml = function () {
    const renderData = SamplingSiteInventoryModalController.getInventoryForRender();
    const additionalProps = getAdditionalProps();
    const html = nunjucks.render(
      "modals/samplingSiteInventory/samplingSiteInventoryGeneralInfo.njk",
      {
        ...renderData,
        ...additionalProps,
      },
    );
    $page = $(".modal-dynamic-content");
    SamplingSiteInventoryModalController.renderPageContent(html);
    loadListeners();
  };

  var getAdditionalProps = function () {
    return {
      siteTypeOptions: SamplingSiteInventoryConstants.getSiteTypeOptions(),
      entityTypeOptions: ToolSettings.getDomainOptions("entity_type"),
    };
  };

  var cleanUp = function () {
    removeListeners();
    $page.empty();
    return true;
  };

  var validate = function () {
    return true;
  };

  return {
    getStringKey,
    getHeaderInformation,
    render,
    renderPageHtml,
    cleanUp,
    validate,
  };
};

module.exports = SamplingSiteInventoryGeneralInfo();

const SamplingSiteInventoryConstants = require("./samplingSiteInventoryConstants");
const SamplingSiteInventoryModalController = require("./samplingSiteInventoryModalController");
const Form = require("../general/form");
const ToolSettings = require("../settings/toolSettings");
