/**
 * @public
 */

/**
 * @public
 */
class ParentSchemaAwareVisitor {
  parent;
  constructor({
    parent
  }) {
    this.parent = parent;
  }
}
export default ParentSchemaAwareVisitor;