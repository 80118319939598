"use strict";

/*
  iOS Safari is very picky about programmatically opening windows only as the
  direct result of a user click, so much so that any asynchronous code breaks
  its trust. The only work around is to open an empty window before the
  asynchronous code, then navigate it to the downloaded content later.

  TLDR: instantiate PreviewDownloader BEFORE calling API or using `async`
*/
function PreviewDownloader() {
  var previewWindow = openPreviewWindow();

  function openPreviewWindow() {
    const blob = new Blob([LoadingScreen.getLoadingHtml()], { type: "text/html" });
    const url = URL.createObjectURL(blob);
    const preview = window.open(url, "_blank");
    URL.revokeObjectURL(url);
    return preview;
  }

  var openBlob = function (blob) {
    const url = URL.createObjectURL(blob);
    openUrl(url);
    // If you revoke the URL they can't save the file
    // URL.revokeObjectURL(url);
  };

  var openUrl = function (url) {
    if (previewWindow === null || previewWindow.closed) {
      return;
    }

    previewWindow.location = url;
  };

  return { openBlob, openUrl };
}

module.exports = PreviewDownloader;

const LoadingScreen = require("../general/loadingScreen");
