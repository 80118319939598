"use strict";

const NewProjectInspectionGeneralInfo = function () {
  const stringKey = "general-info";
  const tabHeader = "General Info";
  let $page;

  var loadListeners = function (data) {
    $page = $(`#assessment-modal #${stringKey}`);

    $page.on("2N:FormInput", "[name='precipitation[precipitation-now]']", _rerenderWeather);
    $page.on(
      "2N:FormInput",
      "[name='start-time'], [name='precipitation[last-rainfall-date]']",
      updateDaysSinceLastRainfall,
    );

    _rerenderWeather();
  };

  var unloadListeners = function () {
    $page.off("2N:FormInput", "[name='precipitation[precipitation-now]']", _rerenderWeather);
    $page.off(
      "2N:FormInput",
      "[name='start-time'], [name='precipitation[last-rainfall-date]']",
      updateDaysSinceLastRainfall,
    );
  };

  var getProps = function (data) {
    let inspectionTypeOptions = NewProjectInspectionConstants.getInspectionTypeOptions();
    if (!Object.keys(data.openFindings ?? {}).length) {
      inspectionTypeOptions = inspectionTypeOptions.filter(
        (option) => option.value !== "follow-up",
      );
    }
    return {
      inspectionTypeOptions: inspectionTypeOptions,
      precipitationNowOptions: NewProjectInspectionConstants.getPrecipitationNowOptions(),
    };
  };

  var getStringKey = function () {
    return stringKey;
  };

  var getTabHeader = function () {
    return tabHeader;
  };

  var updateDaysSinceLastRainfall = function () {
    const data = NewProjectInspectionController.getAllData();
    const daysSinceLastRainfall = getDaysSinceLastRainfall(
      data.startTime,
      data.precipitation?.lastRainfallDate,
    );

    $page.find(".days-since-last-rainfall p").text(daysSinceLastRainfall);
    NewProjectInspectionController.setFormData(
      ["precipitation", "daysSinceLastRainfall"],
      daysSinceLastRainfall,
    );
  };

  var getDaysSinceLastRainfall = function (startTime, lastRainfallDate) {
    if (!lastRainfallDate || !startTime) {
      return null;
    }

    lastRainfallDate = DateTime.parseIsoDate(lastRainfallDate);
    startTime = DateTime.parseIsoDate(startTime);

    return DateTime.getDaysDifferenceNumber(startTime, lastRainfallDate);
  };

  var _rerenderWeather = function () {
    const $currentWeather = $page.find(`[name='precipitation[precipitation-now]']`).parent();
    $currentWeather.siblings().remove();

    const data = NewProjectInspectionController.getAllData();
    const weatherHtml = nunjucks.render("modals/newProjectInspection/templates/weather.njk", {
      props: data,
    });

    $currentWeather.after(weatherHtml);

    NewProjectInspectionController.reloadListeners();
    updateDaysSinceLastRainfall();
  };

  return {
    loadListeners,
    unloadListeners,
    getStringKey,
    getTabHeader,
    getProps,
  };
};

module.exports = NewProjectInspectionGeneralInfo();

const DateTime = require("../../dateTime");
const NewProjectInspectionConstants = require("./newProjectInspectionConstants");
const NewProjectInspectionController = require("./newProjectInspectionController");
