"use strict";

const SamplingSiteIcon = function () {
  var getSamplingSiteMarker = function (latlng, condition = null) {
    return L.marker(latlng, {
      icon: getSamplingSiteMarkerIcon(condition),
    });
  };

  var getSamplingSiteMarkerIcon = function (condition) {
    const iconClass = getSamplingSiteIconClass(condition);
    var icon = L.divIcon({
      iconSize: _updateIconSizeOnZoom(),
      popupAnchor: [0, 5],
      className: iconClass,
    });

    return icon;
  };

  var _updateIconSizeOnZoom = function () {
    const currentZoomLevel = Tree.get("zoomLevel");
    const currentDataView = Tree.get("dataView");
    const iconSizesArray = [8, 14, 14, 14, 24, 24, 24, 24];
    const updatedIconProps = MapFunctions.resizeIconsOnZoom(
      currentZoomLevel,
      false,
      false,
      false,
      false,
      iconSizesArray,
    );

    const activeDataViews = ["sampling-site", "wq-monitoring"];
    return activeDataViews.includes(currentDataView) ? updatedIconProps.size : [24, 24];
  };

  var getSamplingSiteIconClass = function (condition = null) {
    const validConditions = [null, "optimal", "poor", "closed"];
    const classNames = ["sampling-site"];

    if (!validConditions.includes(condition)) {
      throw new Error(`${condition} is not a valid condition.`);
    }

    if (condition) {
      classNames.push(condition);
    }

    return classNames.join("-");
  };

  const getConditionColor = function (condition) {
    if (condition === undefined) {
      condition = null;
    }

    return MapStyles.getColorFromCondition(condition);
  };

  return {
    getSamplingSiteMarker,
    getSamplingSiteIconClass,
    getSamplingSiteMarkerIcon,
    getConditionColor,
    _updateIconSizeOnZoom,
  };
};

module.exports = SamplingSiteIcon();

const MapFunctions = require("../mapFunctions/mapFunctions");
const Tree = require("../tree");
const MapStyles = require("../mapFunctions/mapStyles");
