"use strict";

function ResourceConfig() {
  function getResources() {
    const AllPropertiesGeoServerLayer = require("../esg/geoServer/allPropertiesGeoServerLayer");
    const PropertiesFilters = require("../esg/geoServer/propertiesFilters");
    const PlanPropertiesGeoServerLayer = require("../esg/geoServer/planPropertiesGeoServerLayer");
    const ApiCalls = require("../apiCalls");
    const BmpFcsInventoryModalController = require("../bmpfcs/bmpFcsInventoryModalController");
    const BmpFilter = require("../filters/bmpFilter");
    const ConstructionFilters = require("../construction/constructionFilters");
    const ConstructionProjectGeoServerLayer = require("../constructionG2/constructionProjectGeoServerLayer");
    const ConstructionProjectG2Filters = require("../constructionG2/constructionProjectG2Filters");
    const ConstructionProjectLayer = require("../construction/constructionProjectLayer");
    const DataResource = require("../offline/dataResource");
    const DryWeatherOutfallGeoServerLayer = require("../illicitDischarge/dryWeatherOutfallGeoServerLayer");
    const DryWeatherGeoServerLayerFilters = require("../illicitDischarge/dryWeatherGeoServerLayerFilters");
    const FacilityInspectionConstants = require("../indcom/facilityInspection/facilityInspectionConstants");
    const FacilityInspectionController = require("../indcom/facilityInspection/facilityInspectionController");
    const IndcomFacilityFilters = require("../indcom/indcomFacilityFilters");
    const IndcomFacilityGeoServerLayer = require("../indcom/indcomFacilityGeoServerLayer");
    const MainMap = require("../mapFunctions/mainMap");
    const MuniCatchBasinFilters = require("../muni/muniCatchBasinFilters");
    const MuniCatchBasinGeoServerLayer = require("../muni/muniCatchBasinGeoServerLayer");
    const MuniCleanoutFilters = require("../muni/muniCleanoutFilters");
    const MuniCleanoutGeoServerLayer = require("../muni/muniCleanoutGeoServerLayer");
    const MuniBmpFilters = require("../muni/bmp/muniBmpFilters");
    const MuniBmpGeoServerLayer = require("../muni/bmp/muniBmpGeoServerLayer");
    const LidBmpGeoServerLayer = require("../lid/lidBmpGeoServerLayer");
    const LidBmpFilters = require("../lid/lidBmpFilters");
    const MuniCulvertFilters = require("../muni/culvert/muniCulvertFilters");
    const MuniCulvertGeoServerLayer = require("../muni/culvert/muniCulvertGeoServerLayer");
    const MuniOpenChannelFilters = require("../muni/openChannel/muniOpenChannelFilters");
    const MuniOpenChannelGeoServerLayer = require("../muni/openChannel/muniOpenChannelGeoServerLayer");
    const MuniManholeFilters = require("../muni/muniManholeFilters");
    const MuniManholeGeoServerLayer = require("../muni/muniManholeGeoServerLayer");
    const MuniLiftStationsGeoServerLayer = require("../muni/muniLiftStationsGeoServerLayer");
    const MuniOutfallsFilters = require("../muni/outfalls/muniOutfallsFilters");
    const MuniLiftStationsFilters = require("../muni/muniLiftStationsFilters");
    const MuniOutfallsGeoServerLayer = require("../muni/outfalls/muniOutfallsGeoServerLayer");
    const OutfallFunctions = require("../outfalls/outfallFunctions");
    const ProjectDeliveryFilters = require("../projectDelivery/projectDeliveryFilters");
    const ProjectInspectionConstants = require("../construction/inspections/projectInspectionConstants");
    const ProjectInspectionController = require("../construction/inspections/projectInspectionController");
    const NewProjectInspectionController = require("../construction/newInspections/newProjectInspectionController");
    const NewProjectInspectionConstants = require("../construction/newInspections/newProjectInspectionConstants");
    const RoutineMaintenanceConstants = require("../bmpfcs/routineMaintenanceConstants");
    const RoutineMaintenanceController = require("../bmpfcs/routineMaintenanceController");
    const OutfallInspectionConstants = require("../outfalls/outfallInspectionConstants");
    const OutfallRoutineMaintenanceConstants = require("../outfalls/outfallRoutineMaintenanceConstants");
    const OutfallRoutineMaintenanceController = require("../outfalls/routineMaintenance/outfallRoutineMaintenanceController");
    const MuniPipesFilters = require("../muni/muniPipesFilters");
    const MuniPipesGeoServerLayer = require("../muni/muniPipesGeoServerLayer");
    const projectDeliveryBmpsGeoServerLayer = require("../projectDelivery/projectDeliveryBmpsGeoServerLayer");
    const SimpleOutfallGeoServerDynamicMapLayer = require("../outfalls/simpleOutfallGeoServerDynamicMapLayer");
    const StructuralBmpsGeoServerLayer = require("../bmpFcsG2/structuralBmpsGeoServerLayer");
    const StructuralBmpFilters = require("../bmpFcsG2/structuralBmpFilters");
    const TelrResultBmpGeoServerLayer = require("../scenario/telrResultBmpGeoServerLayer");
    const TelrResultBmpFilters = require("../scenario/telrResultBmpFilters");
    const PostConstructionProjectGeoServerLayer = require("../constructionG2/postConstructionProjectGeoServerLayer");
    const PostConstructionProjectG2Filters = require("../constructionG2/postConstructionProjectG2Filters");
    const MuniCatchBasinReportGeoServerLayer = require("../report/muni/g2/muniCatchBasinReportGeoServerLayer");
    const IddeManholeGeoServerLayer = require("../muni/iddeManholeGeoServerLayer");
    const IncentiveProgramsGeoServerLayer = require("../esg/geoServer/incentiveProgramsGeoServerLayer");
    const IncentiveProgramsFilters = require("../esg/geoServer/incentiveProgramsFilters");
    const ScenarioFilter = require("../filters/scenarioFilter");
    const incidentsFilters = require("../illicitDischarge/incidents/incidentsFilters");
    const ActivitiesLayer = require("../peo/activitiesLayer");
    const ActivityFilters = require("../peo/activityFilters");
    const SamplingSiteGeoServerLayer = require("../wq/samplingSiteGeoServerLayer");
    const SamplingSiteFilters = require("../wq/samplingSiteFilters");
    const SamplingSiteInspectionController = require("../wq/inspection/samplingSiteInspectionController");
    const SamplingSiteInspectionConstants = require("../wq/inspection/samplingSiteInspectionConstants");
    const WqMonitoringLocationGeoServerLayer = require("../wq/wqMonitoringLocationGeoServerLayer");
    const WqMonitoringGeoServerLayerFilters = require("../wq/wqMonitoringGeoServerLayerFilters");

    return [
      // Warning: renaming keys will invalidate offline data.

      // See DataResource for available `options`
      {
        resource: DataResource,
        key: "fcs",
        options: {
          getAllOnline: ApiCalls.getFcss,
          filterResourceDataHandler: BmpFilter.filterResourceDataHandler,
        },
      },
      {
        resource: DataResource,
        key: "scenarios",
        options: {
          getAllOnline: ApiCalls.getUnfilteredScenarios,
          filterResourceDataHandler: ScenarioFilter.filterResourceDataHandler,
        },
      },
      // Construction
      {
        resource: DataResource,
        key: "constructionProject",
        options: {
          getAllOnline: ConstructionProjectLayer.getProjectDeliveryLayerData,
          filterResourceDataHandler: ConstructionFilters.filterResourceDataHandler,
        },
      },
      {
        resource: DataResource,
        key: "constructionProjectG2",
        options: {
          getAllOnline: (loadingScreen) =>
            ConstructionProjectGeoServerLayer.getLayerData(false, loadingScreen),
          filterResourceDataHandler: ConstructionProjectG2Filters.filterResourceDataHandler,
        },
      },
      {
        resource: DataResource,
        key: "postConstructionProjectG2",
        options: {
          getAllOnline: (loadingScreen) =>
            PostConstructionProjectGeoServerLayer.getLayerData(false, loadingScreen),
          filterResourceDataHandler: PostConstructionProjectG2Filters.filterResourceDataHandler,
        },
      },

      {
        resource: DataResource,
        key: "incidents",
        options: {
          getAllOnline: ApiCalls.getIncidents,
          filterResourceDataHandler: incidentsFilters.filterResourceDataHandler,
        },
      },

      {
        resource: DataResource,
        key: "constructionProjectInspection",
        options: {
          formLockedKey: "locked",
          getRelatedData: (data) => data.project,
          relatedResourceKey: "constructionProjectG2",
          getNewOnline: async function (id) {
            var data = await ApiCalls.getNewConstructionProjectInspection(id);
            data = ProjectInspectionConstants.getNewProjectInspectionDefaults(data);
            return ProjectInspectionConstants.organizeProjectInspectionData(data);
          },
          processNewOfflineData: async function (data) {
            return ProjectInspectionConstants.getNewProjectInspectionDefaults(data);
          },
          getExistingOnline: ApiCalls.getExistingConstructionProjectInspection,
          processExistingOnlineData: ProjectInspectionConstants.organizeProjectInspectionData,
          saveNewOnline: async function (initial, changes, syncId) {
            const data = ProjectInspectionController.getUpdateData(initial, changes, true);
            data.syncId = syncId;

            return await ApiCalls.createConstructionProjectInspection(data);
          },
          saveUpdatesOnline: async function (initial, changes, syncId) {
            const data = ProjectInspectionController.getUpdateData(initial, changes, false);
            data.syncId = syncId;

            return await ApiCalls.updateConstructionProjectInspection(data);
          },
        },
      },

      {
        resource: DataResource,
        key: "newConstructionProjectInspection",
        options: {
          formLockedKey: "locked",
          getRelatedData: (data) => data.project,
          relatedResourceKey: "constructionProjectG2",
          getNewOnline: async function (id) {
            var data = await ApiCalls.getNewConstructionProjectInspection(id);
            data = NewProjectInspectionConstants.getNewProjectInspectionDefaults(data);
            return NewProjectInspectionConstants.prepareInspectionData(data);
          },
          processNewOfflineData: async function (data) {
            data = {
              project: data,
              openFindings: data.openFindings,
            };
            data = NewProjectInspectionConstants.getNewProjectInspectionDefaults(data);

            return NewProjectInspectionConstants.prepareInspectionData(data);
          },
          getExistingOnline: ApiCalls.getExistingConstructionProjectInspection,
          processExistingOnlineData: NewProjectInspectionConstants.prepareInspectionData,
          saveNewOnline: async function (initial, changes, syncId) {
            const data = NewProjectInspectionController.getUpdateData(initial, changes, true);
            data.syncId = syncId;

            return await ApiCalls.createConstructionProjectInspection(data);
          },
          saveUpdatesOnline: async function (initial, changes, syncId) {
            const data = NewProjectInspectionController.getUpdateData(initial, changes, false);
            data.syncId = syncId;

            return await ApiCalls.updateConstructionProjectInspection(data);
          },
          postSync: NewProjectInspectionController.postUpdate,
        },
      },

      // Muni catch basin
      {
        resource: DataResource,
        key: "muniCatchBasin",
        options: {
          getAllOnline: (loadingScreen) =>
            MuniCatchBasinGeoServerLayer.getLayerData(false, loadingScreen),
          filterResourceDataHandler: MuniCatchBasinFilters.filterResourceDataHandler,
        },
      },
      {
        resource: DataResource,
        key: "muniCatchBasinReport",
        options: {
          getAllOnline: (loadingScreen) =>
            MuniCatchBasinReportGeoServerLayer.getLayerData(false, loadingScreen),
          filterResourceDataHandler: MuniCatchBasinFilters.filterResourceDataHandler,
        },
      },
      {
        resource: DataResource,
        key: "structuralBmps",
        options: {
          getAllOnline: (loadingScreen) =>
            StructuralBmpsGeoServerLayer.getLayerData(false, loadingScreen),
          filterResourceDataHandler: StructuralBmpFilters.filterResourceDataHandler,
        },
      },
      {
        resource: DataResource,
        key: "planProperties",
        options: {
          getAllOnline: (loadingScreen) =>
            PlanPropertiesGeoServerLayer.getLayerData(false, loadingScreen),
          filterResourceDataHandler: PropertiesFilters.filterResourceDataHandler,
        },
      },
      {
        resource: DataResource,
        key: "allProperties",
        options: {
          getAllOnline: (loadingScreen) =>
            AllPropertiesGeoServerLayer.getLayerData(false, loadingScreen),
          filterResourceDataHandler: PropertiesFilters.filterResourceDataHandler,
        },
      },
      {
        resource: DataResource,
        key: "muniManhole",
        options: {
          getAllOnline: (loadingScreen) =>
            MuniManholeGeoServerLayer.getLayerData(false, loadingScreen),
          filterResourceDataHandler: MuniManholeFilters.filterResourceDataHandler,
        },
      },
      {
        resource: DataResource,
        key: "iddeManhole",
        options: {
          getAllOnline: (loadingScreen) =>
            IddeManholeGeoServerLayer.getLayerData(false, loadingScreen),
          filterResourceDataHandler: MuniManholeFilters.filterResourceDataHandler,
        },
      },
      {
        resource: DataResource,
        key: "dryWeatherOutfall",
        options: {
          getAllOnline: (loadingScreen) =>
            DryWeatherOutfallGeoServerLayer.getLayerData(false, loadingScreen),
          filterResourceDataHandler: DryWeatherGeoServerLayerFilters.filterResourceDataHandler,
        },
      },
      {
        resource: DataResource,
        key: "samplingSites",
        options: {
          getAllOnline: (loadingScreen) =>
            SamplingSiteGeoServerLayer.getLayerData(false, loadingScreen),
          filterResourceDataHandler: SamplingSiteFilters.filterResourceDataHandler,
        },
      },
      {
        resource: DataResource,
        key: "wqMonitoringLocations",
        options: {
          getAllOnline: (loadingScreen) =>
            WqMonitoringLocationGeoServerLayer.getLayerData(false, loadingScreen),
          filterResourceDataHandler: WqMonitoringGeoServerLayerFilters.filterResourceDataHandler,
        },
      },
      {
        resource: DataResource,
        key: "muniCleanout",
        options: {
          getAllOnline: (loadingScreen) =>
            MuniCleanoutGeoServerLayer.getLayerData(false, loadingScreen),
          filterResourceDataHandler: MuniCleanoutFilters.filterResourceDataHandler,
        },
      },
      {
        resource: DataResource,
        key: "muniLiftStations",
        options: {
          getAllOnline: (loadingScreen) =>
            MuniLiftStationsGeoServerLayer.getLayerData(false, loadingScreen),
          filterResourceDataHandler: MuniLiftStationsFilters.filterResourceDataHandler,
        },
      },
      {
        resource: DataResource,
        key: "muniBmp",
        options: {
          getAllOnline: (loadingScreen) => MuniBmpGeoServerLayer.getLayerData(false, loadingScreen),
          filterResourceDataHandler: MuniBmpFilters.filterResourceDataHandler,
        },
      },
      {
        resource: DataResource,
        key: "lidBmp",
        options: {
          getAllOnline: (loadingScreen) => LidBmpGeoServerLayer.getLayerData(false, loadingScreen),
          filterResourceDataHandler: LidBmpFilters.filterResourceDataHandler,
        },
      },
      {
        resource: DataResource,
        key: "muniCulvert",
        options: {
          getAllOnline: (loadingScreen) =>
            MuniCulvertGeoServerLayer.getLayerData(false, loadingScreen),
          filterResourceDataHandler: MuniCulvertFilters.filterResourceDataHandler,
        },
      },
      {
        resource: DataResource,
        key: "projectDeliveryBmps",
        options: {
          getAllOnline: (loadingScreen) =>
            projectDeliveryBmpsGeoServerLayer.getLayerData(false, loadingScreen),
          filterResourceDataHandler: ProjectDeliveryFilters.filterResourceDataHandler,
        },
      },
      {
        resource: DataResource,
        key: "telrResultBmp",
        options: {
          getAllOnline: (loadingScreen) =>
            TelrResultBmpGeoServerLayer.getLayerData(false, loadingScreen),
          filterResourceDataHandler: TelrResultBmpFilters.filterResourceDataHandler,
        },
      },
      {
        resource: DataResource,
        key: "muniOpenChannel",
        options: {
          getAllOnline: (loadingScreen) =>
            MuniOpenChannelGeoServerLayer.getLayerData(false, loadingScreen),
          filterResourceDataHandler: MuniOpenChannelFilters.filterResourceDataHandler,
        },
      },
      {
        resource: DataResource,
        key: "muniPipes",
        options: {
          getAllOnline: (loadingScreen) =>
            MuniPipesGeoServerLayer.getLayerData(false, loadingScreen),
          filterResourceDataHandler: MuniPipesFilters.filterResourceDataHandler,
        },
      },
      {
        resource: DataResource,
        key: "simpleOutfallGeoServerDynamicMap",
        options: {
          getAllOnline: (loadingScreen) =>
            SimpleOutfallGeoServerDynamicMapLayer.getLayerData(false, loadingScreen),
        },
      },
      {
        resource: DataResource,
        key: "routineMaintenanceInspection",
        options: {
          getRelatedData: (data) => data.bmpFcsInfo,
          relatedResourceKey: "muniCatchBasin",
          getNewOnline: async function (id) {
            const bmpFcsInfo = await ApiCalls.getBmpFcs(id);
            const missingFields = await BmpFcsInventoryModalController.getMissingFields(bmpFcsInfo);

            return RoutineMaintenanceConstants.getNewRoutineMaintenanceData(
              bmpFcsInfo,
              missingFields ?? [],
            );
          },
          processNewOfflineData: async function (data) {
            const bmpFcsInfo = data;

            return RoutineMaintenanceConstants.getNewRoutineMaintenanceData(
              bmpFcsInfo,
              bmpFcsInfo.catchBasinInventoryIncomplete?.additionalInfo ?? [],
            );
          },
          getExistingOnline: ApiCalls.getRoutineMaintenance,
          processExistingOnlineData: function (allData) {
            return RoutineMaintenanceConstants.getExistingRoutineMaintenanceData(
              allData.routineMaintenance,
              [],
              allData.bmp,
            );
          },
          saveNewOnline: async function (initial, changes, syncId) {
            const data = RoutineMaintenanceController.getUpdateData(initial, changes, true);
            data.syncId = syncId;

            return await ApiCalls.createRoutineMaintenance(data);
          },
          saveUpdatesOnline: async function (initial, changes, syncId) {
            const data = RoutineMaintenanceController.getUpdateData(initial, changes, false);
            data.syncId = syncId;

            return await ApiCalls.updateRoutineMaintenance(data);
          },
          postSync: MainMap.reloadBmpFcsLayerWithoutLoadingScreen,
        },
      },

      // Muni outfalls
      {
        resource: DataResource,
        key: "muniOutfalls",
        options: {
          getAllOnline: (loadingScreen) =>
            MuniOutfallsGeoServerLayer.getLayerData(false, loadingScreen),
          filterResourceDataHandler: MuniOutfallsFilters.filterResourceDataHandler,
        },
      },
      {
        resource: DataResource,
        key: "outfallRoutineMaintenance",
        options: {
          getRelatedData: (data) => data.asset,
          relatedResourceKey: "muniOutfalls",
          getNewOnline: async function (id) {
            const outfallInfo = await ApiCalls.getOutfall(id);
            return OutfallInspectionConstants.getNewRoutineMaintenanceDefaults(id, outfallInfo);
          },
          getExistingOnline: ApiCalls.getOutfallRoutineMaintenance,
          processNewOfflineData: async function (data) {
            const outfallInfo = data;
            return OutfallInspectionConstants.getNewRoutineMaintenanceDefaults(
              outfallInfo.id,
              outfallInfo,
            );
          },
          processExistingOnlineData: function (allData) {
            return OutfallRoutineMaintenanceConstants.organizeRoutineMaintenanceTasks(allData);
          },
          saveNewOnline: async function (initial, changes, syncId) {
            const uploadData = OutfallRoutineMaintenanceController.getUpdateData(
              initial,
              changes,
              true,
            );
            uploadData.syncId = syncId;

            return await ApiCalls.createOutfallRoutineMaintenance(uploadData);
          },
          saveUpdatesOnline: async function (initial, changes, syncId) {
            const uploadData = OutfallRoutineMaintenanceController.getUpdateData(
              initial,
              changes,
              false,
            );
            uploadData.syncId = syncId;

            return await ApiCalls.updateOutfallRoutineMaintenance(uploadData);
          },
          postSync: OutfallFunctions.invalidateCurrentOutfallLayerWithoutLoadingScreen,
        },
      },

      {
        resource: DataResource,
        key: "samplingSiteInspection",
        options: {
          getRelatedData: (data) => data.asset,
          relatedResourceKey: "samplingSites",
          getNewOnline: async function (id) {
            const samplingSiteInfo = await ApiCalls.getSamplingSite(id);
            return SamplingSiteInspectionConstants.getNewInspectionDefaults(id, samplingSiteInfo);
          },
          getExistingOnline: ApiCalls.getSamplingSiteInspection,
          processNewOfflineData: async function (data) {
            const samplingSiteInfo = data;
            return SamplingSiteInspectionConstants.getNewInspectionDefaults(
              samplingSiteInfo.id,
              samplingSiteInfo,
            );
          },
          processExistingOnlineData: function (allData) {
            return allData;
          },
          saveNewOnline: async function (initial, changes, syncId) {
            const uploadData = SamplingSiteInspectionController.getUpdateData(
              initial,
              changes,
              true,
            );
            uploadData.syncId = syncId;

            return await ApiCalls.createSamplingSiteInspection(uploadData);
          },
          saveUpdatesOnline: async function (initial, changes, syncId) {
            const uploadData = SamplingSiteInspectionController.getUpdateData(
              initial,
              changes,
              false,
            );
            uploadData.syncId = syncId;

            return await ApiCalls.updateSamplingSiteInspection(uploadData);
          },
          postSync: SamplingSiteGeoServerLayer.invalidateLayerData,
        },
      },

      // BMP
      {
        resource: DataResource,
        key: "bmpRoutineMaintenanceInspection",
        options: {
          getRelatedData: (data) => data.bmpFcsInfo,
          getNewOnline: async function (id) {
            const bmpFcsInfo = await ApiCalls.getBmpFcs(id);
            const missingFields = await BmpFcsInventoryModalController.getMissingFields(bmpFcsInfo);

            return RoutineMaintenanceConstants.getNewRoutineMaintenanceData(
              bmpFcsInfo,
              missingFields ?? [],
            );
          },
          getExistingOnline: ApiCalls.getRoutineMaintenance,
          processExistingOnlineData: function (allData) {
            return RoutineMaintenanceConstants.getExistingRoutineMaintenanceData(
              allData.routineMaintenance,
              [],
              allData.bmp,
            );
          },
          saveNewOnline: async function (initial, changes, syncId) {
            const data = RoutineMaintenanceController.getUpdateData(initial, changes, true);
            data.syncId = syncId;

            return await ApiCalls.createRoutineMaintenance(data);
          },
          saveUpdatesOnline: async function (initial, changes, syncId) {
            const data = RoutineMaintenanceController.getUpdateData(initial, changes, false);
            data.syncId = syncId;

            return await ApiCalls.updateRoutineMaintenance(data);
          },
          postSync: MainMap.reloadBmpFcsLayerWithoutLoadingScreen,
        },
      },

      // Indcom
      {
        resource: DataResource,
        key: "indcomFacilities",
        options: {
          getAllOnline: (loadingScreen) =>
            IndcomFacilityGeoServerLayer.getLayerData(false, loadingScreen),
          filterResourceDataHandler: IndcomFacilityFilters.filterResourceDataHandler,
        },
      },
      {
        resource: DataResource,
        key: "facilityInspection",
        options: {
          formLockedKey: "locked",
          getRelatedData: (data) => data.facility,
          relatedResourceKey: "indcomFacilities",
          getNewOnline: async function (id) {
            var data = await ApiCalls.getNewFacilityInspection(id);
            data = FacilityInspectionConstants.getNewFacilityInspectionDefaults(data);
            return FacilityInspectionConstants.prepareInspectionData(data);
          },
          processNewOfflineData: async function (data) {
            data = {
              facility: data,
              openFindings: data.openFindings,
            };
            data = FacilityInspectionConstants.getNewFacilityInspectionDefaults(data);

            return FacilityInspectionConstants.prepareInspectionData(data);
          },
          getExistingOnline: ApiCalls.getExistingFacilityInspection,
          processExistingOnlineData: FacilityInspectionConstants.prepareInspectionData,
          saveNewOnline: async function (initial, changes, syncId) {
            const data = FacilityInspectionController.getUpdateData(initial, changes, true);
            data.syncId = syncId;

            return await ApiCalls.createFacilityInspection(data);
          },
          saveUpdatesOnline: async function (initial, changes, syncId) {
            const data = FacilityInspectionController.getUpdateData(initial, changes, false);
            data.syncId = syncId;

            return await ApiCalls.updateFacilityInspection(data);
          },
          postSync: IndcomFacilityGeoServerLayer.invalidateLayerData,
        },
      },
      {
        resource: DataResource,
        key: "activities",
        options: {
          getAllOnline: (loadingScreen) => ActivitiesLayer.getLayerData(false, loadingScreen),
          filterResourceDataHandler: ActivityFilters.filterResourceDataHandler,
        },
      },
      // ESG
      {
        resource: DataResource,
        key: "incentivePrograms",
        options: {
          getAllOnline: (loadingScreen) =>
            IncentiveProgramsGeoServerLayer.getLayerData(false, loadingScreen),
          filterResourceDataHandler: IncentiveProgramsFilters.filterResourceDataHandler,
        },
      },
    ];
  }

  return { getResources };
}

module.exports = ResourceConfig();
