"use strict";

const BmpFcsInventoryModalController = function () {
  const $modal = $("#inventory-modal");
  const bmpFcsInventoryFormKey = "bmp-fcs-inventory";
  let options;

  function loadFormListener() {
    unloadFormListener();
    $("#inventory-modal .modal-dynamic-content").on("2N:FormInput", onFormInput);
  }

  function unloadFormListener() {
    $("#inventory-modal .modal-dynamic-content").off("2N:FormInput");
  }

  function onFormInput(e, value, path) {
    checkEnableSaveButton(value, path);
    setModalTitle();
  }

  async function invokeBmpInventoryModal(maybeId, options = {}, startingPage) {
    if (!("mode" in options)) {
      options.mode = getMode();
    }

    InventoryModal.setOpenInventory(bmpFcsInventoryFormKey);
    if (maybeId) {
      Tree.set("fromUpdate", true);
      const data = await ApiCalls.getBmpFcs(maybeId);
      const missingFields = await getMissingFields(data);
      if (!startingPage) {
        startingPage = getStartingPageFromMissingFields(missingFields, options.mode);
      } else {
        startingPage = "bmp-fcs-set-location";
      }
      initializeBmpInventoryModalPages(data, startingPage, {
        ...options,
        existingLocation: true,
        missingFields: missingFields,
      });
    } else {
      return initializeBmpInventoryModalPages(
        BmpFcsInventoryConstants.getNewBmpFcsInventoryDefaults(options.mode),
        startingPage,
        options,
      );
    }
  }

  function getMode() {
    const tool = Tree.get("tool");
    const dataView = Tree.get("dataView");

    if (tool === "bmpram" || dataView === "muni-bmp" || dataView === "lid-bmp") {
      return "bmpram";
    } else if (tool === "trashram") {
      return "trashram";
    } else if (dataView === "muni-catch-basin") {
      return "muni";
    } else if (tool === "projdelivery" || tool === "lid" || tool === "scenario") {
      return "scenario";
    } else {
      throw new Error(`No inventory mode defined for data view: ${dataView}.`);
    }
  }

  function initializeBmpInventoryModalPages(processedData, startingPage, options = {}) {
    const pageOptions = {
      existingLocation: !!(processedData || {}).projectIdentifier,
      ...options,
    };
    $("#modalMap").hide();
    setOptions(pageOptions);
    Tree.set(["asset", bmpFcsInventoryFormKey], processedData);
    resetAllPages();
    const inputPages = getBmpInventoryPages(processedData, options);
    InventoryModal.showModal(inputPages, startingPage, pageOptions, {
      saveAndCloseHandler: checkSaveAndClose,
      cancelHandler: closeInventory,
      formHasUpdatesHandler: () => !noUpdatesInForm(),
      onPageChangeHandler: () => {
        toggleTelrRequiredFieldsByType(InventoryModal.getAllData("bmp-fcs-inventory")?.bmpType);
      },
    });
    InventoryModal.disableSaveButton();
    InventoryModal.resetModalTitle();
    setModalTitle();
    if (processedData.bmpName !== undefined) {
      InventoryModal.allowedSaveState(true);
    }
    InventoryModal.setHideInventoryOnClose(!options?.modalInModal);
    loadFormListener();
  }

  function getBmpInventoryPages(processedData, options) {
    const basePages = [
      BmpFcsInventorySetLocation,
      BmpFcsInventoryBmpFcsInformation,
      BmpFcsInventoryDrainageArea,
    ];

    const extraPageFns = getExtraPageFns(processedData)[options.mode];
    if (!extraPageFns) {
      throw new Error(`No extra page configuration defined for mode ${options.mode}.`);
    }
    for (const extraPageFn of extraPageFns) {
      const extraPage = extraPageFn();
      if (extraPage) {
        basePages.push(extraPage);
      }
    }

    return basePages;
  }

  function getExtraPageFns(processedData) {
    const additionalInfoIsFcs = function () {
      if (processedData.fcs) {
        return BmpFcsInventoryAdditionalFcsInformation;
      }
    };
    const additionalInfoIsCatchBasinBmp = function () {
      if (BmpFcsFunctions.isPriorityCatchBasinType(processedData.bmpType)) {
        return BmpFcsInventoryAdditionalCatchBasinInformation;
      } else if (processedData.bmp) {
        return BmpFcsInventoryAdditionalBmpInformation;
      }
    };

    return {
      bmpram: [additionalInfoIsFcs],
      muni: [additionalInfoIsFcs],
      trashram: [additionalInfoIsCatchBasinBmp],
      scenario: [],
      projdelivery: [],
    };
  }

  function getLatestBmpInventoryData(path) {
    path = Array.isArray(path) ? path : [path];
    return InventoryModal.getLatestData(bmpFcsInventoryFormKey, path);
  }

  function setModalTitle() {
    const bmpName = getLatestBmpInventoryData("bmpName");
    const bmpType = getLatestBmpInventoryData("bmpType");
    const assetDisplayName = BmpFcsFunctions.getAssetDisplayName(bmpType);

    if (bmpName === undefined || bmpName === "") {
      InventoryModal.setModalTitle(`Add New ${assetDisplayName}`);
    } else {
      InventoryModal.setModalTitle(`${assetDisplayName} ID: `, bmpName);
    }

    setModalTitleCatchment();
  }

  function setModalTitleCatchment() {
    const catchment = getLatestBmpInventoryData(["locationInfo", "catchmentName"]);
    if (catchment) {
      InventoryModal.setModalTitle("Catchment ID: ", catchment, "catchid");
    } else {
      InventoryModal.setModalTitle("", null, "catchid");
    }
  }

  function renderPageContent(html) {
    InventoryModal.setModalDynamicContent(html);
  }

  function resetAllPages() {
    const allPages = [
      BmpFcsInventorySetLocation,
      BmpFcsInventoryBmpFcsInformation,
      BmpFcsInventoryDrainageArea,
      BmpFcsInventoryAdditionalBmpInformation,
      BmpFcsInventoryAdditionalFcsInformation,
      BmpFcsInventoryAdditionalCatchBasinInformation,
    ];

    allPages.forEach((page) => {
      if (page.resetState) {
        page.resetState();
      }
    });
  }

  function getUniqueRequiredFields(missingFields) {
    return Array.from(
      new Set([
        ...(missingFields?.bmpRequiredFields ?? []),
        ...(missingFields?.fcsRequiredFields ?? []),
        ...(missingFields?.notTelrReadyFields ?? []),
      ]),
    );
  }

  function checkSaveAndClose() {
    const currentMethod = DrawController.getSelectedMethod();
    const existingMethod = getExistingData("drawMethod");
    if (currentMethod && existingMethod !== currentMethod) {
      return new Promise((resolve) => {
        MessageModal.showConfirmWarningModal(
          getSelectionMethodErrorMessage(currentMethod),
          () => {
            setTimeout(checkMissingFields, 2000);
          },
          "Return",
          "Confirm",
          null,
          InventoryModal.restoreAllButtons,
        );
        resolve(false);
      });
    } else {
      return new Promise((resolve) => {
        checkMissingFields();
        resolve(false);
      });
    }
  }

  function getSelectionMethodErrorMessage(currentMethod) {
    if (currentMethod === "drawing-tools") {
      return `You have redrawn this drainage area using drawing tools, this will overwrite your existing drainage area created using data layers. Click Confirm to replace your previous drainage area. Click Return and change the method to "Data Layer" to revert to your previous drainage area.`;
    } else if (currentMethod === "data-layer") {
      return `You have redrawn this drainage area using data layers, this will overwrite your existing drainage area created using drawing tools. Click Confirm to replace your previous drainage area. Click Return and change the method to "Drawing Tools" to revert to your previous drainage area.`;
    } else {
      return null;
    }
  }

  async function checkMissingFields() {
    const bmpToUpload = InventoryModal.getAllData(bmpFcsInventoryFormKey);
    const missingFields = await getMissingFields(bmpToUpload);
    if (
      (missingFields?.bmpRequiredFields ?? []).length === 0 &&
      (missingFields?.fcsRequiredFields ?? []).length === 0 &&
      (missingFields?.notTelrReadyFields ?? []).length === 0
    ) {
      return saveAndCloseModal();
    } else {
      handleMissingFieldsWarning(missingFields);
      return false;
    }
  }

  function handleMissingFieldsWarning(missingFields) {
    const missingFieldsArray = getUniqueRequiredFields(missingFields);
    const options = getOptions();
    options.missingFields = missingFields;
    setOptions(options);
    addHighlightsToFields($modal);
    SaveIncompleteModal.showConfirmSaveIncompleteModal(
      getLatestBmpInventoryData("bmpName"),
      "inventory",
      CommonModalFunctions.getInventoryFieldsByInputNames(missingFieldsArray),
      saveAndCloseModal,
      InventoryModal.restoreAllButtons,
    );
  }

  async function saveAndCloseModal() {
    try {
      const existingIdbmp = getExistingData("idbmp");
      const bmpToUpload = Form.getDataFromForm(bmpFcsInventoryFormKey);
      processBmpFormData(bmpToUpload, getExistingData([]));
      DrawController.hideDrawLayer();
      DrawController.resetDrawnItemsLayer();
      if (existingIdbmp !== undefined) {
        const data = Form.toFormData({
          idbmp: existingIdbmp,
          bmpFcsUpdates: bmpToUpload,
        });
        await ApiCalls.updateBmpFcs(data);
        closeModal();
        return false;
      } else {
        const newBmpData = $.extend(
          true,
          {},
          BmpFcsInventoryConstants.getNewBmpFcsInventoryDefaults(options.mode),
          bmpToUpload,
        );
        const data = Form.toFormData({
          bmpFcsData: newBmpData,
        });
        await ApiCalls.createBmpFcs(data);
        closeModal();
        return false;
      }
    } catch (e) {
      return UploadValidator.reportError(e);
    }
  }

  function closeModal() {
    const existingIdbmp = getExistingData("idbmp");
    const dataView = Tree.get("dataView");
    const currentTab = Tree.get("activeTab");

    closeInventory();

    if (dataView === "muni-bmp") {
      MuniBmpGeoServerLayer.invalidateLayerData();
    } else if (dataView === "lid-bmp") {
      LidBmpGeoServerLayer.invalidateLayerData();
    } else if (dataView === "scenarios") {
      ScenariosLayer.loadLayer();
    } else if (dataView === "bmp" && currentTab === "data") {
      _reloadBmpG2Layer(existingIdbmp, _openBmpPopup);
    } else {
      MainMap.reloadBmpFcsLayer();
    }

    InventoryModal.hide();
  }

  function _openBmpPopup(existingIdbmp) {
    const dataViewConfig = DataViewFunctions.getDataViewConfig(Tree.get("dataView"));
    const currentLayer = dataViewConfig.defaultLayers[0];
    MapFunctions.openPopupById(existingIdbmp, currentLayer);
  }

  function _reloadBmpG2Layer(existingIdbmp, callback) {
    const dataViewConfig = DataViewFunctions.getDataViewConfig(Tree.get("dataView"));
    const loadingScreen = true;
    LayerFunctions.getLayerModule(dataViewConfig.defaultLayers[0]).invalidateLayerData(
      loadingScreen,
    );

    if (existingIdbmp !== undefined) {
      setTimeout(function () {
        callback(existingIdbmp);
      }, 1000);
    }
  }

  function processBmpFormData(bmpToUpload, existingData) {
    processAreaSelection(bmpToUpload);
    if (bmpToUpload?.areaPolygons !== undefined) {
      cleanAreaPolygons(bmpToUpload);
    }
    processBmpTypeUnits(bmpToUpload, existingData);
    cleanUnnecessaryData(bmpToUpload);
  }

  function processAreaSelection(bmpToUpload) {
    const drawMethod = DrawController.getSelectedMethod();
    if (drawMethod === "drawing-tools" && DrawController.getDrawnItems()) {
      bmpToUpload.drawMethod = drawMethod;
      const polygons = DrawController.getDrawnDrainageGeom();
      if (polygons && polygons.length > 0) {
        bmpToUpload.drawnGeom = JSON.stringify(polygons);
      }
    }
  }

  function processBmpTypeUnits(bmpToUpload, existingData) {
    const conversionFields = {
      treatmentCapacity: "cubicFeet",
      footprint: "squareFeet",
      designDepth: "feet",
      diameter: "feet",
      wetPoolCapacity: "cubicFeet",
      totalDepth: "feet",
      length: "feet",
      width: "feet",
      rimElevation: "feet",
      baseElevation: "feet",
      invertElevation: "feet",
    };

    for (const [fieldToConvert, targetUnit] of Object.entries(conversionFields)) {
      const selectedUnits = bmpToUpload?.[`${fieldToConvert}Unit`];
      const currentValue = bmpToUpload?.[fieldToConvert] ?? existingData?.[fieldToConvert];
      if (selectedUnits && currentValue) {
        bmpToUpload[fieldToConvert] = UnitConversion.convertVal(
          currentValue,
          selectedUnits,
          targetUnit,
        );
      }
    }
  }

  function cleanUnnecessaryData(bmpToUpload) {
    ["centralizedBmps", "possibleAssociatedProjects", "locationInfo"].forEach(function (property) {
      if (bmpToUpload?.hasOwnProperty(property)) {
        delete bmpToUpload[property];
      }
    });
  }

  function cleanAreaPolygons(bmpToUpload) {
    const reducedAreaPolygons = bmpToUpload.areaPolygons.map((polygon) => {
      return { id: polygon.polygon_id, percent: polygon.percent, catchid: polygon.catch_name };
    });
    bmpToUpload.areaPolygons = JSON.stringify(reducedAreaPolygons);
  }

  function closeInventory() {
    unloadFormListener();

    Form.clearForm(bmpFcsInventoryFormKey);

    DrawController.hideDrawLayer();
    DrawController.resetDrawnItemsLayer();
  }

  function noUpdatesInForm() {
    return Object.keys(Form.getDataFromForm(bmpFcsInventoryFormKey, false)).length === 0;
  }

  function checkEnableSaveButton(value, path) {
    const existingValue = getExistingData(path);
    if (existingValue !== undefined && value === existingValue) {
      Form.manuallyUnsetField(bmpFcsInventoryFormKey, path);
    }
    if (noUpdatesInForm()) {
      InventoryModal.readySaveState(false);
    } else {
      InventoryModal.readySaveState(true);
    }
  }

  function getExistingData(path) {
    const pathToCheck = $.isArray(path) ? path : [path];
    return Tree.get(["asset", bmpFcsInventoryFormKey, ...pathToCheck]);
  }

  function setFormDataField(path, value) {
    path = Array.isArray(path) ? path : [path];
    Form.manuallySetFormDataField(bmpFcsInventoryFormKey, path, value);
  }

  function unsetFormDataField(path) {
    path = Array.isArray(path) ? path : [path];
    Form.manuallyUnsetField(bmpFcsInventoryFormKey, path);
    return Form.getDataFromForm(bmpFcsInventoryFormKey, false);
  }

  function getChangedFormData(path) {
    path = Array.isArray(path) ? path : [path];
    return Form.getFormDataAtPath(bmpFcsInventoryFormKey, path);
  }

  function getBmpFcsMarker(zoom) {
    const bmpFcsData = InventoryModal.getAllData(bmpFcsInventoryFormKey);
    if (bmpFcsData.idbmp) {
      return BmpFcsIcon.getIconMarker(
        bmpFcsData.score || null,
        BmpFcsFunctions.isCentralizedBMPType(bmpFcsData.bmpType),
        BmpFcsFunctions.getBmpTypeAbbr(bmpFcsData.bmpType),
        bmpFcsData.fcs,
        bmpFcsData.fcs ? bmpFcsData.fcsAssessmentDue : bmpFcsData.bmpAssessmentDue,
        bmpFcsData.phase,
        null,
        null,
        zoom,
      );
    } else {
      return BmpFcsIcon.getUnsavedIconMarker(zoom);
    }
  }

  function getCatchBasinMarker(zoom) {
    const catchBasinData = InventoryModal.getAllData(bmpFcsInventoryFormKey);
    if (catchBasinData.idbmp) {
      return MuniCatchBasinIcon.getIconMarker(
        catchBasinData.score,
        catchBasinData.routineMaintenanceDue,
        catchBasinData.isCatchBasinPriority,
        20,
      );
    } else {
      return MuniCatchBasinIcon.getUnsavedIconMarker(zoom);
    }
  }

  function loadCentralizedBmpMarker() {
    const bmpType = getLatestBmpInventoryData("bmpType");
    const latlng = getBmpLatLng();
    const abbreviation = FormConstants.bmpTypeAbbrs[bmpType];
    const activeMarker = L.marker(latlng, {
      icon: BmpFcsIcon.getIconMarker(null, true, abbreviation, null, null, null, "blue"),
    });
    ModalMap.setSingleMarker(activeMarker);
  }

  function loadExistingBmpFcsCatchBasinMarker(zoom) {
    const latlng = getBmpLatLng();
    const dataView = Tree.get("dataView");
    const marker = new L.marker(latlng, {
      icon: dataView === "muni-catch-basin" ? getCatchBasinMarker(zoom) : getBmpFcsMarker(zoom),
      zIndexOffset: 2000,
    });
    ModalMap.setSingleMarker(marker);
  }

  function getBmpLatLng() {
    return {
      lat: getLatestBmpInventoryData("latitude"),
      lng: getLatestBmpInventoryData("longitude"),
    };
  }

  function toggleAdditionalPage(page, toggle) {
    if (page === "fcs") {
      toggle
        ? InventoryModal.insertInventoryStep(4, BmpFcsInventoryAdditionalFcsInformation)
        : InventoryModal.removeInventoryStep(BmpFcsInventoryAdditionalFcsInformation);
    } else if (page === "bmp") {
      toggle
        ? InventoryModal.insertInventoryStep(4, BmpFcsInventoryAdditionalBmpInformation)
        : InventoryModal.removeInventoryStep(BmpFcsInventoryAdditionalBmpInformation);
      InventoryModal.removeInventoryStep(BmpFcsInventoryAdditionalCatchBasinInformation);
    } else if (page === "catch-basin") {
      toggle
        ? InventoryModal.insertInventoryStep(4, BmpFcsInventoryAdditionalCatchBasinInformation)
        : InventoryModal.removeInventoryStep(BmpFcsInventoryAdditionalCatchBasinInformation);
      InventoryModal.removeInventoryStep(BmpFcsInventoryAdditionalBmpInformation);
    }
  }

  function setOptions(newOptions) {
    options = newOptions;
  }

  function getOptions() {
    return options || {};
  }

  function initializeDropzones(readOnly) {
    const dropzones = ["additional", "summary", "design", "plan"];
    dropzones.forEach(function (dropzoneName) {
      const dropzoneElement = $(`[data-field=${dropzoneName}]`);
      if (dropzoneElement.length > 0) {
        const existingFiles = getExistingFiles(dropzoneName);
        const newFiles = getNewFiles(dropzoneName);
        const dropzone = Form.initializeDropzone(
          bmpFcsInventoryFormKey,
          $(`[data-field=${dropzoneName}]`),
          {
            newFiles,
            existingFiles,
            maxNumberFiles: dropzoneName === "additional" ? 9 : 1,
            readOnly,
          },
        );

        dropzone.setDownloadHandler((filename) => {
          const idbmp = getLatestBmpInventoryData("idbmp");
          ApiCalls.downloadTrashAttachment(idbmp, filename, "fcs", dropzoneName);
        });
      }
    });
  }

  function getExistingFiles(dropzone) {
    const files = getExistingData(dropzone) ?? [];
    const removedFiles = getLatestBmpInventoryData(["trashedFiles", dropzone]) ?? [];
    return files.filter((file) => !removedFiles.includes(file));
  }

  function getNewFiles(dropzone) {
    return Form.getFormDataAtPath(bmpFcsInventoryFormKey, [dropzone]);
  }

  function toggleTreatsStormFields() {
    const treatsStorm = getLatestBmpInventoryData("treatsStorm");
    if (treatsStorm !== null) {
      $modal.find("[data-field=storm-inches]").toggleClass("hidden", !treatsStorm);
      $modal.find("[data-field=partial-storm-inches]").toggleClass("hidden", treatsStorm);
    }
  }

  function toggleMultiSystemFields() {
    const multiSystem = getLatestBmpInventoryData("multiSystem");
    if (multiSystem !== null) {
      $modal.find(".fcs-grouping").toggleClass("hidden", false);
      $modal.find(".fcs-mbs-section").toggleClass("hidden", !multiSystem);
      $modal.find(".fcs-tdd-section").toggleClass("hidden", multiSystem);
    }
  }

  function getOtherCentralizedBmps() {
    const idbmp = getLatestBmpInventoryData("idbmp");
    const otherCentralizedBmps = getLatestBmpInventoryData("centralizedBmps") ?? [];
    return otherCentralizedBmps.filter((bmp) => bmp.idbmp !== idbmp);
  }

  function getMissingFields(data) {
    const tool = Tree.get("tool");
    if (tool === "scenario" || tool === "projdelivery") {
      const fieldsNeededForTelrReady = getFieldsNeededForTelrReady(data);
      return ApiCalls.getTelrReadyMissingFields(data.idbmp, fieldsNeededForTelrReady);
    }
    const fieldsReadyToInventory = getFieldsReadyToInventory(data);
    const fieldsAffectingRequiredFieldSet = getFieldsAffectingRequiredFieldSet(data);

    return ApiCalls.getBmpFcsInventoryRemaining(
      data.idbmp,
      !data.idbmp,
      data.bmp,
      data.fcs,
      fieldsReadyToInventory,
      fieldsAffectingRequiredFieldSet,
    );
  }

  function getStartingPageFromMissingFields(missingFields, tool = "bmpram") {
    if (tool === "scenario" || tool === "projdelivery") {
      return "bmp-fcs-information";
    }

    const drainageFields = ["imperviousArea", "drainageSelection", "treatmentTrain", "flowLength"];
    const isDefaultBmp = ["bmpram", "muni"].includes(tool) && missingFields.bmpRequiredFields;

    const primaryMissingFields = isDefaultBmp
      ? missingFields?.bmpRequiredFields
      : missingFields?.fcsRequiredFields;
    const secondaryMissingFields = isDefaultBmp
      ? missingFields?.fcsRequiredFields
      : missingFields?.bmpRequiredFields;
    const secondaryInventoryCompleted = isDefaultBmp
      ? missingFields.fcsInventoryCompleted
      : missingFields.bmpInventoryCompleted;

    let pageToLoad = "bmp-fcs-set-location";

    if (primaryMissingFields?.includes("installationDate")) {
      pageToLoad = "bmp-fcs-set-location";
    } else if (primaryMissingFields?.length > 0) {
      const filteredRequiredFields = primaryMissingFields.filter(
        (missingField) => !drainageFields.includes(missingField),
      ).length;
      pageToLoad = filteredRequiredFields > 0 ? "bmp-fcs-information" : "bmp-fcs-drainage-area";
    } else if (secondaryInventoryCompleted === false && secondaryMissingFields.length > 0) {
      pageToLoad = isDefaultBmp ? "additional-fcs-information" : "additional-bmp-information";
    }

    return pageToLoad;
  }

  function getFieldsNeededForTelrReady(data) {
    const fields = {
      bmp: data.bmp,
      bmpType: data.bmpType,
      flowLength: data.flowLength,
      lowFlow: data.lowFlow,
      imperviousArea: data.imperviousArea,
      treatmentCapacity: data.treatmentCapacity,
      footprint: data.footprint,
      treatmentRate: data.treatmentRate,
      drawdownTime: data.drawdownTime,
      intakeRate: data.intakeRate,
      wetPoolCapacity: data.wetPoolCapacity,
      infiltrationRate: data.infiltrationRate,
    };
    processAreaSelection(fields);
    return fields;
  }

  function getFieldsReadyToInventory(data) {
    const fileFields = ["additional", "summary", "plan", "design"];
    const drainageFields = ["drawMethod", "drainageArea", "pluTreated", "drawAcres"];
    const drawMethod = getLatestBmpInventoryData("drawMethod");
    const drawingToolsLength =
      drawMethod === "drawing-tools" && DrawController.getDrawnItems()?.getLayers()?.length > 0;
    const dataLayerLength = drawMethod === "data-layer" && data.areaPolygons?.length > 0;

    const fieldsReady = Object.entries(data)
      .filter(([field, value]) => {
        if (fileFields.includes(field)) {
          return value?.length > 0;
        }
        if (drainageFields.includes(field)) {
          return false;
        }
        if (field === "bmpType") {
          return value !== 16;
        }
        return value !== null && value !== "";
      })
      .map(([field, value]) => field);

    if (drawingToolsLength || dataLayerLength) {
      fieldsReady.push("drainageSelection");
    }

    return fieldsReady;
  }

  function getFieldsAffectingRequiredFieldSet(data) {
    return {
      bmpType: data.bmpType,
      systemAccess: data.systemAccess,
      visibleScreen: data.visibleScreen,
      isMultiBenefitSystem: data.multiSystem,
      phase: data.phase,
      treatedStormType: data.treatedStormType,
      shape: data.shape,
    };
  }

  function addHighlightsToFields($page) {
    const options = getOptions();
    const requiredFields = options?.missingFields ?? [];
    const missingFieldsArray = getUniqueRequiredFields(requiredFields);
    const fieldData = getLatestBmpInventoryData([]);
    const fieldValuesToHighlight = getFieldValuesToHighlight();

    CommonModalFunctions.highlightEmptyFormFields(
      $page,
      missingFieldsArray,
      fieldData,
      fieldValuesToHighlight,
    );
  }

  function getFieldValuesToHighlight() {
    const emptyOrZero = [0, "", null, undefined];
    if (Tree.get("tool") === "scenario") {
      return {
        bmpType: ["16", "18"],
        flowLength: emptyOrZero,
        drainage: emptyOrZero,
        lowFlow: emptyOrZero,
        imperviousArea: emptyOrZero,
        treatmentCapacity: emptyOrZero,
        footprint: emptyOrZero,
        treatmentRate: emptyOrZero,
        drawdownTime: emptyOrZero,
        intakeRate: emptyOrZero,
        wetPoolCapacity: emptyOrZero,
      };
    } else {
      return {
        bmpType: ["16"],
      };
    }
  }

  function hasBeenSaved() {
    return getExistingData("id") !== undefined;
  }

  var toggleTelrRequiredFieldsByType = function (type, removeHighlights = false) {
    if (options?.mode !== "scenario") return;

    $(".telr-required").not(".required-hint .required").removeClass("telr-required");
    if (removeHighlights) {
      $(".highlight-field").removeClass("highlight-field");
    }
    BmpFcsInventoryConstants.getTelrRequiredFieldsByType(type).forEach((field) => {
      toggleTelrRequired(field, true);
    });
    const isCentralized = BmpFcsFunctions.isCentralizedBMPType(type);
    if (isCentralized) {
      BmpFcsInventoryConstants.cBmpTelrRequiredFields.forEach((field) => {
        toggleTelrRequired(field, true);
      });
    } else {
      toggleTelrRequired("impervious-area", true);
    }
  };

  var toggleTelrRequired = function (fieldName, required) {
    const $field = $(`[name=${fieldName}]`);
    $field.closest(".fieldset, fieldset").find("legend").toggleClass("telr-required", required);
  };

  return {
    invokeBmpInventoryModal,
    getLatestBmpInventoryData,
    loadCentralizedBmpMarker,
    loadExistingBmpFcsCatchBasinMarker,
    setModalTitle,
    renderPageContent,
    setFormDataField,
    unsetFormDataField,
    getChangedFormData,
    setOptions,
    getOptions,
    toggleAdditionalPage,
    initializeDropzones,
    toggleTreatsStormFields,
    toggleMultiSystemFields,
    getBmpInventoryPages,
    getOtherCentralizedBmps,
    processBmpTypeUnits,
    addHighlightsToFields,
    getStartingPageFromMissingFields,
    checkSaveAndClose,
    closeModal,
    processBmpFormData,
    getExistingData,
    checkMissingFields,
    handleMissingFieldsWarning,
    hasBeenSaved,
    getMissingFields,
    getUniqueRequiredFields,
    toggleTelrRequiredFieldsByType,
    getCatchBasinMarker,
  };
};

module.exports = BmpFcsInventoryModalController();

const ApiCalls = require("../apiCalls");
const BmpFcsFunctions = require("./bmpFcsFunctions");
const BmpFcsIcon = require("../bmpfcs/bmpFcsIcon");
const BmpFcsInventoryAdditionalBmpInformation = require("./bmpFcsInventoryAdditionalBmpInformation");
const BmpFcsInventoryAdditionalCatchBasinInformation = require("./bmpFcsInventoryAdditionalCatchBasinInformation");
const BmpFcsInventoryAdditionalFcsInformation = require("./bmpFcsInventoryAdditionalFcsInformation");
const BmpFcsInventoryBmpFcsInformation = require("./bmpFcsInventoryBmpFcsInformation");
const BmpFcsInventoryConstants = require("./bmpFcsInventoryConstants");
const BmpFcsInventoryDrainageArea = require("./bmpFcsInventoryDrainageArea");
const BmpFcsInventorySetLocation = require("./bmpFcsInventorySetLocation");
const CommonModalFunctions = require("../modals/commonModalFunctions");
const DrawController = require("../mapFunctions/drawController");
const Form = require("../general/form");
const FormConstants = require("../mapFunctions/formConstants");
const InventoryModal = require("../general/inventoryModal");
const MainMap = require("../mapFunctions/mainMap");
const MessageModal = require("../modals/messageModal");
const MuniCatchBasinIcon = require("../muni/muniCatchBasinIcon");
const SaveIncompleteModal = require("../modals/saveIncompleteModal");
const ModalMap = require("../mapFunctions/modalMap");
const Tree = require("../tree");
const UnitConversion = require("../unitConversion");
const UploadValidator = require("../general/uploadValidator");
const MuniBmpGeoServerLayer = require("../muni/bmp/muniBmpGeoServerLayer");
const ScenariosLayer = require("../scenario/scenariosLayer");
const MapFunctions = require("../mapFunctions/mapFunctions");
const DataViewFunctions = require("../dataViewFunctions");
const LayerFunctions = require("../layerFunctions");
const LidBmpGeoServerLayer = require("../lid/lidBmpGeoServerLayer");
