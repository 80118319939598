"use strict";

const FactSheetNew = require("../general/factSheetNew");
const ProjectFactSheetController = require("./projectFactSheetController");

const ProjectDeliveryFactSheetController = function () {
  var getConfig = function () {
    return {
      ...ProjectFactSheetController.getConfig(),
      routeName: "construction-project-delivery-fact-sheet",
      template: "factSheet/construction/constructionProjectDeliveryFactSheet.njk",
      closeUrl: "/ram/projdelivery/construction-project-delivery",
    };
  };

  var open = async function (id, options = {}) {
    FactSheetNew.open(id, getConfig(), null, options);
  };

  var reload = function (data) {
    ProjectFactSheetController.reload(data);
  };

  return {
    open,
    routeName: getConfig().routeName,
    reload,
  };
};

module.exports = ProjectDeliveryFactSheetController();
