"use strict";

const ApiDocs = function () {
  var initialize = function () {
    const token = TokenManager.loadToken();
    const url = Network.getApiUrl("/v1/docs");
    SwaggerUI({
      dom_id: "#swagger-ui",
      url: url,
      requestInterceptor: function (request) {
        if (!request.headers.Authorization && token) {
          request.headers["Authorization"] = "Bearer " + token;
        }
        return request;
      },
    });
  };

  return {
    initialize,
  };
};

module.exports = ApiDocs();

const SwaggerUI = require("swagger-ui");
const Network = require("./network");
const TokenManager = require("./login/tokenManager");
