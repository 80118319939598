"use strict";

const UserVariableValidator = function () {
  const init = function (variableNamesArray, selector = ".validate-variables") {
    const $inputElements = $(selector);
    if (!$inputElements.length || !variableNamesArray.length) {
      return;
    }

    $inputElements.highlightWithinTextarea({
      highlight: [
        {
          highlight: _getInvalidVariableRegex(variableNamesArray),
          className: "invalid-variable",
        },
        {
          highlight: _getValidVariableRegex(variableNamesArray),
          className: "valid-variable",
        },
      ],
    });
  };

  const anyErrors = function () {
    const $invalidVariables = $(".invalid-variable");
    return $invalidVariables.length > 0;
  };

  const _getInvalidVariableRegex = function (validKeys) {
    return new RegExp(`\\{\\{\\s*(?!\\s*\\$(?:${validKeys.join("|")})\\s*\\}\\}).*?\\}\\}`, "g");
  };

  const _getValidVariableRegex = function (validKeys) {
    return new RegExp(`\\{\\{\\s*\\$(?:${validKeys.join("|")})\\s*\\}\\}`, "g");
  };

  return {
    init,
    anyErrors,
    _getInvalidVariableRegex,
    _getValidVariableRegex,
  };
};

module.exports = UserVariableValidator();
