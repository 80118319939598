"use strict";
const SamplingSiteInventoryConstants = function () {
  var getStatesOptions = function () {
    return FormSettings.getDropdownOptions("states");
  };

  var getSitePhaseOptions = function () {
    return ToolSettings.getDomainOptions("sampling_site_phase");
  };

  var getSiteTypeOptions = function () {
    return ToolSettings.getDomainOptions("sampling_site_type");
  };

  const uploadLocationOptions = [
    {
      name: "File Upload",
      value: "upload",
    },
    {
      name: "File Location",
      value: "location",
    },
    {
      name: "File Link",
      value: "external-url",
    },
  ];

  const fileTypeOptions = [
    {
      displayName: "Calibration Log",
      name: "Calibration Log",
      value: "calibration-log",
    },
    {
      displayName: "Chain of Custody",
      name: "Chain of Custody",
      value: "chain-of-custody",
    },
    {
      displayName: "Event Report",
      name: "Event Report",
      value: "event-report",
    },
    {
      displayName: "Lab Results",
      name: "Lab Results",
      value: "lab-results",
    },
    {
      displayName: "Monitoring Plan",
      name: "Monitoring Plan",
      value: "monitoring-plan",
    },
    {
      displayName: "Sampling Protocol",
      name: "Sampling Protocol",
      value: "sampling-protocol",
    },
    {
      displayName: "Technical Report",
      name: "Technical Report",
      value: "technical-report",
    },
    {
      displayName: "Other",
      name: "Other",
      value: "other",
    },
  ];

  var getFileTypeOptions = function () {
    return fileTypeOptions;
  };

  var getSamplingSiteInventoryDefaults = function () {
    const defaults = {
      sitePhase: "active",
      wqMonitoringLocation: true,
      dryWeatherMonitoringLocation: null,
      samplingSiteFiles: [],
    };

    return defaults;
  };

  return {
    getSitePhaseOptions,
    getSiteTypeOptions,
    getSamplingSiteInventoryDefaults,
    getStatesOptions,
    getFileTypeOptions,
    uploadLocationOptions,
  };
};
module.exports = SamplingSiteInventoryConstants();

const FormSettings = require("../settings/formSettings");
const ToolSettings = require("../settings/toolSettings");
