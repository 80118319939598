"use strict";

const EswftSyncStatusModal = function () {
  var initialized = false;
  var $modal;

  const init = function () {
    if (initialized) {
      return;
    }
    $modal = $("#eswft-sync-status-modal");
    initialized = true;
  };

  const showEswftSyncStatusModal = async function () {
    init();
    const syncStatus = await getSyncStatus();

    $modal.html(nunjucks.render("modals/settingsModal/eswftSyncStatusModal.njk", { syncStatus }));
    $modal.modal("show");
  };

  const getSyncStatus = async function () {
    return await ApiCalls.getAllEswftSyncStatus();
  };

  return {
    showEswftSyncStatusModal,
  };
};

module.exports = EswftSyncStatusModal();

const ApiCalls = require("../apiCalls");
