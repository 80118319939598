import CallbackElement from "../elements/Callback.mjs";
import ComponentsElement from "../elements/Components.mjs";
import ContactElement from "../elements/Contact.mjs";
import DiscriminatorElement from "../elements/Discriminator.mjs";
import EncodingElement from "../elements/Encoding.mjs";
import ExampleElement from "../elements/Example.mjs";
import ExternalDocumentationElement from "../elements/ExternalDocumentation.mjs";
import HeaderElement from "../elements/Header.mjs";
import InfoElement from "../elements/Info.mjs";
import LicenseElement from "../elements/License.mjs";
import LinkElement from "../elements/Link.mjs";
import MediaTypeElement from "../elements/MediaType.mjs";
import OAuthFlowElement from "../elements/OAuthFlow.mjs";
import OAuthFlowsElement from "../elements/OAuthFlows.mjs";
import OpenapiElement from "../elements/Openapi.mjs";
import OpenApi3_0Element from "../elements/OpenApi3-0.mjs";
import OperationElement from "../elements/Operation.mjs";
import ParameterElement from "../elements/Parameter.mjs";
import PathItemElement from "../elements/PathItem.mjs";
import PathsElement from "../elements/Paths.mjs";
import ReferenceElement from "../elements/Reference.mjs";
import RequestBodyElement from "../elements/RequestBody.mjs";
import ResponseElement from "../elements/Response.mjs";
import ResponsesElement from "../elements/Responses.mjs";
import SchemaElement from "../elements/Schema.mjs";
import SecurityRequirementElement from "../elements/SecurityRequirement.mjs";
import SecuritySchemeElement from "../elements/SecurityScheme.mjs";
import ServerElement from "../elements/Server.mjs";
import ServerVariableElement from "../elements/ServerVariable.mjs";
import TagElement from "../elements/Tag.mjs";
import XmlElement from "../elements/Xml.mjs";
import { createRefractor } from "./index.mjs"; // register refractors specific to element types
CallbackElement.refract = createRefractor(['visitors', 'document', 'objects', 'Callback', '$visitor']);
ComponentsElement.refract = createRefractor(['visitors', 'document', 'objects', 'Components', '$visitor']);
ContactElement.refract = createRefractor(['visitors', 'document', 'objects', 'Contact', '$visitor']);
ExampleElement.refract = createRefractor(['visitors', 'document', 'objects', 'Example', '$visitor']);
DiscriminatorElement.refract = createRefractor(['visitors', 'document', 'objects', 'Discriminator', '$visitor']);
EncodingElement.refract = createRefractor(['visitors', 'document', 'objects', 'Encoding', '$visitor']);
ExternalDocumentationElement.refract = createRefractor(['visitors', 'document', 'objects', 'ExternalDocumentation', '$visitor']);
HeaderElement.refract = createRefractor(['visitors', 'document', 'objects', 'Header', '$visitor']);
InfoElement.refract = createRefractor(['visitors', 'document', 'objects', 'Info', '$visitor']);
LicenseElement.refract = createRefractor(['visitors', 'document', 'objects', 'License', '$visitor']);
LinkElement.refract = createRefractor(['visitors', 'document', 'objects', 'Link', '$visitor']);
MediaTypeElement.refract = createRefractor(['visitors', 'document', 'objects', 'MediaType', '$visitor']);
OAuthFlowElement.refract = createRefractor(['visitors', 'document', 'objects', 'OAuthFlow', '$visitor']);
OAuthFlowsElement.refract = createRefractor(['visitors', 'document', 'objects', 'OAuthFlows', '$visitor']);
OpenapiElement.refract = createRefractor(['visitors', 'document', 'objects', 'OpenApi', 'fixedFields', 'openapi']);
OpenApi3_0Element.refract = createRefractor(['visitors', 'document', 'objects', 'OpenApi', '$visitor']);
OperationElement.refract = createRefractor(['visitors', 'document', 'objects', 'Operation', '$visitor']);
ParameterElement.refract = createRefractor(['visitors', 'document', 'objects', 'Parameter', '$visitor']);
PathItemElement.refract = createRefractor(['visitors', 'document', 'objects', 'PathItem', '$visitor']);
PathsElement.refract = createRefractor(['visitors', 'document', 'objects', 'Paths', '$visitor']);
ReferenceElement.refract = createRefractor(['visitors', 'document', 'objects', 'Reference', '$visitor']);
RequestBodyElement.refract = createRefractor(['visitors', 'document', 'objects', 'RequestBody', '$visitor']);
ResponseElement.refract = createRefractor(['visitors', 'document', 'objects', 'Response', '$visitor']);
ResponsesElement.refract = createRefractor(['visitors', 'document', 'objects', 'Responses', '$visitor']);
SchemaElement.refract = createRefractor(['visitors', 'document', 'objects', 'Schema', '$visitor']);
SecurityRequirementElement.refract = createRefractor(['visitors', 'document', 'objects', 'SecurityRequirement', '$visitor']);
SecuritySchemeElement.refract = createRefractor(['visitors', 'document', 'objects', 'SecurityScheme', '$visitor']);
ServerElement.refract = createRefractor(['visitors', 'document', 'objects', 'Server', '$visitor']);
ServerVariableElement.refract = createRefractor(['visitors', 'document', 'objects', 'ServerVariable', '$visitor']);
TagElement.refract = createRefractor(['visitors', 'document', 'objects', 'Tag', '$visitor']);
XmlElement.refract = createRefractor(['visitors', 'document', 'objects', 'XML', '$visitor']);
export { CallbackElement, ComponentsElement, ContactElement, DiscriminatorElement, EncodingElement, ExampleElement, ExternalDocumentationElement, HeaderElement, InfoElement, LicenseElement, LinkElement, MediaTypeElement, OAuthFlowElement, OAuthFlowsElement, OpenapiElement, OpenApi3_0Element, OperationElement, ParameterElement, PathItemElement, PathsElement, ReferenceElement, RequestBodyElement, ResponseElement, ResponsesElement, SchemaElement, SecurityRequirementElement, SecuritySchemeElement, ServerElement, ServerVariableElement, TagElement, XmlElement };