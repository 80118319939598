"use strict";

const SamplingSiteInventoryMonitoringScreening = function () {
  const stringKey = "sampling-site-monitoring-screening";
  const headerInformation = "Monitoring & Screening";
  let $page;

  var getStringKey = function () {
    return stringKey;
  };

  var getHeaderInformation = function () {
    return headerInformation;
  };

  var render = async function () {
    await renderPageHtml();
  };

  var loadListeners = function () {
    $page.on("change", `[name="site-phase"]`, onSitePhaseChange);

    Form.initializeAndLoadListeners(
      $page,
      SamplingSiteInventoryModalController.getSamplingSiteInventoryFormKey(),
      { isMultiPart: true },
    );
  };

  var removeListeners = function () {
    $page.off("change", `[name="site-phase"]`, onSitePhaseChange);
  };

  var onSitePhaseChange = function (e) {
    const sitePhase =
      SamplingSiteInventoryModalController.getLatestSamplingSiteInventoryData("sitePhase");
    const inactiveDateRequiredToggle = sitePhase === "inactive";
    $page.find(".inactive-date legend").toggleClass("required", inactiveDateRequiredToggle);
  };

  var renderPageHtml = function () {
    const renderData = SamplingSiteInventoryModalController.getInventoryForRender();
    const additionalProps = getAdditionalProps();
    const html = nunjucks.render(
      "modals/samplingSiteInventory/samplingSiteInventoryMonitoringScreening.njk",
      {
        ...renderData,
        ...additionalProps,
      },
    );
    $page = $(".modal-dynamic-content");
    SamplingSiteInventoryModalController.renderPageContent(html);
    loadListeners();
  };

  var getAdditionalProps = function () {
    return {
      sitePhaseOptions: SamplingSiteInventoryConstants.getSitePhaseOptions(),
    };
  };

  var cleanUp = function () {
    removeListeners();
    $page.empty();
    return true;
  };

  var validate = function () {
    const sitePhase =
      SamplingSiteInventoryModalController.getLatestSamplingSiteInventoryData("sitePhase");
    const inactiveDate =
      SamplingSiteInventoryModalController.getLatestSamplingSiteInventoryData("inactiveDate");

    if (sitePhase === "inactive" && !inactiveDate) {
      MessageModal.showSimpleWarningModal("<p>Please enter a valid inactive date.</p>", true);
      return false;
    }

    return true;
  };

  return {
    getStringKey,
    getHeaderInformation,
    render,
    renderPageHtml,
    cleanUp,
    validate,
  };
};

module.exports = SamplingSiteInventoryMonitoringScreening();

const SamplingSiteInventoryConstants = require("./samplingSiteInventoryConstants");
const SamplingSiteInventoryModalController = require("./samplingSiteInventoryModalController");
const Form = require("../general/form");
const MessageModal = require("../modals/messageModal");
