"use strict";

const DryWeatherResult = function () {
  var getDryWeatherResultData = function (assetName, data) {
    const conditionResultMap = {
      none: {
        messageColor: "green",
        message: `No illicit discharge was found at ${assetName}.`,
      },
      open: {
        messageColor: "red",
        message: `Illicit discharge was found at ${assetName}, and the investigation is open.`,
      },
      closed: {
        messageColor: "grey",
        message: `Illicit discharge was found at ${assetName}. The investigation has been closed because "${
          DryWeatherConstants.getCloseOutReasonOptions().find(
            (option) => option.value === data.closeOutReason,
          )?.name
        }".`,
      },
      "no-access": {
        messageColor: "grey",
        message: `${assetName} could not be accessed for inspection due to "${
          DryWeatherConstants.getAccessIssueOptions().find(
            (option) => option.value === data.accessIssue,
          )?.name
        }".`,
      },
      null: {},
    };

    let condition = null;
    if (
      FeatureFlag.enabled("sampling-site-inspection") &&
      WqMonitoringConstants.isWqMonitoringWorkflowAssetType(data?.assetType)
    ) {
      condition = getDryWeatherConditionWqMonitoringWorkflow(data);
    } else {
      condition = getDryWeatherCondition(data);
    }

    return {
      issuesMessages: [],
      resultKey: condition,
      ...conditionResultMap[condition],
    };
  };

  var getDryWeatherCondition = function (data) {
    const assetAccessible = MaintenanceResult.updateAssetAccessible(
      data.activeMaintenanceIssues ?? [],
      data.assetAccessible,
    );

    if (data.closeOutInvestigation) {
      return "closed";
    } else if (!assetAccessible) {
      return "no-access";
    } else if (data.illicitDischarge) {
      return "open";
    }

    return "none";
  };

  var getDryWeatherConditionWqMonitoringWorkflow = function (data) {
    if (data.closeOutInvestigation) {
      return "closed";
    } else if (data.illicitDischarge) {
      return "open";
    }

    return "none";
  };

  return {
    getDryWeatherResultData,
  };
};

module.exports = DryWeatherResult();

const DryWeatherConstants = require("../../illicitDischarge/dryWeatherScreening/dryWeatherConstants");
const MaintenanceResult = require("./maintenanceResult");
const WqMonitoringConstants = require("../../wq/inspection/wqMonitoringConstants");
const FeatureFlag = require("../../settings/featureFlag");
