"use strict";

const ProjectFactSheetController = require("../construction/projectFactSheetController");

const LidProjectFactSheetController = function () {
  var getConfig = function () {
    const LidIcon = require("./lidIcon");
    return {
      ...ProjectFactSheetController.getConfig(),
      routeName: "lid-fact-sheet",
      template: "factSheet/construction/lidProjectFactSheet.njk",
      dataSort: "lidProjectId",
      iconFunction: LidIcon.getLidProjectMapIcon,
      closeUrl: "/ram/lid/lid-project",
      getDataFunction: getData,
      onCloseFunction: onClose,
      afterOpenFunction: afterOpen,
      tableTemplates: {
        history: "factSheet/construction/lidHistoryRows.njk",
      },
    };
  };

  var getData = async function (id) {
    try {
      return await ApiCalls.getLidProjectForFactSheet(id);
    } catch (e) {
      ApiError.assertIs(e);
      if (e.status === 404) {
        return e;
      }
    }

    return await ResourceController.get("postConstructionProjectG2").getById(id);
  };

  var open = async function (id, options = {}) {
    FactSheetNew.open(id, getConfig(), null, options);
  };

  var afterOpen = function () {
    _loadListeners();
    _handleMessages();
  };

  const _handleMessages = function () {
    const channel = `lid-project.${FactSheetNew.getId()}`;

    Messages.subscribe({
      channel,
      event: "update",
      callback: (data) => reload(data.project),
    });
  };

  var reload = function (data) {
    ProjectFactSheetController.reload(data);
  };

  var _loadListeners = function () {
    ProjectFactSheetController.loadListeners();
    $("#fact-sheet-container")
      .off("click", ".map-button", _openMapPopup)
      .on("click", ".map-button", _openMapPopup);
  };

  var _openMapPopup = function () {
    FactSheetNew.close();
    MapFunctions.openPopupById(Number(FactSheetNew.getId()));
    Analytics.sendDataListEvent("address_to_map", "lid-project");
  };

  var onClose = function () {
    const channel = `lid-project.${FactSheetNew.getId()}`;
    Messages.unsubscribe(channel);
  };

  return {
    open,
    routeName: getConfig().routeName,
    reload,
  };
};

module.exports = LidProjectFactSheetController();

const ApiCalls = require("../apiCalls");
const ApiError = require("../errors/apiError");
const ResourceController = require("../offline/resourceController");
const FactSheetNew = require("../general/factSheetNew");
const Messages = require("../messages");
const MapFunctions = require("../mapFunctions/mapFunctions");
const Analytics = require("../general/analytics");
