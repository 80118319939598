"use strict";

const FacilityEnforcementController = require("./facilityEnforcementController");
const BaseEnforcementAction = require("../../enforcement/baseEnforcementAction");

const EnforcementAction = function () {
  var getPropsFunction = function (data) {
    const openFindingsOptions = _getOpenFindingsOptions(data);
    return {
      levelOptions: FormSettings.getDropdownOptions("level", { sort: false }),
      enforcementReasonOptions: getEnforcementReasonOptions(data),
      openFindingsOptions,
      openFindings: data.openFindings,
    };
  };

  var getEnforcementReasonOptions = function (data) {
    const options = FormSettings.getDropdownOptions("enforcement-reason");

    return Object.keys(data?.openFindingsOptions ?? {})?.length === 0
      ? options.filter((option) => option.value !== "open-findings")
      : options;
  };

  var _getOpenFindingsOptions = function (data) {
    const grouped = new Map();
    (data.openFindingsOptions ?? []).forEach((openFinding) => {
      const observation = FacilityInspectionConstants.getConstantById(openFinding.observationId);
      if (observation) {
        if (!grouped.has(observation.observationCategory)) {
          grouped.set(observation.observationCategory, []);
        }
        const category = grouped.get(observation.observationCategory);
        category.push({
          name: getOpenFindingDisplay(openFinding),
          value: openFinding.id,
        });
      }
    });
    return grouped;
  };

  var getOpenFindingDisplay = function (openFinding) {
    const observation = FacilityInspectionConstants.getConstantById(openFinding.observationId);
    return observation?.observationText + (openFinding.closed ? " (closed)" : "");
  };

  const base = BaseEnforcementAction(FacilityEnforcementController, { getPropsFunction });

  return {
    ...base,
    getEnforcementReasonOptions,
    getOpenFindingDisplay,
    _getOpenFindingsOptions,
  };
};

module.exports = EnforcementAction();

const FormSettings = require("../../settings/formSettings");
const FacilityInspectionConstants = require("../facilityInspection/facilityInspectionConstants");
