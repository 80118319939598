"use strict";

const SamplingSiteInspectionController = function () {
  const htmlPath = "modals/samplingSiteInspection";
  const formKey = "sampling-site-inspection";

  let $modal;
  let tabs;
  let dataStore;

  var showSamplingSiteInspectionModal = function (newDataStore, readOnly) {
    dataStore = newDataStore;
    const initialData = dataStore.getInitial();

    tabs = getTabs(initialData);
    const renderData = mapTabProps(tabs);

    $modal = TabbedModal.showTabbedModal(formKey, renderData, tabs, htmlPath, { readOnly });

    initializeForm();
    setInspectionHeaderText();
    setAdditionalModalHandlers();
  };

  var getTabs = function (data) {
    const GeneralInfo = require("./samplingSiteInspectionGeneralInfo");
    const tabs = [new GeneralInfo(module.exports)];

    DryWeatherController.addDryWeatherTabs(tabs, module.exports);

    return tabs;
  };

  var mapTabProps = function (tabs) {
    const data = dataStore.getWithUpdates();
    const tabProps = tabs.reduce((props, tab) => {
      return $.extend(true, props, tab.getProps(data));
    }, {});

    return {
      ...data,
      ...tabProps,
      showPrecipitation: !ToolSettings.getSetting("outfall", "hide-precipitation"),
      wqMonitoringWorkflow: true,
    };
  };

  var getHeaderHtml = function () {
    const data = dataStore.getInitial();
    return nunjucks.render("modals/samplingSiteInspection/samplingSiteInspectionsHeader.njk", data);
  };

  var setInspectionHeaderText = function () {
    TabbedModal.setHeaderText(getHeaderHtml());
  };

  var setAdditionalModalHandlers = function () {
    TabbedModal.setCancelHandler(dataStore.discard);
    TabbedModal.setSaveHandler(saveButtonClick, false);
    TabbedModal.setFormHasUpdatesHandler(formHasUpdates);
    TabbedModal.goToPrevUrlOnClose();
  };

  var saveButtonClick = async function () {
    const initialData = dataStore.getInitial();

    TabbedModal.hide(false);
    const results = getResults(initialData);

    ResultModal.renderAndShow(
      results,
      getHeaderHtml(initialData.samplingSiteInfo),
      saveAndCloseModal,
      TabbedModal.show,
      {
        createsPdf: false,
        extraButtons: [],
      },
    );

    return false;
  };

  var getResults = function (initialData) {
    const updatedData = getUpdateData(dataStore.getInitial(), dataStore.getForm(), true)?.updates;

    if (updatedData.inspectionType === "wq-monitoring") {
      const wqMonitoringResult = WqMonitoringResult.getWqMonitoringResultData(
        initialData.samplingSiteInfo.siteIdentifier,
        getInspectionRenderData(),
      );
      return [wqMonitoringResult];
    } else if (updatedData.inspectionType === "idde-screening") {
      const dryWeatherResult = DryWeatherResult.getDryWeatherResultData(
        initialData.samplingSiteInfo.siteIdentifier,
        getInspectionRenderData(),
      );
      return [dryWeatherResult];
    }
  };

  var getUpdateData = function (initial, dataToUpload, isNew) {
    DryWeatherController.processInspectionFormData(initial, dataToUpload);
    if (isNew) {
      dataToUpload = $.extend(true, {}, initial, dataToUpload);
    } else {
      dataToUpload = mergeIssueIdsIntoUpdates(initial, dataToUpload);
    }

    dataToUpload = RoutineMaintenanceFormTable.combineRoutineMaintenanceIssues(dataToUpload, [
      "structuralConditionIssues",
      "activeMaintenanceIssues",
    ]);
    dataToUpload.maintenanceIssues = RoutineMaintenanceFormTable.cleanRoutineMaintenanceIssues(
      dataToUpload.maintenanceIssues,
      !isNew,
    );

    if (isNew) {
      return {
        updates: dataToUpload,
      };
    } else {
      return {
        id: initial.id,
        updates: dataToUpload,
      };
    }
  };

  var mergeIssueIdsIntoUpdates = function (initial, dataToUpload) {
    if (dataToUpload.samplingSiteInfo) {
      dataToUpload.samplingSiteInfo.id = initial.samplingSiteInfo.id;
    }

    addIssueIds(initial, dataToUpload, "structuralConditionIssues");
    addIssueIds(initial, dataToUpload, "activeMaintenanceIssues");

    return dataToUpload;
  };

  var addIssueIds = function (initial, updates, key) {
    for (const i in updates[key]) {
      updates[key][i].id = initial[key][i].id;
    }
  };

  var formHasUpdates = function () {
    return !Form.isFormEmpty(formKey);
  };

  var getInspectionRenderData = function () {
    return dataStore.getWithUpdates();
  };

  var getExistingInspectionData = function () {
    return dataStore.getInitial();
  };

  var getFormData = function (path) {
    return Form.getFormDataAtPath(formKey, path);
  };

  var setFormData = function (path, data) {
    return Form.manuallySetFormDataField(formKey, path, data);
  };

  var unsetFormData = function (path) {
    return Form.manuallyUnsetField(formKey, path);
  };

  var saveAndCloseModal = async function () {
    await Form.getReadyToSavePromise(formKey);
    await dataStore.save();
    TabbedModal.hide();
  };

  var _getTabs = function () {
    return tabs;
  };

  var initializeForm = function () {
    Form.initializeAndLoadListeners($modal, formKey, { dataStore });
    FormSettings.customizeForm($modal, formKey);

    const existingFiles = dataStore.getInitial().files ?? [];
    const newFiles = dataStore.getForm().files;
    Form.initializeDropzone(formKey, $modal, {
      newFiles,
      existingFiles: existingFiles,
      downloadCallback: ApiCalls.downloadSamplingSiteInspectionFile,
    });
  };

  return {
    showSamplingSiteInspectionModal,
    getInspectionRenderData,
    getExistingInspectionData,
    getFormData,
    setFormData,
    unsetFormData,
    saveAndCloseModal,
    getUpdateData,
    _getTabs,
  };
};

module.exports = SamplingSiteInspectionController();

const ApiCalls = require("../../apiCalls");
const DryWeatherResult = require("../../general/results/dryWeatherResult");
const Form = require("../../general/form");
const WqMonitoringResult = require("../../general/results/wqMonitoringResult");
const ResultModal = require("../../general/resultModal");
const RoutineMaintenanceFormTable = require("../../general/routineMaintenanceFormTable");
const TabbedModal = require("../../general/tabbedModal");
const ToolSettings = require("../../settings/toolSettings");
const FormSettings = require("../../settings/formSettings");
const DryWeatherController = require("../../illicitDischarge/dryWeatherScreening/dryWeatherController");
