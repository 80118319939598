"use strict";

const ProjectDeliveryFactSheetController = require("../../construction/projectDeliveryFactSheetController");
const ProjectFactSheetController = require("../../construction/projectFactSheetController");
const LidProjectFactSheetController = require("../../lid/lidProjectFactSheetController");

const ConstructionRoutes = function () {
  const lidProjectFactSheetRoute = {
    as: LidProjectFactSheetController.routeName,
    handler: function ({ data, hashString }) {
      LidProjectFactSheetController.open(data.id, { highlightId: hashString });
    },
  };

  const lidProjectFactSheetHighlightRoute = {
    as: `${LidProjectFactSheetController.routeName}-highlight`,
    handler: lidProjectFactSheetRoute.handler,
  };

  const projectFactSheetRoute = {
    as: ProjectFactSheetController.routeName,
    handler: function ({ data, hashString }) {
      ProjectFactSheetController.open(data.id, { highlightId: hashString });
    },
  };

  const projectFactSheetHighlightRoute = {
    as: `${ProjectFactSheetController.routeName}-highlight`,
    handler: projectFactSheetRoute.handler,
  };

  const projectDeliveryFactSheetRoute = {
    as: ProjectDeliveryFactSheetController.routeName,
    handler: function ({ data }) {
      ProjectDeliveryFactSheetController.open(data.id);
    },
  };
  return {
    lidProjectFactSheetRoute,
    lidProjectFactSheetHighlightRoute,
    projectFactSheetRoute,
    projectFactSheetHighlightRoute,
    projectDeliveryFactSheetRoute,
  };
};

module.exports = ConstructionRoutes();
