"use strict";

const OutfallRoutineMaintenanceIllicitDischarge = function (Controller) {
  const stringKey = "illicit-discharge";
  const tabHeader = "Illicit Discharge";
  let $page;

  var setPage = function ($thisPage) {
    $page = $thisPage;
  };

  var loadListeners = function (data) {
    if (!$page) {
      $page = $(`#assessment-modal #${stringKey}`);
    }

    $page.on("2N:FormInput", `[name="illicit-discharge"]`, onIllicitDischargeChange);
    $page.on("2N:FormInput", `[name="follow-up-needed"]`, onFollowUpNeededChange);
    $page.on("2N:FormInput", `[name="close-out-investigation"]`, onCloseOutInvestigationChange);

    if (data) {
      toggleIllicitDischarge(data.illicitDischarge);
      toggleFollowUpNeeded(data.followUpNeeded);
      toggleCloseOutInvestigation(data.closeOutInvestigation);
    }
  };

  var unloadListeners = function () {
    $page.off("2N:FormInput", `[name="illicit-discharge"]`, onIllicitDischargeChange);
    $page.off("2N:FormInput", `[name="follow-up-needed"]`, onFollowUpNeededChange);
    $page.off("2N:FormInput", `[name="close-out-investigation"]`, onCloseOutInvestigationChange);
  };

  var getProps = function () {
    return {
      locationCollectedFromOptions: DryWeatherConstants.getLocationCollectedFromOptions(),
      closeOutReasonOptions: DryWeatherConstants.getCloseOutReasonOptions(),
      followUpActionOptions: DryWeatherConstants.getFollowUpActionOptions(),
    };
  };

  var getStringKey = function () {
    return stringKey;
  };

  var getTabHeader = function () {
    return tabHeader;
  };

  var onIllicitDischargeChange = function (e, illicitDischarge) {
    toggleIllicitDischarge(illicitDischarge);
  };

  var toggleIllicitDischarge = function (illicitDischarge) {
    $page.find(`.illicit-discharge-show-when-discharge`).toggle(illicitDischarge);
  };

  var onFollowUpNeededChange = function (e, followUpNeeded) {
    toggleFollowUpNeeded(followUpNeeded);
  };

  var toggleFollowUpNeeded = function (followUpNeeded) {
    $page.find(`.follow-up-needed-show-when-follow-up`).toggle(followUpNeeded);
  };

  var onCloseOutInvestigationChange = function (e, closeOutInvestigation) {
    toggleCloseOutInvestigation(closeOutInvestigation);
  };

  var toggleCloseOutInvestigation = function (closeOutInvestigation) {
    $page.find(`.close-out-investigation-show-when-close-out`).toggle(closeOutInvestigation);
  };

  return {
    loadListeners,
    unloadListeners,
    getStringKey,
    getTabHeader,
    getProps,
    setPage,
  };
};

module.exports = OutfallRoutineMaintenanceIllicitDischarge;

const DryWeatherConstants = require("../../illicitDischarge/dryWeatherScreening/dryWeatherConstants");
