"use strict";

var FilterConstants = function () {
  const spatialFilters = [
    "catchments",
    "receivingWaters",
    "maintenanceZones",
    "highways",
    "spatialGroupingId",
  ];

  const sbmpLayerFilters = {
    ownership: ["our-agency", "private", "partner", "unknown"],
    responsiblePartyMaintenance: ["our-agency", "private", "partner", "unknown"],
    landOwnership: ["0", "1", "2"],
    structureType: ["centralized", "decentralized"],
  };

  const muniBmpFilters = {
    ownership: [
      "our-agency",
      "private",
      "partner",
      "dot",
      "ms4",
      "local-agency",
      "other",
      "unknown",
    ],
    responsiblePartyMaintenance: ["our-agency"],
    landOwnership: ["0", "1", "2", "3", "4", "5", "6", "7"],
    phase: ["completed", "certified"],
  };

  const muniOutfallFilters = {
    responsiblePartyMaintenance: ["our-agency"],
  };

  const constructionProjectProgressFilters = {
    phase: ["construction", "post-active", "completed", "certified"],
    ownership: [
      "our-agency",
      "private",
      "partner",
      "dot",
      "ms4",
      "local-agency",
      "other",
      "unknown",
    ],
    landOwnership: null,
    get inspectionType() {
      const FormSettings = require("../../settings/formSettings");
      return [
        ...FormSettings.getDropdownOptions("inspection-type", { tool: "construction" }).map(
          (option) => option.value,
        ),
        "no-inspection",
      ];
    },
    priority: false,
  };

  const lidProjectProgressFilters = {
    phase: ["completed", "certified"],
    dateTypeLidProject: "completed",
    performanceTier: ["?", "0", "1", "2", "3", "4"],
    lidOwnership: [
      "our-agency",
      "private",
      "partner",
      "dot",
      "ms4",
      "local-agency",
      "other",
      "unknown",
    ],
  };

  const getMuniCatchBasinProgressFilters = function () {
    const year = AnnualReports.getSelectedYear(true);
    const demoFeatureFlagged = Session.isDemoGroup();

    return year < 2024 && !demoFeatureFlagged
      ? {
          landOwnership: ["0"],
          ownership: ["our-agency", "private", "partner", "unknown"],
          responsiblePartyMaintenance: ["our-agency", "private", "partner", "unknown"],
          types: [11, 12],
          phase: ["completed", "certified"],
          catchBasinPriority: ["high", "moderate", "low", "unknown"],
          consequenceOfFailure: ["very-high", "high", "moderate", "low", "very-low", "unknown"],
        }
      : {
          landOwnership: ["0", "1", "2", "3", "4", "5", "6", "7"],
          ownership: [
            "our-agency",
            "private",
            "partner",
            "dot",
            "ms4",
            "local-agency",
            "other",
            "unknown",
          ],
          responsiblePartyMaintenance: ["our-agency"],
          types: [11, 12],
          phase: ["completed", "certified"],
          catchBasinPriority: ["very-high", "high", "moderate", "low", "very-low", "unknown"],
          consequenceOfFailure: ["very-high", "high", "moderate", "low", "very-low", "unknown"],
        };
  };

  const muniFacilityFilters = {
    responsiblePartyManagement: ["municipal"],
  };

  const getIndcomFacilityFilters = function () {
    return {
      responsiblePartyManagement: ["private", "Private"],
    };
  };

  const getDefaultFiltersByDataViewAndMapId = function () {
    return {
      sbmpView: {
        report: sbmpLayerFilters,
      },
      constructionView: {
        report: constructionProjectProgressFilters,
      },
      lidView: {
        report: lidProjectProgressFilters,
      },
      muniCatchBasinView: {
        report: getMuniCatchBasinProgressFilters(),
      },
      runoffView: {
        inputs: sbmpLayerFilters,
      },
      particulatesView: {
        inputs: sbmpLayerFilters,
      },
      runoffRatioView: {
        inputs: sbmpLayerFilters,
      },
      indCommView: {
        report: getIndcomFacilityFilters(),
      },
      muniFacilityView: {
        report: muniFacilityFilters,
      },
      muniBmpView: {
        report: muniBmpFilters,
      },
      outfallView: {
        report: muniOutfallFilters,
      },
    };
  };

  return {
    getDefaultFiltersByDataViewAndMapId,
    spatialFilters,
    constructionProjectProgressFilters,
    lidProjectProgressFilters,
    getMuniCatchBasinProgressFilters,
    sbmpLayerFilters,
  };
};

module.exports = FilterConstants();

const AnnualReports = require("../annualReports");
const Session = require("../../login/session");
