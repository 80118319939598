"use strict";

/**
 * If updating any of these field listings, be sure to also update the corresponding
 * import template PDF so it lists the most up-to-date fields.
 **/
const ImportFields = function () {
  const catchBasinDisplayNames = {
    assetId: "Unique ID",
    catchBasinType: "Catch Basin Type",
    phase: "Phase",
    responsiblePartyMaintenance: "Maintained By",
    latitude: "Latitude",
    longitude: "Longitude",
    totalDepth: "Total Depth",
    sump: "Sump?",
    sumpDepth: "Sump Depth",
    installDate: "Install Date",
    systemAccess: "Confined Space",
    length: "Length",
    width: "Width",
    diameter: "Diameter",
    consequenceOfFailure: "Consequence Of Failure",
    assessmentFrequency: "Functional Assessment Frequency",
    inspectionFrequency: "Routine Site Visit Frequency",
    proximityTo303d: "In Proximity to 303d",
    dryWeatherMonitoringLocation: "Dry Weather Monitoring Location",
    fullCaptureSystem: "Full Capture System?",
    drainageArea: "Drainage Area",
    imperviousArea: "Impervious Area Treated",
    structureId: "Structure ID",
    projectId: "Associated Project",
    inletType: "Catch Basin Inlet Type",
    siphon: "Siphon",
    footprintMaterial: "Footprint Material",
    wallMaterial: "Wall Material",
    assetShape: "Catch Basin Shape",
    invertElevation: "Invert Elevation",
    baseElevation: "Base Elevation",
    rimElevation: "Rim Elevation",
    datum: "Datum",
    manufacturer: "Manufacturer",
    model: "Name/Model",
    property: "Land Ownership",
    responsiblePartyDelivery: "Delivered By",
    address: "Street Address",
    city: "City",
    state: "State",
    zip: "Zip Code",
    notes: "Notes",
  };

  const sbmpsDisplayNames = {
    assetId: "Unique ID",
    bmpType: "BMP Type",
    phase: "Phase",
    responsiblePartyMaintenance: "Maintained By",
    latitude: "Latitude",
    longitude: "Longitude",
    installDate: "Install Date",
    systemAccess: "Confined Space",
    consequenceOfFailure: "Consequence Of Failure",
    proximityTo303d: "In Proximity to 303d",
    inletQuantity: "Inlets",
    outletQuantity: "Outlets",
    visibleScreen: "Visible Screen at Outlet",
    assessmentFrequency: "Functional Assessment Frequency",
    inspectionFrequency: "Routine Site Visit Frequency",
    certAssessmentFrequency: "Certification Inspection Frequency",
    dryWeatherMonitoringLocation: "Dry Weather Monitoring Location",
    footprint: "Footprint",
    capacity: "Treatment Capacity",
    maintenanceInterval: "Closed Space Maintenance Interval",
    invStwat: "Observation Ports for Standing Water",
    invSedacc: "Observation Ports for Material Accumulation",
    totalDepth: "Total Depth",
    fullCaptureSystem: "Full Capture System?",
    lowflow: "Low Flow Bypass",
    diversion: "Dry Weather Diversion",
    drainsout: "Capture & Reuse",
    flowpath: "Flow Path Length to BMP (ft)",
    drainageArea: "Drainage Area",
    imperviousArea: "Impervious Area Treated",
    treatmentRate: "Treatment Rate",
    wetPoolCapacity: "Wet Pool Capacity",
    infiltrationRate: "Infiltration Rate",
    drawdownTime: "Drawdown Time",
    intakeRate: "Intake Rate",
    structureId: "Structure ID",
    projectId: "Associated Project",
    footprintMaterial: "Footprint Material",
    wallMaterial: "Wall Material",
    treatedStormType: "Design Storm Type",
    treatedStormPercentile: "Design Storm Percentile",
    treatedStormDepth: "Design Storm Depth",
    invertElevation: "Invert Elevation",
    baseElevation: "Base Elevation",
    rimElevation: "Rim Elevation",
    datum: "Datum",
    manufacturer: "Manufacturer",
    model: "Name/Model",
    property: "Land Ownership",
    responsiblePartyDelivery: "Delivered By",
    filterQuantity: "Number of Filters",
    mediaType: "Media Type",
    filterThickness: "Thickness",
    screenCount: "Number of Screens",
    address: "Street Address",
    city: "City",
    state: "State",
    zip: "Zip Code",
    notes: "Notes",
  };

  const fcssDisplayNames = {
    multiSystem: "FCS Grouping",
    volumeBased: "FCS Function",
    trapsParticles: "Trap 5mm Particles",
    treatsStorm: "Treat 1yr 1hr Storm",
    stormInches: "1yr 1hr Storm",
    partialStormInches: "Treated Storm",
    stateApproved: "FCS State Approved Type",
    facility: "Facility Name",
    fcsInspectFreq: "Trash FCS Inspection Frequency",
  };

  const bmpObservationsDisplayNames = {
    bmpId: "BMP Unique ID",
    observationDate: "Date",
    condition: "Condition",
  };

  const outfallsDisplayNames = {
    assetId: "Unique ID",
    rotationAzimuth: "Azimuth Direction",
    latitude: "Latitude",
    longitude: "Longitude",
    drainageAreaAcres: "Drainage Area",
    diameter: "Diameter",
    width: "Width of Pipe",
    height: "Length of Pipe",
    invertElevation: "Outlet Invert Elevation",
    baseElevation: "Outlet Base Elevation",
    rimElevation: "Outlet Rim Elevation",
    outletType: "Outlet Type",
    outfallType: "Type of Outfall",
    assetShape: "Shape of Outfall",
    assetMaterial: "Material of Outfall",
    consequenceOfFailure: "Consequence Of Failure",
    notes: "Notes",
    installDate: "Install Date",
    datum: "Datum",
    responsiblePartyMaintenance: "Maintained By",
    phase: "Phase",
    structureId: "Structure ID",
    confinedSpace: "Confined Space",
    property: "Property",
    responsiblePartyDelivery: "Delivered By",
    dryWeatherMonitoringLocation: "IDDE Screening Location",
    wqMonitoringLocation: "WQ Monitoring Location",
    locationPhase: "Monitoring Location Phase",
    locationActiveDate: "Active Date",
    locationInactiveDate: "Inactive Date",
    address: "Address",
    city: "City",
    state: "State",
    zip: "Zip",
  };

  const projectsDisplayNames = {
    // Applies to All Projects
    projectIdentifier: "Project ID",
    projectName: "Project Name",
    latitude: "Latitude",
    longitude: "Longitude",
    streetAddress: "Address",
    city: "City",
    state: "State",
    zipCode: "ZIP Code",
    implementerType: "Delivered By",
    maintainerType: "Maintained By",
    highPriority: "Priority",
    priorityRationale: "Priority Rationale",
    developmentType: "Development Type",
    briefDescription: "Brief Project Description",
    areaOfDisturbance: "Area of Disturbance (acres)",
    manualArea: "Total Manual Project Area (acres)",
    netImperviousArea: "Net Impervious Area (acres)",
    preImperviousArea: "Pre-Project Impervious Area (acres)",
    postImperviousArea: "Post-Project Impervious Area (acres)",
    proximityToReceivingWater: "Proximity to Receiving Waters (feet)",
    proximityTo303dReceivingWater: "Proximity to 303(d) Receiving Waters (feet)",
    phase: "Project Phase",
    has303dPollutant: "303d Pollutant",
    adjacentToEsa: "Adjacent to ESA",
    initialMaintenanceNotice: "Initial Maintenance Notice",
    initialRoutineInspection: "Initial Routine Inspection",

    // Applies to Compliance Projects
    projectStartDate: "Construction Start",
    projectEndDate: "Construction End",
    projectDeliveryCost: "Delivery Cost",
    projectType: "Project Type",
    averageSiteSlopePercent: "Average Site Slope (%)",
    erosiveSiteSoils: "Erosive Site Soils",
    nonstormwaterDischarge: "Non-Stormwater Discharge",
    generalPermitCoverage: "CGP Coverage",
    wasteDischargerId: "Permit/WDID Number",
    cgpRiskLevel: "CGP Risk Level",
    linearUndergroundProject: "Linear Underground Project",
    erosivityWaiver: "Erosivity Waiver",
    offsiteMitigation: "Off-Site Mitigation",
    relatedProject: "Off-Site Related Project ID",
    inLieuFees: "In-Lieu Fees",
    inLieuFeeAmount: "In-Lieu Fee Amount",
    alternativeCompliance: "Alternative Compliance",

    exempt: "Tier: Exempt",
    siteDesign: "Tier: Site Design",
    wqTreatment: "Tier: WQ Treatment",
    runoffRetention: "Tier: Runoff Retention",
    peakManagement: "Tier: Peak Management",
    exemptionDescription: "Exemption Rationale",
    contaminatedSoils: "Special Circumstance: Contaminated Soils",
    contiguousToWetland: "Special Circumstance: Contiguous to Wetland",
    femaArea: "Special Circumstance: FEMA Area",
    highlyAlteredChannel: "Special Circumstance: Highly Altered Channel",
    historicLakeOrWetland: "Special Circumstance: Historic Lake or Wetland",
    intermediateFlowControl: "Special Circumstance: Intermediate Flow Control",
    infeasibilityWqTreatment: "Tech. Infeasibility: WQ Treatment",
    infeasibilityRunoffRetention: "Tech. Infeasibility: Runoff Retention",
    infeasibilityPeakManagement: "Tech. Infeasibility: Peak Management",
    watershedOrRegionPlan: "Alt. Compliance: Watershed or Regional Plan",
    urbanSustainabilityArea: "Alt. Compliance: Urban Sustainability Area",
    otherDescription: "Alt. Compliance: Other description",

    // Applies to Project Delivery
    landOwnership: "Land Ownership",
    budgetBookId: "Budget Book ID",
    rejectionReason: "Shelved/Rejection Reason",

    // Improvement Types
    improvementType: "Improvement Type",
    improvementBmpType: "Improvement BMP Type",
    improvementEnvironmentalSiteDesignType: "Improvement Environmental Site Design Type",
    improvementRiparianRestorationType: "Riparian Restoration Type",
    improvementCount: "Improvement Count",
    improvementCapacityAcreFeet: "Improvement Capacity (ac-ft)",
    improvementImperviousAreaAcres: "Treated Impervous Area (ac)",
    improvementFootprintSquareFeet: "Improvement Footprint (sqft)",
    improvementLengthFeet: "Length (ft)",
  };

  const projectContactsDisplayNames = {
    projectIdentifier: "Project Identifier",
    firstName: "First Name",
    lastName: "Last Name",
    role: "Role",
    phoneNumber: "Phone Number",
    emailAddress: "Email Address",
    mailingAddress: "Street Address",
    city: "City",
    state: "State",
    zipCode: "Zip Code",
    affiliation: "Affiliation",
    isInactive: "Inactive?",
    companyName: "Company Name",
  };

  const facilitiesDisplayNames = {
    facilityIdentifier: "Facility ID",
    latitude: "Latitude",
    longitude: "Longitude",
    facilityName: "Facility Name",
    facilityType: "Facility Type",
    facilityPhase: "Facility Phase",
    responsiblePartyManagement: "Responsible Party",
    sicCode: "SIC Code",
    isIndustrialGeneralPermit: "Registered Under IGP",
    industrialGeneralPermitNumber: "Industrial General Permit Number",
    wasteDischargerIdentifier: "Permit/WDID Number",
    fogParticipant: "FOG Participant",
    riskProfile: "Risk Profile",
    exemptionNumber: "Exemption Number",
    openedDate: "Facility Open Date",
    closedDate: "Facility Closed Date",
    inspectionFrequencyMonths: "Inspection Frequency",
    address: "Street Address",
    city: "City",
    state: "State",
    zip: "ZIP Code",
    has303dPollutant: "303d Pollutant",
    adjacentToEsa: "Adjacent to ESA",
    mobileBusiness: "Mobile Business",
    notes: "Notes",
  };

  const drainageDisplayNames = {
    bmpId: "BMP Unique ID",
    geometry: "Geometry",
  };

  const projectAreaDisplayNames = {
    projectIdentifier: "Project Identifier",
    geometry: "Geometry",
  };

  const zonesDisplayNames = {
    identifier: "Unique Identifier",
    name: "Zone Name",
    geometry: "Geometry",
  };

  const openChannelsDisplayNames = {
    objectid: "Unique Identifier",
    latitude: "Latitude",
    longitude: "Longitude",
    geometry: "Geometry",
    asset_name: "Asset ID",
    structure_identifier: "Structure ID",
    asset_type: "Channel Type",
    service_type: "Line Service Type",
    flow_type: "Flow Type",
    material: "Material",
    length: "Length",
    width: "Width",
    diameter: "Diameter",
    channel_shape: "Shape",
    slope: "Slope",
    upelev: "Upstream Elevation",
    downelev: "Downstream Elevation",
    datum: "Elevation Datum",
    flow_direction: "Flow Direction",
    depth: "Channel Design Depth",
    implementer_type: "Owned By",
    responsible_party_maintenance: "Maintained By",
    install_date: "Install Date",
    phase: "Phase",
    lined: "Lined?",
    liner_method: "Liner Method",
    liner_year: "Liner Year",
    accessible: "Accessible?",
    priority: "Priority",
    city: "City",
    state: "State",
    zip: "Zip Code",
    notes: "Notes",
    shape_length: "Shape Length",
  };

  const cleanoutsDisplayNames = {
    objectid: "Unique Identifier",
    asset_name: "Asset Name",
    structure_identifier: "Structure Identifier",
    assettype: "Cleanout Type",
    type: "Cover Type",
    conetype: "Cone Type",
    latitude: "Latitude",
    longitude: "Longitude",
    material: "Material",
    surface: "Surface",
    length: "Length",
    width: "Width",
    diameter: "Diameter",
    covershape: "Cover Shape",
    invert_elev: "Invert Elevation",
    base_elev: "Base Elevation",
    rim_elev: "Rim Elevation",
    datum: "Elevation Datum",
    rotation_azimuth: "Rotaion Azimuth",
    depth: "Depth",
    implementer_type: "Implementer Type",
    responsible_party_maintenance: "Maintained By",
    install_date: "Install Date",
    priority: "Priority?",
    phase: "Phase",
    address: "Address",
    city: "City",
    state: "State",
    zip: "Zip",
    notes: "Notes",
    geometry: "Geometry",
  };

  const pipesDisplayNames = {
    objectid: "Unique Identifier",
    latitude: "Latitude",
    longitude: "Longitude",
    geometry: "Geometry",
    asset_name: "Asset ID",
    structure_identifier: "Structure ID",
    pipe_type: "Pipe Type",
    service_type: "Line Service Type",
    material: "Material",
    length: "Length",
    width: "Width",
    diameter: "Diameter",
    pipe_shape: "Shape",
    slope: "Slope",
    upelev: "Upstream Elevation",
    downelev: "Downstream Elevation",
    datum: "Elevation Datum",
    flow_direction: "Flow Direction",
    implementer_type: "Owned By",
    responsible_party_maintenance: "Maintained By",
    install_date: "Install Date",
    phase: "Phase",
    lined: "Lined?",
    liner_method: "Liner Method",
    lineryear: "Liner Year",
    accessible: "Accessible?",
    priority: "Priority",
    city: "City",
    state: "State",
    zip: "Zip Code",
    notes: "Notes",
    shape_length: "Shape Length",
  };

  const liftStationsDisplayNames = {
    objectid: "Unique Identifier",
    asset_name: "Asset Name",
    structure_identifier: "Structure Identifier",
    type: "Type",
    latitude: "Latitude",
    longitude: "Longitude",
    material: "Material",
    length: "Length",
    width: "Width",
    sump: "Sump?",
    sump_elev: "Sump Elevation",
    invert_elevation: "Invert Elevation",
    base_elevation: "Base Elevation",
    rim_elevation: "Rim Elevation",
    out_elev: "Outlet Elevation",
    datum: "Elevation Datum",
    rot_azi: "Rotaion Azimuth",
    sump_depth: "Sump Depth",
    depth: "Depth",
    design_volume: "Design Volume",
    storage_volume: "Storage Volume",
    area: "Area",
    implementer_type: "Implementer Type",
    responsible_party_maintenance: "Maintained By",
    install_date: "Install Date",
    priority: "Priority?",
    phase: "Phase",
    address: "Address",
    city: "City",
    state: "State",
    zip: "Zip",
    notes: "Notes",
    geometry: "Geometry",
  };

  const manholesDisplayNames = {
    manholeName: "Asset ID",
    latitude: "Latitude",
    longitude: "Longitude",
    structureIdentifier: "Structure ID",
    manholeType: "Manhole Type",
    coverType: "Cover Type",
    coneType: "Cone Type",
    frameMaterial: "Frame Material",
    wallMaterial: "Wall Material",
    surfaceMaterial: "Surface Material",
    coverShape: "Cover Shape",
    confinedSpace: "Confined Space",
    manholeLength: "Length",
    manholeWidth: "Width",
    manholeDiameter: "Diameter",
    invertElevation: "Invert Elevation",
    baseElevation: "Base Elevation",
    rimElevation: "Rim Elevation",
    datum: "Elevation Datum",
    depth: "Junction Depth",
    property: "Land Ownership",
    implementerType: "Delivered By",
    responsiblePartyMaintenance: "Maintained By",
    installDate: "Install Date",
    phase: "Phase",
    cofIndividualOverrideText: "Consequence of Failure",
    dryWeatherMonitoringLocation: "Dry Weather Monitoring Location",
    address: "Street Address",
    city: "City",
    state: "State",
    zipCode: "Zip Code",
    notes: "Notes",
  };

  const culvertsDisplayNames = {
    objectid: "Unique Identifier",
    latitude: "Latitude",
    longitude: "Longitude",
    geometry: "Geometry",
    asset_name: "Asset ID",
    structure_identifier: "Structure ID",
    assettype: "Culvert Type",
    service_type: "Line Service Type",
    material: "Material",
    length: "Length",
    width: "Width",
    diameter: "Diameter",
    culvert_shape: "Shape",
    slope: "Slope",
    upelev: "Upstream Elevation",
    downelev: "Downstream Elevation",
    datum: "Elevation Datum",
    flow_direction: "Flow Direction",
    depth: "Channel Design Depth",
    implementer_type: "Owned By",
    responsible_party_maintenance: "Maintained By",
    install_date: "Install Date",
    phase: "Phase",
    wall: "Wall?",
    lined: "Lined?",
    liner_method: "Liner Method",
    liner_year: "Liner Year",
    accessible: "Accessible?",
    priority: "Priority",
    city: "City",
    state: "State",
    zip: "Zip Code",
    notes: "Notes",
    shape_length: "Shape Length",
  };

  const samplingSitesDisplayNames = {
    siteIdentifier: "Site ID",
    latitude: "Latitude",
    longitude: "Longitude",
    siteName: "Site Name",
    siteType: "Site Type",
    maintainerType: "Maintained By",
    dryWeatherMonitoringLocation: "IDDE Screening Location",
    wqMonitoringLocation: "WQ Monitoring Location",
    sitePhase: "Site Phase",
    activeDate: "Site Active Date",
    inactiveDate: "Site Inactive Date",
    address: "Street Address",
    city: "City",
    state: "State",
    zip: "Zip Code",
    notes: "Notes",
  };

  const getDisplayNameMapsByLayer = function () {
    return {
      outfalls: outfallsDisplayNames,
      catchBasins: catchBasinDisplayNames,
      sbmps: sbmpsDisplayNames,
      fcss: fcssDisplayNames,
      "bmp-observations": bmpObservationsDisplayNames,
      projects: projectsDisplayNames,
      "project-contacts": projectContactsDisplayNames,
      facilities: facilitiesDisplayNames,
      drainage: drainageDisplayNames,
      zones: zonesDisplayNames,
      "project-area": projectAreaDisplayNames,
      "open-channels": openChannelsDisplayNames,
      culverts: culvertsDisplayNames,
      cleanouts: cleanoutsDisplayNames,
      pipes: pipesDisplayNames,
      "lift-stations": liftStationsDisplayNames,
      manholes: manholesDisplayNames,
      samplingSites: samplingSitesDisplayNames,
    };
  };

  var getFieldNames = function (layer) {
    const displayNameMapsByLayer = getDisplayNameMapsByLayer();
    if (layer in displayNameMapsByLayer) {
      return Object.keys(displayNameMapsByLayer[layer]);
    }
    throw `Fields with names not known for layer ${layer}.`;
  };

  var getFieldObjs = function (importFieldNames, fieldMapping, layer) {
    const displayNameMapsByLayer = getDisplayNameMapsByLayer();
    if (!(layer in displayNameMapsByLayer)) {
      throw `Fields with names not known for layer ${layer}.`;
    }
    return importFieldNames.map((importFieldName) => {
      const mappedFieldName =
        importFieldName in fieldMapping ? fieldMapping[importFieldName] : importFieldName;
      const displayName = displayNameMapsByLayer[layer][mappedFieldName];
      return {
        importFieldName: importFieldName,
        finalFieldName: mappedFieldName,
        displayName: displayName || importFieldName,
      };
    });
  };

  return {
    getFieldNames,
    getFieldObjs,
  };
};

module.exports = ImportFields();
