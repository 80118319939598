class OfflineExporter {
  static typeConversions = [
    {
      class: Set,
      string: "$THIS_IS_A_SET$",
      serialize: (set) => [...set],
      parse: (array) => new Set(array),
    },
    {
      class: Map,
      string: "$THIS_IS_A_MAP$",
      serialize: (map) => [...map],
      parse: (array) => new Map(array),
    },
  ];
  static fileId = "$THIS_IS_A_FILE$";

  async export({ onlyIncludeObjectStores = null } = {}) {
    const resourcesByKey = ResourceController.getAll();
    const data = {};

    for (const key in resourcesByKey) {
      const resource = resourcesByKey[key];
      data[key] = {};

      await this._forEachDataStore(resource, onlyIncludeObjectStores, async function (store, name) {
        data[key][name] = await OfflineExporter._getStoreData(store, name);
      });
    }

    const json = OfflineExporter._stringify(data);

    return json;
  }

  async exportChunks(chunkSize, callback, { onlyIncludeObjectStores = null } = {}) {
    const resourcesByKey = ResourceController.getAll();

    for (const key in resourcesByKey) {
      const resource = resourcesByKey[key];

      await this._forEachDataStore(resource, onlyIncludeObjectStores, async function (store, name) {
        await OfflineExporter._chunkStoreData(store, name, chunkSize, async function (data) {
          data = OfflineExporter._stringify({
            [key]: {
              [name]: data,
            },
          });
          await callback(data);
        });
      });
    }
  }

  async _forEachDataStore(resource, onlyIncludeObjectStores, callback) {
    const stores = resource._getCachedObjectStores();

    for (const name in stores) {
      if (onlyIncludeObjectStores && !onlyIncludeObjectStores.includes(name)) {
        continue;
      }

      await callback(stores[name], name);
    }
  }

  static async _getStoreData(store, name) {
    const data = await store._dbGetAll();

    if (name === "cachedFileStore") {
      await OfflineExporter._serializeFiles(data);
    }

    return data;
  }

  static async _chunkStoreData(store, name, chunkSize, callback) {
    let transaction = await store._getDbObjectStoreTransaction();
    const allKeys = await transaction.getAllKeys();
    let index = 0;
    let chunk = [];

    for (const key of allKeys) {
      chunk.push(await transaction.get(key));
      index++;

      if (index % chunkSize === 0 || index === allKeys.length) {
        if (name === "cachedFileStore") {
          await OfflineExporter._serializeFiles(chunk);
        }
        await callback(chunk);
        chunk = [];
        transaction = await store._getDbObjectStoreTransaction();
      }
    }
  }

  static async _serializeFiles(data) {
    for (const datum of data) {
      if (!datum?.file) {
        continue;
      }

      const type = datum.file.type;
      const typeLength = type.length.toString().padStart(3, "0");
      datum.file =
        OfflineExporter.fileId +
        typeLength +
        type +
        (await OfflineExporter._blobAsBase64(datum.file));
    }
  }

  static async _blobAsBase64(blob) {
    const base64 = await new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result);
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    });

    // Remove prefixed "data:*/*;base64,"
    const base64Index = base64.indexOf("base64,") + 7;
    return base64.slice(base64Index);
  }

  static _stringify(data) {
    const dataTypes = new Set();

    data = JSON.stringify(data, (key, value) => OfflineExporter._replacer(key, value, dataTypes));
    console.log("Data types found:", dataTypes);

    return data;
  }

  static _replacer(key, value, dataTypes) {
    dataTypes.add(value?.constructor?.name);

    for (const { class: Class, string, serialize } of OfflineExporter.typeConversions) {
      if (value instanceof Class) {
        return [string, serialize(value)];
      }
    }

    return value;
  }

  static async exportAndDownloadOfflineData(options = {}) {
    LoadingScreen.show();

    const exporter = new OfflineExporter();
    const json = await exporter.export(options);
    const blob = new Blob([json], {
      type: "application/json",
    });
    Downloader.fromBlob(blob);

    LoadingScreen.hide();
  }

  static async uploadOfflineData() {
    try {
      await MessageModal.showConfirmWarningModalAsPromise(
        `
        <p>Before uploading a debug report, please contact us at <a href='mailto:customersuccess@2ndnaturewater.com'>customersuccess@2ndnaturewater.com</a> for assistance.</p>
        <p>This will upload local browser data to our servers for debugging. Please make sure you have a strong WiFi signal before proceeding, and be aware the upload may take several minutes. Leave the browser open and your device awake while the upload happens.</p>
      `,
        { okMessage: "Upload", returnMessage: "Cancel" },
      );
    } catch (e) {
      return;
    }

    LoadingScreen.show();
    const id = new Date().getTime();

    try {
      const exporter = new OfflineExporter();
      await OfflineExporter._uploadMetadata(exporter, id);
      await OfflineExporter._uploadFiles(exporter, id);
    } catch (e) {
      LoadingScreen.hide();
      Sentry.captureException(e);
      MessageModal.showSimpleWarningModal(
        "An error occurred while uploading the debug report, please try again later or contact us at customersuccess@2ndnaturewater.com for assistance.",
      );
      return;
    }

    LoadingScreen.hide();
    Sentry.captureException(
      `User uploaded debug report to "${FormSettings.getDirectUploadPost().fileNameValuePrefix}${id}" (there may be multiple files with this prefix)\nRun "await $2NLib.commands.importOfflineData()" in the browser console to import offline data into the app.`,
    );
    MessageModal.showNoticeMessage(
      "Debug data successfully uploaded. Our team has been notified and will investigate the issue.",
    );
  }

  static async _uploadMetadata(exporter, id) {
    const json = await exporter.export({
      onlyIncludeObjectStores: ["cachedDefaultStore", "cachedMetadataStore"],
    });
    await OfflineExporter._uploadJson(json, `${id}-metadata.json`);
  }

  static async _uploadFiles(exporter, id) {
    let chunkIndex = 0;

    await exporter.exportChunks(
      1,
      async function (data) {
        await OfflineExporter._uploadJson(data, `${id}-file-${chunkIndex}.json`);
        chunkIndex++;
      },
      {
        onlyIncludeObjectStores: ["cachedFileStore"],
      },
    );
  }

  static async _uploadJson(json, filename) {
    let data = new Blob([json], {
      type: "application/json",
    });
    data = new File([data], filename, {
      type: "application/json",
    });
    await DirectUpload.uploadFile(data);
  }
}

module.exports = OfflineExporter;

const ResourceController = require("../offline/resourceController");
const Downloader = require("../files/downloader");
const LoadingScreen = require("../general/loadingScreen");
const MessageModal = require("../modals/messageModal");
const DirectUpload = require("../files/directUpload");
const Sentry = require("@sentry/browser");
const FormSettings = require("../settings/formSettings");
