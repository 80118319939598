"use strict";

const ProjectCompleteModal = function () {
  const formKey = "project-complete";
  var $modal;
  var inited = false;
  var props;

  var init = function () {
    $modal = $("#project-complete-modal");
    loadDomListeners();
    inited = true;
  };

  var loadDomListeners = function () {
    $modal.off("click", ".save-button", _saveCompleteProjectStatus);
    $modal.on("click", ".save-button", _saveCompleteProjectStatus);

    $modal.off("click", ".complete-project-button", completeProjectButtonClick);
    $modal.on("click", ".complete-project-button", completeProjectButtonClick);

    $modal.off("click", ".cancel-button", _closeModalIfConfirmed);
    $modal.on("click", ".cancel-button", _closeModalIfConfirmed);

    $modal.off("click", ".open-project-files", openProjectFiles);
    $modal.on("click", ".open-project-files", openProjectFiles);

    $modal.off("click", ".open-project-inventory", openProjectInventory);
    $modal.on("click", ".open-project-inventory", openProjectInventory);

    $modal.off("2N:FormInput", _validateForm);
    $modal.on("2N:FormInput", _validateForm);

    $modal.off("change", ".excluded", _validateForm);
    $modal.on("change", ".excluded", _validateForm);

    ProjectStageData.loadStageDataListeners($modal, {
      formKey,
      isInventory: false,
      limitToPast: true,
    });
  };

  var _saveCompleteProjectStatus = async function () {
    await ApiCalls.saveCompleteProjectStatus(props.id, getIgnoreFields());
    const projectUpdate = Form.getDataFromForm(formKey);
    if ($.isEmptyObject(projectUpdate)) {
      close();
      return;
    }
    $modal.find(".modal-footer .complete-project-button").addClass("disabled");
    $modal.find(".modal-footer .save-button").addClass("disabled").text("Saving");

    return updateProject(projectUpdate);
  };

  var getIgnoreFields = function () {
    return {
      ignoreSwpppOrEscpComplete: $modal
        .find("[name='ignore-swppp-or-escp-complete']")
        .is(":checked"),
      ignoreEngineerSignOffComplete: $modal
        .find("[name='ignore-engineer-sign-off-complete']")
        .is(":checked"),
      ignoreNoticeOfTerminationComplete: $modal
        .find("[name='ignore-notice-of-termination-complete']")
        .is(":checked"),
      ignoreQspSignOffComplete: $modal.find("[name='ignore-qsp-sign-off-complete']").is(":checked"),
      ignoreCgpRegionalBoardSignOffComplete: $modal
        .find("[name='ignore-cgp-regional-board-sign-off-complete']")
        .is(":checked"),
      ignoreAllRequirements: allIgnored(),
    };
  };

  var completeProjectButtonClick = function () {
    MessageModal.showConfirmWarningModal(
      `This will update the phase for the project ${props.projectIdentifier} as well as the ${props.bmpCount} structural BMPs associated with it.`,
      _completeProject,
      "Go Back",
      "Change Phase",
    );
  };

  var _completeProject = async function () {
    $modal.find(".modal-footer .complete-project-button").addClass("disabled").text("Completing");
    $modal.find(".modal-footer .save-button").addClass("disabled");

    var projectUpdate = Form.getDataFromForm(formKey);
    projectUpdate.phase = "completed";
    return updateProject(projectUpdate);
  };

  var updateProject = async function (projectUpdate) {
    var projectId = props.id;

    var formData = Form.toFormData({
      constructionProjectId: projectId,
      constructionProjectUpdates: projectUpdate,
    });
    await ApiCalls.updateConstructionProjectAsPromise(formData);

    if (Tree.get("tool") === "construction") {
      ConstructionProjectGeoServerLayer.invalidateLayerData();
    } else if (Tree.get("tool") === "scenario") {
      ScenariosLayer.loadLayer();
    } else {
      ConstructionProjectLayer.loadLayer();
    }

    ToDoListController.loadTodos();
    close();
  };

  var openProjectFiles = function () {
    close();
    ProjectInventoryModalController.invokeProjectInventoryModal(props.id, "project-files");
  };

  var openProjectInventory = function () {
    close();
    ProjectInventoryModalController.invokeProjectInventoryModal(props.id);
  };

  var _closeModalIfConfirmed = function () {
    if (Object.keys(Form.getDataFromForm(formKey, false)).length === 0) {
      close();
    } else {
      MessageModal.showConfirmWarningModal(
        null,
        close,
        "Go Back",
        "Close Without Saving",
        CommonModalFunctions.getCloseWithoutSavingPrompt(),
      );
    }
  };

  var renderAndShow = async function (projectId) {
    if (!inited) init();
    Form.initializeAndLoadListeners($modal, formKey);
    const projectCompleteStatus = await ApiCalls.getCompleteProjectStatus(projectId);
    await addUrlsToProject(projectCompleteStatus);
    props = Object.assign({}, projectCompleteStatus);
    props.enablePostActivePhase = ToolSettings.getSetting(
      "construction",
      "enable-post-active-phase",
    );
    const renderPromise = render();
    initializeFields();
    Analytics.sendScreenView("modal", formKey);
    return renderPromise;
  };

  var addUrlsToProject = async function (projectCompleteStatus) {
    await ConstructionProjectPopup.addInspectionButtonProps(
      projectCompleteStatus,
      projectCompleteStatus,
    );
    projectCompleteStatus.bmpStatuses.forEach((bmp) => {
      bmp.installationInspectionUrl = Survey123UrlBuilder.getBmpInstallationInspectionUrl(
        bmp.installationInspectionSurvey123Fields,
      );
    });
  };

  var render = async function () {
    const html = nunjucks.render("modals/projectInventory/projectCompleteModal.njk", props);
    $modal.html(html);
    return new Promise((resolve) => {
      $modal.one("shown.bs.modal", resolve);
      $modal.modal("show");
    });
  };

  var initializeFields = function () {
    Form.initializeDatePickers($modal, formKey);
    ProjectStageData.limitDateFieldToNow("certified", "stageData[certified][endDateIso]");
    ProjectStageData.updateAllStageDataDatePickers(props.stageData);
    _validateForm();
  };

  var allIgnored = function () {
    return $modal.find(`[name='ignore-all-requirements']`).first().is(":checked");
  };

  var _isRequirementIgnored = function (key) {
    return yesNoCheckboxValue(`ignore-${key}`);
  };

  var yesNoCheckboxValue = function (name) {
    return $modal.find(`[name='${name}']`).is(":checked");
  };

  var _validateForm = function () {
    props.canComplete = canComplete();

    $modal
      .find(".complete-project-button")
      .toggleClass("disabled", !props.canEditProject || !props.canComplete);
    $modal.find(".save-button").toggleClass("disabled", !props.canEditProject || props.canComplete);
  };

  var canComplete = function () {
    const endDatePopulated = !!(
      Form.getFormDataAtPath(formKey, ["stageData", "certified", "endDateIso"]) ??
      props["currentEndDateIso"]
    );
    const permitTerminationDatePopulated = !!(
      Form.getFormDataAtPath(formKey, ["postActivePermitTerminationDate"]) ??
      props["postActivePermitTerminationDateIso"]
    );
    const datePopulated = ToolSettings.getSetting("construction", "enable-post-active-phase")
      ? permitTerminationDatePopulated
      : endDatePopulated;
    const ignoredFields = getIgnoreFields();

    if (datePopulated && ignoredFields.ignoreAllRequirements) {
      return true;
    }

    const completed = tasksCompleted(ignoredFields);
    const bmpStatusesCompleted = props.bmpStatuses.every(
      (status) => status.remainingInstallationInspections.length === 0,
    );

    return datePopulated && completed && bmpStatusesCompleted;
  };

  var tasksCompleted = function (ignoredFields) {
    return (
      propIsCompleted("closeoutInspectionCompleted") &&
      propIsCompleted("projectDetailsComplete") &&
      propIsCompleted("swpppOrEscpComplete", ignoredFields.ignoreSwpppOrEscpComplete) &&
      propIsCompleted("engineerSignOffComplete", ignoredFields.ignoreEngineerSignOffComplete) &&
      propIsCompleted(
        "noticeOfTerminationComplete",
        ignoredFields.ignoreNoticeOfTerminationComplete,
      ) &&
      propIsCompleted("qspSignOffComplete", ignoredFields.ignoreQspSignOffComplete) &&
      propIsCompleted(
        "cgpRegionalBoardSignOffComplete",
        ignoredFields.ignoreCgpRegionalBoardSignOffComplete,
      )
    );
  };

  var propIsCompleted = function (propName, ignoreField = false) {
    // undefined prop means it's not applicable
    return ignoreField === true || props[propName] !== false;
  };

  var close = function () {
    Form.clearForm(formKey);
    $modal.modal("hide");
    inited = false;
    Analytics.sendScreenView();
    ProjectInventoryModalController.reopenPopupForUpdatedData();
  };

  return {
    renderAndShow,
    close,
    render,
    _closeModalIfConfirmed,
    _validateForm,
    _saveCompleteProjectStatus,
    _completeProject,
    _isRequirementIgnored,
  };
};

module.exports = ProjectCompleteModal();

const Analytics = require("../general/analytics");
const ApiCalls = require("../apiCalls");
const CommonModalFunctions = require("../modals/commonModalFunctions");
const ConstructionProjectGeoServerLayer = require("../constructionG2/constructionProjectGeoServerLayer");
const ConstructionProjectPopup = require("./constructionProjectPopup");
const ToolSettings = require("../settings/toolSettings");
const Form = require("../general/form");
const MessageModal = require("../modals/messageModal");
const ProjectInventoryModalController = require("./projectInventoryModalController");
const ProjectStageData = require("./projectStageData");
const Survey123UrlBuilder = require("./survey123UrlBuilder");
const ToDoListController = require("../mapFunctions/toDoListController");
const Tree = require("../tree");
const ConstructionProjectLayer = require("../construction/constructionProjectLayer");
const ScenariosLayer = require("../scenario/scenariosLayer");
