"use strict";

const LetterCommunicationModal = function () {
  const formKey = "update-response";
  var $modal;
  var props;

  const actionOptions = [
    {
      name: "Receive & Approve",
      value: "receive",
      formData: {
        receive: {
          reviewResult: "approved",
          sbmpCondition: "desired",
        },
      },
    },
    {
      name: "Reject & Generate Reminder",
      value: "receive-reminder",
      formData: {
        receive: {
          action: "receive",
          reviewResult: "rejected",
          sbmpCondition: "desired",
        },
        send: {
          action: "reminder",
        },
      },
    },
    {
      name: "Generate Reminder",
      value: "reminder",
    },
    {
      name: "Mark Unresponsive",
      value: "unresponsive",
      formData: {
        receive: {
          sbmpCondition: "poor",
        },
      },
    },
  ];

  var loadDomListeners = function () {
    const $modalContent = $modal.find(".modal-content");

    $modalContent.on("click", ".cancel-button", _closeModalIfConfirmed);
    $modalContent.on("click", ".save-response-button", _saveResponseIfConfirmed);
    $modalContent.on("click", ".preview-button", _onPreviewLetter);
    $modalContent.on("click", ".js-download-project-preview", _onPreviewProject);
    $modalContent.on("2N:FormInput", `[name="action"]`, _toggleResponseActionFields);
    $modalContent.on("click", ".download-inspection-report", _downloadInspectionReport);
    $modalContent.on("2N:FormInput", checkEnableSendButton);
    $modalContent.on("click", ".js-edit-project", _onEditProject);
  };

  var _closeModalIfConfirmed = function () {
    if (Object.keys(Form.getDataFromForm(formKey, false)).length === 0) {
      close();
    } else {
      MessageModal.showConfirmWarningModal(
        null,
        close,
        "Go Back",
        "Close Without Saving",
        CommonModalFunctions.getCloseWithoutSavingPrompt(),
      );
    }
  };

  var _downloadInspectionReport = function () {
    const inspectionId = $(this).data("inspectionId");
    ApiCalls.downloadConstructionInspectionReport(inspectionId, null, "inspection");
  };

  var _saveResponseIfConfirmed = function () {
    const action = getCurrentData().action;
    const returnMessage = "Go Back";
    const okMessage = getActionTypes(action).send ? "Generate" : "Save";
    const confirmMessage =
      `Clicking “${okMessage}” will save the information entered` +
      getResponseResultMessages(action, props.projectsData.length) +
      ` To proceed, click “${okMessage}”. To go back, click “${returnMessage}”.`;

    MessageModal.showConfirmWarningModal(null, _save, returnMessage, okMessage, confirmMessage);
  };

  var getResponseResultMessages = function (action, projectsLength) {
    if (action === "receive") {
      if (projectsLength === 1) {
        return `, indicating this project has completed its operations & maintenance for the year, and remove it from your To Do List.`;
      } else {
        return `, indicating these <strong>${projectsLength}</strong> projects have completed their operations & maintenance for the year, and remove them from your To Do List.`;
      }
    } else if (getActionTypes(action).send) {
      if (projectsLength === 1) {
        const emailsEnabled = Session.notificationEnabled("pdf-letters");
        return ` and generate a PDF O&M letter for this project${getSavePdfText(emailsEnabled)}.`;
      } else {
        const emailsEnabled = Session.notificationEnabled("pdf-letters");
        return ` and generate${emailsEnabled ? ` a .zip file that includes` : ""} <strong>${projectsLength}</strong> O&M PDF letters${getSavePdfText(emailsEnabled)}. If the selected contact information is missing for a project, 2NFORM will attempt to generate the letter using the other contact option.`;
      }
    } else if (action === "unresponsive") {
      if (projectsLength === 1) {
        return `, indicating this project is unresponsive for the year, and remove it from your To Do List.`;
      } else {
        return `, indicating these <strong>${projectsLength}</strong> projects are unresponsive for the year, and remove them from your To Do List.`;
      }
    } else {
      throw new Error(`No confirm message set for action ${action}`);
    }
  };

  var getSavePdfText = function (emailEnabled) {
    if (emailEnabled) {
      return " that will be emailed to you shortly";
    }

    return " that will be available from the project history shortly";
  };

  var checkEnableSendButton = function () {
    const canUpdateResponse = _hasAllRequiredFields();
    $modal.find(".preview-button").toggleClass("disabled", !canUpdateResponse);
    $modal.find(".save-response-button").toggleClass("disabled", !canUpdateResponse);
    $modal
      .find(".lid-letter-communication-modal-projects .js-download-project-preview")
      .toggleClass("disabled", !canUpdateResponse);
  };

  var renderAndShow = async function (
    projectIds,
    { noticeData = {}, readOnly = false, action = null } = {},
  ) {
    $modal = $("#update-response-modal");

    await setProps(projectIds, noticeData, action, readOnly);
    render();
    initializeFields();
    setupForm(readOnly);

    Analytics.sendScreenView("modal", formKey);
  };

  var setupForm = function (readOnly) {
    handleFormDisplayByAction(getCurrentData().action);
    checkEnableSendButton();

    if (readOnly) {
      Misc.toggleDisabledInputs($modal.find(".modal-body"), readOnly);
    }
  };

  var setProps = async function (projectIds, noticeData, action, readOnly) {
    const projectsData = await getProjectsData(projectIds);

    setProjectPortalReceiveNotice(noticeData, projectsData);
    addBmpMaintenance(projectsData[0].bmps, noticeData.receive?.bmpMaintenance);
    const isPortalResponse =
      noticeData?.receive?.isPortalResponse ?? allProjectsNeedPortalResponse(projectsData);

    props = {
      ...noticeData,
      readOnly,
      isNew: !dataIsNew(noticeData),
      isPortalResponse,
      projectsData,
      actionOptions: getActionOptions(isPortalResponse),
      projectContactIdOptions: InspectionSummaryLetterModal.getProjectContactIdOptions(
        projectsData[0].contacts,
      ),
      bulkProjectContactTypeOptions: [
        {
          value: "operator-maintainer",
          name: "O&M Contact(s)",
        },
        {
          value: "owner",
          name: "Property Owner(s)/LRP",
        },
      ],
    };

    if (!props.action) {
      props.action = getAction(action);
    }
  };

  var getActionOptions = function (isPortalResponse) {
    if (isPortalResponse) {
      return actionOptions.filter((option) => option.value !== "unresponsive");
    } else {
      return actionOptions;
    }
  };

  var setProjectPortalReceiveNotice = function (noticeData, projectsData) {
    if (
      !noticeData?.["receive"] &&
      projectsData.length === 1 &&
      projectsData[0].portalReceiveNotice
    ) {
      noticeData["receive"] = projectsData[0].portalReceiveNotice;
      noticeData["receive"].isUnsavedPortalReview = true;
      delete noticeData["receive"].action;
    }
  };

  var dataIsNew = function (data) {
    if (data.send?.id || data.receive?.id) {
      return data.receive?.isUnsavedPortalReview !== true;
    }

    return false;
  };

  var allProjectsNeedPortalResponse = function (projectsData) {
    return projectsData.every((project) => project.portalReceiveNotice);
  };

  var getAction = function (action) {
    const sendAction = props.send?.action;
    const receiveAction = props.receive?.action;

    if (sendAction && receiveAction) {
      return `${receiveAction}-${sendAction}`;
    } else if (sendAction) {
      return sendAction;
    } else if (receiveAction) {
      return receiveAction;
    }

    return action;
  };

  var getProjectsData = async function (projectIds) {
    const projectsData = await ApiCalls.getConstructionProjectForLidAction(projectIds);

    for (const project of projectsData) {
      addFirstContactNames(project);
    }

    return projectsData;
  };

  var addFirstContactNames = function (project) {
    project.firstOmContactName = getFirstProjectContactNameByRole("operator-maintainer", project);
    project.firstOwnerContactName = getFirstProjectContactNameByRole("owner", project);
  };

  var getFirstProjectContactNameByRole = function (role, project) {
    return project.contacts?.find((contact) => contact.role === role)?.name;
  };

  var render = async function () {
    const html = nunjucks.render("modals/lid/letterCommunicationModal.njk", props);
    $modal.html(html);
    $modal.modal("show");
  };

  var initializeFields = function () {
    Form.initializeAndLoadListeners($modal, formKey);

    loadDomListeners();
    initializeDropzone();
  };

  var initializeDropzone = function () {
    const dropzone = Form.initializeDropzone(formKey, $modal, {
      existingFiles: $.extend(true, [], props.receive?.files),
    });

    if (props.receive?.id) {
      dropzone.setDownloadHandler((filename) => {
        ApiCalls.downloadLidSelfInspectionFile(props.receive.id, "receive", filename);
      });
    }
  };

  var _getUpdateData = function (clearData = false) {
    let updateData = Form.getDataFromForm(formKey, clearData);
    updateData = _getLetterUpdateData(updateData);
    addProjectIds(updateData);
    return updateData;
  };

  var addProjectIds = function (updateData) {
    updateData.projectIds = props.projectsData.map(function (project) {
      return { id: project.id, isPortalResponse: !!project.portalReceiveNotice };
    });
  };

  var _getLetterUpdateData = function (updateData) {
    if (!updateData.action) {
      updateData.action = props?.action;
    }
    _updateSendReceive(updateData);

    if (updateData.receive && !props.receive?.isPortalResponse) {
      if (props.isNew) {
        updateData.receive.sentDate = DateTime.getTodayIso();
      }

      combineBmpMaintenance(updateData);
    }

    _addActionFormData(updateData);

    return updateData;
  };

  var combineBmpMaintenance = function (updateData) {
    if (props.projectsData.length !== 1) {
      return;
    }

    const combinedMaintenance = $.extend(
      true,
      [],
      props.receive?.bmpMaintenance?.map((bmp) => bmp.maintained),
      updateData.receive.bmpMaintenance,
    );

    updateData.receive.bmpMaintenance = LidSelfInspectionModal.getBmpMaintenance(
      props.projectsData[0].bmps,
      combinedMaintenance,
    );
  };

  var _updateSendReceive = function (updateData) {
    const actionTypes = getActionTypes(updateData.action);

    _setOrUnsetSendReceive(updateData, "send", actionTypes.send);
    _setOrUnsetSendReceive(updateData, "receive", actionTypes.receive);
  };

  var _setOrUnsetSendReceive = function (updateData, actionType, action) {
    if (action) {
      if (!updateData[actionType]) {
        updateData[actionType] = {};
      }

      updateData[actionType].action = action;

      if (props[actionType]?.id) {
        updateData[actionType].id = props[actionType].id;
      }
    } else {
      delete updateData[actionType];
    }
  };

  var _addActionFormData = function (updateData) {
    const actionData = actionOptions.find((option) => option.value === updateData.action)?.[
      "formData"
    ];

    if (actionData && props.isNew) {
      $.extend(true, updateData, actionData);
    }
  };

  var _save = async function () {
    await Form.getReadyToSavePromise(formKey);
    const updateData = _getUpdateData(true);
    await ApiCalls.saveLidSelfInspection(updateData);

    close();
    LidProjectLayer.invalidateLayerData();
  };

  var close = function () {
    Form.clearForm(formKey);
    $modal.modal("hide");
    $modal.html("");
    Analytics.sendScreenView();
  };

  var _hasAllRequiredFields = function () {
    if (Form.isFormEmpty(formKey)) {
      return false;
    }

    const data = getCurrentData();
    const requiredFields = getActionRequiredFields(data.action);

    return requiredFields.every((requiredField) => !!_getDataFromInputName(requiredField, data));
  };

  var getActionRequiredFields = function (action) {
    const actionTypes = getActionTypes(action);
    const requiredFields = [];

    if (actionTypes.receive && !props.isPortalResponse) {
      requiredFields.push("receive[receivedDate]");
    }
    if (actionTypes.send) {
      if (props.projectsData.length === 1) {
        requiredFields.push("send[projectContactId]");
      } else {
        requiredFields.push("send[bulkProjectContactType]");
      }
      requiredFields.push("send[responseDueDate]");
    }
    if (action === "receive-reminder") {
      requiredFields.push("receive[rejectionReason]");
    }
    if (action !== "send") {
      requiredFields.push("action");
    }

    return requiredFields;
  };

  var _getDataFromInputName = function (inputName, data) {
    const pathParts = Form.getPathPartsFromName(inputName);
    return Form.getDataAtPath(data, pathParts);
  };

  var _toggleResponseActionFields = function () {
    handleFormDisplayByAction($(this).val());
  };

  var handleFormDisplayByAction = function (action) {
    const actionTypes = getActionTypes(action);

    $(`[data-show-action]`).hide();
    $(`[data-show-action~="${action}"]`).show();

    if (actionTypes.send) {
      $modal.find(".save-response-button").text("Save & Generate");
    } else {
      $modal.find(".save-response-button").text("Save & Close");
    }

    $modal.find(".associated-bmps table").toggleClass("bmp-maintenance", !!actionTypes.receive);
    $modal.find(".associated-bmps table .bmp-maintenance").toggle(!!actionTypes.receive);
  };

  var _onPreviewLetter = async function () {
    previewLetter(props?.projectsData?.[0].id);
  };

  var previewLetter = async function (projectId) {
    InspectionPreview.preview(function () {
      let updateData = Form.getDataFromForm(formKey, false);
      updateData = _getLetterUpdateData(updateData)["send"];
      updateData.projectId = projectId;
      return updateData;
    }, ApiCalls.previewLidSelfInspectionPdf);
  };

  var getActionTypes = function (action) {
    const actionTypes = {
      send: { send: "send", receive: false },
      reminder: { send: "reminder", receive: false },
      "receive-reminder": { send: "reminder", receive: "receive" },
      receive: { send: false, receive: "receive" },
      unresponsive: { send: false, receive: "unresponsive" },
      null: {},
    };

    const metadata = actionTypes[action];

    if (!metadata) {
      throw new Error(`Unknown action ${action}`);
    }

    return metadata;
  };

  var _onEditProject = async function (event) {
    $modal.modal("hide");

    const projectId = $(event.currentTarget).closest("tr").data("id");
    await ProjectInventoryModalController.invokeProjectInventoryModal(
      projectId,
      "project-contacts",
    );

    const $inventoryModal = InventoryModal.getModal();
    $inventoryModal.one("hidden.bs.modal", () => _onProjectInventoryClose(projectId));
  };

  var _onProjectInventoryClose = async function (projectId) {
    $modal.modal("show");

    const projectData = (await getProjectsData([projectId]))[0];

    for (const i in props.projectsData) {
      if (props.projectsData[i].id === projectData.id) {
        props.projectsData[i] = projectData;
        rerenderProjectContact(projectData);
        return;
      }
    }
  };

  var rerenderProjectContact = function (projectData) {
    const newRowHtml = nunjucks.render("modals/lid/letterCommunicationModalContactRow.njk", {
      project: projectData,
    });
    $getProjectContactRowById(projectData.id).html(newRowHtml);
    setupForm(false);
  };

  var _onPreviewProject = function (event) {
    const projectId = $(event.currentTarget).closest("tr").data("id");
    previewLetter(projectId);
  };

  var $getProjectContactRowById = function (id) {
    return $modal.find(`.lid-letter-communication-modal-projects tr[data-id="${id}"]`);
  };

  var getCurrentData = function () {
    return $.extend(true, {}, props, Form.getDataFromForm(formKey, false));
  };

  var addBmpMaintenance = function (projectBmps, bmpMaintenance) {
    if (!bmpMaintenance) {
      return;
    }

    for (const bmp of projectBmps) {
      const maintainedBmp = bmpMaintenance.find((element) => element.id === bmp.id);

      if (maintainedBmp) {
        bmp.maintained = maintainedBmp.maintained;
      }
    }
  };

  return {
    renderAndShow,
    close,
    render,
    addFirstContactNames,
    _hasAllRequiredFields,
    _closeModalIfConfirmed,
    _save,
    _getUpdateData,
    _saveResponseIfConfirmed,
    _onProjectInventoryClose,
    _onEditProject,
  };
};

module.exports = LetterCommunicationModal();

const Analytics = require("../general/analytics");
const ApiCalls = require("../apiCalls");
const CommonModalFunctions = require("../modals/commonModalFunctions");
const DateTime = require("../dateTime");
const Form = require("../general/form");
const LidProjectLayer = require("./lidProjectLayer");
const MessageModal = require("../modals/messageModal");
const Misc = require("../misc");
const InspectionPreview = require("../general/inspectionPreview");
const ProjectInventoryModalController = require("../construction/projectInventoryModalController");
const InventoryModal = require("../general/inventoryModal");
const LidSelfInspectionModal = require("../portal/lidSelfInspection/lidSelfInspectionModal");
const Session = require("../login/session");
const InspectionSummaryLetterModal = require("./inspectionSummaryLetterModal");
