"use strict";

const EswftSyncManagement = function () {
  const key = "eswft-sync-management";
  const option = "ESWFT Sync";
  const iconClass = "sync";
  let $page;
  let $errorModal;
  let props = [];

  function loadListeners() {
    $page = SettingsModalController.getParentPage();

    $page.off("click", `input[name="active-toggle"]`, toggleActive);
    $page.on("click", `input[name="active-toggle"]`, toggleActive);

    $page.off("click", ".category-row", toggleSubRows);
    $page.on("click", ".category-row", toggleSubRows);

    $page.off("click", ".error-display", showErrorModal);
    $page.on("click", ".error-display", showErrorModal);
  }

  function loadErrorModalListeners() {
    $errorModal = $("#eswft-error-modal");

    $errorModal.off("click", ".download-error", downloadError);
    $errorModal.on("click", ".download-error", downloadError);

    $errorModal.off("click", ".close-button", hideErrorModal);
    $errorModal.on("click", ".close-button", hideErrorModal);
  }

  async function showErrorModal() {
    const layer = $(this.parentElement).find(".layer-col")[0].innerHTML;
    const layerData = getErrorByLayer(layer);
    const $errorModal = $("#eswft-error-modal");
    $errorModal.html(
      nunjucks.render("modals/settingsModal/eswftSyncManagementError.njk", {
        layer: layer,
        display: layerData.display,
        error: layerData.error,
        description: layerData.description,
      }),
    );
    loadErrorModalListeners();
    $errorModal.modal("show");
  }

  function getErrorByLayer(layName) {
    const categoryData = props.eswftSync.categories;
    for (const category of Object.keys(categoryData)) {
      if (layName in categoryData[category]) {
        return categoryData[category][layName];
      }
    }
    return null;
  }

  function downloadError() {
    var jsonDict = {};
    const layerData = getErrorByLayer(this.value);
    jsonDict = {
      layer: this.value,
      type: layerData.display,
      description: layerData.description,
      error: layerData.error,
    };

    const blob = new Blob([JSON.stringify(jsonDict, null, 4)], {
      type: "application/json",
    });
    Downloader.fromBlob(blob);
  }

  var toggleActive = async function () {
    const enabled = !!this.checked;
    const accessKey = this.value;
    await ApiCalls.updateApiKey(accessKey, !enabled);
    $(this).closest("tr").toggleClass("enabled", enabled);
  };

  var toggleSubRows = async function () {
    const category = this.id.split("-")[1];
    $(this).find(".expand-arrow").toggleClass("open");
    $(this.parentElement)
      .find(".sync-" + category)
      .toggle("fast");
    $(this.parentElement)
      .find(".category-layer-" + category)
      .toggle("fast");
    $(this.parentElement)
      .find(".category-error-" + category)
      .toggle("fast");
    $(this.parentElement)
      .find(".category-last-" + category)
      .toggle("fast");
  };

  async function getProps() {
    const eswftSync = await ApiCalls.getEswftSyncStatus();
    props = { eswftSync };
    return props;
  }

  function getMenuKey() {
    return key;
  }

  function getMenuOption() {
    return option;
  }

  function getMenuIcon() {
    return iconClass;
  }

  async function hideErrorModal() {
    $("#eswft-error-modal").modal("hide");
  }

  function onShow() {
    return;
  }

  function onClose() {
    hideErrorModal();
  }

  return {
    getProps,
    getMenuKey,
    getMenuOption,
    getMenuIcon,
    onClose,
    onShow,
    loadListeners,
  };
};

module.exports = EswftSyncManagement();

const ApiCalls = require("../../apiCalls");
const SettingsModalController = require("../settingsModalController");
const Downloader = require("../../files/downloader");
