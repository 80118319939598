"use strict";

const WqMonitoringGeoServerLayerPopup = function () {
  var getPopupHtml = function (properties) {
    const assetType = properties.assetType || properties.asset_type;

    if (assetType === "samplingSite") {
      return SamplingSitePopup.getPopupHtml(properties);
    } else if (assetType === "outfall") {
      return OutfallPopup.getPopupHtml(properties);
    }
  };

  var onPopupOpen = async function (properties, event = null) {
    const assetType = properties.assetType || properties.asset_type;
    const isWqMonitoringLocationLayer = true;

    if (assetType === "samplingSite") {
      return SamplingSitePopup.onPopupOpen(properties, event, isWqMonitoringLocationLayer);
    } else if (assetType === "outfall") {
      return OutfallPopup.onPopupOpen(properties, "outfalls", isWqMonitoringLocationLayer);
    }
  };

  return {
    getPopupHtml,
    onPopupOpen,
  };
};

module.exports = WqMonitoringGeoServerLayerPopup();

const SamplingSitePopup = require("./samplingSitePopup");
const OutfallPopup = require("../outfalls/outfallPopup");
