"use strict";

const SettingsModalController = function () {
  const htmlPath = "modals/settingsModal";
  let $settingsModal;
  let loadedMenus;
  let props;

  function getParentPage() {
    return $settingsModal;
  }
  function isEsg() {
    return Tree.get("tool") === "esg";
  }

  function loadListeners(pages) {
    $settingsModal.off("click", ".leave-settings", closeModal);
    $settingsModal.on("click", ".leave-settings", closeModal);

    $settingsModal.off("click", ".settings-nav li", _setActivePageClick);
    $settingsModal.on("click", ".settings-nav li", _setActivePageClick);

    $(window).off("resize", _resizeWindow);
    $(window).on("resize", _resizeWindow);

    pages.forEach(function (page) {
      page.loadListeners();
    });
  }

  function _setActivePageClick(e) {
    const pageToNav = $(e.currentTarget).attr("id");
    setMenuActive(pageToNav);
  }

  function _resizeWindow() {
    if ($(window).width() < 1180) {
      loadedMenus.forEach((menu) => menu.onClose());
      closeModal();
    }
  }

  function canAccessSettings() {
    const menus = _getMenus();
    return menus.length > 0;
  }

  async function showSettingsModal() {
    $settingsModal = $("#settings-modal");
    const menus = _getMenus();
    if (menus.length === 0) {
      return;
    }
    const menuNav = getNavProps(menus);
    const menuProps = await getMenuProps(menus);
    props = menuProps;
    const html = nunjucks.render("modals/settingsModal/settingsModal.njk", {
      menus: menuNav,
      props: menuProps,
    });
    $settingsModal.html(html);
    setMenuActive(menuNav[0].menuKey);
    loadListeners(menus);
    $settingsModal.modal("show");
    menus[0].onShow();
  }

  function updateExistingProps(newProps) {
    props = newProps;
  }

  function getExistingProps() {
    return props;
  }

  function _getMenus() {
    const loadedMenus = [];
    const menus = [
      {
        model: UserManagement,
        permission: "can_manage_users",
      },
      {
        model: ApiKeyManagement,
        permission: "can_manage_api_keys",
        featureFlag: "public-api-keys",
        esg: false,
      },
      {
        model: EswftSyncManagement,
        permission: "can_manage_eswft_sync",
        esg: false,
      },
    ];

    for (const index in menus) {
      const menuConfig = menus[index];
      if (menuConfig.permission && !UserPermissions.getPermission(menuConfig.permission)) {
        continue;
      }
      if (menuConfig.featureFlag && !FeatureFlag.enabled(menuConfig.featureFlag)) {
        continue;
      }
      if (isEsg() && menuConfig.esg === false) {
        continue;
      }

      loadedMenus.push(menuConfig.model);
    }

    return loadedMenus;
  }

  function getNavProps(menus) {
    return menus.map((menu) => ({
      menuOption: menu.getMenuOption(),
      menuIcon: menu.getMenuIcon(),
      menuKey: menu.getMenuKey(),
      menuPath: `${htmlPath}/${Misc.kebabToCamel(menu.getMenuKey())}.njk`,
    }));
  }

  async function getMenuProps(menus) {
    const menuPropsFunctions = menus.flatMap((menu) => menu.getProps());
    const props = await Promise.all(menuPropsFunctions).then((values) => values);
    const propsReduced = props.reduce((props, tab) => {
      return $.extend(true, props, tab);
    }, {});

    return propsReduced;
  }

  function closeModal() {
    $settingsModal.modal("hide");
    Analytics.clearFilterContext();
  }

  function setMenuActive(menuKey) {
    $settingsModal.find("li").toggleClass("active", false);
    $settingsModal.find(`li#${menuKey}`).toggleClass("active", true);
    $settingsModal.find(".modal-dynamic-content > *").toggleClass("hidden", true);
    $settingsModal.find(`.modal-dynamic-content #${menuKey}-page`).toggleClass("hidden", false);
    Analytics.sendSettingsEvent(`${menuKey}-page`);
  }

  return {
    canAccessSettings,
    showSettingsModal,
    getParentPage,
    getExistingProps,
    updateExistingProps,
    setMenuActive,
  };
};

module.exports = SettingsModalController();

const Analytics = require("../general/analytics");
const Misc = require("../misc");
const UserPermissions = require("../login/userPermissions");
const UserManagement = require("./settingsMenus/userManagement");
const ApiKeyManagement = require("./settingsMenus/apiKeyManagement");
const EswftSyncManagement = require("./settingsMenus/eswftSyncManagement");
const FeatureFlag = require("./featureFlag");
const Tree = require("../tree");
