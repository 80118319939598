"use strict";

const OutfallInventoryMonitoringScreening = function () {
  const stringKey = "outfall-monitoring-screening";
  const headerInformation = "Monitoring & Screening";
  let $page;

  var getStringKey = function () {
    return stringKey;
  };

  var getHeaderInformation = function () {
    return headerInformation;
  };

  var render = async function () {
    await renderPageHtml();
  };

  var loadListeners = function () {
    $page.on("change", `[name="location-phase"]`, onLocationPhaseChange);

    Form.initializeAndLoadListeners(
      $page,
      OutfallInventoryModalController.getOutfallInventoryFormKey(),
      { isMultiPart: true },
    );
  };

  var removeListeners = function () {
    $page.off("change", `[name="location-phase"]`, onLocationPhaseChange);
  };

  var onLocationPhaseChange = function (e) {
    const locationPhase =
      OutfallInventoryModalController.getLatestOutfallInventoryData("locationPhase");
    const locationInactiveDateRequiredToggle = locationPhase === "inactive";
    $page.find(".inactive-date legend").toggleClass("required", locationInactiveDateRequiredToggle);
  };

  var renderPageHtml = function () {
    const renderData = OutfallInventoryModalController.getInventoryForRender();
    const additionalProps = getAdditionalProps();
    const html = nunjucks.render("modals/wqInventory/assetInventoryMonitoringScreening.njk", {
      ...renderData,
      ...additionalProps,
    });
    $page = $(".modal-dynamic-content");
    OutfallInventoryModalController.renderPageContent(html);
    loadListeners();
  };

  var getAdditionalProps = function () {
    return {
      locationPhaseOptions: SamplingSiteInventoryConstants.getSitePhaseOptions(),
    };
  };

  var cleanUp = function () {
    removeListeners();
    $page.empty();
    return true;
  };

  var validate = function () {
    const locationPhase =
      OutfallInventoryModalController.getLatestOutfallInventoryData("locationPhase");
    const locationInactiveDate =
      OutfallInventoryModalController.getLatestOutfallInventoryData("locationInactiveDate");

    if (locationPhase === "inactive" && !locationInactiveDate) {
      MessageModal.showSimpleWarningModal("<p>Please enter a valid inactive date.</p>", true);
      return false;
    }

    return true;
  };

  return {
    getStringKey,
    getHeaderInformation,
    render,
    renderPageHtml,
    cleanUp,
    validate,
  };
};

module.exports = OutfallInventoryMonitoringScreening();

const SamplingSiteInventoryConstants = require("../wq/samplingSiteInventoryConstants");
const OutfallInventoryModalController = require("../outfalls/outfallInventoryModalController");
const Form = require("../general/form");
const MessageModal = require("../modals/messageModal");
