"use strict";

const FactSheetConfig = function () {
  const getFactSheetConfig = function (asset) {
    const config = get();
    if (config[asset]) {
      return config[asset];
    }
    console.error(`Undefined asset in factSheetConfig: ${asset}`);
  };

  const get = function () {
    const ApiCalls = require("../apiCalls");
    const ToolSettings = require("../settings/toolSettings.js");
    const DateTime = require("../dateTime");
    const FacilityFactSheetController = require("../indcom/facilityFactSheetController");
    const IndcomFacilityGeoServerLayer = require("../indcom/indcomFacilityGeoServerLayer");
    const SortUtilities = require("../general/sortUtilities.js");
    const alphaSorter = SortUtilities.alphaSorter;
    const dateSorter = DateTime.dbDateStringComparator;
    const numberSorter = SortUtilities.numberSorter;
    const IndcomIcon = require("../indcom/indcomIcon");
    const MuniCatchBasinGeoServerLayer = require("../muni/muniCatchBasinGeoServerLayer.js");
    const CatchBasinFactSheetController = require("../muni/catchBasinFactSheetController.js");
    const MuniCatchBasinIcon = require("../muni/muniCatchBasinIcon.js");
    const OutfallsIcon = require("../outfalls/outfallsIcon.js");
    const MuniOutfallsGeoServerLayer = require("../muni/outfalls/muniOutfallsGeoServerLayer.js");
    const OutfallFactSheetController = require("../muni/outfallFactSheetController.js");

    return {
      facility: {
        assetDisplayName: ToolSettings.getSetting(
          "constants",
          "assetDisplayNames",
          "indcom-facility",
        ),
        apiAssetName: "facility",
        getFactSheetIconFunction: IndcomIcon.getFacilityIconClass,
        dataListFilter: "indcomFacilitiesName",
        deleteFactSheetFn: ApiCalls.deleteFacilityFactSheet,
        layerConfigs: IndcomFacilityGeoServerLayer,
        loadNewFactSheet: FacilityFactSheetController.initFactSheet,
        nunjucks: {
          contacts: {
            header: "facilityFactSheet/sections/facilityFactSheetContactsHeader.njk",
            table: "facilityFactSheet/sections/facilityFactSheetContactsTable.njk",
          },
          history: {
            header: "facilityFactSheet/sections/facilityFactSheetHistoryHeader.njk",
            table: "facilityFactSheet/sections/facilityFactSheetHistoryTable.njk",
          },
          sectionHandler: "facilityFactSheet/sections/facilityFactSheetSectionHandler.njk",
        },
        openSections: {
          contacts: true,
          history: true,
          photos: true,
        },
        sortingConfigs: {
          dictionary: {
            contacts: {
              type: alphaSorter,
              name: alphaSorter,
              address: alphaSorter,
              phoneNumber: alphaSorter,
              emailAddress: alphaSorter,
            },
            history: {
              displayType: alphaSorter,
              date: dateSorter,
              displayResult: alphaSorter,
            },
          },
          keys: {
            contacts: "displayRole",
            history: "displayType",
          },
          reversed: {},
        },
        toolData: {
          toolId: "facilityId",
          photoType: "facility-photo",
        },
      },

      "muni-catch-basins": {
        assetDisplayName: ToolSettings.getSetting("constants", "assetDisplayNames", "catch-basin"),
        apiAssetName: "bmp",
        getFactSheetIconFunction: MuniCatchBasinIcon.getMuniIconClass,
        dataListFilter: "bmpName",
        deleteFactSheetFn: ApiCalls.deleteBmp,
        layerConfigs: MuniCatchBasinGeoServerLayer,
        loadNewFactSheet: CatchBasinFactSheetController.initFactSheet,
        nunjucks: {
          siteVisitHistory: {
            header: "muniFactSheet/catchBasinFactSheetSiteVisitHistoryHeader.njk",
            table: "muniFactSheet/catchBasinFactSheetSiteVisitHistoryTable.njk",
          },
          sectionHandler: "muniFactSheet/catchBasinFactSheetSectionHandler.njk",
        },
        openSections: {
          siteVisitHistory: true,
          contacts: true,
          photos: true,
        },
        sortingConfigs: {
          dictionary: {
            siteVisitHistory: {
              displayType: alphaSorter,
              fieldPersonnel: alphaSorter,
              displayDate: dateSorter,
              displayResult: alphaSorter,
              openFindings: numberSorter,
              closedFindings: numberSorter,
            },
          },
          keys: {
            siteVisitHistory: "displayType",
          },
          reversed: {},
        },
        toolData: {
          toolId: "id",
          photoType: "project-photo",
        },
      },

      "muni-outfalls": {
        assetDisplayName: ToolSettings.getSetting("constants", "assetDisplayNames", "outfall"),
        apiAssetName: "outfall",
        getFactSheetIconFunction: OutfallsIcon.getOutfallsFactSheetIconClass,
        dataListFilter: "outfallsId",
        deleteFactSheetFn: ApiCalls.deleteOutfall,
        layerConfigs: MuniOutfallsGeoServerLayer,
        loadNewFactSheet: OutfallFactSheetController.initFactSheet,
        nunjucks: {
          sectionHandler: "muniFactSheet/outfallFactSheetSectionHandler.njk",
        },
        openSections: {
          contacts: true,
          photos: true,
        },
        sortingConfigs: {
          dictionary: {},
          keys: {},
          reversed: {},
        },
        toolData: {
          toolId: "id",
          photoType: "project-photo",
        },
      },
    };
  };

  return { getFactSheetConfig };
};

module.exports = FactSheetConfig();
