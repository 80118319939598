"use strict";

function SamplingSiteInventoryModalController() {
  const inventoryFormKey = "sampling-site-inventory";

  function loadFormListener() {
    $("#inventory-modal .modal-dynamic-content").off("2N:FormInput");
    $("#inventory-modal .modal-dynamic-content").on("2N:FormInput", onFormInput);
  }

  function refreshState() {
    Tree.set(["samplingSiteInventoryState", "currentSamplingSiteData"], {});
    Tree.set(["samplingSiteInventoryState", "existing"], false);
    Tree.set(["samplingSiteInventoryState", "siteIdentifier"], null);
  }

  function getLatestSamplingSiteInventoryData(path) {
    path = Array.isArray(path) ? path : [path];
    return InventoryModal.getLatestData(inventoryFormKey, path);
  }

  function onFormInput(e, value, path) {
    if (path[0] === "siteIdentifier") {
      Tree.set(["samplingSiteInventoryState", "siteIdentifier"], value);
    }

    if (path[0] === "wqMonitoringLocation" || path[0] === "dryWeatherMonitoringLocation") {
      onChangeMonitoringLocationFieldChange();
    }

    checkEnableSaveButton(value, path);
  }

  function onChangeMonitoringLocationFieldChange() {
    const wqMonitoringLocation = getLatestSamplingSiteInventoryData("wqMonitoringLocation") ?? null;
    const dryWeatherMonitoringLocation =
      getLatestSamplingSiteInventoryData("dryWeatherMonitoringLocation") ?? null;

    const showMonitoringScreeningTabToggle = wqMonitoringLocation || dryWeatherMonitoringLocation;

    if (showMonitoringScreeningTabToggle) {
      InventoryModal.insertInventoryStep(3, SamplingSiteInventoryMonitoringScreening);
    } else {
      InventoryModal.removeInventoryStep(SamplingSiteInventoryMonitoringScreening);
    }
  }

  function loadExistingMarker(zoom) {
    const markerIcon = getMarkerIcon(zoom);
    const latlng = getLatLng();

    const marker = new L.marker(latlng, {
      icon: markerIcon,
      zIndexOffset: 2000,
    });
    ModalMap.setSingleMarker(marker);
  }

  function getMarkerIcon(zoom) {
    const inventoryData = InventoryModal.getAllData(inventoryFormKey);

    inventoryData.zoom = zoom;

    return SamplingSiteIcon.getSamplingSiteMarkerIcon(inventoryData?.condition);
  }

  function getExistingData(path) {
    const pathToCheck = $.isArray(path) ? path : [path];
    return Tree.get(["asset", inventoryFormKey, ...pathToCheck]);
  }

  function unsetFormDataField(path) {
    path = Array.isArray(path) ? path : [path];
    Form.manuallyUnsetField(inventoryFormKey, path);
    return Form.getDataFromForm(inventoryFormKey, false);
  }

  async function invokeSamplingSiteInventoryModal(maybeId, options, startingPage) {
    InventoryModal.setOpenInventory(inventoryFormKey);
    if (maybeId) {
      const data = await ApiCalls.getSamplingSite(maybeId);
      Tree.set(["samplingSiteInventoryState", "currentSamplingSiteData"], data);
      Tree.set(["samplingSiteInventoryState", "existing"], true);
      return initializeInventoryModalPages(data, "sampling-site-set-location", {
        existingLocation: true,
      });
    } else {
      refreshState();
      return initializeInventoryModalPages(
        SamplingSiteInventoryConstants.getSamplingSiteInventoryDefaults(),
        startingPage,
        options,
      );
    }
  }

  async function initializeInventoryModalPages(processedData, startingPage, options = {}) {
    const pageOptions = {
      existingLocation: !!(processedData || {}).siteIdentifier,
      ...options,
    };

    $("#modalMap").hide();
    Tree.set(["asset", inventoryFormKey], processedData);
    resetAllPages();
    const inputPages = getInventoryPages();
    await InventoryModal.showModal(inputPages, startingPage, pageOptions, {
      saveAndCloseHandler: checkSaveAndClose,
      cancelHandler: closeInventory,
      formHasUpdatesHandler: () => !noUpdatesInForm(),
      onPageChangeHandler: highlightIncompleteFields,
    });
    InventoryModal.disableSaveButton();
    InventoryModal.resetModalTitle();
    setModalTitle();

    InventoryModal.setHideInventoryOnClose(!options?.modalInModal);
    loadFormListener();
    onChangeMonitoringLocationFieldChange();
  }

  function getInventoryPages() {
    const basePages = [
      SamplingSiteInventorySetLocation,
      SamplingSiteInventoryGeneralInfo,
      SamplingSiteInventoryMonitoringScreening,
      SamplingSiteInventoryFiles,
    ];

    return basePages;
  }

  function getLatLng() {
    return {
      lat: getLatestSamplingSiteInventoryData("latitude"),
      lng: getLatestSamplingSiteInventoryData("longitude"),
    };
  }

  function setModalTitle() {
    if (Tree.get("samplingSiteInventoryState", "existing")) {
      const siteIdentifier = Tree.get(
        "samplingSiteInventoryState",
        "currentSamplingSiteData",
        "siteIdentifier",
      );
      const assetDisplayName = ToolSettings.getSetting(
        "constants",
        "assetDisplayNames",
        "sampling-site",
      );
      InventoryModal.setModalTitle(`${assetDisplayName} ID: `, siteIdentifier);

      const siteType = Tree.get(
        "samplingSiteInventoryState",
        "currentSamplingSiteData",
        "displaySiteType",
      );
      InventoryModal.setModalTitle("Type: ", siteType, "type");
    } else {
      InventoryModal.setModalTitle("Add New Sampling Site");
      InventoryModal.setModalTitle("", null, "type");
    }
  }

  function resetAllPages() {
    const allPages = getInventoryPages();
    allPages.forEach((page) => {
      if (page?.resetState) {
        page.resetState();
      }
    });
  }

  function checkSaveAndClose() {
    return new Promise((resolve) => {
      saveAndCloseModal();
      resolve(false);
    });
  }

  function closeInventory() {
    resetInventory();
  }
  function resetInventory() {
    Tree.unset("modalMapSingleMarkerLastValidLatLng");
    Form.clearForm(inventoryFormKey);
    ModalMap.resetModalMap();
    ModalMap.setModalMapView();
  }

  function checkEnableSaveButton(value, path) {
    const existingValue = getExistingData(path);
    if (existingValue !== undefined && value === existingValue) {
      Form.manuallyUnsetField(inventoryFormKey, path);
    }
    if (noUpdatesInForm()) {
      InventoryModal.readySaveState(false);
    } else {
      InventoryModal.readySaveState(true);
    }
  }
  function setFormDataField(path, value) {
    path = Array.isArray(path) ? path : [path];
    Form.manuallySetFormDataField(inventoryFormKey, path, value);
  }
  function noUpdatesInForm() {
    const noUpdates = Object.keys(Form.getDataFromForm(inventoryFormKey, false)).length === 0;
    return noUpdates;
  }
  function renderPageContent(html) {
    InventoryModal.setModalDynamicContent(html);
  }
  function closeModal() {
    InventoryModal.hide();
  }

  async function saveAndCloseModal() {
    try {
      const exists = Tree.get(["samplingSiteInventoryState", "existing"]);
      const currentId = Tree.get(["samplingSiteInventoryState", "currentSamplingSiteData", "id"]);
      const samplingSiteToLoad = Form.getDataFromForm(inventoryFormKey);
      _processFormData(samplingSiteToLoad);

      if (exists) {
        const data = Form.toFormData({
          samplingSiteId: currentId,
          samplingSiteUpdates: samplingSiteToLoad,
        });
        await ApiCalls.updateSamplingSite(data);
        handleCleanUpAfterSave();
      } else {
        const newSiteData = $.extend(
          true,
          {},
          SamplingSiteInventoryConstants.getSamplingSiteInventoryDefaults(),
          samplingSiteToLoad,
        );
        const data = Form.toFormData({
          samplingSiteData: newSiteData,
        });
        await ApiCalls.createNewSamplingSite(data);
        handleCleanUpAfterSave();
      }
    } catch (e) {
      return UploadValidator.reportError(e);
    }
  }

  function handleCleanUpAfterSave() {
    refreshState();
    closeModal();
    SamplingSiteGeoServerLayer.invalidateLayerData();

    if (Tree.get("dataView") === "wq-monitoring") {
      WqMonitoringLocationGeoServerLayer.invalidateLayerData();
    }
  }

  function getInventoryForRender() {
    return InventoryModal.getAllData(inventoryFormKey);
  }

  function getSamplingSiteInventoryFormKey() {
    return inventoryFormKey;
  }

  function _processFormData(samplingSiteToLoad) {
    if (samplingSiteToLoad.samplingSiteFiles !== undefined) {
      const existingFiles = getExistingData("samplingSiteFiles") ?? [];
      samplingSiteToLoad.samplingSiteFiles = CommonModalFunctions.cleanIds(
        samplingSiteToLoad.samplingSiteFiles,
        existingFiles,
      );
      CommonModalFunctions.removeUploadOrLocation(samplingSiteToLoad.samplingSiteFiles);
    }
  }

  function highlightIncompleteFields() {
    const incompleteRequiredFields = getExistingData("incompleteRequiredFields") ?? [];
    CommonModalFunctions.highlightEmptyFormFields(
      $("#inventory-modal .modal-dynamic-content"),
      incompleteRequiredFields,
      Form.getDataFromForm(inventoryFormKey, false),
    );
  }

  return {
    invokeSamplingSiteInventoryModal,
    initializeInventoryModalPages,
    setFormDataField,
    renderPageContent,
    unsetFormDataField,
    getExistingData,
    onFormInput,
    saveAndCloseModal,
    closeModal,
    getLatestSamplingSiteInventoryData,
    loadExistingMarker,
    getInventoryForRender,
    getSamplingSiteInventoryFormKey,
    getInventoryPages,
  };
}

module.exports = SamplingSiteInventoryModalController();

const ApiCalls = require("../apiCalls");
const Form = require("../general/form");
const InventoryModal = require("../general/inventoryModal");
const ModalMap = require("../mapFunctions/modalMap");
const Tree = require("../tree");
const UploadValidator = require("../general/uploadValidator");
const SamplingSiteInventoryConstants = require("./samplingSiteInventoryConstants");
const SamplingSiteInventorySetLocation = require("./samplingSiteInventorySetLocation");
const SamplingSiteInventoryGeneralInfo = require("./samplingSiteInventoryGeneralInfo");
const SamplingSiteInventoryMonitoringScreening = require("./samplingSiteInventoryMonitoringScreening");
const SamplingSiteInventoryFiles = require("./samplingSiteInventoryFiles");
const SamplingSiteGeoServerLayer = require("./samplingSiteGeoServerLayer");
const SamplingSiteIcon = require("./samplingSiteIcon");
const CommonModalFunctions = require("../modals/commonModalFunctions");
const ToolSettings = require("../settings/toolSettings");
const WqMonitoringLocationGeoServerLayer = require("../wq/wqMonitoringLocationGeoServerLayer");
