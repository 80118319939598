"use strict";

const IndComSettings = function () {
  const stringKey = "indcom-settings";
  const tabHeader = "IndCom";

  var loadListeners = function (data) {
    UserVariableValidator.init(
      Object.keys(data.indcom?.maintenanceNoticeUserTemplate || {}),
      ".validate-indcom-maintenance-notice-variables",
    );
  };

  var unloadListeners = function () {};

  var getProps = function (data) {
    return {};
  };

  var getStringKey = function () {
    return stringKey;
  };

  var getTabHeader = function () {
    return tabHeader;
  };

  return {
    loadListeners,
    unloadListeners,
    getStringKey,
    getTabHeader,
    getProps,
  };
};

module.exports = IndComSettings();

const UserVariableValidator = require("../../misc/userVariableValidator");
