"use strict";

const SamplingSiteInventoryFiles = function () {
  const stringKey = "sampling-site-files";
  const headerInformation = "Files";
  var $page;

  var getStringKey = function () {
    return stringKey;
  };

  var getHeaderInformation = function () {
    return headerInformation;
  };

  var loadListeners = function () {
    const inventoryFormKey = SamplingSiteInventoryModalController.getSamplingSiteInventoryFormKey();

    Form.initializeAndLoadListeners($page, inventoryFormKey, { isMultiPart: true });
    $page.on("click", ".save-button", saveFileData);
    $page.on("click", ".edit-button", editFile);
    $page.on("click", `[name*="location-type"]`, setLocationRadioClick);
    $page.on("click", ".new-file", insertNewFile);
    $page.on("click", ".delete-button", deleteFileButtonClick);
    $page.on("click", ".delete-editing-button", deleteEditingFileButtonClick);
    $page.on("click", ".save-initial-button", saveInitialFileData);
  };

  var unloadListeners = function () {
    $page.off("click", ".save-button");
    $page.off("click", ".edit-button");
    $page.off("click", `[name*="location-type"]`);
    $page.off("click", ".new-file");
    $page.off("click", ".delete-button");
    $page.off("click", ".delete-editing-button");
    $page.off("click", ".save-initial-button", saveInitialFileData);
  };

  var render = function (options = {}) {
    const inventoryFormKey = SamplingSiteInventoryModalController.getSamplingSiteInventoryFormKey();

    const site = InventoryModal.getAllData(inventoryFormKey);
    const props = getProps(site);
    renderFilesHtml(props);
    initializeExistingFiles(props);
    loadListeners();
    setInitialState(props, options);
  };

  var getProps = function (site) {
    return {
      samplingSiteFiles: prepareFiles(site),
      fileTypes: SamplingSiteInventoryConstants.getFileTypeOptions(),
      uploadLocation: SamplingSiteInventoryConstants.uploadLocationOptions,
    };
  };

  var prepareFiles = function (props) {
    return props.samplingSiteFiles
      .filter((file) => !file.deleted)
      .map((file) => ({
        ...file,
        displayApprovalDate: getDisplayDateFromIsoDate(file),
        displayType: getDisplayFileType(file.type),
      }))
      .sort((a, b) => a.id - b.id);
  };

  var setInitialState = function (props, options) {
    const numFiles = ((props || {}).samplingSiteFiles || []).length;
    if (options.editFile) {
      editFileById(options.editFile);
    } else if (numFiles === 0 || options.newFile) {
      $page.find(".new-file").hide();
      stageNewFile();
      const index = $page.find(".file-display").first().data("index");
      $page.on(
        "input drop",
        `[name*="samplingSiteFiles[${index}]"], [data-name*="samplingSiteFiles[${index}]"]`,
        stageInitialFileObj,
      );
    }
  };

  var insertNewFile = function () {
    const inventoryFormKey = SamplingSiteInventoryModalController.getSamplingSiteInventoryFormKey();
    const { html, newFile, currentFilesLength } = createFileHtml();
    Form.manuallySetFormDataField(
      inventoryFormKey,
      ["samplingSiteFiles", currentFilesLength],
      newFile,
    );
    $page.find(".sampling-site-files").prepend(html);
    toggleEditSaveButtonsDisabled(true);
    initializeAdditionalFormFields(newFile);
  };

  var stageNewFile = function () {
    const { html, newFile, currentFilesLength } = createFileHtml(true);
    $page.on("input", `[name*="samplingSiteFiles[${currentFilesLength}]"]`, createFormFileEntry);
    $page.find(".sampling-site-files").prepend(html);
    toggleEditSaveButtonsDisabled(true);
    initializeAdditionalFormFields(newFile);
  };

  var createFormFileEntry = function (e) {
    const filePosition = $(e.currentTarget).parents(".file-display").data("index");
    stageInitialFileObj(e);
    removeInitialInputListener(filePosition);
  };

  var createFileHtml = function (initialFile = false) {
    const fileTypes = SamplingSiteInventoryConstants.getFileTypeOptions();
    var uploadLocation = SamplingSiteInventoryConstants.uploadLocationOptions;
    uploadLocation = uploadLocation.find((location) => {
      return location.value === "external-url";
    });

    const newId = Date.now();
    const newFile = {
      id: newId,
      type: fileTypes[0].value,
      locationType: uploadLocation.value,
      displayLocationType: uploadLocation.name,
      approvalDate: DateTime.getTodayIso(),
    };
    const currentFilesLength = getCurrentFilesLength();

    return {
      html: nunjucks.render("modals/samplingSiteInventory/samplingSiteInventoryNewFile.njk", {
        fileTypes: fileTypes,
        file: newFile,
        uploadLocation: SamplingSiteInventoryConstants.uploadLocationOptions,
        index: currentFilesLength,
        initialFile: initialFile,
      }),
      newFile: newFile,
      currentFilesLength: currentFilesLength,
    };
  };

  var getCurrentFilesLength = function () {
    const inventoryFormKey = SamplingSiteInventoryModalController.getSamplingSiteInventoryFormKey();
    const data = InventoryModal.getAllData(inventoryFormKey);
    return (data.samplingSiteFiles || []).length;
  };

  var stageInitialFileObj = function (e) {
    const $firstFile = $page.find(".sampling-site-files .file").first();
    const fileId = $firstFile.data("id");
    const filePosition = $(e.currentTarget).parents(".file").find(".file-display").data("index");
    const inventoryFormKey = SamplingSiteInventoryModalController.getSamplingSiteInventoryFormKey();

    if (!getFileById(fileId)) {
      const type = $firstFile.find(`[name="samplingSiteFiles[${filePosition}][type]"]`).val();
      const locationType = $firstFile
        .find(`[name="samplingSiteFiles[${filePosition}][location-type]"] option:selected`)
        .val();
      const approvalDate = $firstFile
        .find(`[name="samplingSiteFiles[${filePosition}][approval-date]"]`)
        .val();
      Form.manuallySetFormDataField(
        inventoryFormKey,
        ["samplingSiteFiles", filePosition, "id"],
        fileId,
      );
      Form.manuallySetFormDataField(
        inventoryFormKey,
        ["samplingSiteFiles", filePosition, "locationType"],
        locationType,
      );
      Form.manuallySetFormDataField(
        inventoryFormKey,
        ["samplingSiteFiles", filePosition, "type"],
        type,
      );
      Form.manuallySetFormDataField(
        inventoryFormKey,
        ["samplingSiteFiles", filePosition, "approvalDate"],
        DateTime.truncateMilliseconds(approvalDate),
      );
    }

    $page.off(
      "input drop",
      `[name*="samplingSiteFiles[${filePosition}]"], [data-name*="samplingSiteFiles[${filePosition}]"] `,
      stageInitialFileObj,
    );
  };

  var initializeAdditionalFormFields = function (file) {
    const displayDate = getIsoDateFromDisplayDate(file);
    initializeDatePicker(displayDate);
    initializeDropzone(file, false);
    showInputByLocationType(file.locationType);
  };

  var initializeDatePicker = function (date) {
    const inventoryFormKey = SamplingSiteInventoryModalController.getSamplingSiteInventoryFormKey();
    Form.initializeDatePickers($page, inventoryFormKey);
    if (date) {
      DateTimePicker.setDateOnElement($page.find("[name*=approval-date]").assertLength(1), date);
    }
  };

  var initializeExistingFiles = function (site) {
    site.samplingSiteFiles.forEach((existingFile) => {
      initializeDropzone(existingFile, true);
    });
  };

  var initializeDropzone = function (file, readOnly) {
    const $parent = $page.find(`[data-id=${file.id}]`);
    const dropzoneName = $parent.find(".drop-zone").attr("name");
    const inventoryFormKey = SamplingSiteInventoryModalController.getSamplingSiteInventoryFormKey();

    let newFile = [];
    let existingFile = [];
    if ($.isArray(file.fileUploadLocation)) {
      newFile = file.fileUploadLocation;
    } else if (file.uploadedFilename) {
      existingFile = [file.uploadedFilename];
    }
    const dropzone = Form.initializeDropzone(inventoryFormKey, $parent, {
      newFiles: newFile,
      existingFiles: existingFile,
      maxNumberFiles: 1,
      readOnly,
    });

    configureDropzoneHandlers(file.id, dropzoneName, dropzone);
  };

  var configureDropzoneHandlers = function (fileId, dropzoneName, dropzone) {
    dropzone.setDownloadHandler(() => ApiCalls.downloadSamplingSiteFile(fileId));
    dropzone.setRemoveExistingCallback((file) => {
      removeFileFromForm(dropzoneName, file);
    });
  };

  var setLocationRadioClick = function (e) {
    const value = $(e.currentTarget).val();
    showInputByLocationType(value);
  };

  var editFile = function (e) {
    const $file = $(e.currentTarget);
    const fileId = $file.closest(".file").data("id");
    editFileById(fileId);
  };

  var editFileById = function (fileId) {
    const fileInfo = getFileById(fileId);
    const options = getFileTypeOptionsWithFile(fileInfo);

    const html = nunjucks.render(
      "modals/samplingSiteInventory/samplingSiteInventoryModifyFile.njk",
      {
        fileTypes: options,
        uploadLocation: SamplingSiteInventoryConstants.uploadLocationOptions,
        file: fileInfo,
        index: getFileIndexById(fileId),
      },
    );
    $(`.file[data-id=${fileId}]`).html(html);
    toggleEditSaveButtonsDisabled(true);
    initializeAdditionalFormFields(fileInfo);
  };

  var getFileTypeOptionsWithFile = function (fileInfo) {
    const options = Array.from(SamplingSiteInventoryConstants.getFileTypeOptions());

    if (
      !options.some(function (option) {
        return option.value === fileInfo.type;
      })
    ) {
      options.unshift(getFileType(fileInfo.type));
    }

    return options;
  };

  var saveInitialFileData = function (e) {
    stageInitialFileObj(e);
    saveFileData(e);
  };

  var saveFileData = function (e) {
    const $file = $(e.currentTarget);
    const fileId = $file.closest(".file").data("id");
    let file = getFileById(fileId);
    const type = SamplingSiteInventoryConstants.uploadLocationOptions.find((option) => {
      return option.value === file.locationType;
    });
    if (type === undefined) {
      throw new Error(`File type ${file.locationType} does not exist.`);
    }
    file.displayLocationType = type.name;
    file = addAbsoluteUrlsToFiles([file])[0];
    const html = nunjucks.render(
      "modals/samplingSiteInventory/samplingSiteInventoryExistingFile.njk",
      {
        file: {
          ...file,
          displayApprovalDate: getDisplayDateFromIsoDate(file),
          displayType: getDisplayFileType(file.type),
        },
      },
    );
    removeInitialInputListener();
    toggleEditSaveButtonsDisabled(false);
    $file.closest("section").html(html);
    initializeDropzone(file, true);
  };

  var deleteFileButtonClick = function (e) {
    showDeleteFileModal(e, false);
  };

  var deleteEditingFileButtonClick = function (e) {
    showDeleteFileModal(e, true);
  };

  var showDeleteFileModal = function (e, editingFile) {
    const $file = $(e.currentTarget).closest(".file");
    const fileId = $file.data("id");
    const displayFileType = getDisplayFileTypeById(fileId);
    if (displayFileType) {
      const deleteCallback = function () {
        deleteFile(fileId);
        if (editingFile === true) {
          toggleEditSaveButtonsDisabled(false);
        }
      };

      MessageModal.showConfirmWarningModal(
        `You have selected to delete this ${displayFileType}. To permanently delete this file from your 2NFORM data, click Delete. To keep the record, click Cancel.`,
        deleteCallback,
        "Cancel",
        "Delete",
      );
    } else {
      removeInitialInputListener();
      $(`[data-id="${fileId}"]`).remove();
      toggleEditSaveButtonsDisabled(false);
    }
  };

  var deleteFile = function (fileId) {
    const inventoryFormKey = SamplingSiteInventoryModalController.getSamplingSiteInventoryFormKey();
    const fileIndex = getFileIndexById(fileId);
    const existingFileIds = (
      SamplingSiteInventoryModalController.getExistingData("samplingSiteFiles") || []
    ).map((file) => {
      return file.id;
    });

    if (existingFileIds.includes(fileId)) {
      Form.manuallySetFormDataField(inventoryFormKey, ["samplingSiteFiles", fileIndex], {
        deleted: true,
        id: fileId,
      });
    } else {
      Form.manuallyUnsetField(inventoryFormKey, ["samplingSiteFiles", fileIndex]);
    }
    $(`[data-id="${fileId}"]`).remove();
  };

  var toggleEditSaveButtonsDisabled = function (disabled) {
    if (disabled === false) {
      $page.find(".new-file").show();
    }
    $(".edit-button, .new-file").prop("disabled", disabled);
  };

  var renderFilesHtml = function (site) {
    site.samplingSiteFiles = addAbsoluteUrlsToFiles(site.samplingSiteFiles || []);
    const html = nunjucks.render(
      "modals/samplingSiteInventory/samplingSiteInventoryFiles.njk",
      site,
    );
    SamplingSiteInventoryModalController.renderPageContent(html);
    $page = $("#inventory-modal .modal-dynamic-content").assertLength(1);
  };

  function addAbsoluteUrlsToFiles(files) {
    return files.map((file) => {
      const makeAbsolute = file.externalUrl && !/^(https?:)?\/\/.*$/.test(file.externalUrl);
      file.absoluteUrl = makeAbsolute ? "//" + file.externalUrl : file.externalUrl;
      return file;
    });
  }

  var getFileById = function (fileId) {
    const inventoryFormKey = SamplingSiteInventoryModalController.getSamplingSiteInventoryFormKey();
    const data = InventoryModal.getAllData(inventoryFormKey);
    return data.samplingSiteFiles.find((file) => {
      return file?.id === fileId;
    });
  };

  var getFileIndexById = function (fileId) {
    const inventoryFormKey = SamplingSiteInventoryModalController.getSamplingSiteInventoryFormKey();
    const data = InventoryModal.getAllData(inventoryFormKey);
    if (data.samplingSiteFiles) {
      return data.samplingSiteFiles.findIndex((file) => {
        return fileId === (file || {}).id;
      });
    }
  };

  var getDisplayFileTypeById = function (fileId) {
    const inventoryFormKey = SamplingSiteInventoryModalController.getSamplingSiteInventoryFormKey();
    const fileIndex = getFileIndexById(fileId);
    const fileType = InventoryModal.getLatestData(inventoryFormKey, [
      "samplingSiteFiles",
      fileIndex,
      "type",
    ]);
    return fileType === undefined ? undefined : getDisplayFileType(fileType);
  };

  var getDisplayDateFromIsoDate = function (file) {
    if (file.approvalDate !== undefined) {
      return DateTime.formatIsoString(file.approvalDate);
    } else {
      return file.displayApprovalDate;
    }
  };

  var getIsoDateFromDisplayDate = function (file) {
    if (file.approvalDate === undefined) {
      return DateTime.parseDateToIso(file.displayApprovalDate);
    } else return file.approvalDate;
  };

  var getDisplayFileType = function (fileValue) {
    return getFileType(fileValue).displayName;
  };

  var getFileType = function (fileValue) {
    return SamplingSiteInventoryConstants.getFileTypeOptions().find((options) => {
      return options.value === fileValue;
    });
  };

  var cleanUp = function () {
    unloadListeners();
    $page.empty();
    return true;
  };

  var validate = function () {
    return true;
  };

  var showInputByLocationType = function (locationType) {
    $page.find(".file-text-description").toggle(locationType === "location");
    $page.find(".file-drop-zone").toggle(locationType === "upload");
    $page.find(".file-external-url").toggle(locationType === "external-url");
  };

  const removeFileFromForm = function (dropzoneName, file) {
    const inventoryFormKey = SamplingSiteInventoryModalController.getSamplingSiteInventoryFormKey();
    const pathParts = Form.getPathPartsFromName(dropzoneName);
    Form.manuallySetFormDataField(inventoryFormKey, pathParts, "");
  };

  var removeInitialInputListener = function (length) {
    const currentFilesLength = length || getCurrentFilesLength();
    $page.off("input", `[name*="samplingSiteFiles[${currentFilesLength}]"]`, createFormFileEntry);
  };

  return {
    getStringKey,
    getHeaderInformation,
    render,
    cleanUp,
    validate,
    deleteFile,
  };
};

module.exports = SamplingSiteInventoryFiles();

const SamplingSiteInventoryModalController = require("./samplingSiteInventoryModalController");
const InventoryModal = require("../general/inventoryModal");
const SamplingSiteInventoryConstants = require("./samplingSiteInventoryConstants");
const Form = require("../general/form");
const DateTime = require("../dateTime");
const DateTimePicker = require("../general/dateTimePicker");
const MessageModal = require("../modals/messageModal");
const ApiCalls = require("../apiCalls");
