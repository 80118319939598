"use strict";

const OutfallRoutineMaintenanceController = function () {
  const htmlPath = "modals/outfallRoutineMaintenance";
  const formKey = "outfall-routine-maintenance";

  let $modal;
  let tabs;
  let dataStore;

  var showOutfallRoutineMaintenanceModal = function (newDataStore, readOnly) {
    dataStore = newDataStore;
    const initialData = dataStore.getInitial();

    tabs = getTabs(initialData);
    const renderData = mapTabProps(tabs);

    $modal = TabbedModal.showTabbedModal(formKey, renderData, tabs, htmlPath, { readOnly });

    initializeForm();
    DryWeatherController.checkFlowTabs(dataStore.getWithUpdates());
    setInspectionHeaderText();
    setAdditionalModalHandlers();
    CommonModalFunctions.highlightEmptyFormFields(
      $modal,
      initialData.outfallInfo.requiredFields,
      dataStore.getWithUpdates(),
      {},
      ["outfallInfo"],
    );
  };

  var getTabs = function (data) {
    const GeneralInfo = require("./outfallRoutineMaintenanceGeneralInfo");
    const Condition = require("./outfallRoutineMaintenanceCondition");
    const Repair = require("./outfallRoutineMaintenanceRepair");
    const tabs = [new GeneralInfo(module.exports)];

    if (data.activeMaintenanceIssues?.length) {
      tabs.push(Repair);
    }

    tabs.push(Condition);
    DryWeatherController.addDryWeatherTabs(tabs, module.exports);

    return tabs;
  };

  var mapTabProps = function (tabs) {
    const data = dataStore.getWithUpdates();
    const tabProps = tabs.reduce((props, tab) => {
      return $.extend(true, props, tab.getProps(data));
    }, {});

    return {
      ...data,
      ...tabProps,
      showStructuralAttributes: !ToolSettings.getSetting("outfall", "hide-asset-attributes"),
      showPrecipitation: !ToolSettings.getSetting("outfall", "hide-precipitation"),
      showFieldMeasurements: !ToolSettings.getSetting("outfall", "hide-field-measurements"),
      datumOptions: OutfallInventoryConstants.datumOptions,
      wqMonitoringWorkflow: FeatureFlag.enabled("sampling-site-inspection"),
    };
  };

  var getHeaderHtml = function () {
    const data = dataStore.getInitial();
    return nunjucks.render("modals/outfallRoutineMaintenance/outfallInspectionsHeader.njk", data);
  };

  var setInspectionHeaderText = function () {
    TabbedModal.setHeaderText(getHeaderHtml());
  };

  var setAdditionalModalHandlers = function () {
    TabbedModal.setCancelHandler(dataStore.discard);
    TabbedModal.setSaveHandler(saveButtonClick, false);
    TabbedModal.setFormHasUpdatesHandler(formHasUpdates);
    TabbedModal.goToPrevUrlOnClose();
  };

  var saveButtonClick = async function () {
    const initialData = dataStore.getInitial();

    TabbedModal.hide(false);

    const results = FeatureFlag.enabled("sampling-site-inspection")
      ? getResultsWqMonitoringWorkflow(initialData)
      : getResults(initialData);

    ResultModal.renderAndShow(
      results,
      getHeaderHtml(initialData.outfallInfo),
      saveAndCloseModal,
      TabbedModal.show,
      {
        extraButtons: [{ text: "Preview", type: "primary", callback: downloadPreviewPdf }],
      },
    );

    return false;
  };

  var getResults = function (initialData) {
    const updatedData = getUpdateData(dataStore.getInitial(), dataStore.getForm(), true)?.updates;
    const dryWeatherResult = DryWeatherResult.getDryWeatherResultData(
      initialData.outfallInfo.outfallName,
      getInspectionRenderData(),
    );
    var results = [dryWeatherResult];

    if (initialData.maintenanceType === "routine") {
      results.unshift(
        MaintenanceResult.getRoutineMaintenanceResultData(
          initialData.outfallInfo.outfallName,
          updatedData?.maintenanceIssues,
          updatedData?.assetAccessible,
        ),
      );
    }

    return results;
  };

  var getResultsWqMonitoringWorkflow = function (initialData) {
    const updatedData = getUpdateData(dataStore.getInitial(), dataStore.getForm(), true)?.updates;
    var results = [];

    if (updatedData.inspectionType === "wq-monitoring") {
      const wqMonitoringResult = WqMonitoringResult.getWqMonitoringResultData(
        initialData.outfallInfo.outfallName,
        getInspectionRenderData(),
      );
      results.push(wqMonitoringResult);
    } else if (updatedData.inspectionType === "idde-screening") {
      const dryWeatherResult = DryWeatherResult.getDryWeatherResultData(
        initialData.outfallInfo.outfallName,
        getInspectionRenderData(),
      );
      results.push(dryWeatherResult);
    }

    if (updatedData.inspectStructuralConditionMaintenance) {
      const routineInspectionResult = MaintenanceResult.getRoutineMaintenanceResultData(
        initialData.outfallInfo.outfallName,
        updatedData?.maintenanceIssues,
        updatedData?.assetAccessible,
      );

      results.unshift(routineInspectionResult);
    }

    return results;
  };

  var getUpdateData = function (initial, dataToUpload, isNew) {
    DryWeatherController.processInspectionFormData(initial, dataToUpload);
    if (isNew) {
      dataToUpload = $.extend(true, {}, initial, dataToUpload);
    } else {
      dataToUpload = mergeIssueIdsIntoUpdates(initial, dataToUpload);
    }

    OutfallInspectionConstants.migrateOutletShape(dataToUpload.outfallInfo);
    dataToUpload = RoutineMaintenanceFormTable.combineRoutineMaintenanceIssues(dataToUpload, [
      "structuralConditionIssues",
      "activeMaintenanceIssues",
    ]);
    dataToUpload.maintenanceIssues = RoutineMaintenanceFormTable.cleanRoutineMaintenanceIssues(
      dataToUpload.maintenanceIssues,
      !isNew,
    );

    if (isNew) {
      return {
        updates: dataToUpload,
      };
    } else {
      return {
        id: initial.id,
        updates: dataToUpload,
      };
    }
  };

  var mergeIssueIdsIntoUpdates = function (initial, dataToUpload) {
    if (dataToUpload.outfallInfo) {
      dataToUpload.outfallInfo.id = initial.outfallInfo.id;
    }

    addIssueIds(initial, dataToUpload, "structuralConditionIssues");
    addIssueIds(initial, dataToUpload, "activeMaintenanceIssues");

    return dataToUpload;
  };

  var addIssueIds = function (initial, updates, key) {
    for (const i in updates[key]) {
      updates[key][i].id = initial[key][i].id;
    }
  };

  var formHasUpdates = function () {
    return !Form.isFormEmpty(formKey);
  };

  var getInspectionRenderData = function () {
    return dataStore.getWithUpdates();
  };

  var getExistingInspectionData = function () {
    return dataStore.getInitial();
  };

  var getFormData = function (path) {
    return Form.getFormDataAtPath(formKey, path);
  };

  var setFormData = function (path, data) {
    return Form.manuallySetFormDataField(formKey, path, data);
  };

  var unsetFormData = function (path) {
    return Form.manuallyUnsetField(formKey, path);
  };

  var downloadPreviewPdf = async function () {
    InspectionPreview.preview(async function () {
      await Form.getReadyToSavePromise(formKey);
      return getUpdateData(dataStore.getInitial(), dataStore.getForm(), dataStore.isNew());
    }, ApiCalls.previewOutfallInspectionPdf);
  };

  var saveAndCloseModal = async function () {
    await Form.getReadyToSavePromise(formKey);
    await dataStore.save();
    TabbedModal.hide();
  };

  var _getTabs = function () {
    return tabs;
  };

  var initializeForm = function () {
    Form.initializeAndLoadListeners($modal, formKey, { dataStore });
    FormSettings.customizeForm($modal, formKey);

    const existingFiles = dataStore.getInitial().files ?? [];
    const newFiles = dataStore.getForm().files;
    Form.initializeDropzone(formKey, $modal, {
      newFiles,
      existingFiles: existingFiles,
      downloadCallback: ApiCalls.downloadOutfallInspectionFile,
    });
  };

  return {
    showOutfallRoutineMaintenanceModal,
    getInspectionRenderData,
    getExistingInspectionData,
    getFormData,
    setFormData,
    unsetFormData,
    saveAndCloseModal,
    getUpdateData,
    _getTabs,
  };
};

module.exports = OutfallRoutineMaintenanceController();

const ApiCalls = require("../../apiCalls");
const CommonModalFunctions = require("../../modals/commonModalFunctions");
const DryWeatherResult = require("../../general/results/dryWeatherResult");
const Form = require("../../general/form");
const MaintenanceResult = require("../../general/results/maintenanceResult");
const ResultModal = require("../../general/resultModal");
const RoutineMaintenanceFormTable = require("../../general/routineMaintenanceFormTable");
const TabbedModal = require("../../general/tabbedModal");
const ToolSettings = require("../../settings/toolSettings");
const FormSettings = require("../../settings/formSettings");
const OutfallInspectionConstants = require("../outfallInspectionConstants");
const OutfallInventoryConstants = require("../outfallInventoryConstants");
const DryWeatherController = require("../../illicitDischarge/dryWeatherScreening/dryWeatherController");
const InspectionPreview = require("../../general/inspectionPreview");
const WqMonitoringResult = require("../../general/results/wqMonitoringResult");
const FeatureFlag = require("../../settings/featureFlag");
