"use strict";

const PauseProjectModal = function () {
  const formKey = "post-active";
  var $modal;
  var inited = false;
  var props;

  var init = function () {
    $modal = $("#pause-project-modal");
    loadDomListeners();
    inited = true;
  };

  var loadDomListeners = function () {
    $modal.off("click", ".save-pause-status-button", savePauseStatusButtonClick);
    $modal.on("click", ".save-pause-status-button", savePauseStatusButtonClick);

    $modal.off("click", ".cancel-button", _closeModalIfConfirmed);
    $modal.on("click", ".cancel-button", _closeModalIfConfirmed);

    $modal.off("2N:FormInput", _validateForm);
    $modal.on("2N:FormInput", _validateForm);
  };

  var savePauseStatusButtonClick = function () {
    let message = "";
    if (props.pauseStatus === "paused") {
      message = `<p>Active construction for Project ${props.projectIdentifier} will be restarted. Scheduled inspections will resume on the same schedule as before. Active construction can be paused again at any time.</p>
                <p>Click Save to restart active construction. Click Go Back to return to the data entry form.<p>`;
    } else {
      message = `<p>Active construction for Project ${props.projectIdentifier} will be paused. No inspections will be scheduled while paused. Active construction can be restarted at any time.</p>
                <p>Click Save to pause active construction. Click Go Back to return to the data entry form.</p>`;
    }

    MessageModal.showConfirmWarningModal(null, _savePauseStatus, "Go Back", "Save", message);
  };

  var _savePauseStatus = async function () {
    $modal.find(".modal-footer .save-pause-status-button").addClass("disabled").text("Saving");

    var projectUpdate = Form.getDataFromForm(formKey);
    if (props.pauseStatus === "paused") {
      projectUpdate.pauseRecord.action = "restart";
    } else {
      projectUpdate.pauseRecord.action = "pause";
    }

    return updateProject(projectUpdate);
  };

  var updateProject = async function (projectUpdate) {
    var projectId = props.id;

    var formData = Form.toFormData({
      constructionProjectId: projectId,
      constructionProjectUpdates: projectUpdate,
    });
    await ApiCalls.updateConstructionProjectAsPromise(formData);

    if (Tree.get("tool") === "construction") {
      ConstructionProjectGeoServerLayer.invalidateLayerData();
    } else if (Tree.get("tool") === "scenario") {
      ScenariosLayer.loadLayer();
    } else {
      ConstructionProjectLayer.loadLayer();
    }

    ToDoListController.loadTodos();
    close();
  };

  var _closeModalIfConfirmed = function () {
    if (Object.keys(Form.getDataFromForm(formKey, false)).length === 0) {
      close();
    } else {
      MessageModal.showConfirmWarningModal(
        null,
        close,
        "Go Back",
        "Close Without Saving",
        CommonModalFunctions.getCloseWithoutSavingPrompt(),
      );
    }
  };

  var renderAndShow = async function (projectId) {
    if (!inited) init();
    const postActiveStatus = await ApiCalls.getProjectPauseStatus(projectId);
    props = Object.assign({}, postActiveStatus);
    const renderPromise = render();
    Form.initializeAndLoadListeners($modal, formKey);
    initializeFields();
    Analytics.sendScreenView("modal", formKey);
    if (props.lastPauseRecordDate) {
      DateTimePicker.setDatePickerRange(
        $modal,
        "pauseRecord[date]",
        false,
        props.lastPauseRecordDate,
      );
    }
    return renderPromise;
  };

  var render = async function () {
    const html = nunjucks.render("modals/projectInventory/pauseProjectModal.njk", props);
    $modal.html(html);
    return new Promise((resolve) => {
      $modal.one("shown.bs.modal", resolve);
      $modal.modal("show");
    });
  };

  var initializeFields = function () {
    Form.initializeDatePickers($modal, formKey);
    _validateForm();
  };

  var _validateForm = function () {
    props.canSavePauseStatus = canSavePauseStatus();

    if (props.pauseStatus === "paused") {
      if (props.canSavePauseStatus) {
        $modal.find(".next-inspection-due-date").text(props.nextInspectionDueDate);
      } else {
        $modal.find(".next-inspection-due-date").text("—");
      }
    }

    $modal
      .find(".save-pause-status-button")
      .toggleClass("disabled", !props.canEditProject || !props.canSavePauseStatus);
  };

  var canSavePauseStatus = function () {
    const datePopulated = !!(
      Form.getFormDataAtPath(formKey, ["pauseRecord", "date"]) ?? props[("pauseRecord", "date")]
    );

    return datePopulated;
  };

  var close = function () {
    Form.clearForm(formKey);
    $modal.modal("hide");
    inited = false;
    Analytics.sendScreenView();
  };

  return {
    renderAndShow,
    close,
    render,
    _closeModalIfConfirmed,
    _validateForm,
    _savePauseStatus,
  };
};

module.exports = PauseProjectModal();

const Analytics = require("../general/analytics");
const ApiCalls = require("../apiCalls");
const CommonModalFunctions = require("../modals/commonModalFunctions");
const DateTimePicker = require("../general/dateTimePicker");
const Form = require("../general/form");
const MessageModal = require("../modals/messageModal");
const ToDoListController = require("../mapFunctions/toDoListController");
const Tree = require("../tree");
const ConstructionProjectLayer = require("./constructionProjectLayer");
const ConstructionProjectGeoServerLayer = require("../constructionG2/constructionProjectGeoServerLayer");
const ScenariosLayer = require("../scenario/scenariosLayer");
