"use strict";

const ConsoleCommands = function () {
  const breakOffline = function () {
    const ResourceController = require("./offline/resourceController");
    ResourceController._closeAll();
    return "Offline broken.";
  };

  const removeToken = function () {
    const TokenManager = require("./login/tokenManager");
    TokenManager.removeToken();
    return "Token removed.";
  };

  const getCachedObjectStores = async function (resourceName) {
    const ResourceController = require("./offline/resourceController");

    const resource = await ResourceController.get(resourceName);
    return resource._getCachedObjectStores();
  };

  const samlLogin = function (email) {
    const SingleSignOn = require("./login/sso");

    return SingleSignOn.samlLogin(email);
  };

  const importOfflineData = async function () {
    const LoadingScreen = require("./general/loadingScreen");

    LoadingScreen.show();

    const input = document.createElement("input");
    input.type = "file";
    input.multiple = true;
    input.accept = ".json";
    input.click();

    await new Promise((resolve) => input.addEventListener("change", () => resolve()));
    const fileContentsByFileName = await _readFiles(input);
    const results = await _importFiles(fileContentsByFileName);
    LoadingScreen.hide();
    return results;
  };

  const _readFiles = async function (input) {
    const fileContentsByFileName = {};

    for (const file of input.files) {
      const reader = new FileReader();
      const json = await new Promise((resolve) => {
        reader.onload = () => resolve(reader.result);
        reader.readAsText(file, "UTF-8");
      });
      fileContentsByFileName[file.name] = json;
    }

    return fileContentsByFileName;
  };

  const _importFiles = async function (fileContentsByFileName) {
    const OfflineImporter = require("./offline/offlineImporter");
    let metadata = null;
    const files = [];

    for (const fileName in fileContentsByFileName) {
      if (fileName.endsWith("metadata.json")) {
        if (metadata) {
          console.error("Multiple metadata files found. Only the first one will be used.");
        } else {
          metadata = fileContentsByFileName[fileName];
        }
      } else {
        files.push(fileContentsByFileName[fileName]);
      }
    }

    return await OfflineImporter.importAll(metadata, files);
  };

  const openBlob = function (blob) {
    const PreviewDownloader = require("./files/previewDownloader");
    const preview = new PreviewDownloader();
    preview.openBlob(blob);
  };

  return {
    breakOffline,
    removeToken,
    getCachedObjectStores,
    samlLogin,
    importOfflineData,
    openBlob,
  };
};

module.exports = ConsoleCommands();

// `require()` modules inside functions above to avoid circular decencies
