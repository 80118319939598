"use strict";

const SamplingSiteGeoServerLayer = function () {
  const layerName = "samplingSites";
  const layerKey = "samplingSitesLayer";

  var createMarkerFunction = function (feature, latlng) {
    return SamplingSiteIcon.getSamplingSiteMarker(latlng);
  };

  var loadLayerListenersForMap = function (map, mapLayers, mapId) {
    const layer = new GeoServerMapLayer(layerName, layerKey, createMarkerFunction);
    GeoServerLayerFunctions.setLayerInstanceByMapId(layerName, mapId, layer);

    layer.loadToggledListenerForMap(map, mapLayers, mapId);
    layer.loadTreeUpdateListenersForMap(map, mapLayers, mapId);
  };

  var loadLayer = function (mapId = "main") {
    GeoServerLayerFunctions.loadLayerByMapId(layerName, mapId);
  };

  var invalidateLayerData = function (loadingScreen = true) {
    GeoServerLayerFunctions.invalidateLayerDataForMainMap(layerName, loadingScreen);
  };

  var organizeLayerData = function (layerData) {
    return layerData.map(function (data) {
      return getOrganizedProperties(data.properties, data.geometry);
    });
  };

  var getOrganizedProperties = function (data, geometry) {
    const properties = {
      id: data.id,
      groupId: data.group_id,
      geometry: geometry,
      siteIdentifier: data.site_identifier,
      siteName: data.site_name,
      sitePhase: data.site_phase,
      siteType: data.site_type,
      address: data.address,
      city: data.city,
      state: data.state,
      zip: data.zip,
      maintainerType: data.maintainer_type,
      maintainedBy: data.maintainer_type,
      notes: data.notes,

      permissions: {
        // @TODO: Update to use sampling stie permissions
        canCreateOutfallInspection: ToolSettings.getSetting(
          "permissions",
          "can_create_outfall_inspection",
        ),
        canDeleteOutfallInspection: ToolSettings.getSetting(
          "permissions",
          "can_delete_outfall_inspection",
        ),
        canBeDeleted: ToolSettings.getSetting("permissions", "can_delete_outfall"),
        canBeEdited: ToolSettings.getSetting("permissions", "can_edit_outfall"),
      },

      catchment_name: data.catchment_name,
      drains_to_rw: data.drains_to_rw,
      drains_to_c: data.drains_to_c,
      zone_names: data.zone_names,
    };

    properties.tableIcon = SamplingSiteIcon.getSamplingSiteIconClass();

    return properties;
  };

  var getLayerData = async function (onlineFiltering = false, loadingScreen = true) {
    const data = await GeoServerLayerFunctions.getFeaturesForLayer(
      layerName,
      onlineFiltering,
      loadingScreen,
    );
    return organizeLayerData(data);
  };

  return {
    loadLayerListenersForMap,
    loadLayer,
    invalidateLayerData,
    organizeLayerData,
    getLayerData,
  };
};

module.exports = SamplingSiteGeoServerLayer();

const GeoServerLayerFunctions = require("../mapFunctions/geoServerLayerFunctions");
const GeoServerMapLayer = require("../mapFunctions/geoServerMapLayer");
const SamplingSiteIcon = require("./samplingSiteIcon");
const ToolSettings = require("../settings/toolSettings");
