"use strict";

const OutfallRoutineMaintenanceGeneralInfo = function (Controller) {
  const stringKey = "general-info";
  let $page;

  var loadListeners = function (data) {
    $page = $(`#assessment-modal #${stringKey}`);
    $page.on("2N:FormInput", `[name="outfall-info[outlet-shape]"]`, onOutletShapeChange);
    $page.on("2N:FormInput", `[name="outfall-info[outlet-material]"]`, onOutletMaterialChange);

    if (FeatureFlag.enabled("sampling-site-inspection")) {
      RoutineMaintenanceFormGeneral.loadListenersWqMonitoringWorkflow($page, data, Controller);
    } else {
      RoutineMaintenanceFormGeneral.loadListeners(
        $page,
        data,
        [stringKey, "maintenance-repair"],
        Controller,
      );
    }
  };

  var unloadListeners = function () {
    $page.off("2N:FormInput", `[name="outfall-info[outlet-shape]"]`, onOutletShapeChange);
    $page.off("2N:FormInput", `[name="outfall-info[outlet-material]"]`, onOutletMaterialChange);
    RoutineMaintenanceFormGeneral.unloadListeners($page);
  };

  var getProps = function (data) {
    const props = {
      hideFieldMeasurements: ToolSettings.getSetting("outfall", "hide-field-measurements"),
      outletMaterialOptions: OutfallInventoryGeneralInfo.sortOptions(
        OutfallInspectionConstants.getOutletMaterialOptions(),
      ),
      typeOptions: OutfallInspectionConstants.getOutletTypeOptions(),
      outletShapeOptions: OutfallInspectionConstants.getOutletShapeOptions(),
      lengthUnitOptions: OutfallInspectionConstants.lengthUnitOptions,
      elevationUnitOptions: OutfallInspectionConstants.elevationUnitOptions,
      isOutletCircular: OutfallInspectionConstants.outletShapeIsCircular(
        data.outfallInfo?.outletShape,
      ),
      enableAssessmentTime: ToolSettings.getSetting("outfall", "hide-field-measurements"),
      wqInspectionTypeOptions: WqMonitoringConstants.getWqInspectionTypeOptions(),
      flowConditionOptions: WqMonitoringConstants.getFlowConditionOptions(),
      currentWeatherOptions: WqMonitoringConstants.getCurrentWeatherOptions(),
    };

    RoutineMaintenanceFormGeneral.addProps(props, data);

    return props;
  };

  var getStringKey = function () {
    return stringKey;
  };

  var getTabHeader = function () {
    return RoutineMaintenanceFormGeneral.getTabName();
  };

  var getPage = function () {
    return $page;
  };

  var onOutletShapeChange = function (e, outletShape) {
    OutfallInventoryGeneralInfo.toggleOutfallDimensionsByShape(
      outletShape,
      $page,
      $page.find(`[name="outfall-info[outlet-diameter]"]`),
      $page.find(`[name="outfall-info[outlet-length]"]`),
      $page.find(`[name="outfall-info[outlet-width]"]`),
    );
  };

  var onOutletMaterialChange = function (e, outletMaterial) {
    OutfallInventoryGeneralInfo.toggleOutfallMaterialChange($page, outletMaterial);
  };

  return {
    loadListeners,
    unloadListeners,
    getStringKey,
    getTabHeader,
    getProps,
    getPage,
  };
};

module.exports = OutfallRoutineMaintenanceGeneralInfo;

const OutfallInspectionConstants = require("../outfallInspectionConstants");
const OutfallInventoryGeneralInfo = require("../outfallInventoryGeneralInfo");
const RoutineMaintenanceFormGeneral = require("../../general/routineMaintenanceFormGeneral");
const ToolSettings = require("../../settings/toolSettings");
const WqMonitoringConstants = require("../../wq/inspection/wqMonitoringConstants");
const FeatureFlag = require("../../settings/featureFlag");
