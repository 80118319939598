"use strict";

const OutfallRoutineMaintenanceCondition = function () {
  const stringKey = "condition";
  let $modalTab;

  var loadListeners = function (data) {
    $modalTab = $(`#assessment-modal #${stringKey}`);
    $modalTab.on("2N:FormInput", `.fieldset [type="radio"]`, toggleDropdowns);
    $modalTab.on(
      "2N:FormInput",
      `[name="condition-info[maintenance-needed]"]`,
      toggleSiblingFieldsets,
    );

    if (FeatureFlag.enabled("sampling-site-inspection")) {
      $modalTab.on("2N:FormInput", `[name="asset-accessible"]`, (e, isAccessible) =>
        RoutineMaintenanceFormGeneral.toggleAssetAccessible($modalTab, isAccessible),
      );
      RoutineMaintenanceFormGeneral.toggleAssetAccessible($modalTab, data.assetAccessible);

      $modalTab.on(
        "2N:FormInput",
        `[name="inspect-structural-condition-maintenance"]`,
        onToggleInspectStructuralConditionMaintenance,
      );
      if (data) {
        toggleInspectConditionFields(
          data.assetAccessible,
          data.toggleInspectStructuralConditionMaintenance,
        );
      }
    }

    RoutineMaintenanceFormTable.loadListeners($modalTab);
  };

  var unloadListeners = function () {
    $modalTab.off("2N:FormInput", `.fieldset [type="radio"]`);
    $modalTab.off("2N:FormInput", `[name="condition-info[maintenance-needed]"]`);
    RoutineMaintenanceFormTable.unloadListeners($modalTab);
  };

  var getProps = function () {
    return {
      trashRemovalVolumeUnitOptions: RoutineMaintenanceConstants.trashRemovalVolumeUnitOptions,
      damageDescriptionOptions: OutfallInspectionConstants.damageDescriptionOptions,
      cloggingMaterialOptions: OutfallInspectionConstants.cloggingMaterialOptions,
      stainsDescriptionOptions: OutfallInspectionConstants.stainsDescriptionOptions,
      vegetationGrowthDescriptionOptions:
        OutfallInspectionConstants.getVegetationGrowthDescriptionOptions(),
      biologicalGrowthDescriptionOptions:
        OutfallInspectionConstants.biologicalGrowthDescriptionOptions,
      activeErosionDescriptionOptions: OutfallInspectionConstants.activeErosionDescriptionOptions,
    };
  };

  var getStringKey = function () {
    return stringKey;
  };

  var getTabHeader = function () {
    return RoutineMaintenanceFormTable.getConditionTabName();
  };

  var toggleDropdowns = function (e, value) {
    const dropdowns = $(e.currentTarget).parents(".fieldset").find(".autonumeric, select");
    dropdowns.toggle(value);
  };

  var toggleSiblingFieldsets = function (e, value) {
    const siblingFields = $(e.currentTarget).parents("fieldset").siblings();
    siblingFields.toggleClass("hidden", !value);
  };

  var onToggleInspectStructuralConditionMaintenance = function (
    e,
    inspectStructuralConditionMaintenance,
  ) {
    toggleInspectConditionFields(null, inspectStructuralConditionMaintenance);
  };

  var toggleInspectConditionFields = function (
    assetAccessible = null,
    inspectStructuralConditionMaintenance = null,
  ) {
    if ($modalTab) {
      if (assetAccessible === null) {
        assetAccessible =
          OutfallRoutineMaintenanceController.getInspectionRenderData()?.assetAccessible;
      }
      if (inspectStructuralConditionMaintenance === null) {
        inspectStructuralConditionMaintenance =
          OutfallRoutineMaintenanceController.getInspectionRenderData()
            ?.inspectStructuralConditionMaintenance;
      }
      const showInspectConditionFields = assetAccessible && inspectStructuralConditionMaintenance;
      $modalTab.find(`.show-when-accessible-and-inspect`).toggle(showInspectConditionFields);

      const showWheninspectStructuralConditionMaintenance = inspectStructuralConditionMaintenance;
      $modalTab
        .find(`.show-when-inspect-structural-condition-maintenance`)
        .toggle(showWheninspectStructuralConditionMaintenance);
      TabbedModal.toggleTab("maintenance-repair", showWheninspectStructuralConditionMaintenance);

      const $modal = $modalTab.closest(".modal-content");
      const hasNoAccessIssue = $modal.find(`[data-issue-key="asset-accessible"]`).length > 0;
      const noAccessIssueNotCorrected = $modal
        .find(`[data-issue-key="asset-accessible"][value="false"]`)
        .prop("checked");
      const hasNonCorrectedNoAccessIssue = hasNoAccessIssue && noAccessIssueNotCorrected;
      const showNoAccessMessage =
        inspectStructuralConditionMaintenance && hasNonCorrectedNoAccessIssue;
      $modalTab.find(`.no-access-message`).toggle(showNoAccessMessage);
    }
  };

  return {
    loadListeners,
    unloadListeners,
    getStringKey,
    getTabHeader,
    getProps,
    toggleInspectConditionFields,
  };
};

module.exports = OutfallRoutineMaintenanceCondition();

const OutfallRoutineMaintenanceController = require("./outfallRoutineMaintenanceController");
const OutfallInspectionConstants = require("../outfallInspectionConstants");
const RoutineMaintenanceFormTable = require("../../general/routineMaintenanceFormTable");
const RoutineMaintenanceFormGeneral = require("../../general/routineMaintenanceFormGeneral");
const RoutineMaintenanceConstants = require("../../bmpfcs/routineMaintenanceConstants");
const TabbedModal = require("../../general/tabbedModal");
const FeatureFlag = require("../../settings/featureFlag");
