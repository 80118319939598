"use strict";

const ConstructionProjectLayer = function () {
  const layerName = "constructionProject";
  var constructionMarkerReferences = {};

  var loadLayerListenersForMap = function (map, mapLayers, mapId) {
    loadToggledListenerForMap(map, mapLayers, mapId);
    loadDataUpdatedListenerForMap(map, mapLayers, mapId);

    if (Tree.get("tool") === "construction") {
      loadConditionPhaseToggleUpdatedListener(map, mapLayers);
    }
    if (mapId === "main") {
      loadTreeUpdateListenersForMap(map, mapLayers);
    }

    loadGlobalListeners();
  };

  var createConstructionProjectLayerAndAddToMap = function (
    map,
    mapLayers,
    constructionProjectLayer,
    constructionProjectsData,
    mapId,
  ) {
    if (mapLayers.constructionProjectLayer) {
      map.removeLayer(mapLayers.constructionProjectLayer);
    }
    mapLayers.constructionProjectLayer = createConstructionProjectLayer(
      constructionProjectsData,
      mapId,
    );
    loadPopupEventListeners(mapLayers.constructionProjectLayer);
    mapLayers.constructionProjectLayer.addTo(map);
    return mapLayers.constructionProjectLayer;
  };

  var startZoomListener = function (map, mapLayers) {
    map.on("zoomend", function () {
      MapFunctions.resizeLayerMarkers(
        mapLayers.constructionProjectLayer,
        createIcon,
        map.getZoom(),
      );
    });
  };

  var createIcon = function (properties) {
    return ConstructionIcon.getConstructionProjectIconMarker(properties);
  };

  var createConstructionProjectLayer = function (constructionProjectsData, mapId) {
    var bindPopup = mapId !== "modal";
    const constructionProjectLayer = L.featureGroup();
    L.geoJson(constructionProjectsData, {
      pointToLayer: function (feature, latlng) {
        const constructionProjectData = feature.properties;
        const markerOpacity = ConstructionIcon.getMarkerOpacity(mapId, constructionProjectData.id);
        const marker = L.marker(latlng, {
          icon: ConstructionIcon.getConstructionProjectIconMarker(constructionProjectData),
          opacity: markerOpacity,
        });

        storeConstructionMarkerReference(constructionProjectData.id, marker);
        return marker;
      },
      onEachFeature: function (feature, layer) {
        if (bindPopup) {
          layer.on({
            click: (e) => {
              Table.expandTableAndScroll(e.target.feature.properties.id);
              ConstructionProjectPopup.getConstructionProjectPopupHtml(feature.properties).then(
                (html) => layer.bindPopup(html, MapFunctions.getPopupOptions()).openPopup(),
              );
            },
          });
        }
      },
    }).addTo(constructionProjectLayer);
    return constructionProjectLayer;
  };

  var loadPopupEventListeners = function (layer) {
    layer.on("popupopen", function (e) {
      MapFunctions.toggleLayerInFront(e.layer, true);
    });

    layer.on("popupclose", function (e) {
      removeHoverClassFromTableRow(e.layer.feature.properties.id);
      removeProjectAreaHighlight();
      MapFunctions.toggleLayerInFront(e.layer, false);
    });
    ConstructionProjectPopup.loadDomListeners();
  };

  var removeHoverClassFromTableRow = function (constructionProjectId) {
    $(".table-data-list")
      .find(".map-hover[data-id='" + constructionProjectId + "']")
      .removeClass("map-hover");
  };

  var loadToggledListenerForMap = function (map, mapLayers, mapId) {
    MapFunctions.whenLayerToggled(layerName, mapId, function (isEnabled, sameSpatialFilter) {
      if (isEnabled) {
        if (
          mapLayers.constructionProjectLayer &&
          (mapId === "modal" || (mapId === "main" && sameSpatialFilter))
        ) {
          map.addLayer(mapLayers.constructionProjectLayer);
          loadLayer(mapId, false);
        } else {
          loadLayer(mapId);
        }
      } else {
        if (MapFunctions.mapHasLayer(map, mapLayers.constructionProjectLayer)) {
          map.removeLayer(mapLayers.constructionProjectLayer);
        }
      }
      if (mapId === "main") {
        DisplayOptions.handleConditionPhaseToggleDisable(layerName, isEnabled);
      }
    });
  };

  var loadDataUpdatedListenerForMap = function (map, mapLayers, mapId) {
    MapFunctions.whenLayerDataUpdated(layerName, mapId, function (data) {
      const geoJsonData = CleanData.cleanGeoJson(data);
      mapLayers.constructionProjectLayer = createConstructionProjectLayerAndAddToMap(
        map,
        mapLayers,
        mapLayers.constructionProjectLayer,
        geoJsonData,
        mapId,
      );
      if (Tree.get("dataView") != "construction-project") {
        startZoomListener(map, mapLayers);
      }
      if (mapId === "main") {
        Clustering.setState(layerName);
        const constructionId = Tree.get("constructionLayerOpenOnReload");
        if (constructionId) {
          const constructionMarker = getMarkerReference(constructionId);
          constructionMarker.openPopup();

          if (Tree.get("dataView") !== "construction-project-delivery") {
            ConstructionProjectPopup.showInspectionHistory(constructionId);
          }
        }
      }
    });
  };

  var loadGlobalListeners = function () {
    // Not sure if this is the best way or place to do this, but the
    // inspection button is used across a lot of different places
    // in construction, so a global listener seemed easiest.

    $("body").off("click", ".js-add-construction-project-inspection", newProjectInspectionClick);
    $("body").on("click", ".js-add-construction-project-inspection", newProjectInspectionClick);
    $("body").off("click", ".js-edit-construction-project-inspection", editProjectInspectionClick);
    $("body").on("click", ".js-edit-construction-project-inspection", editProjectInspectionClick);
  };

  var newProjectInspectionClick = function () {
    const projectId = $(this).data("projectId");
    const inspectionType = $(this).data("inspectionType");
    const defaultData = {};

    if (inspectionType) {
      defaultData.inspectionType = inspectionType;
    }

    if (FeatureFlag.enabled("new-construction-inspections")) {
      NewProjectInspectionController.loadNewProjectInspection(projectId, { defaultData });
    } else {
      ProjectInspectionController.loadNewProjectInspection(projectId);
    }
  };

  var editProjectInspectionClick = function () {
    const inspectionId = $(this).data("id");
    if (FeatureFlag.enabled("new-construction-inspections")) {
      NewProjectInspectionController.loadExistingProjectInspection(inspectionId);
    } else {
      ProjectInspectionController.loadExistingProjectInspection(inspectionId);
    }
  };

  // Temporary. Need to refactor how we reload layers on filter update
  // for construction, FCSs, BMPs
  var loadTreeUpdateListenersForMap = function (map, mapLayers) {
    Tree.select("filters").on("update", async function () {
      const isProjectDeliveryDataList =
        Tree.get("dataView") === "construction-project-delivery" &&
        Tree.get("activeTab") === "data" &&
        Tree.get("navToggleSelection") === "list";
      if (
        ((MapFunctions.mapHasLayer(map, mapLayers.constructionProjectLayer) &&
          Tree.get("layers", layerName, "isEnabled")) ||
          isProjectDeliveryDataList) &&
        Tree.get("reloadFilter")
      ) {
        loadLayer("main", false);
      } else {
        renderProjectDeliverySummaryTableIfNeeded();
      }
    });
  };

  var renderProjectDeliverySummaryTableIfNeeded = function () {
    if (Tree.get("dataView") === "construction-project-delivery") {
      ProjectDeliverySummaryTable.render();
    }
  };

  var loadConditionPhaseToggleUpdatedListener = function (map, mapLayers) {
    Tree.select(["filters", "conditionPhaseToggle"]).on("update", function (e) {
      const toggle = e.data.currentData;
      cleanUpAreaLayerOnConditionPhaseToggleChange(map, mapLayers);
      const currentLayer = Tree.get("layers", "constructionProjectG2", "isEnabled")
        ? "constructionProjectG2"
        : "constructionProject";
      if (toggle === "condition") {
        Filters.disableNonConditionPhases(["construction", "post-active"]);
      } else {
        Filters.enableDisabledPhases();
      }
      DisplayOptions.handleLegendDisplayByConditionPhaseToggle(
        currentLayer,
        toggle,
        "constructionProjectArea",
      );
      DisplayOptions.validateConditionPhaseToggleRadioButtons(toggle);
    });
  };

  var cleanUpAreaLayerOnConditionPhaseToggleChange = function (map, mapLayers) {
    if (!MapFunctions.mapHasLayer(map, mapLayers.constructionProjectAreaLayer)) {
      mapLayers.constructionProjectAreaLayer = null;
    }
  };

  var storeConstructionMarkerReference = function (id, marker) {
    constructionMarkerReferences[id] = marker;
  };

  var getMarkerReference = function (id) {
    return constructionMarkerReferences[id];
  };

  var removeProjectAreaHighlight = function () {
    return;
  };

  var loadLayer = async function (mapId, refreshData = true) {
    var dataPath = Actions.getLayerDataPathByMapId(mapId);
    var filters = Actions.getFiltersByMapId(mapId);

    ApiCalls.checkspatialboxes(filters);
    if (FilterSummary.areAllCheckboxesChecked("associatedBmpTypes")) {
      filters.associatedBmpTypes = [];
    }

    Tree.set(["layers", layerName, "isFetching"], true);

    const resource = ResourceController.get(layerName);
    const data = await resource.getAll({ filters, refreshData });
    Tree.set(["layers", layerName, dataPath], data);

    Tree.set(["layers", layerName, "isFetching"], false);
    if (mapId !== "modal") {
      Actions.updateConstructionProjectCount();
    }

    renderProjectDeliverySummaryTableIfNeeded();
  };

  var getProjectDeliveryLayerData = function () {
    return ApiCalls.getConstructionProjectDeliveryProjects()
      .then((data) => {
        const updatedData = data.map((project) => {
          const permissions = ConstructionProjectGeoServerLayer.getProjectPermissions(project);
          return {
            ...project,
            ...permissions,
          };
        });
        return updatedData;
      })
      .catch((error) => {
        console.warn("Warning:", error);
      });
  };

  return {
    loadLayerListenersForMap,
    getMarkerReference,
    loadLayer,
    getProjectDeliveryLayerData,
  };
};

module.exports = ConstructionProjectLayer();

const Actions = require("../actions");
const CleanData = require("../mapFunctions/cleanData");
const Clustering = require("../mapFunctions/clustering");
const ConstructionIcon = require("../construction/constructionIcon");
const ConstructionProjectPopup = require("./constructionProjectPopup");
const DisplayOptions = require("../mapFunctions/displayOptions");
const Filters = require("../mapFunctions/filters");
const MapFunctions = require("../mapFunctions/mapFunctions");
const ProjectInspectionController = require("./inspections/projectInspectionController");
const NewProjectInspectionController = require("./newInspections/newProjectInspectionController");
const ResourceController = require("../offline/resourceController");
const Table = require("../mapFunctions/table");
const Tree = require("../tree");
const ProjectDeliverySummaryTable = require("../projectDelivery/projectDeliverySummaryTable");
const ApiCalls = require("../apiCalls");
const FilterSummary = require("../filters/filterSummary");
const ConstructionProjectGeoServerLayer = require("../constructionG2/constructionProjectGeoServerLayer");
const FeatureFlag = require("../settings/featureFlag");
