"use strict";

const NavBar = function () {
  var initialize = function () {
    _showHideLoggedInMenus();
    _populateDropdowns();
  };

  var initializeEsg = function () {
    _checkFeatureFlag();
    _showHideLoggedInMenus();
    _populateDropdownsEsg();
  };

  var _checkFeatureFlag = function () {
    if (UserPermissions.getPermission("can_manage_users")) {
      $("#user-settings").html(nunjucks.render("modals/settingsModal/userManagementButton.njk"));
    }
  };

  var _showHideLoggedInMenus = function () {
    if (Session.isLoggedIn()) {
      $(".logged-in-only").removeClass("hidden");
      $(".logged-out-only").addClass("hidden");
    } else {
      $(".logged-in-only").addClass("hidden");
      $(".logged-out-only").removeClass("hidden");
    }
  };

  var _populateDropdowns = function () {
    _setupDropdowns();
    _initDropdownListeners();
    DataImport.init();
    DataExport.init();
  };

  var _populateDropdownsEsg = function () {
    _setupDropdownsEsg();
    _initDropdownListeners();
  };

  var repopulateDropdowns = function () {
    $("#navbar-menu .dropdown .dropdown-menu").html("");
    _populateDropdowns();
  };

  var _setupDropdowns = function () {
    _populateDropdown("import-export", []);

    const settings = getSettingsDropdownOptions();
    if (settings.length > 0) {
      _populateDropdown("settings", settings);
    } else {
      $("#navbar-menu .dropdown.settings").remove();
    }

    const helpDropdownOptions = [
      {
        class: "navbar-help-center",
        text: "Help Center",
        href: "https://kb.2ndnaturewater.com/_hcms/mem/login?redirect_url=https://kb.2ndnaturewater.com/knowledge",
        target: "_blank",
      },
      {
        class: "navbar-contact-support",
        text: "Contact Support",
        href: "https://kb.2ndnaturewater.com/knowledge/kb-tickets/new",
        target: "_blank",
      },
      {
        class: "upload-debug",
        text: "Upload Debug Report",
        target: "_blank",
      },
      {
        text: "ESWFT Resources",
        class: "navbar-eswft-resources",
        href: ToolSettings.getSetting("constants", "eswftUrl"),
        target: "_blank",
      },
      {
        text: "Asset Definitions",
        class: "navbar-bmp-definitions",
        href: "https://go.2ndnaturewater.com/hubfs/StormwaterGIS-2NDNATURE-Version1-09-2023.pdf",
        target: "_blank",
      },
    ];
    if (FeatureFlag.enabled("public-api-keys")) {
      helpDropdownOptions.push({
        text: "API Documentation",
        class: "navbar-api-documentation",
        href: "/docs",
        target: "_blank",
      });
    }
    _populateDropdown("help", helpDropdownOptions);

    const aliasList = ToolSettings.getSetting("alias");
    let userDropdownOptions = [
      { text: "User Profile", class: "show-user-profile" },
      { text: "Feature Flag Settings", class: "feature-flag-settings", defaultHidden: true },
      {
        class: "show-privacy-policy",
        text: "Privacy Policy",
        href: "https://drive.google.com/file/d/1NXiurl1Y11RVJ6NyPf1Xz-HYoRMR9KXy/view?usp=sharing",
        target: "_blank",
      },
      { text: "Sign Out", class: `sign-out ${aliasList?.length > 1 ? "list-divide" : ""}` },
    ];

    if (aliasList?.length > 5) {
      userDropdownOptions = userDropdownOptions.concat(_createAliasObject(aliasList.slice(0, 5)));
      userDropdownOptions.push({ text: "More...", class: "more-alias" });
    } else if (aliasList?.length > 1) {
      userDropdownOptions = userDropdownOptions.concat(_createAliasObject(aliasList));
    }

    _populateDropdown("user", userDropdownOptions);

    if (aliasList?.length > 5) {
      _setupShowMoreAliasDropdown(_createAliasObject(aliasList));
    }

    FeatureFlagModal.enableFeatureFlagModal();
  };

  var _setupDropdownsEsg = function () {
    const aliasList = ToolSettings.getSetting("alias");
    let userDropdownOptions = [
      { text: "User Profile", class: "show-user-profile" },
      { text: "Feature Flag Settings", class: "feature-flag-settings", defaultHidden: true },
      {
        class: "show-privacy-policy",
        text: "Privacy Policy",
        href: "https://drive.google.com/file/d/1NXiurl1Y11RVJ6NyPf1Xz-HYoRMR9KXy/view?usp=sharing",
        target: "_blank",
      },
      { text: "Sign Out", class: `sign-out ${aliasList?.length > 1 ? "list-divide" : ""}` },
    ];

    if (aliasList?.length > 5) {
      userDropdownOptions = userDropdownOptions.concat(_createAliasObject(aliasList.slice(0, 5)));
      userDropdownOptions.push({ text: "More...", class: "more-alias" });
    } else if (aliasList?.length > 1) {
      userDropdownOptions = userDropdownOptions.concat(_createAliasObject(aliasList));
    }

    _populateDropdown("user", userDropdownOptions);
    _populateDropdown("help", [
      {
        class: "navbar-help-center",
        text: "Help Center",
        href: "https://kb.2ndnaturewater.com/_hcms/mem/login?redirect_url=https://kb.2ndnaturewater.com/knowledge/rainsteward",
        target: "_blank",
      },
      {
        class: "navbar-contact-support",
        text: "Contact Support",
        href: "https://kb.2ndnaturewater.com/knowledge/kb-tickets/new",
        target: "_blank",
      },
    ]);

    if (aliasList?.length > 5) {
      _setupShowMoreAliasDropdown(_createAliasObject(aliasList));
    }
    FeatureFlagModal.enableFeatureFlagModal();
  };

  var _setupShowMoreAliasDropdown = function (aliasList) {
    const aliasHtml = nunjucks.render("navbar/aliasList.njk", { aliasList });
    if ($(".alias-more").length > 0) {
      $(".alias-more").remove();
    }
    $(".dropdown.user").append(aliasHtml);
  };

  var _createAliasObject = function (aliasList) {
    return aliasList.map(function (alias) {
      return {
        text: `${alias.gname} - ${alias.role}`,
        class: `select-alias ${alias.active ? "active" : ""}`,
        id: alias.id,
      };
    });
  };

  var _populateDropdown = function (dropdownName, defaultListItems) {
    if (Session.isLoggedIn()) {
      var listItems = [
        ...defaultListItems,
        ...MenuConfig.getCurrentMenuItems(dropdownName),
        ...MenuConfig.getGroupBasedMenuItems(dropdownName),
      ];

      if (Session.isRegulator()) {
        listItems = listItems.filter((item) => !item.regulatorHidden);
      }

      if (Session.isFieldPersonnel()) {
        listItems = listItems.filter((item) => !item.fieldPersonnelHidden);
      }

      listItems = MenuConfig.filterMenuItemsByPermission(listItems);
      MenuConfig.disableMenuItemsWithoutDataViewProperties(listItems);
    } else {
      listItems = defaultListItems;
    }

    const dropdownUser = nunjucks.render(`navbar/dropdown.njk`, {
      listItems,
    });
    $(`.dropdown.${dropdownName} .dropdown-menu`).html(dropdownUser);

    if (listItems.length === 0) {
      $(`.dropdown.${dropdownName}`).hide();
    }
  };

  var _initDropdownListeners = function () {
    const Login = require("./login/login");
    $(".sign-out").off("click", Login.logout);
    $(".sign-out").on("click", Login.logout);

    const $navbarMenu = $("#navbar-menu");

    $navbarMenu.off("click", "ul.dropdown-menu a", Analytics.sendNavClickEvent);
    $navbarMenu.on("click", "ul.dropdown-menu a", Analytics.sendNavClickEvent);

    $navbarMenu.off("click", "a.show-manage-users", ManageUsers.showManageUsersModal);
    $navbarMenu.on("click", "a.show-manage-users", ManageUsers.showManageUsersModal);

    $navbarMenu.off("click", "a.show-user-profile", UserSettingsFunctions.showUserSettingsModal);
    $navbarMenu.on("click", "a.show-user-profile", UserSettingsFunctions.showUserSettingsModal);

    $navbarMenu.off("click", "a.select-alias", Login.selectAlias);
    $navbarMenu.on("click", "a.select-alias", Login.selectAlias);

    $navbarMenu.off("click", "a.more-alias", showMoreAlias);
    $navbarMenu.on("click", "a.more-alias", showMoreAlias);

    $navbarMenu.off("click", "a.alias-back", aliasBack);
    $navbarMenu.on("click", "a.alias-back", aliasBack);

    $navbarMenu.off("click", "a.show-module-settings", ModuleSettingsController.open);
    $navbarMenu.on("click", "a.show-module-settings", ModuleSettingsController.open);

    $navbarMenu.off("input", ".filter-alias", onInputAliasSearch);
    $navbarMenu.on("input", ".filter-alias", onInputAliasSearch);

    $navbarMenu.off("click", "a.show-settings-modal", SettingsModalController.showSettingsModal);
    $navbarMenu.on("click", "a.show-settings-modal", SettingsModalController.showSettingsModal);

    $navbarMenu.off("click", "a.show-eswft-modal", EswftSyncStatusModal.showEswftSyncStatusModal);
    $navbarMenu.on("click", "a.show-eswft-modal", EswftSyncStatusModal.showEswftSyncStatusModal);

    $navbarMenu.off("click", "a.upload-debug", OfflineExporter.uploadOfflineData);
    $navbarMenu.on("click", "a.upload-debug", OfflineExporter.uploadOfflineData);

    UserSettingsFunctions.loadListeners();
    ManageUsers.loadListeners();
  };

  var showMoreAlias = function (e) {
    e.preventDefault();
    e.stopImmediatePropagation();
    toggleAliasDisplay(true);
    $(".filter-alias-list input.filter-alias").trigger("focus");
  };

  var aliasBack = function (e) {
    e.preventDefault();
    e.stopImmediatePropagation();
    toggleAliasDisplay(false);
  };

  var onInputAliasSearch = function () {
    const searchString = $(this).val();
    const $selector = $(this).parents(".dropdown-menu.alias-more").find("li.alias");

    FilterDom.filterByText(searchString, $selector);
  };

  var toggleAliasDisplay = function (toggle) {
    const $userDropdowns = $(".dropdown.user");

    $userDropdowns.find(".dropdown-menu.alias-more").toggleClass("hidden", !toggle);
    $userDropdowns.find(".dropdown-menu.users").toggleClass("hidden", toggle);
  };

  var getSettingsDropdownOptions = function () {
    const options = [];

    if (SettingsModalController.canAccessSettings()) {
      options.push({ text: "System Settings", class: "show-settings-modal" });
    }

    if (
      UserPermissions.getPermission("can_manage_users") &&
      FeatureFlag.enabled("old-user-management")
    ) {
      options.push({ text: "Old User Management", class: "show-manage-users" });
    }

    if (Session.is2nAdmin()) {
      options.push({ text: "Module Settings", class: "show-module-settings" });
      options.push({
        text: "ESWFT Sync Status",
        class: "show-eswft-modal",
      });
    }

    return options;
  };

  return {
    initialize,
    initializeEsg,
    repopulateDropdowns,
  };
};

module.exports = NavBar();

const MenuConfig = require("./config/menuConfig");
const DataExport = require("./dataExport/dataExport");
const DataImport = require("./dataImport/dataImport");
const Analytics = require("./general/analytics");
const FilterDom = require("./general/filterDom");
const Session = require("./login/session");
const UserPermissions = require("./login/userPermissions");
const ManageUsers = require("./modals/manageUsers");
const UserSettingsFunctions = require("./modals/userSettingsFunctions");
const FeatureFlagModal = require("./settings/featureFlagModal");
const EswftSyncStatusModal = require("./settings/eswftSyncStatusModal");
const ToolSettings = require("./settings/toolSettings");
const ModuleSettingsController = require("./settings/moduleSettings/moduleSettingsController");
const SettingsModalController = require("./settings/settingsModalController");
const FeatureFlag = require("./settings/featureFlag");
const OfflineExporter = require("./offline/offlineExporter");
