"use strict";

const SamplingSitePopup = function () {
  var getPopupHtml = function (properties, isWqMonitoringLocationLayer = false) {
    return PopupContent.getLayerPopup(
      { ...properties, isWqMonitoringLocationLayer },
      "samplingSites",
    );
  };

  var onPopupOpenWithData = function (data, $element, invalidateLayerDataFunction) {
    var onPopupDelete = function () {
      deleteSamplingSite(data.id, data.siteIdentifier, invalidateLayerDataFunction);
    };

    $element.off("click", ".popup-delete-button", onPopupDelete);
    $element.on("click", ".popup-delete-button", onPopupDelete);

    renderWqInspectionHistory(data.history, data.id);
  };

  var deleteSamplingSite = function (id, siteIdentifier, invalidateLayerDataFunction) {
    const popupCloseButn = $(".leaflet-popup-close-button");
    MessageModal.showConfirmWarningModal(
      `Delete Sampling Site ${siteIdentifier}? Click "Delete" to permanently delete the Sampling Site. Click "Cancel" to return.`,
      async function () {
        await ApiCalls.deleteSamplingSite(id);
        invalidateLayerDataFunction(popupCloseButn);
        _closePopup(popupCloseButn);
      },
      "Cancel",
      "Delete",
    );
    Analytics.sendPopupEvent("delete_asset", "samplingSites");
  };

  var _closePopup = function (popupCloseButn) {
    if (popupCloseButn.length) {
      popupCloseButn[0].click();
    }
  };

  var onPopupOpen = async function (properties, event = null, isWqMonitoringLocationLayer = false) {
    const id = properties.id;
    var data = await getPopupData(id);
    const html = getPopupHtml(data, isWqMonitoringLocationLayer);
    const layerModule = LayerFunctions.getLayerModule("samplingSites");
    const $popup = $(".leaflet-popup-content");
    $popup.html(html);

    onPopupOpenWithData(data, $popup, layerModule.invalidateLayerData);
  };

  var getPopupData = async function (id) {
    const popupData = await ApiCalls.getSamplingSitePopup(id);
    popupData.color = SamplingSiteIcon.getConditionColor();

    popupData.showViewInventoryButton =
      popupData.permissions?.canCreateSamplingSite &&
      FeatureFlag.enabled("sampling-site-inventory-modal");

    popupData.showInspectionButton =
      popupData.permissions?.canCreateSamplingSiteInspection &&
      FeatureFlag.enabled("sampling-site-inspection");

    return popupData;
  };

  var renderWqInspectionHistory = function (history, assetId) {
    var html = nunjucks.render("popups/history/wqInspectionHistory.njk", {
      history,
      assetId,
    });
    $(".popup-history-pane").html(html);
  };

  var editHistory = async function (tableRowData, readOnly) {
    const inspectionId = tableRowData.id;
    const id = tableRowData.assetId;

    UrlRoutes.navigate(`/ram/asset/samplingSite/${id}/inspection/${inspectionId}`);
  };

  var confirmDeleteHistory = function ($todoTr, tableRowData) {
    var selectedId = tableRowData.id;

    MessageModal.showDeleteRecordModal(() => {
      deleteHistory(selectedId);
      $todoTr.remove();
      if (tableRowData.assetId) {
        onPopupOpen({ id: tableRowData.assetId });
      }
    });
  };

  var deleteHistory = function (id) {
    ApiCalls.deleteSamplingSiteInspection(id, SamplingSiteGeoServerLayer.invalidateLayerData());
  };

  return {
    getPopupHtml,
    onPopupOpen,
    editHistory,
    confirmDeleteHistory,
  };
};

module.exports = SamplingSitePopup();

const ApiCalls = require("../apiCalls");
const PopupContent = require("../mapFunctions/popupContent");
const MessageModal = require("../modals/messageModal");
const LayerFunctions = require("../layerFunctions");
const Analytics = require("../general/analytics");
const SamplingSiteIcon = require("./samplingSiteIcon");
const FeatureFlag = require("../settings/featureFlag");
const SamplingSiteGeoServerLayer = require("./samplingSiteGeoServerLayer");
const UrlRoutes = require("../routes/urlRoutes");
