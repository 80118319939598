"use strict";

const StreetSweepingInsightDashboardController = function () {
  var $insightContainer;
  var inited = false;
  var controller = null;

  var dashboardTypeOptions = [
    { name: "Annual Comparisons", value: "annual" },
    { name: "Plan vs Actual", value: "plan" },
  ];
  var dashboardType = "annual";

  var timePeriodOptions = [
    { name: "Past 3 Months", value: "past-3-months" },
    { name: "Past 6 Months", value: "past-6-months" },
    { name: "Past 9 Months", value: "past-9-months" },
    { name: "Past 12 Months", value: "past-12-months" },
  ];
  var timePeriod = "past-12-months";

  var reports = [];
  var reportingYears;

  var priorityOptions;
  var routeOptions;
  var debrisCollectedUnitOptions;
  var sweeperTypeOptions;

  var totalRouteCount;
  var routeNamesWithPlan;
  var routeWithPlanOptions;
  var isCatchment;

  var focusYear;
  var comparisonYear;
  var currentReportingYear;
  var priority;
  var route;
  var debrisCollectedUnit;
  var displayDebrisCollectedUnit;

  var init = async function (reInitialize = false) {
    if (!inited || reInitialize) {
      await loadInitData();

      $insightContainer = $(".insight-container");

      priority = "total";
      route = "total";

      debrisCollectedUnitOptions = RecordSweepConstants.debrisCollectedUnitOptions;
      debrisCollectedUnit = ToolSettings.getSetting("streetSweeping", "debris-collected-unit");
      displayDebrisCollectedUnit = debrisCollectedUnitOptions.getObjectWith(
        "value",
        debrisCollectedUnit,
      )?.name;

      loadDomListeners();

      var config = {
        getInsightDataFunction: ApiCalls.getStreetSweepingInsight,
        widgets: getWidgets(),
        hasFilter: true,
        showFilterButton: true,
        getFilterMenuHtmlFunction,
        saveFilterFunction,
        getMobileFilterButtonTextFunction,
        hasDashboardType: true,
      };

      controller = new InsightDashboardController(config);
      controller.init();
      inited = true;
      render();
    }
  };

  var getWidgets = function () {
    return dashboardType === "plan" ? getPlanWidgets() : getAnnualWidgets();
  };

  var getAnnualWidgets = function () {
    var widgetSweepDebris = new InsightWidgetSimpleNumber({
      size: 1,
      title: "Total Debris Collected",
      neutralTrendColor: true,
      dataDecimalPlaces: 1,
      getDataUnit: () => displayDebrisCollectedUnit,
      getDataNumber: (insightData, dataPath) => {
        return insightData[dataPath]["sweepDebris"];
      },
      getSubtitle: (insightData, dataPath) => {
        return getYearByDataPath(dataPath);
      },
      getHoverTitle: () => {
        return `${focusYear} & ${comparisonYear} <br/> Total Debris Collected`;
      },
      getHoverDescription: () => {
        return "The percent difference in total debris collected between the comparison and focus years";
      },
    });

    var widgetSweepMiles = new InsightWidgetSimpleNumber({
      size: 1,
      title: "Curb Miles Swept",
      neutralTrendColor: true,
      dataDecimalPlaces: 1,
      getDataUnit: () => "miles",
      getDataNumber: (insightData, dataPath) => {
        return insightData[dataPath]["sweepMiles"];
      },
      getSubtitle: (insightData, dataPath) => {
        return getYearByDataPath(dataPath);
      },
      getHoverTitle: () => {
        return `${focusYear} & ${comparisonYear} <br/> Curb Miles Swept`;
      },
      getHoverDescription: () => {
        return "The percent difference in total curb miles swept between the comparison and focus years";
      },
    });

    var widgetSweepDebrisPerMile = new InsightWidgetSimpleNumber({
      size: 1,
      title: "Debris Collected per Mile",
      neutralTrendColor: true,
      dataDecimalPlaces: 2,
      getDataUnit: () => {
        return `${displayDebrisCollectedUnit}/mile`;
      },
      getDataNumber: (insightData, dataPath) => {
        return insightData[dataPath]["sweepDebrisPerMile"];
      },
      getSubtitle: (insightData, dataPath) => {
        return getYearByDataPath(dataPath);
      },
      getHoverTitle: () => {
        return `${focusYear} & ${comparisonYear} <br/> Debris Collected per Mile`;
      },
      getHoverDescription: () => {
        return "The percent difference in debris collected per mile between the comparison and focus years";
      },
    });

    var widgetAverageSweepCurbAccessibility = new InsightWidgetSimpleNumber({
      size: 1,
      title: "Average Curb Accessibility",
      upGreenDownRed: true,
      dataDecimalPlaces: 0,
      simpleText: true,
      convertDataNumberFunction: getCurbValueDisplayName,
      getDataUnit: () => "",
      getDataNumber: (insightData, dataPath) => {
        return insightData[dataPath]["averageSweepCurbValuePerMile"];
      },
      getSubtitle: (insightData, dataPath) => {
        return getYearByDataPath(dataPath);
      },
      getHoverTitle: () => {
        return `${focusYear} & ${comparisonYear} <br/> Change in Average Curb Accessibility`;
      },
      getHoverDescription: () => {
        if (FeatureFlag.enabled("sweep-insights-curb")) {
          return "The percent difference in average curb accessibility between the comparison and focus years.";
        } else {
          return "Curb accessibility categories represent a range of values: Low (<25%), Moderate (25-50%), High (50-75%), and High (>75%)";
        }
      },
    });

    var widgetTopRoutesByDebrisPerMile = new InsightWidgetTopList({
      size: 2,
      title: "Top 5 Routes by Debris Collected Per Mile",
      dataDecimalPlaces: 2,
      unit: `${displayDebrisCollectedUnit}/mile`,
      getDataArray: (insightData, dataPath) => {
        const dataArray = insightData[dataPath]["topRoutesByDebrisPerMile"];
        dataArray.forEach((item) => {
          if (item.key) {
            item.displayName = item.networkName || item.catchmentName;
          }
        });

        return dataArray;
      },
      getSubtitle: (insightData, dataPath) => {
        return getYearByDataPath(dataPath);
      },
      getHoverTitle: (insightData, hoverType) => {
        return `${hoverType} <br/>Debris Collected per Mile Ranking`;
      },
    });

    var widgetSweepDebrisByPriority = new InsightWidgetCountByTypeChart({
      size: 2,
      title: "Debris Collected by Route Priority",
      dataTypeKey: "priority",
      excludeZeros: true,
      neutralTrendColor: true,
      dataDecimalPlaces: 1,
      typeCountLimit: 4,
      unit: displayDebrisCollectedUnit,
      getDataArray: (insightData, dataPath) => {
        const dataArray = insightData[dataPath]["sweepDebrisByPriority"];
        InsightWidgetFunctions.sortDataArrayByKey(
          dataArray,
          SharedDataFunctions.getPrioritySortOrder(),
        );
        const filteredDataArray = dataArray.filter((item) => item.value > 0);
        filteredDataArray.forEach((item) => {
          item.displayName = priorityOptions.getObjectWith("value", item.key)?.name;
        });
        return filteredDataArray;
      },
      getYearByDataPath,
      getSelectedType: getSelectedPriority,
      getHoverTitle: (insightData, hoverType) => {
        const matchedItem = priorityOptions.find((item) => item.value === hoverType);
        const displayName = matchedItem?.name || hoverType;
        return `${focusYear} & ${comparisonYear} <br/> ${displayName}`;
      },
      getHoverDescription: () => {
        return "The percent difference in total debris collected between the comparison and focus years";
      },
    });

    var widgetMonthlySweepMiles = new InsightWidgetMonthlyCountChart({
      size: 4,
      sizeSm: 2,
      title: "Cumulative Total Miles Swept per Month",
      neutralTrendColor: true,
      dataDecimalPlaces: 1,
      unit: "miles",
      getDataArray: (insightData, dataPath) => {
        const monthlyData = insightData[dataPath]["monthlySweepMiles"];
        InsightWidgetFunctions.handleMonthlyDataDisplay(monthlyData);
        return monthlyData;
      },
      getYearByDataPath,
      getHoverTitle: (hoverIndex) => {
        const monthIndex = hoverIndex + 1;

        return `${focusYear} & ${comparisonYear} Month ${monthIndex} <br/> Cumulative Curb Miles Swept`;
      },
      getHoverDescription: () => {
        return "The percent difference in total curb miles swept between the comparison and focus reporting years";
      },
      getNotes: () => {
        const focusReport = reports.find((report) => report.reportingYear === parseInt(focusYear));

        return `*${focusReport.displayDataRangeFirst} is the start of the focus reporting year. ${focusReport.displayDataRangeLast} is the end of the focus reporting year.`;
      },
    });

    return [
      widgetSweepDebris,
      widgetSweepMiles,
      widgetSweepDebrisPerMile,
      widgetAverageSweepCurbAccessibility,
      widgetTopRoutesByDebrisPerMile,
      widgetSweepDebrisByPriority,
      widgetMonthlySweepMiles,
    ];
  };

  var getPlanWidgets = function () {
    var planWidgetSweepRoutes = new InsightWidgetSimpleNumber({
      size: 1,
      title: "Sweeping Routes",
      neutralTrendColor: true,
      hideTrendIcon: true,
      showFocusOverComparisonPercent: true,
      dataDecimalPlaces: 0,
      getDataUnit: () => "",
      getDataNumber: (insightData, dataPath) => {
        return dataPath === "focus"
          ? insightData["planRouteCount"]
          : insightData["totalRouteCount"];
      },
      getSubtitle: (insightData, dataPath) => {
        return dataPath === "focus" ? "Routes with Plans" : "Total Routes";
      },
      getHoverTitle: () => {
        return `Routes with Plan`;
      },
      getHoverDescription: () => {
        return "The percent of total routes with plan data inputs";
      },
    });

    var planWidgetSweepMiles = new InsightWidgetSimpleNumber({
      size: 1,
      title: "Total Curb Miles Swept",
      neutralTrendColor: true,
      dataDecimalPlaces: 0,
      hideHoverTrendIcon: true,
      getDataUnit: () => "miles",
      getDataNumber: (insightData, dataPath) => {
        return dataPath === "focus"
          ? insightData["actualSweepMiles"]
          : insightData["planSweepMiles"];
      },
      getSubtitle: (insightData, dataPath) => {
        return getPlanWidgetSubtitle(dataPath);
      },
      getHoverTitle: () => {
        return `Plan vs Actual <br/> Curb Miles Swept`;
      },
      getHoverDescription: () => {
        return "The percent difference in total curb miles swept between the plan and actual data";
      },
    });

    var planWidgetAverageSweepCurbAccess = new InsightWidgetSimpleNumber({
      size: 1,
      title: "Average Curb Accessibility",
      upGreenDownRed: true,
      dataDecimalPlaces: 0,
      hideHoverTrendIcon: true,
      simpleText: true,
      convertDataNumberFunction: getCurbValueDisplayName,
      getDataUnit: () => "",
      getDataNumber: (insightData, dataPath) => {
        return dataPath === "focus"
          ? insightData["actualAverageSweepCurbValuePerMile"]
          : insightData["planAverageSweepCurbValuePerMile"];
      },
      getSubtitle: (insightData, dataPath) => {
        return getPlanWidgetSubtitle(dataPath);
      },
      getHoverTitle: () => {
        return `Plan vs Actual <br/> Average Curb Accessibility`;
      },
      getHoverDescription: () => {
        return "The percent difference in average curb accessibility between the plan and actual data";
      },
    });

    var planWidgetSweepFrequency = new InsightWidgetSimpleNumber({
      size: 1,
      title: "Sweep Frequency",
      neutralTrendColor: true,
      hideHoverTrendIcon: true,
      dataDecimalPlaces: 1,
      getDataUnit: () => "sweeps/route",
      getDataNumber: (insightData, dataPath) => {
        return dataPath === "focus"
          ? insightData["actualSweepFrequency"]
          : insightData["planSweepFrequency"];
      },
      getSubtitle: (insightData, dataPath) => {
        return getPlanWidgetSubtitle(dataPath);
      },
      getHoverTitle: () => {
        return `Plan vs Actual <br/> Sweep Frequency`;
      },
      getHoverDescription: () => {
        return "The percent difference in sweep frequency between the plan and actual data";
      },
    });

    var planWidgetSweepMilesBySweeperType = new InsightWidgetCountByTypeChart({
      size: 2,
      title: "Total Curb Miles Swept by Sweeper Type",
      dataTypeKey: "sweeper-type",
      excludeZeros: true,
      neutralTrendColor: true,
      hideTrendIcon: true,
      dataDecimalPlaces: 0,
      typeCountLimit: 3,
      unit: "curb miles",
      getDataArray: (insightData, dataPath) => {
        const dataArray =
          dataPath === "focus"
            ? insightData["actualSweepMilesBySweeperType"]
            : insightData["planSweepMilesBySweeperType"];

        const order = ["mechanical_broom", "vacuum_assisted", "regenerative_air"];
        InsightWidgetFunctions.sortDataArrayByKey(dataArray, order);

        const filteredArray = dataArray.filter((item) => item.value > 0);
        filteredArray.forEach((item) => {
          item.displayName = sweeperTypeOptions.getObjectWith("value", item.key)?.name;
        });
        return filteredArray;
      },
      getYearByDataPath: (dataPath) => {
        return dataPath === "focus" ? "Actual" : "Plan";
      },
      getSelectedType: () => "total",
      getHoverTitle: (insightData, hoverType) => {
        const matchedItem = sweeperTypeOptions.find((item) => item.value === hoverType);
        const displayName = matchedItem?.name || hoverType;
        return `Plan vs Actual <br/> ${displayName}`;
      },
      getHoverDescription: () => {
        return "The percent difference in total curb miles swept between the plan and actual data";
      },
    });

    var planWidgetComparisonToTargetCurbAccess = new InsightWidgetComparisonToTarget({
      size: 2,
      title: "Comparison to Target: Curb Accessibility",
      unit: "% curb accessible",
      dataDecimalPlaces: 0,
      getLegend: (insightData) => {
        return {
          dataPointLabel: "Actual",
          rangeLabel: "Plan",
        };
      },
      getChartData: getPlanWidgetComparisonToTargetCurbAccessChartData,
      getHoverTitle: () => {
        return `Routes with Plan`;
      },
      getHoverDescription: () => {
        return "The percent of total routes with plan data inputs";
      },
    });

    var planWidgetComparisonToTargetSweepFrequency = new InsightWidgetComparisonToTarget({
      size: 2,
      title: "Comparison to Target: Sweep Frequency",
      unit: "sweeps",
      dataDecimalPlaces: 0,
      getLegend: (insightData) => {
        return {
          dataPointLabel: "Actual",
          rangeLabel: "Plan",
        };
      },
      getChartData: getPlanWidgetComparisonToTargetSweepFrequencyChartData,
      getHoverTitle: () => {
        return `Routes with Plan`;
      },
      getHoverDescription: () => {
        return "The percent of total routes with plan data inputs";
      },
    });

    if (routeNamesWithPlan?.length) {
      if (isCatchment) {
        return [
          planWidgetAverageSweepCurbAccess,
          planWidgetSweepFrequency,
          planWidgetSweepRoutes,
          planWidgetComparisonToTargetCurbAccess,
          planWidgetComparisonToTargetSweepFrequency,
        ];
      }
      return [
        planWidgetSweepMiles,
        planWidgetSweepMilesBySweeperType,
        planWidgetAverageSweepCurbAccess,
        planWidgetSweepFrequency,
        planWidgetSweepRoutes,
        planWidgetComparisonToTargetCurbAccess,
        planWidgetComparisonToTargetSweepFrequency,
      ];
    } else {
      return [planWidgetSweepRoutes];
    }
  };

  var getPlanWidgetComparisonToTargetCurbAccessChartData = function (insightData) {
    const dataArray = insightData.individualRouteData;
    const lowPonts = [];
    const moderatePoints = [];
    const highPoints = [];
    const veryHighPoints = [];
    const filteredArray = dataArray.filter(
      (item) => item.actualRouteSweepCurbValuePerMile !== null,
    );

    filteredArray.forEach((route) => {
      if (
        route.planRouteSweepCurbValuePerMile === null ||
        route.planRouteSweepCurbValuePerMile === undefined
      ) {
        return;
      } else if (route.planRouteSweepCurbValuePerMile <= 0.25) {
        lowPonts.push(route);
      } else if (
        route.planRouteSweepCurbValuePerMile > 0.25 &&
        route.planRouteSweepCurbValuePerMile <= 0.5
      ) {
        moderatePoints.push(route);
      } else if (
        route.planRouteSweepCurbValuePerMile > 0.5 &&
        route.planRouteSweepCurbValuePerMile < 0.75
      ) {
        highPoints.push(route);
      } else if (route.planRouteSweepCurbValuePerMile >= 0.75) {
        veryHighPoints.push(route);
      }
    });
    const tickPoints = [25, 50, 75];
    const dataRangeMin = 0;
    const dataRangeMax = 100;

    const dataRows = [];
    if (lowPonts.length) {
      dataRows.push({
        label: "Low",
        dataRangeMin,
        dataRangeMax,
        tickPoints,
        targetRangeMin: 0,
        targetRangeMax: 25,
        dataPoints: lowPonts.map((point) => point.actualRouteSweepCurbValuePerMile * 100),
      });
    }
    if (moderatePoints.length) {
      dataRows.push({
        label: "Moderate",
        dataRangeMin,
        dataRangeMax,
        tickPoints,
        targetRangeMin: 25,
        targetRangeMax: 50,
        dataPoints: moderatePoints.map((point) => point.actualRouteSweepCurbValuePerMile * 100),
      });
    }
    if (highPoints.length) {
      dataRows.push({
        label: "High",
        dataRangeMin,
        dataRangeMax,
        tickPoints,
        targetRangeMin: 50,
        targetRangeMax: 75,
        dataPoints: highPoints.map((point) => point.actualRouteSweepCurbValuePerMile * 100),
      });
    }
    if (veryHighPoints.length) {
      dataRows.push({
        label: "Very High",
        dataRangeMin,
        dataRangeMax,
        tickPoints,
        targetRangeMin: 75,
        targetRangeMax: 100,
        dataPoints: veryHighPoints.map((point) => point.actualRouteSweepCurbValuePerMile * 100),
      });
    }

    return dataRows;
  };

  var getPlanWidgetComparisonToTargetSweepFrequencyChartData = function (insightData) {
    const dataArray = insightData.individualRouteData;
    const lessThanMonthlyPoints = [];
    const monthlyPonts = [];
    const twoMonthlyPoints = [];
    const weeklyPoints = [];
    const twoWeeklyPoints = [];

    dataArray.forEach((route) => {
      if (route.planSweeperFrequency === "less_than_monthly") {
        lessThanMonthlyPoints.push(route);
      } else if (route.planSweeperFrequency === "monthly") {
        monthlyPonts.push(route);
      } else if (route.planSweeperFrequency === "two_monthly") {
        twoMonthlyPoints.push(route);
      } else if (route.planSweeperFrequency === "weekly") {
        weeklyPoints.push(route);
      } else if (route.planSweeperFrequency === "two_weekly") {
        twoWeeklyPoints.push(route);
      }
    });

    const timePeriodMonthNumberMap = {
      "past-12-months": 12,
      "past-9-months": 9,
      "past-6-months": 6,
      "past-3-months": 3,
    };
    const frequencyMaxSweepCountMap = {
      less_than_monthly: 6,
      monthly: 12,
      two_monthly: 24,
      weekly: 52,
      two_weekly: 104,
    };
    const timePeriodMonthNumber = timePeriodMonthNumberMap[timePeriod];
    const timePeriodRatio = timePeriodMonthNumber / 12;
    const maxSweepCount = 104 * timePeriodRatio;

    const dataRangeMin = 0;
    const maxActualRouteSweepCount = dataArray.reduce((max, route) => {
      return Math.max(max, route.actualRouteSweepCount);
    }, -Infinity);
    const dataRangeMax = Math.max(maxSweepCount, maxActualRouteSweepCount);
    const tickPoints = [dataRangeMax / 4, dataRangeMax / 2, (dataRangeMax * 3) / 4];

    const dataRows = [];
    if (lessThanMonthlyPoints.length) {
      dataRows.push({
        label: "< Monthly",
        dataRangeMin,
        dataRangeMax,
        tickPoints,
        targetRangeMin: frequencyMaxSweepCountMap["less_than_monthly"] * timePeriodRatio - 2,
        targetRangeMax: frequencyMaxSweepCountMap["less_than_monthly"] * timePeriodRatio + 2,
        dataPoints: lessThanMonthlyPoints.map((point) => point.actualRouteSweepCount),
      });
    }
    if (monthlyPonts.length) {
      dataRows.push({
        label: "Monthly",
        dataRangeMin,
        dataRangeMax,
        tickPoints,
        targetRangeMin: frequencyMaxSweepCountMap["monthly"] * timePeriodRatio - 2,
        targetRangeMax: frequencyMaxSweepCountMap["monthly"] * timePeriodRatio + 2,
        dataPoints: monthlyPonts.map((point) => point.actualRouteSweepCount),
      });
    }
    if (twoMonthlyPoints.length) {
      dataRows.push({
        label: "2x Monthly",
        dataRangeMin,
        dataRangeMax,
        tickPoints,
        targetRangeMin: frequencyMaxSweepCountMap["two_monthly"] * timePeriodRatio - 2,
        targetRangeMax: frequencyMaxSweepCountMap["two_monthly"] * timePeriodRatio + 2,
        dataPoints: twoMonthlyPoints.map((point) => point.actualRouteSweepCount),
      });
    }
    if (weeklyPoints.length) {
      dataRows.push({
        label: "Weekly",
        dataRangeMin,
        dataRangeMax,
        tickPoints,
        targetRangeMin: frequencyMaxSweepCountMap["weekly"] * timePeriodRatio - 2,
        targetRangeMax: frequencyMaxSweepCountMap["weekly"] * timePeriodRatio + 2,
        dataPoints: weeklyPoints.map((point) => point.actualRouteSweepCount),
      });
    }
    if (twoWeeklyPoints.length) {
      dataRows.push({
        label: "2x Weekly",
        dataRangeMin,
        dataRangeMax,
        tickPoints,
        targetRangeMin: frequencyMaxSweepCountMap["two_weekly"] * timePeriodRatio - 2,
        targetRangeMax: frequencyMaxSweepCountMap["two_weekly"] * timePeriodRatio + 2,
        dataPoints: twoWeeklyPoints.map((point) => point.actualRouteSweepCount),
      });
    }

    return dataRows;
  };

  var getPlanWidgetSubtitle = function (dataPath) {
    return dataPath === "focus" ? "Actual" : "Plan";
  };

  var loadDomListeners = function () {
    $insightContainer.off("change", `[name="dashboard-type"]`, onDashboardTypeChange);
    $insightContainer.on("change", `[name="dashboard-type"]`, onDashboardTypeChange);

    $insightContainer.off("change", `[name="time-period"]`, onTimePeriodChange);
    $insightContainer.on("change", `[name="time-period"]`, onTimePeriodChange);

    $insightContainer.off("change", ".year-dropdown", onYearChange);
    $insightContainer.on("change", ".year-dropdown", onYearChange);

    $insightContainer.off("change", `[name="priority"]`, onPriorityChange);
    $insightContainer.on("change", `[name="priority"]`, onPriorityChange);

    $insightContainer.off("change", `[name="route"]`, onRouteChange);
    $insightContainer.on("change", `[name="route"]`, onRouteChange);

    $insightContainer.off("click", ".count-by-type-chart-container .bar-group", onBarGroupClick);
    $insightContainer.on("click", ".count-by-type-chart-container .bar-group", onBarGroupClick);
  };

  var onDashboardTypeChange = function () {
    dashboardType = this.value;
    const widgets = getWidgets();
    controller.setConfigWidgets(widgets);
    controller.clearInsightContainer();
    render();
  };

  var onTimePeriodChange = function () {
    timePeriod = this.value;
    render();
  };

  var onYearChange = function () {
    if (this.name === "focus-year") {
      focusYear = this.value;
    } else if (this.name === "comparison-year") {
      comparisonYear = this.value;
    }
    render();
  };

  var onPriorityChange = function () {
    priority = this.value;
    render();
  };

  var onRouteChange = function () {
    route = this.value;
    render();
  };

  var onBarGroupClick = function () {
    var $barGroup = $(this);

    if ($barGroup.data("typeKey") === "priority") {
      var $prioritySelect = $insightContainer.find(`select[name="priority"]`);

      if ($barGroup.hasClass("selected")) {
        $prioritySelect.val("total").change();
      } else {
        const priority = $barGroup.data("type");
        if (priority) {
          $prioritySelect.val(priority).change();
        }
      }
    }
  };

  var render = function () {
    if (Tree.get("dataView") !== "roads" || (!focusYear && !comparisonYear)) {
      return;
    }

    controller.render({
      dashboardType,
      timePeriod,
      focusYear,
      comparisonYear,
      priority,
      currentReportingYear,
      route,
    });
  };

  var loadInitData = async function () {
    const initData = await ApiCalls.getStreetSwepingInsightInitData();

    reports = initData.reports;
    reportingYears = initData.years.sort().reverse();
    focusYear = reportingYears[0];
    currentReportingYear = reportingYears[0];
    comparisonYear = reportingYears[1] || reportingYears[0];

    const allPriorityOptions = RoadBulkOmOptions.priorityOptions.slice();
    priorityOptions = initData.priorities.map((priority) => {
      if (priority === null) {
        return {
          name: "No Data",
          value: "no-data",
        };
      }
      return {
        name: allPriorityOptions.getObjectWith("value", priority)?.name,
        value: priority,
      };
    });
    InsightWidgetFunctions.sortDataArrayByKey(
      priorityOptions,
      SharedDataFunctions.getPrioritySortOrder(),
      "value",
    );
    priorityOptions.unshift({ name: "All", value: "total" });

    sweeperTypeOptions = RoadBulkOmOptions.sweeperTypeSelectOptions.slice();

    const routes = initData.networkNames?.length ? initData.networkNames : initData.catchmentNames;
    routeOptions = getRouteNameOptions(routes);

    isCatchment = initData.isCatchment;
    totalRouteCount = initData.totalRouteCount;
    routeNamesWithPlan = initData.routeNamesWithPlan;
    if (routeNamesWithPlan?.length) {
      routeWithPlanOptions = getRouteNameOptions(routeNamesWithPlan);
    }
  };

  var getRouteNameOptions = function (names) {
    const options = names.map((route) => {
      if (route === null) {
        return {
          name: "Other",
          value: null,
        };
      }

      return {
        name: route,
        value: route,
      };
    });

    options.unshift({ name: "All", value: "total" });

    return options;
  };

  var getFilterMenuHtmlFunction = function () {
    var yearOptions = reportingYears.map((year) => {
      return { name: year, value: year };
    });

    return nunjucks.render("insight/streetSweepingInsightDashboardFilters.njk", {
      dashboardType,
      dashboardTypeOptions,
      priorityOptions,
      priority,
      yearOptions,
      focusYear,
      comparisonYear,
      routeOptions,
      routeWithPlanOptions,
      route,
      timePeriodOptions,
      timePeriod,
      showDashboardType: totalRouteCount,
    });
  };

  var getMobileFilterButtonTextFunction = function () {
    const displayPriority = priorityOptions.getObjectWith("value", priority)?.name;
    let displayRoute = route;

    if (route === "total") {
      displayRoute = "All";
    }

    if (route === null) {
      displayRoute = "Other";
    }

    if (route === "total" && priority === "total") {
      return "Default";
    }

    return `${displayRoute}, ${displayPriority}`;
  };

  var saveFilterFunction = function (formData) {
    if (formData) {
      if ("focusYear" in formData) {
        focusYear = parseInt(formData["focusYear"]);
        $insightContainer.find(`select[name="focus-year"]`).val(focusYear);
      }
      if ("comparisonYear" in formData) {
        comparisonYear = parseInt(formData["comparisonYear"]);
        $insightContainer.find(`select[name="comparison-year"]`).val(comparisonYear);
      }
      if ("priority" in formData) {
        priority = formData["priority"];
        $insightContainer.find(`select[name="priority"]`).val(priority);
      }
      if ("route" in formData) {
        route = formData["route"] || null;
        $insightContainer.find(`select[name="route"]`).val(route);
      }
    }

    render();
  };

  var getYearByDataPath = function (dataPath) {
    return dataPath === "focus" ? focusYear : comparisonYear;
  };

  var getSelectedPriority = function () {
    return priority;
  };

  var getCurbValueDisplayName = function (curbValue) {
    if (!FeatureFlag.enabled("sweep-insights-curb")) {
      return legacyGetCurbValueDisplayName(curbValue);
    }

    return NunjucksFilters.wholeNumberZeroOnePercent(curbValue);
  };

  var legacyGetCurbValueDisplayName = function (curbValue) {
    if (curbValue === null || curbValue === undefined) {
      return "—";
    } else if (curbValue <= 0.25) {
      return "Low";
    } else if (curbValue > 0.25 && curbValue <= 0.5) {
      return "Moderate";
    } else if (curbValue > 0.5 && curbValue < 0.75) {
      return "High";
    } else if (curbValue >= 0.75) {
      return "Very High";
    } else {
      return "—";
    }
  };

  return {
    init,
  };
};

module.exports = StreetSweepingInsightDashboardController();

const ApiCalls = require("../../apiCalls");
const InsightDashboardController = require("../../general/widgets/insightDashboardController");
const InsightWidgetFunctions = require("../../general/widgets/insightWidgetFunctions");
const InsightWidgetSimpleNumber = require("../../general/widgets/insightWidgetSimpleNumber");
const InsightWidgetTopList = require("../../general/widgets/insightWidgetTopList");
const InsightWidgetMonthlyCountChart = require("../../general/widgets/insightWidgetMonthlyCountChart");
const InsightWidgetCountByTypeChart = require("../../general/widgets/insightWidgetCountByTypeChart");
const InsightWidgetComparisonToTarget = require("../../general/widgets/insightWidgetComparisonToTarget");
const RoadBulkOmOptions = require("../../bulkSelect/roadBulkOmOptions");
const RecordSweepConstants = require("../recordSweepConstants");
const Tree = require("../../tree");
const ToolSettings = require("../../settings/toolSettings");
const SharedDataFunctions = require("../../general/sharedDataFunctions");
const FeatureFlag = require("../../settings/featureFlag");
const NunjucksFilters = require("../../general/nunjucksFilters");
