"use strict";

const RouteFunctions = require("./routeFunctions");
const SamplingSiteInspectionController = require("../../wq/inspection/samplingSiteInspectionController");

const SamplingSiteRoutes = function () {
  const inspectionRoute = {
    handler: function ({ data, params }) {
      return RouteFunctions.openNewOrExistingInspection({
        openInspectionFn: SamplingSiteInspectionController.showSamplingSiteInspectionModal,
        resourceKey: "samplingSiteInspection",
        assetId: data.id,
        inspectionId: data.inspectionId,
        params: params,
      });
    },
    hooks: {
      leave: RouteFunctions.leaveTabbedModalRoute,
    },
    data: {
      dataView: "sampling-site",
    },
  };

  return { inspectionRoute };
};

module.exports = SamplingSiteRoutes();
