"use strict";

const LidProjectDataListController = function () {
  var inited = false;

  var init = function (reInitialize = false) {
    if (inited && !reInitialize) {
      DataList.render();
    } else {
      inited = true;
      DataList.init({
        prepareDataCallback: prepareLidProjects,
        template: "list/lidProjectDataList.njk",
        layerName: _getLayerForDatalist(),
      });
      initialRender();
      DataList.loadDomListeners();
      DataList.loadDomListeners([{ target: ".open-fact-sheet", callback: clickOpenFactSheet }]);
      DataList.loadDomListeners([{ target: ".map-button-datalist-lid", callback: openPopup }]);
      DataList.loadDomListeners([
        { target: "input.check-all", callback: addAnalyticsToCheckboxInput },
      ]);
      LidSelfInspectionController.init();
    }
  };

  var clickOpenFactSheet = function () {
    const id = DataList.getIdFromRow($(this));
    UrlRoutes.go("lid-fact-sheet", { id });
  };

  var addAnalyticsToCheckboxInput = function () {
    Analytics.sendDataListEvent("filter:checkbox", "lid-project");
  };

  var openPopup = function () {
    const projectId = $(this).data("id");
    MapFunctions.openPopupById(projectId);
    Analytics.sendDataListEvent("address_to_map", "lid-project");
  };

  var initialRender = function () {
    DataList.setSorting("projectIdentifier", false);
    DataList.render();
  };

  var prepareLidProjects = function (lidProjects) {
    addRenderingProperties(lidProjects);

    sortLidProjectsBy(lidProjects);
    return { rows: lidProjects, options: {} };
  };

  var _updateImperviousAreaForSortingPurpose = function (project) {
    return project.priorityInformation?.displayNetImperviousArea;
  };

  var addRenderingProperties = function (lidProjects) {
    lidProjects.forEach((project) => {
      project.iconClass = _getTableIconClass(project);
      project.tierNumber = _getTierNumber(project);
      project.imperviousAreaDisplay = _updateImperviousAreaForSortingPurpose(project);
      project.showGenerateSummaryButton = showGenerateSummaryButton(project);
    });
  };

  var _getTierNumber = function (project) {
    return LidIcon.getTierNumber(project, true);
  };

  var _getTableIconClass = function (project) {
    const fontColor = LidProjectStyles.getLidPerformanceFontColor(project);
    return (
      "construction-marker-icon " +
      "lid-new " +
      LidProjectStyles.getNewLidProjectIconClass(project) +
      " g2-lid-table-icon-" +
      fontColor
    );
  };

  var isLidProjectActionable = function (phase) {
    return (
      SharedDataFunctions.isPhaseOrLater(phase, "completed") &&
      UserPermissions.getPermission("can_inspect_projects")
    );
  };

  var showGenerateSummaryButton = function (project) {
    return isLidProjectActionable(project.phase) && project.summaryLetterReady;
  };

  var sortLidProjectsBy = function (lidProjects) {
    const alphaSorter = SortUtilities.alphaSorter;
    var sortersDict = {
      projectIdentifier: alphaSorter,
      projectName: alphaSorter,
      streetAddress: alphaSorter,
      imperviousAreaDisplay: alphaSorter,
    };
    DataList.sortWithDict(lidProjects, sortersDict);
  };

  var _getLayerForDatalist = function () {
    const activeTab = Tree.get("activeTab");
    let layer;

    if (activeTab === "data") {
      layer = "postConstructionProjectG2";
    } else {
      layer = "lidProject";
    }

    return layer;
  };

  return {
    init,
    showGenerateSummaryButton,
    _updateImperviousAreaForSortingPurpose,
    _getTierNumber,
    _getTableIconClass,
    _getLayerForDatalist,
  };
};

module.exports = LidProjectDataListController();

const DataList = require("../mapFunctions/dataList");
const LidIcon = require("./lidIcon");
const LidProjectStyles = require("./lidProjectStyles");
const LidSelfInspectionController = require("./lidSelfInspectionController");
const SortUtilities = require("../general/sortUtilities");
const SharedDataFunctions = require("../general/sharedDataFunctions");
const UserPermissions = require("../login/userPermissions");
const MapFunctions = require("../mapFunctions/mapFunctions");
const Analytics = require("../general/analytics");
const Tree = require("../tree");
const UrlRoutes = require("../routes/urlRoutes");
