"use strict";

const OutfallInspectionHistory = function () {
  var renderOutfallInspectionHistory = function (data, outfallId) {
    var html = nunjucks.render("popups/history/outfallInspectionHistory.njk", {
      assessments: data,
      outfallId,
      hideResultColumn:
        FeatureFlag.enabled("sampling-site-inventory-modal") &&
        Tree.get("tool") === "outmon" &&
        !FeatureFlag.enabled("sampling-site-inspection"),
    });
    $(".popup-history-pane").html(html);
  };

  var editHistory = async function (tableRowData, readOnly) {
    const inspectionId = tableRowData.id;
    const outfallId = tableRowData.assetId;

    UrlRoutes.navigate(`/ram/asset/outfall/${outfallId}/routineInspection/${inspectionId}`);
  };

  var confirmDeleteHistory = function ($todoTr, tableRowData) {
    var selectedId = tableRowData.id;

    MessageModal.showDeleteRecordModal(() => {
      deleteHistory(selectedId);
      $todoTr.remove();
      if (tableRowData.assetId) {
        OutfallPopup.onPopupOpen({ id: tableRowData.assetId }, "muniOutfalls");
      }
    });
  };

  var deleteHistory = function (id) {
    ApiCalls.deleteOutfallInspection(id, OutfallFunctions.invalidateOutfallLayerDataByDataView());
  };

  return {
    editHistory,
    confirmDeleteHistory,
    renderOutfallInspectionHistory,
  };
};

module.exports = OutfallInspectionHistory();

const ApiCalls = require("../apiCalls");
const MessageModal = require("../modals/messageModal");
const OutfallFunctions = require("./outfallFunctions");
const UrlRoutes = require("../routes/urlRoutes");
const OutfallPopup = require("./outfallPopup");
const Tree = require("../tree");
const FeatureFlag = require("../settings/featureFlag");
