"use strict";

const WqMonitoringConstants = function () {
  var getWqInspectionTypeOptions = function (noRoutineInspection = false) {
    const options = ToolSettings.getDomainOptions("wq_inspection_type");

    if (noRoutineInspection) {
      return options.filter((option) => option.value !== "routine-inspection");
    }
    return options;
  };

  var getFlowConditionOptions = function () {
    return ToolSettings.getDomainOptions("flow_condition");
  };

  var getPrecipitationTypeOptions = function () {
    return FormSettings.getDropdownOptions("precipitation-type", { tool: "muni" });
  };

  var getCurrentWeatherOptions = function () {
    return ToolSettings.getDomainOptions("current_weather");
  };

  var getDefaultInspectionTypeByTool = function () {
    const tool = Tree.get("tool");

    if (tool === "outmon") {
      return "wq-monitoring";
    } else if (tool === "idde") {
      return "idde-screening";
    } else {
      return "routine-inspection";
    }
  };

  var isWqMonitoringWorkflowAssetType = function (assetType) {
    return ["outfall", "sampling-site"].includes(assetType);
  };

  return {
    getWqInspectionTypeOptions,
    getFlowConditionOptions,
    getPrecipitationTypeOptions,
    getCurrentWeatherOptions,
    getDefaultInspectionTypeByTool,
    isWqMonitoringWorkflowAssetType,
  };
};

module.exports = WqMonitoringConstants();

const FormSettings = require("../../settings/formSettings");
const ToolSettings = require("../../settings/toolSettings");
const Tree = require("../../tree");
