"use strict";

const DataSortConfig = function () {
  const get = function () {
    const SideBar = require("../esg/sideBar");
    return {
      watersheds: {
        displayName: "Watershed",
        topLevelLayer: "catchments",
        sortingProperty: "catchid",
        grouping: "watershed",
        dataGrouping: "gid",
        secondaryGroupingSort: "rw",
      },
      rw: {
        displayName: "Receiving Water",
        topLevelLayer: "catchments",
        sortingProperty: "catchid",
        grouping: "drains_to_rw",
        dataGrouping: "gid",
      },
      ud: {
        displayName: "Urban Drainage",
        topLevelLayer: "catchments",
        sortingProperty: "catchid",
        grouping: "drains_to_c",
        dataGrouping: "gid",
      },
      uc: {
        displayName: "Urban Catchment",
        topLevelLayer: "catchments",
        sortingProperty: "catchid",
        grouping: null,
        dataGrouping: "gid",
      },

      // Spatial Grouopings
      monitoringDrainages: {
        isSpatialGrouping: true,
        displayName: "Monitoring Drainage",
        topLevelLayer: "catchments",
        sortingProperty: "catchid",
        grouping: "spatialGrouping",
        dataGrouping: "gid",
      },

      assessmentAreas: {
        isSpatialGrouping: true,
        displayName: "Assessment Area",
        topLevelLayer: "catchments",
        sortingProperty: "catchid",
        grouping: "spatialGrouping",
        dataGrouping: "gid",
      },

      jurisdictions: {
        isSpatialGrouping: true,
        displayName: "Jurisdiction",
        topLevelLayer: "catchments",
        sortingProperty: "catchid",
        grouping: "spatialGrouping",
        dataGrouping: "gid",
      },

      maintenanceZones: {
        displayName: "Zones",
        topLevelLayer: "maintenanceZones",
        sortingProperty: "maintenanceZoneName",
        grouping: null,
        dataGrouping: "gid",
      },
      sweepingNetworks: {
        displayName: "Sweeping Routes",
        topLevelLayer: "sweepingNetworks",
        sortingProperty: Session.isNewDataDrivenRoadConditionGroup()
          ? "route_name"
          : "network_name",
        grouping: null,
        dataGrouping: Session.isNewDataDrivenRoadConditionGroup() ? "route_name" : "network_name",
      },
      highways: {
        displayName: "Highways",
        topLevelLayer: "highways",
        sortingProperty: "highwayName",
        grouping: null,
        dataGrouping: "gid",
      },

      projectIdentifier: {
        sortProperty: "projectIdentifier",
        isFlatList: true,
        displayName: "Project ID",
      },
      projectName: {
        sortProperty: "projectName",
        customDisplayProperty: "projectName",
        isFlatList: true,
        displayName: "Project Name",
      },
      projectAddress: {
        sortProperty: "streetAddressShort",
        customDisplayProperty: "streetAddressShort",
        isFlatList: true,
        displayName: "Address",
      },
      projectAddressG2: {
        sortProperty: "streetAddress",
        customDisplayProperty: "streetAddress",
        isFlatList: true,
        displayName: "Address",
      },
      status: {
        sortProperty: "statusSortingValue",
        isFlatList: true,
        displayName: "Project Status",
        disableInList: true,
      },
      priority: {
        sortProperty: "highPriority",
        isFlatList: true,
        displayName: "Priority",
        disableInList: true,
      },
      address: {
        sortProperty: "address",
        customDisplayProperty: "address",
        isFlatList: true,
        displayName: "Street Address",
        disableInList: true,
      },

      fcsId: { sortProperty: "bmpName", isFlatList: true, displayName: "FCS ID" },
      bmpName: {
        sortProperty: "bmpName",
        isFlatList: true,
        displayName: "sBMP ID",
        customDisplayProperty: "bmpName",
      },
      fcsTotal: {
        sortProperty: "formatted_bmp_drainarea",
        isFlatList: true,
        displayName: "FCS Drainage Area",
      },

      bmpTotal: {
        sortProperty: "formatted_bmp_drainarea",
        isFlatList: true,
        displayName: "Drainage Area",
        customDisplayProperty: "formatted_bmp_drainarea",
      },
      bmpImp: {
        sortProperty: "bmp_imparea",
        isFlatList: true,
        displayName: "Impervious Area",
        customDisplayProperty: "bmp_imparea",
      },
      fcsPlu: { sortProperty: "plu_treated", isFlatList: true, displayName: "FCS Treated PLU" },
      fcsType: { sortProperty: "type", isFlatList: true, displayName: "FCS Type" },
      bmpType: {
        sortProperty: "bmpType",
        isFlatList: true,
        displayName: "sBMP Type",
        customDisplayProperty: "bmpType",
      },
      fcsStatus: { sortProperty: "status", isFlatList: true, displayName: "FCS Status" },
      bmpStatus: {
        sortProperty: "bmpStatus",
        isFlatList: true,
        displayName: "sBMP Status",
        customDisplayProperty: "bmpStatus",
      },
      lidProjectId: {
        sortProperty: "projectIdentifier",
        isFlatList: true,
        displayName: "Project ID",
      },
      lidProjectPerformance: {
        sortProperty: "performanceTiers",
        isFlatList: true,
        displayName: "Performance Tier",
        disableInList: true,
      },
      lidProjectPerformanceG2: {
        sortProperty: "performanceTier",
        isFlatList: true,
        displayName: "Performance Tier",
        disableInList: true,
      },
      lidProjectStatus: {
        sortProperty: "projectStatus",
        isFlatList: true,
        displayName: "Status",
        disableInList: true,
      },
      muniCatchBasinId: {
        sortProperty: "bmpName",
        isFlatList: true,
        displayName: "Catch Basin ID",
      },
      structuralBmpsId: { sortProperty: "bmpName", isFlatList: true, displayName: "sBMP ID" },
      muniBmpId: { sortProperty: "bmpName", isFlatList: true, displayName: "sBMP ID" },
      muniManholeId: { sortProperty: "manholeName", isFlatList: true, displayName: "Manhole ID" },
      muniLiftStationsId: {
        sortProperty: "name",
        isFlatList: true,
        displayName: "Lift Station ID",
      },
      muniCleanoutId: { sortProperty: "name", isFlatList: true, displayName: "Cleanout ID" },
      muniCulvertId: { sortProperty: "name", isFlatList: true, displayName: "Culvert ID" },
      muniOpenChannelId: { sortProperty: "name", isFlatList: true, displayName: "Open Channel ID" },
      muniPipesId: { sortProperty: "name", isFlatList: true, displayName: "Pipe ID" },
      outfallsId: { sortProperty: "outfallName", isFlatList: true, displayName: "Outfall ID" },
      monitoringLocationId: {
        sortProperty: "name",
        isFlatList: true,
        displayName: "Monitoring Location ID",
      },
      apn: { defaultButtonDisabled: true, displayName: "APN", isFlatList: true },
      indcomFacilitiesName: {
        sortProperty: "facilityName",
        isFlatList: true,
        displayName: "Facility Name",
        disableInList: false,
      },
      facilityIdentifier: {
        sortProperty: "facilityIdentifier",
        customDisplayProperty: "facilityIdentifier",
        isFlatList: true,
        displayName: "Facility ID",
      },
      incidentName: {
        sortProperty: "incidentName",
        isFlatList: true,
        displayName: "Incident ID",
        disableInList: false,
      },
      activities: {
        sortProperty: "activityName",
        isFlatList: true,
        displayName: "Activity Name",
        disableInList: true,
      },
      scenarioAssetId: {
        sortProperty: "assetId",
        isFlatList: true,
        displayName: "Asset ID",
        disableInList: false,
      },
      assetManagementId: {
        sortProperty: "assetId",
        isFlatList: true,
        displayName: "Assets",
        disableInList: false,
        defaultButtonDisabled: true,
      },

      imperviousAreaTreated: {
        sortProperty: "imperviousAreaTreated",
        isFlatList: true,
        displayName: "Impervious Area",
      },
      typeAbbreviation: {
        sortProperty: "typeAbbreviation",
        isFlatList: true,
        displayName: "sBMP Type",
      },
      bmpPhase: {
        sortProperty: "phase",
        isFlatList: true,
        displayName: "sBMP Phase",
      },
      drainageArea: {
        sortProperty: "drainageArea",
        isFlatList: true,
        displayName: "Drainage Area",
      },
      siteIdentifier: { sortProperty: "siteIdentifier", isFlatList: true, displayName: "Site ID" },
      wqMonitoringLocationId: {
        sortProperty: "locationIdentifier",
        isFlatList: true,
        displayName: "Location ID",
      },

      // ESG
      propertyIdentifier: {
        sortProperty: "propertyIdentifier",
        isFlatList: SideBar.isFlatList(),
        displayName: "Property ID",
        topLevelLayer: "propertyIdentifier",
      },
      propertyName: {
        sortProperty: "propertyName",
        isFlatList: SideBar.isFlatList(),
        displayName: "Property Name",
        topLevelLayer: "propertyName",
      },
      rank: {
        sortProperty: "rank",
        isFlatList: SideBar.isFlatList(),
        displayName: "Rank",
        topLevelLayer: "rank",
      },
      programName: {
        sortProperty: "programName",
        isFlatList: SideBar.isFlatList(),
        displayName: "Program Name",
        topLevelLayer: "programName",
      },
    };
  };

  return {
    get,
  };
};

module.exports = DataSortConfig();

const Session = require("../login/session");
