"use strict";

const ProjectInventoryConstants = function () {
  const roleUserRoleMap = {
    auditor: ["viewer"],
    "ms4-project-engineer": ["reviewer"],
    "stormwater-inspector": ["responder"],
    "construction-superintendent": ["viewer"],
    "engineering-design-lead": ["data-manager", "planner"],
    "construction-lead": ["data-manager", "planner"],
    "field-lead": ["data-manager", "field-personnel"],
  };

  const noAffiliationRequired = ["ms4-project-engineer"];

  const specificUserOnly = [
    "stormwater-inspector",
    "construction-superintendent",
    "ms4-project-engineer",
  ];

  const ownershipOptions = [
    {
      name: "Our Agency",
      value: "our-agency",
    },
    {
      name: "Partner",
      value: "partner",
    },
    {
      name: "Private",
      value: "private",
    },
    {
      name: "DOT",
      value: "dot",
    },
    {
      name: "MS4",
      value: "ms4",
    },
    {
      name: "Local Agency",
      value: "local-agency",
    },
    {
      name: "Other",
      value: "other",
    },
    {
      name: "Unknown",
      value: "unknown",
    },
  ];

  const phaseOptions = [
    {
      name: "Planning",
      value: "planning",
    },
    {
      name: "Design",
      value: "planned",
    },
    {
      name: "Construction",
      value: "construction",
    },
    {
      name: "Completed",
      value: "completed",
    },
    {
      name: "Shelved",
      value: "shelved",
    },
    {
      name: "Rejected",
      value: "rejected",
    },
  ];

  const alternativeComplianceCircumstancesOptions = [
    {
      name: "Tech. Infeasibility: WQ Treatment",
      value: "water-quality-treatment",
    },
    {
      name: "Tech. Infeasibility: Runoff Retention",
      value: "runoff-retention",
    },
    {
      name: "Tech. Infeasibility: Peak Management",
      value: "peak-management",
    },
    {
      name: "Watershed or Regional Plan",
      value: "watershed-or-regional-plan",
    },
    {
      name: "Urban Sustainability Area",
      value: "urban-sustainability-area",
    },
  ];

  const cgpRiskLevelOptions = [
    {
      name: "1",
      value: "1",
    },
    {
      name: "2",
      value: "2",
    },
    {
      name: "3",
      value: "3",
    },
  ];

  var getRoleOptions = function () {
    const dataView = Tree.get("dataView");

    if (dataView === "construction-project-delivery") {
      return roleOptionsDelivery;
    }

    if (ToolSettings.getSetting("construction", "is-findings-workflow")) {
      return roleOptionsFindings;
    }

    return roleOptions;
  };

  var getUserRoleOptions = function () {
    const dataView = Tree.get("dataView");

    if (dataView === "construction-project-delivery") {
      return userRoleOptionsDelivery;
    }

    if (ToolSettings.getSetting("construction", "is-findings-workflow")) {
      return userRoleOptionsPcrFindings;
    }

    return userRoleOptionsPcr;
  };

  var getAllRoleOptions = function () {
    return roleOptions.concatWithUniqueObjectProperty(roleOptionsDelivery, "value");
  };

  var getAllUserRoleOptions = function () {
    return userRoleOptionsPcr
      .concatWithUniqueObjectProperty(userRoleOptionsPcrFindings, "value")
      .concatWithUniqueObjectProperty(userRoleOptionsDelivery, "value");
  };

  const affiliationAccessOptions = [
    {
      name: "All Users",
      value: true,
    },
    {
      name: "Specific User",
      value: false,
    },
  ];

  const roleOptions = [
    {
      displayName: "Property Owner/LRP",
      name: "Property Owner/LRP",
      value: "owner",
    },
    {
      displayName: "Applicant",
      name: "Applicant",
      value: "applicant",
    },
    {
      displayName: "General Contractor",
      name: "General Contractor",
      value: "responder",
    },
    {
      displayName: "Subcontractor",
      name: "Subcontractor",
      value: "sub-contractor",
    },
    {
      displayName: "Construction Project Engineer",
      name: "Construction Project Engineer",
      value: "engineer",
    },
    {
      displayName: "O&M Contact",
      name: "O&M Contact",
      value: "operator-maintainer",
    },
    {
      displayName: "Construction Superintendent",
      name: "Construction Superintendent",
      value: "construction-superintendent",
    },
    {
      displayName: "Stormwater Inspector",
      name: "Stormwater Inspector",
      value: "stormwater-inspector",
    },
    {
      displayName: "Stormwater Plan Designer",
      name: "Stormwater Plan Designer",
      value: "stormwater-plan-designer",
    },
  ];

  const roleOptionsFindings = [
    {
      displayName: "Property Owner/LRP",
      name: "Property Owner/LRP",
      value: "owner",
    },
    {
      displayName: "Applicant",
      name: "Applicant",
      value: "applicant",
    },
    {
      displayName: "General Contractor",
      name: "General Contractor",
      value: "responder",
    },
    {
      displayName: "Subcontractor",
      name: "Subcontractor",
      value: "sub-contractor",
    },
    {
      displayName: "Construction Project Engineer",
      name: "Construction Project Engineer",
      value: "engineer",
    },
    {
      displayName: "O&M Contact",
      name: "O&M Contact",
      value: "operator-maintainer",
    },
    {
      displayName: "Stormwater Plan Designer",
      name: "Stormwater Plan Designer",
      value: "stormwater-plan-designer",
    },
  ];

  const roleOptionsDelivery = [
    {
      displayName: "Property Owner/LRP",
      name: "Property Owner/LRP",
      value: "owner",
    },
  ];

  const userRoleOptionsDelivery = [
    {
      displayName: "Engineering Design Lead",
      name: "Engineering Design Lead",
      value: "engineering-design-lead",
    },
    {
      displayName: "Construction Lead",
      name: "Construction Lead",
      value: "construction-lead",
    },
    {
      displayName: "Field Lead",
      name: "Field Lead",
      value: "field-lead",
    },
  ];

  const userRoleOptionsPcr = [
    {
      displayName: "Auditor",
      name: "Auditor",
      value: "auditor",
    },
  ];

  const userRoleOptionsPcrFindings = [
    {
      displayName: "MS4 Project Engineer",
      name: "MS4 Project Engineer",
      value: "ms4-project-engineer",
    },
    {
      displayName: "Stormwater Inspector",
      name: "Stormwater Inspector",
      value: "stormwater-inspector",
    },
    {
      displayName: "Construction Superintendent",
      name: "Construction Superintendent",
      value: "construction-superintendent",
    },
    {
      displayName: "Auditor",
      name: "Auditor",
      value: "auditor",
    },
  ];

  const uploadLocationOptions = [
    {
      name: "File upload",
      value: "upload",
    },
    {
      name: "File location",
      value: "location",
    },
    {
      name: "File link",
      value: "external-url",
    },
  ];

  var getFileTypeOptions = function () {
    const dataView = Tree.get("dataView");

    if (dataView === "construction-project-delivery") {
      return fileTypeOptionsDelivery;
    }

    return getConstructionFileTypeOptions().sort(SortUtilities.alphaSortFormOptions);
  };

  var getAllFileTypeOptions = function () {
    return getConstructionFileTypeOptions().concatWithUniqueObjectProperty(
      fileTypeOptionsDelivery,
      "value",
    );
  };

  var getConstructionFileTypeOptions = function () {
    return [
      {
        displayName: "Asset Inspection Report",
        name: "Asset Inspection Report",
        value: "asset-inspection-report",
      },
      {
        displayName: "Asset Maintenance Report",
        name: "Asset Maintenance Report",
        value: "asset-maintenance-report",
      },
      {
        displayName: "Asset O&M Photo Log",
        name: "Asset O&M Photo Log",
        value: "asset-om-photo-log",
      },
      {
        displayName: "Building Permit",
        name: "Building Permit",
        value: "building-permit",
      },
      {
        displayName: "CGP Regional Board Sign Off",
        name: "CGP Regional Board Sign Off",
        value: "cgp-regional-board-sign-off",
      },
      {
        displayName: "Engineer Sign Off",
        name: "Engineer Sign Off",
        value: "engineer-sign-off",
      },
      {
        displayName: "Erosion Sediment Control Plan",
        name: "ESCP",
        value: "escp",
      },
      {
        displayName: "Final Design Plan",
        name: "Final Design Plan",
        value: "final-design-plan",
      },
      {
        displayName: "Maintenance Notice",
        name: "Maintenance Notice",
        value: "formal-communication",
      },
      {
        displayName: "Grading Permit",
        name: "Grading Permit",
        value: "grading-permit",
      },
      {
        displayName: "Inspection Report",
        name: "Inspection Report",
        value: "inspection-summary",
      },
      {
        displayName: "Maintenance Declaration",
        name: "Maintenance Declaration",
        value: "maintenance-declaration",
      },
      {
        displayName: "Maintenance Response Supplement",
        name: "Maintenance Response Supplement",
        value: "maintenance-response-supplement",
      },
      {
        displayName: "Notice of Termination",
        name: "Notice of Termination",
        value: "notice-of-termination",
      },
      {
        displayName: "O&M Agreement",
        name: "O&M Agreement",
        value: "operator-maintainer-agreement",
      },
      {
        displayName: "Occupancy Certification",
        name: "Occupancy Certification",
        value: "occupancy-certification",
      },
      {
        displayName: "Photo Album",
        name: "Photo Album",
        value: "photo-album",
      },
      {
        displayName: "Project As-Builts",
        name: "Project As-Builts",
        value: "as-built",
      },
      {
        displayName: "Project Design Plan",
        name: "Project Design Plan",
        value: "project-design-plan",
      },
      {
        displayName: "QSP Sign Off",
        name: "QSP Sign Off",
        value: "qsp-sign-off",
      },
      {
        displayName: "Stormwater Control Plan",
        name: "SWCP",
        value: "swcp",
      },
      {
        displayName: "Stormwater Pollution Prevention Plan",
        name: "SWPPP",
        value: "swppp",
      },
      {
        displayName: "Other",
        name: "Other",
        value: "other",
      },
    ];
  };

  const fileTypeOptionsDelivery = [
    {
      displayName: "30% Design Plan",
      name: "30% Design Plan",
      value: "design-plan-30",
    },
    {
      displayName: "60% Design Plan",
      name: "60% Design Plan",
      value: "design-plan-60",
    },
    {
      displayName: "Project As-Builts",
      name: "Project As-Builts",
      value: "as-built",
    },
    {
      displayName: "Bid Package",
      name: "Bid Package",
      value: "bid-package",
    },
    {
      displayName: "Budget Book",
      name: "Budget Book",
      value: "budget-book",
    },
    {
      displayName: "Building Permit",
      name: "Building Permit",
      value: "building-permit",
    },
    {
      displayName: "Project Certification Docs",
      name: "Project Certification Docs",
      value: "project-certification-docs",
    },
    {
      displayName: "Construction Photo Album",
      name: "Construction Photo Album",
      value: "construction-photo-album",
    },
    {
      displayName: "Environmental Site Assessment",
      name: "Environmental Site Assessment",
      value: "env-site-assess",
    },
    {
      displayName: "Final Design Plan",
      name: "Final Design Plan",
      value: "final-design-plan",
    },
    {
      displayName: "Grading Permit",
      name: "Grading Permit",
      value: "grading-permit",
    },
    {
      displayName: "Marketing Photo Album",
      name: "Marketing Photo Album",
      value: "marketing-photo-album",
    },
    {
      displayName: "Permits",
      name: "Permits",
      value: "permits",
    },
    {
      displayName: "Photo Album",
      name: "Photo Album",
      value: "photo-album",
    },
    {
      displayName: "Project Justification",
      name: "Project Justification",
      value: "project-justification",
    },
    {
      displayName: "Property Owner Consent",
      name: "Property Owner Consent",
      value: "project-owner-consent",
    },
    {
      displayName: "Other",
      name: "Other",
      value: "other",
    },
  ];

  const areaUnits = [
    // Somewhat confusingly named, but 'name' is what we show in the dropdown,
    // 'displayName' is what we display for reading with the value ("10 acres"),
    // and 'value' is for storing and unit conversion.
    {
      name: "ac",
      displayName: "acres",
      value: "acres",
    },
    {
      name: "sq-ft",
      displayName: "square feet",
      value: "squareFeet",
    },
  ];

  const lengthUnits = [
    // Somewhat confusingly named, but 'name' is what we show in the dropdown,
    // 'displayName' is what we display for reading with the value ("10 feet"),
    // and 'value' is for storing and unit conversion.
    {
      name: "ft",
      displayName: "feet",
      value: "feet",
    },
    {
      name: "m",
      displayName: "meters",
      value: "meters",
    },
  ];

  const projectPhaseOptions = [
    {
      name: "Planning",
      value: "planning",
    },
    {
      name: "Design",
      value: "planned",
    },
    {
      name: "Shelved",
      value: "shelved",
    },
    {
      name: "Rejected",
      value: "rejected",
    },
    {
      name: "Construction",
      value: "construction",
    },
  ];

  const constructionPhaseOptions = [
    {
      name: "Planning",
      value: "planning",
    },
    {
      name: "Post Active",
      value: "post-active",
    },
    {
      name: "Design",
      value: "planned",
    },
    {
      name: "Completed",
      value: "completed",
    },
    {
      name: "Certified",
      value: "certified",
    },
    {
      name: "Construction",
      value: "construction",
    },
  ];

  const rejectionReasonOptions = [
    {
      name: "Cost Too High",
      value: "cost-too-high",
    },
    {
      name: "Timing Issue",
      value: "timing-issue",
    },
    {
      name: "External Dependency",
      value: "external-dependency",
    },
    {
      name: "Limited Benefits",
      value: "limited-benefits",
    },
    {
      name: "Resource Issue",
      value: "resource-issue",
    },
  ];

  const allTreatmentTypeFields = [
    "firstType",
    "subType",
    "count",
    "capacityAcreFeet",
    "treatedImperviousAreaAcres",
    "footprintSquareFeet",
  ];

  var getTreatmentTypeFirstTypeOptions = function () {
    return [
      {
        name: "Stormwater Structural BMP",
        value: "bmp-type",
        disabledInPhases: ["planned", "construction", "completed", "certified"],
        subTypeOptions: {
          defaultData: {
            type: "bmp-type-13",
            count: 1,
            capacityAcreFeet: 0,
            treatedImperviousAreaAcres: 0,
            footprintSquareFeet: 0,
            lengthFeet: null,
          },
          enabledFields: allTreatmentTypeFields,
          readOnlyFields: ["count"],
          readOnlyFieldsByPhase: {
            planned: [
              "firstType",
              "subType",
              "capacityAcreFeet",
              "treatedImperviousAreaAcres",
              "footprintSquareFeet",
            ],
            construction: [
              "firstType",
              "subType",
              "capacityAcreFeet",
              "treatedImperviousAreaAcres",
              "footprintSquareFeet",
            ],
          },
          typeOptions: getTreatmentTypeBmpOptions(),
        },
      },
      {
        name: "Environmental Site Design",
        value: "environmental-site-design",
        subTypeOptions: {
          defaultData: {
            type: "environmental-site-design-runoff-neutral",
          },
          enabledFields: ["firstType", "subType"],
          readOnlyFields: [],
          emptyFieldsOnChange: true,
          typeOptions: [
            {
              name: "Runoff Neutral",
              value: "runoff-neutral",
              readOnlyFields: ["footprintSquareFeet"],
              enabledFields: ["firstType", "subType", "footprintSquareFeet"],
            },
            {
              name: "Impervious Removal",
              value: "impervious-removal",
              enabledFields: ["firstType", "subType", "footprintSquareFeet"],
            },
            {
              name: "Rain Barrels",
              value: "rain-barrels",
              enabledFields: ["firstType", "subType", "count", "capacityAcreFeet"],
            },
          ],
        },
      },
      {
        name: "Stormwater Conveyance",
        value: "stormwater-conveyance",
        enabledFields: ["firstType"],
        readOnlyFields: [],
        disableMultiple: true,
      },
      {
        name: "Outfall Protection",
        value: "outfall-protection",
        enabledFields: ["firstType", "footprintSquareFeet"],
        readOnlyFields: ["footprintSquareFeet"],
        disableMultiple: true,
      },
      {
        name: "Riparian Restoration",
        value: "riparian-restoration",
        subTypeOptions: {
          defaultData: {
            type: "riparian-restoration-floodplain-restoration",
            count: null,
            capacityAcreFeet: null,
            treatedImperviousAreaAcres: null,
            footprintSquareFeet: 0,
            lengthFeet: 0,
          },
          enabledFields: ["firstType", "subType", "footprintSquareFeet", "lengthFeet"],
          readOnlyFields: ["footprintSquareFeet"],
          typeOptions: [
            {
              name: "Floodplain Restoration",
              value: "floodplain-restoration",
            },
            {
              name: "Step Pool Storm Conveyance",
              value: "step-pool-storm-conveyance",
            },
            {
              name: "Stream Restoration",
              value: "stream-restoration",
            },
          ],
        },
        disableMultiple: true,
      },
      {
        name: "Pollutant Ground Control",
        value: "pollutant-ground-control",
        enabledFields: ["firstType"],
        readOnlyFields: [],
        disableMultiple: true,
      },
    ];
  };

  const kpiPlanningTotals = [
    {
      key: "capacityAcreFeet",
      class: ".treatment-type-capacity",
    },
    {
      key: "treatedImperviousAreaAcres",
      class: ".treatment-type-treated-impervious-area",
    },
  ];

  var getStatesOptions = function () {
    return FormSettings.getDropdownOptions("states");
  };

  var getTreatmentTypeBmpOptions = function () {
    var bmpTypeOptions = [];
    const unwantedTypeIds = [
      ...FormConstants.fcsOnlyTypeNumbers,
      ...FormConstants.unknownOnlyTypeNumbers,
    ];
    for (let bmpId in FormConstants.bmpTypes) {
      bmpId = parseInt(bmpId);
      if (!unwantedTypeIds.includes(bmpId)) {
        bmpTypeOptions.push({
          name: `(${FormConstants.bmpTypeAbbrs[bmpId]}) ${FormConstants.bmpTypes[bmpId]}`,
          value: bmpId,
        });
      }
    }

    return bmpTypeOptions.sort(function (a, b) {
      return SortUtilities.alphaSorter(a.name, b.name);
    });
  };

  var getAttributeMap = function () {
    return {
      "treatment-rate": "bmp_trate",
      "low-flow": "bmp_flow",
      diversion: "diversion",
      drainsout: "drainsout",
      "treatment-capacity": "bmp_wqcap",
      "treatment-capacity-unit": "treatmentCapacityUnit",
      footprint: "bmp_footprint",
      "footprint-unit": "footprintUnit",
      "intake-rate": "intake_rate",
      "wet-pool-capacity": "bmp_wet",
      "wet-pool-capacity-unit": "wetPoolCapacityUnit",
      "infiltration-rate": "bmp_irate",
      "drawdown-time": "bmp_drawdown",
      "bmp-imparea": "bmpImparea",
      "flow-length": "flowLength",
      "installation-date": "installationDate",
    };
  };

  var requiredFieldMessages = {
    "treatment-capacity": "Treatment capacity",
    "low-flow": "Low flow bypass",
    diversion: "Dry weather diversion",
    drainsout: "Capture & reuse",
    footprint: "Footprint",
    "intake-rate": "Intake rate",
    "wet-pool-capacity": "Wet pool capacity",
    "infiltration-rate": "Infiltration rate",
    "drawdown-time": "Drawdown time",
    "treatment-rate": "Treatment rate",
    "bmp-imparea": "Impervious area",
    bmp_drainarea: "Drainage area",
    "flow-length": "Maximum flow path length",
    "project-start-date": "Construction Start",
    "project-end-date": "Construction End",
  };

  var getFieldNamesAndMessage = function (fields) {
    return fields.map((field) => {
      const name = extractName(field);
      const message = requiredFieldMessages[name];
      if (message) {
        return {
          fieldName: field,
          message: message,
        };
      }
    });
  };

  var getPossibleUserRolesByRole = function (role) {
    if (Object.keys(roleUserRoleMap).includes(role)) {
      return roleUserRoleMap[role];
    }
    return [];
  };

  var extractName = function (field) {
    return field.split("[").slice(-1)[0].split("]")[0];
  };

  var getAffiliationAccessOptions = function (optionLength, role, company) {
    if (noAffiliationRequired.includes(role) || (specificUserOnly.includes(role) && company)) {
      return [
        {
          name: "Specific User",
          value: false,
        },
      ];
    }

    return optionLength > 0 ? affiliationAccessOptions : [];
  };

  const assetsHiddenPhases = ["construction", "post-active", "completed", "certified"];
  const assetsDisabledPhases = ["completed", "certified"];

  return {
    phaseOptions,
    ownershipOptions,
    cgpRiskLevelOptions,
    alternativeComplianceCircumstancesOptions,
    getPossibleUserRolesByRole,
    getRoleOptions,
    getUserRoleOptions,
    getAttributeMap,
    uploadLocationOptions,
    getFileTypeOptions,
    areaUnits,
    lengthUnits,
    getAllFileTypeOptions,
    getAllRoleOptions,
    getAllUserRoleOptions,
    getAffiliationAccessOptions,
    projectPhaseOptions,
    getTreatmentTypeFirstTypeOptions,
    kpiPlanningTotals,
    allTreatmentTypeFields,
    rejectionReasonOptions,
    assetsHiddenPhases,
    assetsDisabledPhases,
    getStatesOptions,
    constructionPhaseOptions,
    getFieldNamesAndMessage,
    extractName,
    noAffiliationRequired,
  };
};

module.exports = ProjectInventoryConstants();

const Tree = require("../tree");
const FormConstants = require("../mapFunctions/formConstants");
const SortUtilities = require("../general/sortUtilities");
const FormSettings = require("../settings/formSettings");
const ToolSettings = require("../settings/toolSettings");
