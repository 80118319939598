"use strict";

const SamplingSiteInspectionGeneralInfo = function (Controller) {
  const stringKey = "general-info";
  let $page;

  var loadListeners = function (data) {
    $page = $(`#assessment-modal #${stringKey}`);

    RoutineMaintenanceFormGeneral.loadListenersWqMonitoringWorkflow($page, data, Controller);
  };

  var unloadListeners = function () {
    RoutineMaintenanceFormGeneral.unloadListeners($page);
  };

  var getProps = function (data) {
    const props = {
      enableAssessmentTime: true,
      wqInspectionTypeOptions: WqMonitoringConstants.getWqInspectionTypeOptions(true),
      flowConditionOptions: WqMonitoringConstants.getFlowConditionOptions(),
      currentWeatherOptions: WqMonitoringConstants.getCurrentWeatherOptions(),
    };

    RoutineMaintenanceFormGeneral.addProps(props, data);

    return props;
  };

  var getStringKey = function () {
    return stringKey;
  };

  var getTabHeader = function () {
    return RoutineMaintenanceFormGeneral.getTabName();
  };

  var getPage = function () {
    return $page;
  };

  return {
    loadListeners,
    unloadListeners,
    getStringKey,
    getTabHeader,
    getProps,
    getPage,
  };
};

module.exports = SamplingSiteInspectionGeneralInfo;

const WqMonitoringConstants = require("./wqMonitoringConstants");
const RoutineMaintenanceFormGeneral = require("../../general/routineMaintenanceFormGeneral");
