"use strict";

const SamplingSiteInventorySetLocation = function () {
  const stringKey = "sampling-site-set-location";
  const headerInformation = "Location";
  let $page;

  var loadSelectLocationListeners = function () {
    $page.on("change", "[name=latitude], [name=longitude]", updateLatLngFromInput);
    $page.on("input", ":input.form-control", changeSetLocationInput);
    Form.initializeAndLoadListeners(
      $page,
      SamplingSiteInventoryModalController.getSamplingSiteInventoryFormKey(),
      { isMultiPart: true },
    );
  };

  var removeListeners = function () {
    $page.off("change", "[name=latitude], [name=longitude]", updateLatLngFromInput);
    $page.off("input", ":input.form-control", changeSetLocationInput);
  };

  var changeSetLocationInput = function () {
    const changedField = $(this).attr("name");
    if (changedField === "site-identifier") {
      setSamplingSiteIdentifier($(this).val());
    }
  };

  var setSamplingSiteIdentifier = function (newName) {
    if (newName === SamplingSiteInventoryModalController.getExistingData("siteIdentifier")) {
      restoreButtonsIfHasAllRequiredInputs();
      SamplingSiteInventoryModalController.unsetFormDataField("siteIdentifier");
    } else if (newName === "") {
      InventoryModal.allowedSaveState(false);
      SamplingSiteInventoryModalController.unsetFormDataField("siteIdentifier");
      InventoryModal.disableSaveButton();
      InventoryModal.disableNavigationButtons();
    } else {
      SamplingSiteInventoryModalController.setFormDataField("siteIdentifier", newName);
      restoreButtonsIfHasAllRequiredInputs();
    }
  };

  var getStringKey = function () {
    return stringKey;
  };

  var getHeaderInformation = function () {
    return headerInformation;
  };

  var render = async function (options) {
    await renderPageHtml();
    setValidLatLongInTree();
    ModalMap.removeMarkerLayer();
    showLocationSelectionMap(options);
    loadSelectLocationListeners();
    disableUnavailableElements();
  };

  var disableUnavailableElements = function () {
    const siteIdentifier =
      SamplingSiteInventoryModalController.getLatestSamplingSiteInventoryData("siteIdentifier");
    Misc.toggleDisabled($("#inventory-modal input[name='site-identifier']"), !siteIdentifier);

    if (siteIdentifier) {
      restoreButtonsIfHasAllRequiredInputs();
    } else {
      InventoryModal.allowedSaveState(false);
      InventoryModal.disableNavigationButtons();
    }
  };

  var checkCreateNewSamplingSiteMarker = function () {
    var id = false;
    var isNewMarker = !id;
    return isNewMarker;
  };

  var showLocationSelectionMap = function (options) {
    const configuration = {
      checkNewMarkerHandler: checkCreateNewSamplingSiteMarker,
      newExistingMarkerHandler: SamplingSiteInventoryModalController.loadExistingMarker,
      locationDataUpdateHandler: updateSetLocationFooter,
      badLocationUpdateHandler: resetLatLngToLastValidValue,
      createMarkerHandler: createSamplingSiteMarker,
    };

    LocationSelectionMap.configureLocationModalMapHandlers(configuration);
    LocationSelectionMap.showLocationSelectionMap(options?.existingLocation);

    setLocationMapText();
  };

  var createSamplingSiteMarker = function (latlng) {
    return ModalMap.getMarker(latlng);
  };

  var updateSetLocationFooter = async function (locationInfo, latlng) {
    const promise = updateLocationInfo(locationInfo);
    updateLatLngFields(latlng);

    const $siteIdentifier = $page.find("input[name='site-identifier']");
    if ($siteIdentifier.attr("disabled")) {
      Misc.toggleDisabled($siteIdentifier, false);
      $siteIdentifier.focus();
    }

    return promise;
  };

  var updateLocationInfo = function (locationInfo) {
    locationInfo = parseLocationInfo(locationInfo);

    SamplingSiteInventoryModalController.setFormDataField("locationInfo", locationInfo);
    setInputField("maintenance-zone", locationInfo.maintenanceZone, true);
    setInputField("maintenanceSection", locationInfo.maintenanceSection, true);
    setInputField("parcel-apn", locationInfo.parcelApn, true);
    setInputField("catchment-id", locationInfo.catchmentName, true);
    setInputField("receiving-water-name", locationInfo.receivingWaterName, true);
    setInputField("address", locationInfo.address, true);
    setInputField("city", locationInfo.groupCityName, true);
    setInputField("zip", locationInfo.zip, true);
    setInputField("state", locationInfo.state, true);
    setInputField("highwayAssignment", locationInfo.highways, true);
    setInputField("region", locationInfo.regionName, true);
    setInputField("patrol", locationInfo.patrol, true);
  };

  var updateLatLngFields = function (latlng) {
    const latInput = $page.find(`input[name="latitude"]`)[0];
    const longInput = $page.find(`input[name="longitude"]`)[0];

    if (latInput && longInput) {
      NumberInput.manuallySetInput(latInput, latlng.lat);
      NumberInput.manuallySetInput(longInput, latlng.lng);
    }

    SamplingSiteInventoryModalController.setFormDataField("latitude", latlng.lat);
    SamplingSiteInventoryModalController.setFormDataField("longitude", latlng.lng);
    restoreButtonsIfHasAllRequiredInputs();
  };

  var setInputField = function (field, value, updateForm) {
    if (field === "state") {
      value = Misc.convertStateForAutoPopulate(value);
    }
    if (field === "catchment-id") {
      $(".site-catch-id").text(value);
    }
    if (field === "receiving-water-name") {
      $(".site-receiving-water-name").text(value);
    }

    $page.find(`[name=${field}]`).val(value);
    if (updateForm && value !== undefined) {
      SamplingSiteInventoryModalController.setFormDataField(field, value);
    }
  };

  var setLocationMapText = function () {
    if (ModalMap.modalMapHasLayer("singleMarkerLayer")) {
      ModalMap.showLocationMessage("Click map or drag marker to change location");
    } else {
      ModalMap.showLocationMessage("Click map to set location");
    }
  };

  var parseLocationInfo = function (locationInfo) {
    return {
      regionName: locationInfo?.gname,
      highways: locationInfo.highway_name,
      catchmentGid: locationInfo.gid,
      catchmentName: locationInfo.catchid,
      receivingWaterName: locationInfo.drains_to_rw,
      landuseType: locationInfo.combo,
      groupName: locationInfo.gname,
      groupId: locationInfo.group_id,
      groupCityName: locationInfo.city,
      address: locationInfo.address,
      zip: locationInfo.zip,
      state: locationInfo.state,
      parcelApn: locationInfo.parcelid,
      maintenanceZone: locationInfo.maintenanceZone,
      maintenanceSection: locationInfo.maintenance_section,
      patrol: locationInfo.patrol,
    };
  };

  var getProps = function () {
    const renderProps = SamplingSiteInventoryModalController.getInventoryForRender();
    const stateOptions = SamplingSiteInventoryConstants.getStatesOptions();

    return {
      ...renderProps,
      locationInfo: parseLocationInfo(renderProps.locationInfo ?? {}),
      stateOptions,
    };
  };

  var setValidLatLongInTree = function () {
    const lat = SamplingSiteInventoryModalController.getLatestSamplingSiteInventoryData("latitude");
    const lng =
      SamplingSiteInventoryModalController.getLatestSamplingSiteInventoryData("longitude");
    if (lat && lng) {
      Tree.set("modalMapSingleMarkerLastValidLatLng", { lat, lng });
    }
  };

  var renderPageHtml = async function () {
    const props = getProps();
    const html = nunjucks.render(
      "modals/samplingSiteInventory/samplingSiteInventorySetLocation.njk",
      props,
    );
    SamplingSiteInventoryModalController.renderPageContent(html);
    $page = $(".modal-dynamic-content");
  };

  var updateLatLngFromInput = function () {
    if ($page.find(`input[name="latitude"]`).val() && $page.find(`input[name="longitude"]`).val()) {
      const e = {
        latlng: {
          lat: $page.find(`input[name="latitude"]`).val(),
          lng: $page.find(`input[name="longitude"]`).val(),
        },
      };
      LocationSelectionMap.locationSelectionMapClick(e);

      restoreButtonsIfHasAllRequiredInputs();
    } else {
      InventoryModal.allowedSaveState(false);
      InventoryModal.disableNavigationButtons();
    }
  };

  var hasAllRequiredInputs = function () {
    const latitude = $page.find(`input[name="latitude"]`).val();
    const longitude = $page.find(`input[name="longitude"]`).val();
    const siteIdentifier = $page.find(`input[name="site-identifier"]`).val();

    return latitude && longitude && siteIdentifier;
  };

  var restoreButtonsIfHasAllRequiredInputs = function () {
    if (hasAllRequiredInputs()) {
      InventoryModal.allowedSaveState(true);
      InventoryModal.restoreNavigationButtons();
    }
  };

  var resetLatLngToLastValidValue = function (latlng) {
    NumberInput.manuallySetInput($page.find(`input[name="latitude"]`)[0], latlng.lat);
    NumberInput.manuallySetInput($page.find(`input[name="longitude"]`)[0], latlng.lng);
  };

  var cleanUp = function () {
    removeListeners();
    LocationSelectionMap.hideLocationSelectionMap();
    $page.empty();
    return true;
  };

  var validateSamplingSiteIdentifier = async function () {
    const alreadyUsedId = Tree.get([
      "samplingSiteInventoryState",
      "currentSamplingSiteData",
      "siteIdentifier",
    ]);
    const currentIdState = Tree.get("samplingSiteInventoryState", "siteIdentifier");

    if (alreadyUsedId === currentIdState) {
      return true;
    } else {
      const groupId = Tree.get("activeGroup", "groupId");
      const isUnique = await ApiCalls.checkUniqueSamplingSiteIdentifier(groupId, currentIdState);
      if (!isUnique[0].unique) {
        MessageModal.showSimpleWarningModal(
          "The ID you entered already exists in our records! Please choose a different name or contact <a href='mailto:customersuccess@2ndnaturewater.com'>customersuccess@2ndnaturewater.com</a> for further assistance.",
          true,
        );
      }
      return isUnique[0].unique;
    }
  };

  var validate = async function () {
    return validateSamplingSiteIdentifier().catch((err) => {
      throw err;
    });
  };

  return {
    getStringKey,
    getHeaderInformation,
    render,
    renderPageHtml,
    setInputField,
    cleanUp,
    validate,
    validateSamplingSiteIdentifier,
    updateSetLocationFooter,
    loadSelectLocationListeners,
  };
};

module.exports = SamplingSiteInventorySetLocation();

const Form = require("../general/form");
const InventoryModal = require("../general/inventoryModal");
const LocationSelectionMap = require("../mapFunctions/locationSelectionMap");
const Misc = require("../misc");
const ModalMap = require("../mapFunctions/modalMap");
const NumberInput = require("../general/numberInput");
const Tree = require("../tree");
const MessageModal = require("../modals/messageModal");
const ApiCalls = require("../apiCalls");
const SamplingSiteInventoryConstants = require("./samplingSiteInventoryConstants");
const SamplingSiteInventoryModalController = require("./samplingSiteInventoryModalController");
