"use strict";

const PopupContent = function () {
  var loadPopupListeners = function () {
    const $map = $("#mapContent");
    $map.off();
    $(".delete-fcs-confirm-btn").off();
    $(".delete-bmp-confirm-btn").off();
    $(".delete-fcs-confirm-btn").on("click", confirmFcsDeleteClick);
    $(".delete-bmp-confirm-btn").on("click", confirmBmpDeleteClick);
    $map.on("click", ".popup-show-history", popupShowHistoryClick);
    $map.on("click", ".popup-show-photos", popupShowPhotosClick);
    $map.on(
      "click",
      ".popup-show-photos-bmp",
      StructuralBmpsPopupGeoServerLayer.loadAndShowPopupPhotos,
    );
    $map.on("click", ".popup-show-files", popupShowFilesClick);
    $map.on("click", ".popup-show-performance", popupShowPerformanceClick);
    $map.on("click", ".open-fact-sheet-facility", openFacilityFactSheet);
    $map.on("click", ".open-outfall-fact-sheet", openOutfallFactSheet);
    $map.on("click", ".popup-back-button", hidePopupSecondPane);
    $map.on("click", ".history-table .js-edit-assessment", editAssessmentClick);
    $map.on("click", ".history-table .js-view-assessment", viewAssessmentClick);
    $map.on("click", ".history-table .js-delete-assessment", deleteAssessmentClick);
    $map.on("click", ".popup-inline-editable .glyphicon-pencil", editInlineClick);
    $map.on("click", ".new-incident-investigation", newIncidentInvestigationClick);
    $map.on("keyup", ".popup-inline-editable input[type='text']", function (e) {
      if (e.keyCode == 13) {
        // Triggers the above listener
        e.target.blur();
      }
    });
    $map.on("click", ".popup-bmp .show-new-benchmark", showNewBenchmarkClick);
    $map.on("click", ".popup-bmp .show-edit-benchmark", showEditBenchmarkClick);
    $map.on("click", ".popup-bmp .show-new-bmp-assessment", showNewBmpAssessmentClick);
    $map.on("click", ".popup-bmp .show-edit-bmp-assessment", showEditBmpAssessmentClick);
    $map.on("click", ".show-fcs-assessment", showFcsAssessmentClick);
    $map.on("click", ".show-inventory", showInventoryModalClick);
    $map.on("click", ".show-outfall-inventory", showOutfallInventoryModal);
    $map.on("click", ".show-facility-inventory", showFacilityInventoryModal);
    $map.on("click", ".view-sampling-site-inventory", showSamplingSiteInventoryModal);
    $map.on("click", ".show-incident-inventory", showIncidentInventoryModal);
    $map.on(
      "click",
      ".show-incident-inventory-from-dryweather",
      showIncidentInventoryModalFromDryWeather,
    );
    $map.on(
      "click",
      ".popup-runoff-condition .show-new-parcel-assessment",
      showNewParcelAssessmentClick,
    );
    $map.on("click", ".add-as-fcs-btn", setBmpAsFcsClick);
    $map.on("click", ".popup-delete-fcs", popupDeleteFcsClick);
    $map.on("click", ".popup-delete-bmp", popupDeleteBmpClick);
    $map.on("change", ".popup-general .js-photo-file-input", PopupPhotos.fileInputChange);
    $map.on("click", ".popup-catchment .go-to-view", popupCatchmentClick);
    $map.on("click", ".record-sweep-button", recordSweepButtonClick);
    Tree.select("bmpFcsAssessmentHistory").on("update", _handleBmpFcsAssessmentHistoryUpdate);
    Tree.select("bmpFcsAssessmentHistory").on("update", updateLastSiteVisit);
    $map.on(
      "click",
      ".js-add-bmpfcs-routine-inspection, .js-add-construction-project-inspection",
      addSiteVisitAnalytics,
    );
  };

  var addSiteVisitAnalytics = function () {
    _addAnalyticsPopupButtons(Tree.get("dataView"), getPopupType($(this)), "open_siteVisit");
  };

  var getLastSiteVisit = function (historyArray) {
    const view = Tree.get("dataView");

    if (view === "bmp") {
      for (let i = 0; i < 15; i++) {
        if (historyArray[i]?.assetType === "bmp" && historyArray[i].bm_score) {
          let lastVisitScore = historyArray[i]?.bm_score;
          let lastVisitDate = historyArray[i]?.date;

          lastVisitScore = NunjucksFilters.formatScore(lastVisitScore);
          lastVisitDate = NunjucksFilters.formatDate(lastVisitDate);

          return {
            lastVisitScore: lastVisitScore ? lastVisitScore : "—",
            lastVisitDate: lastVisitDate ? lastVisitDate : "—",
          };
        }
      }
    } else if (view === "fcs") {
      for (let i = 0; i < 15; i++) {
        if (historyArray[i]?.assetType === "fcs" && historyArray[i].score) {
          let lastVisitScore = historyArray[i]?.score;
          let lastVisitDate = historyArray[i]?.date;

          lastVisitScore = NunjucksFilters.formatScore(lastVisitScore);
          lastVisitDate = NunjucksFilters.formatDate(lastVisitDate);

          return {
            lastVisitScore: lastVisitScore ? lastVisitScore : "—",
            lastVisitDate: lastVisitDate ? lastVisitDate : "—",
          };
        }
      }
    }
  };

  var updateLastSiteVisit = function (e) {
    const data = e.data.currentData;
    const lastVisit = getLastSiteVisit(data.history);
    if (lastVisit) {
      $("#lastVisitScore").text(lastVisit.lastVisitScore);
      $("#lastVisitDate").text(lastVisit.lastVisitDate);
      $(".popup-show-files").text("").css("padding-right", "40px");
    } else {
      $("#lastVisitScore").text("—");
      $("#lastVisitDate").text("—");
      $(".popup-show-files").text("").css("padding-right", "40px");
    }
  };

  var popupCatchmentClick = function () {
    const data = $(this).data();
    const catchName = data.catchid;
    const viewType = data.viewType;
    const gid = data.gid;
    const rw = data.rw;

    SpatialViewController.goToView(viewType, catchName, rw, gid);
  };

  var recordSweepButtonClick = function () {
    const popupData = $(this).data();
    const id = popupData.objectId || popupData.gid;
    const streetSweepingPopupData = Tree.get(["streetSweepingPopupData", id]);
    RecordSweepModal.renderAndShow(streetSweepingPopupData);
    Analytics.sendPopupEvent("record_sweep", "catchment");
  };

  var popupDeleteFcsClick = function () {
    var data = $(this).data();
    const dataView = Tree.get("dataView");
    showDeleteFcsModal(data.bmpName, data.idBmp, data.fcsonly);
    _addAnalyticsPopupButtons(dataView, getPopupType($(this)), "delete_asset");
  };

  var popupDeleteBmpClick = function () {
    var data = $(this).data();
    const dataView = Tree.get("dataView");
    showDeleteBmpModal(data.bmpName, data.idBmp);
    _addAnalyticsPopupButtons(dataView, getPopupType($(this)), "delete_asset");
  };

  var showInventoryModalClick = function () {
    var data = $(this).data();
    _addAnalyticsPopupButtons(Tree.get("dataView"), getPopupType($(this)), "view_inventory");
    showBmpFcsInventoryModal(data.idBmp);
  };

  var showOutfallInventoryModal = function () {
    var data = $(this).data();
    _addAnalyticsPopupButtons("outfall", "outfall", "view_inventory");
    OutfallInventoryModalController.invokeOutfallInventoryModal(data.idOutfall);
  };

  var showFacilityInventoryModal = function () {
    var data = $(this).data();
    _addAnalyticsPopupButtons(Tree.get("dataView"), getPopupType($(this)), "view_inventory");
    IndcomInventoryModalController.invokeIndcomInventoryModal(data.idFacility);
  };

  var showSamplingSiteInventoryModal = function () {
    var data = $(this).data();
    _addAnalyticsPopupButtons(Tree.get("dataView"), getPopupType($(this)), "view_inventory");
    SamplingSiteInventoryModalController.invokeSamplingSiteInventoryModal(data.samplingSiteId);
  };

  var showIncidentInventoryModal = function () {
    var data = $(this).data();
    _addAnalyticsPopupButtons(Tree.get("dataView"), getPopupType($(this)), "view_inventory");
    IncidentInventoryModalController.invokeIncidentInventoryModal(data.idIncident);
  };

  var showIncidentInventoryModalFromDryWeather = async function () {
    var data = $(this).data();
    _addAnalyticsPopupButtons(Tree.get("dataView"), getPopupType($(this)), "view_inventory");
    IncidentInventoryModalController.invokeIncidentInventoryModalFromAsset(
      data.id,
      data.name,
      data.assetType,
      data.assetDisplayType,
    );
  };

  var setBmpAsFcsClick = function () {
    var data = $(this).data();
    Tree.set(["asset", "update"], true);
    showBmpFcsInventoryModal(data.idBmp, true);
  };

  var showBmpFcsInventoryModal = function (idBmp) {
    BmpFcsInventoryModalController.invokeBmpInventoryModal(idBmp);
  };

  var showFcsAssessmentClick = function () {
    var data = $(this).data();
    BmpFunctions.showFcsAssessmentModal(data.bmpName, data.idBmp, data.bmptype);
  };

  var getLayerPopup = function (properties, layerName) {
    if (properties.properties) {
      properties = properties.properties;
    }

    addCommonPopupProps(properties);

    return nunjucks.render(`popups/${layerName}Popup.njk`, properties);
  };

  var addCommonPopupProps = function (properties) {
    properties.availableDataSortLayers = Tree.get("availableDataSortLayers");
    return properties;
  };

  var getWatershedPopup = function (feature) {
    return nunjucks.render("popups/watershed.njk", feature.properties);
  };

  var hidePopupSecondPane = function () {
    const dataView = Tree.get("dataView");
    $(".popup-back-button").hide();
    $(".popup-delete-button").hide();
    $(".popup-main-pane").show();
    $(".popup-history-pane").hide();
    $(".popup-photos-pane").hide();
    $(".popup-files-pane").hide();
    $(".popup-second-pane").hide();

    $(".open-fact-sheet").show();
    $(".open-outfall-fact-sheet").show();
    $(".open-fact-sheet-facility").show();

    if (["lid-project", "bmp"].includes(dataView)) {
      $(".popup-fact-sheet-button").hide();
    }
  };

  var editInlineClick = function (editEvent) {
    var $input = $(editEvent.target).siblings().first();

    Misc.toggleDisabled($input, false);
    $input.focus();
  };

  var popupShowHistoryClick = function (e) {
    var popupType = $(this).closest(".popup-general").data("popupType");
    if (popupType === "bmpfcs") {
      showBmpFcsDeleteButtons();
    } else {
      $(".popup-delete-button").show();
    }

    $(".popup-main-pane").hide();
    $(".popup-back-button").show();
    $(".popup-history-pane").show();
    $(".open-outfall-fact-sheet").hide();
    $(".open-fact-sheet").hide();
    $(".open-fact-sheet-facility").hide();

    const dataView = Tree.get("dataView");
    _addAnalyticsPopupButtons(dataView, popupType, "view_historyTab");

    MapFunctions.rePanMapForPopup($(e.target).closest(".leaflet-popup"));
  };

  var _addAnalyticsPopupButtons = function (currentDataView, popupType, actionType) {
    const dataViewsOfPopupNotMatchingPopupType = ["fcs", "bmp"];
    if (dataViewsOfPopupNotMatchingPopupType.includes(currentDataView) && popupType != "outfall") {
      Analytics.sendPopupEvent(actionType, currentDataView);
    } else if (currentDataView === "lid-project" && popupType === "catchBasin") {
      Analytics.sendPopupEvent(actionType, "lid-bmp");
    } else {
      Analytics.sendPopupEvent(actionType, popupType);
    }
  };

  var showBmpFcsDeleteButtons = function () {
    var popupBmpData = $(".popup-bmp").data();
    var assetData = LayerFunctions.getLayerMarkerById(popupBmpData.idBmp);
    var fcs;
    const layerName = popupBmpData?.layername;
    if (layerName === "structuralBmps") {
      handleDeleteForG2sBmp(popupBmpData);
    } else {
      if (!assetData) {
        return;
      } else {
        assetData = assetData.feature.properties;
        fcs = assetData["fcs"];
      }

      if (fcs) {
        $(".popup-delete-fcs").show();
      } else {
        $(".popup-delete-bmp").show();
      }
    }
  };

  var handleDeleteForG2sBmp = function (data) {
    const isFcs = data.isfcs;
    if (isFcs) {
      $(".sbmp-popup-delete-fcs").show();
    } else {
      $(".sbmp-popup-delete-bmp").show();
    }
  };

  var popupShowPhotosClick = function () {
    $(".popup-main-pane").hide();
    $(".popup-back-button").show();
    $(".popup-photos-pane").show();
    $(".open-fact-sheet").hide();
    $(".open-outfall-fact-sheet").hide();
    $(".open-fact-sheet-facility").hide();

    const dataView = Tree.get("dataView");
    _addAnalyticsPopupButtons(dataView, getPopupType($(this)), "view_photos");
  };

  var popupShowFilesClick = function () {
    $(".popup-main-pane").hide();
    $(".popup-back-button").show();
    $(".popup-files-pane").show();
  };

  var popupShowPerformanceClick = function () {
    $(".popup-main-pane").hide();
    $(".popup-back-button").show();
    $(".popup-performance-pane").show();
  };

  var openOutfallFactSheet = function () {
    const data = $(this).closest(".popup-general").data();
    $("#details-button-container").addClass("desktop-only");
    $(".nav-toggle-list-button").addClass("active");
    $(".nav-toggle-map-button").removeClass("active");
    OutfallFactSheetController.initFactSheet(data.id);
    _addAnalyticsPopupButtons("outfall", "outfall", "view_details");
  };

  var openFacilityFactSheet = function () {
    const data = $(this).closest(".popup-general").data();
    $("#details-button-container").addClass("desktop-only");
    Tree.set("navToggleSelection", "list");
    FactSheet._toggleDataListIcon();
    FacilityFactSheetController.initFactSheet(data.id);
    _addAnalyticsPopupButtons("indcom", "indcom", "view_details");
  };

  var getPopupCallback = function (popupType, callbackName) {
    const popupCallbacksByType = {
      catchBasin: { edit: BmpFunctions.editHistory, delete: BmpFunctions.confirmDeleteHistory },
      bmpfcs: { edit: BmpFunctions.editHistory, delete: BmpFunctions.confirmDeleteHistory },
      muniBmp: { edit: BmpFunctions.editHistory, delete: BmpFunctions.confirmDeleteHistory },
      runoffCondition: {
        edit: ParcelAssessmentHistory.editHistory,
        delete: ParcelAssessmentHistory.confirmDeleteHistory,
      },
      outfall: {
        edit: OutfallInspectionHistory.editHistory,
        delete: OutfallInspectionHistory.confirmDeleteHistory,
      },
      samplingSite: {
        edit: SamplingSitePopup.editHistory,
        delete: SamplingSitePopup.confirmDeleteHistory,
      },
      lidProject: {
        edit: LidSelfInspectionHistory.editHistory,
        delete: LidSelfInspectionHistory.confirmDeleteHistory,
      },
      streetSweeping: {
        edit: RecordSweepModal.editHistory,
        delete: RecordSweepModal.confirmDeleteHistory,
      },
      catchment: {
        edit: RecordSweepModal.editHistory,
        delete: RecordSweepModal.confirmDeleteHistory,
      },
      dryWeather: {
        edit: DryWeatherHistory.editHistory,
        delete: DryWeatherHistory.confirmDeleteHistory,
      },
      incident: {
        edit: IncidentHistory.editHistory,
        delete: IncidentHistory.confirmDeleteHistory,
      },
    };

    const popupCallback = popupCallbacksByType[popupType][callbackName];

    if (!popupCallback) {
      throw new Error(`No popup callback defined for ${popupType} and ${callbackName}`);
    }

    return popupCallback;
  };

  var editAssessmentClick = function () {
    editAssessment($(this));
  };

  var editAssessment = function ($element, popupType = null) {
    popupType ||= $element.closest(".popup-general").data("popupType");
    var data = $element.closest("tr").data();

    Analytics.sendPopupEvent("edit_history", popupType);

    const editCallback = getPopupCallback(popupType, "edit");
    editCallback(data, false);
  };

  var viewAssessmentClick = function () {
    viewAssessment($(this));
  };

  var viewAssessment = function ($element, popupType = null) {
    popupType ||= $element.closest(".popup-general").data("popupType");
    var data = $element.closest("tr").data();

    Analytics.sendPopupEvent("view_history", popupType);

    const editCallback = getPopupCallback(popupType, "edit");
    editCallback(data, true);
  };

  var deleteAssessmentClick = function () {
    deleteAssessment($(this));
  };

  var deleteAssessment = function ($element, popupType = null) {
    popupType ||= $element.closest(".popup-general").data("popupType");
    var $todoTr = $element.closest("tr");
    var data = $todoTr.data();

    Analytics.sendPopupEvent("delete_history", popupType);

    const editCallback = getPopupCallback(popupType, "delete");
    editCallback($todoTr, data);
  };

  var showNewBenchmarkClick = function () {
    const data = $(this).closest(".popup-bmp").data();
    Benchmark.showNewBenchmarkModal(data.bmpName, data.type, data.typeNumber);
  };

  var showEditBenchmarkClick = function () {
    const data = $(this).closest(".popup-bmp").data();
    Benchmark.showEditBenchmarkModal(
      data.bmpName,
      data.idBmp,
      data.type,
      data.typeNumber,
      data.bm_id,
      false,
    );
  };

  var showNewBmpAssessmentClick = function () {
    const data = $(this).closest(".popup-bmp").data();

    BmpObservation.showBmpObsModal(data.idBmp, data.bmpName, data.type, data.typeNumber, false);
  };

  var showEditBmpAssessmentClick = function () {
    const data = $(this).closest(".popup-bmp").data();

    BmpObservation.showBmpObsModal(
      data.idBmp,
      data.bmpName,
      data.type,
      data.typeNumber,
      false,
      data.obs_id,
      data.bm_id,
    );
  };

  var showNewParcelAssessmentClick = function (e) {
    ParcelAssessment.showNewParcelAssessmentModal(e);
  };

  var confirmBmpDeleteClick = function () {
    const dataView = Tree.get("dataView");
    if (dataView !== "lid-project") {
      ApiCalls.deleteBmp(Tree.get(["deleteBmpFcs", "idBmp"])).then(function () {
        MainMap.reloadBmpFcsLayer();
        $("#delete-fcs-modal").modal("hide");
      });
    } else {
      ApiCalls.deleteBmp(Tree.get(["deleteBmpFcs", "idBmp"])).then(function () {
        MainMap.reloadBmpFcsLayer();
        actions.loadLidProjectBmps();
        $("#delete-fcs-modal").modal("hide");
      });
    }

    $("#delete-bmp-modal").modal("hide");
  };

  var confirmFcsDeleteClick = function () {
    BmpFunctions.deleteFcs(Tree.get(["deleteBmpFcs", "idBmp"]));
  };

  const showDeleteFcsModal = function (bmpName, idBmp, isFcsOnly) {
    if (isFcsOnly) {
      $("#delete-fcs-modal .both-bmp-fcs").hide();
      $("#delete-fcs-modal .fcs-only").show();
    } else {
      $("#delete-fcs-modal .fcs-only").hide();
      $("#delete-fcs-modal .both-bmp-fcs").show();
    }

    Tree.set(["deleteBmpFcs", "bmpName"], bmpName);
    Tree.set(["deleteBmpFcs", "idBmp"], idBmp);

    $("#delete-fcs-modal").modal("show");
  };

  const showDeleteBmpModal = function (bmpName, idBmp) {
    const htmlMessage = nunjucks.render("dynamicModals/deleteBMPMessage.njk", {
      bmpName: bmpName,
    });
    $("#delete-bmp-modal").find(".modal-body").html(htmlMessage);

    Tree.set(["deleteBmpFcs", "bmpName"], bmpName);
    Tree.set(["deleteBmpFcs", "idBmp"], idBmp);

    $("#delete-bmp-modal").modal("show");
  };

  var _handleBmpFcsAssessmentHistoryUpdate = function (e) {
    const data = e.data.currentData;
    renderBmpFcsAssessmentHistory(data);
  };

  var renderBmpFcsAssessmentHistory = function (data) {
    const dataView = Tree.get("dataView");
    const readOnlyTopLevel = Session.isRegulator();

    handleAssessmentHistoryReadOnly(data["history"]);
    rearrangeBmpFcsAssessmentHistory(data["history"]);
    if (dataView === "lid-project" || dataView === "lid-runoff") {
      data.history.forEach((item) => {
        item.noActions = true;
      });
    }
    $(".popup-history-pane").html(
      nunjucks.render("popups/history/bmpFcsAssessmentHistory.njk", {
        assessments: data["history"],
        readOnlyTopLevel: readOnlyTopLevel,
        titles: getPopupTitles(),
      }),
    );

    loadBmpFcsConditionBars(data);
  };

  var handleAssessmentHistoryReadOnly = function (historyData, bmps = null) {
    const bmpFcs = bmps === null ? Tree.get("currentBmpFcs") : null;
    if (!historyData) return;
    historyData.forEach(function (item) {
      const currentBmpFcs = Array.isArray(bmps)
        ? bmps.find((bmp) => bmp.id === item.idBmp) || bmpFcs
        : bmpFcs;
      if (!currentBmpFcs) return;
      switch (item.assetType) {
        case "bmpBench":
          setBenchmarkReadOnly(item, currentBmpFcs);
          break;
        case "bmp":
          setBmpObservationReadOnly(item, currentBmpFcs);
          break;
        case "fcs":
          setFcsAssessmentReadOnly(item, currentBmpFcs);
          break;
        case "routineMaintenance":
          setRoutineInspectionReadOnly(item, currentBmpFcs);
          break;
        case "routineRepair":
          setRoutineInspectionReadOnly(item, currentBmpFcs);
          break;
        default:
          console.warn(`No action defined for asset type ${item.assetType}.`);
      }
    });
  };

  var rearrangeBmpFcsAssessmentHistory = function (historyData) {
    for (let i = 0; i < historyData.length; i++) {
      const historyItem = historyData[i];
      if (historyItemIsCurrentBenchmark(historyItem) || historyItemIsCurrentBmpObs(historyItem)) {
        const currentIndexItem = historyData.splice(i, 1)[0];
        historyData.unshift(currentIndexItem);
      }
    }
  };

  var historyItemIsCurrentBenchmark = function (historyItem) {
    var currentBmpFcs = Tree.get("currentBmpFcs");
    return (
      historyItem.assetType === "bmpBench" &&
      !!currentBmpFcs.currentBenchmarkId &&
      historyItem.bm_id == currentBmpFcs.currentBenchmarkId &&
      historyItem.date === null
    );
  };

  var historyItemIsCurrentBmpObs = function (historyItem) {
    var currentBmpFcs = Tree.get("currentBmpFcs");
    return (
      historyItem.assetType === "bmp" &&
      !!currentBmpFcs.currentObsId &&
      historyItem.obs_id == currentBmpFcs.currentObsId &&
      historyItem.obs_date === null
    );
  };

  var getPopupTitles = function () {
    const titlesDefault = {
      type: "Type",
      date: "Date",
      score: "Result",
    };
    const currentTitles = Object.assign({}, titlesDefault);

    if (Tree.get("dataView") === "muni-catch-basin") {
      currentTitles.score = "Result";
    }

    return currentTitles;
  };

  var setBenchmarkReadOnly = function (benchmark, currentBmpFcs) {
    var isCurrentBenchmark = benchmark.bm_id == currentBmpFcs.currentBenchmarkId;
    benchmark.readOnly = currentBmpFcs?.permissions?.canCreateBenchmark
      ? benchmark.report_submitted || !isCurrentBenchmark || currentBmpFcs.currentBenchmarkHasObs
      : true;
  };

  var setBmpObservationReadOnly = function (bmpObs, currentBmpFcs) {
    bmpObs.readOnly = currentBmpFcs?.permissions?.canCreateObservation
      ? bmpObs.report_submitted
      : true;
    bmpObs.noActions = currentBmpFcs.skipObservation || !bmpObs.isFullAssessmentType;
  };

  var setFcsAssessmentReadOnly = function (fcsAssessment, currentBmpFcs) {
    fcsAssessment.readOnly = currentBmpFcs?.permissions?.canCreateFunctionalInspection
      ? fcsAssessment.report_submitted
      : true;
  };

  var setRoutineInspectionReadOnly = function (maintenance, currentBmpFcs) {
    maintenance.readOnly = !currentBmpFcs.permissions.canCreateRoutineInspection;
  };

  var setPopupConditionBar = function ($popupScore, data, getScoreCallback) {
    const $condition = $popupScore.find(".condition-bar-chart");
    const $bars = $condition.find(".bar");
    $bars.remove();

    loadConditionBars($condition, data, getScoreCallback);
  };

  var loadConditionBars = function ($condition, data, getScoreCallback) {
    const history = data ?? [];

    for (var i = 0; i < 15; i++) {
      const item = history[i];

      if (!item) {
        return;
      }

      const score = getScoreCallback(item);
      if (score !== null && score !== undefined) {
        addConditionBar($condition, score);
      }
    }
  };

  var addConditionBar = function ($condition, score) {
    const barColor = MapStyles.getConditionColor(score, true);
    const barHeight = MapStyles.getBarHeight(score);

    $condition.prepend(
      "<div class='bar' style='height:" + barHeight + ";background-color:" + barColor + "'></div>",
    );
  };

  var loadBmpFcsConditionBars = function (data) {
    setPopupConditionBar($(`.tr-bmp-score`), data.bmp, (datum) => datum.bm_score);
    setPopupConditionBar($(`.tr-fcs-score`), data.fcs, (datum) => datum.score);
  };

  var getRunoffConditionPopup = function (feature) {
    var props = feature.properties;
    var popupColor = MapStyles.getParcelConditionColorString(props.condition);
    var conditionAbbr = ParcelAssessment.getParcelConditionAbbr(props.condition);
    var context = $.extend(props, {
      popupColor: popupColor,
      conditionAbbr: conditionAbbr,
      canEditParcel: !Session.isRegulator(),
      canEditCondition:
        !Session.isRegulator() && UserPermissions.getPermission("can_assess_parcels"),
      isPartner: false,
      enableHistory: props.apn,
    });
    return nunjucks.render("popups/runoffConditionPopup.njk", context);
  };

  var newIncidentInvestigationClick = function () {
    const incidentId = $(this).parents(".popup-general").data("id");
    IncidentInvestigationController.loadIncidentInvestigation({ incidentId });
  };

  var getPopupType = function (element) {
    var popupType = element.closest(".popup-general").data("popupType");
    if (typeof popupType === "undefined") {
      console.warn("Popup type is undefined. Analytics won't be matched for this action.");
    }
    return popupType;
  };

  var getCustomLayerPopup = function (feature, layerSettings) {
    const computedValues = layerSettings.popupParameters?.map((popupParameter) => {
      return {
        label: popupParameter.label,
        displayValue: feature.properties[popupParameter.value],
      };
    });
    return nunjucks.render("popups/customLayerPopup.njk", {
      properties: feature.properties,
      rows: computedValues,
      title: layerSettings.layerName,
    });
  };

  return {
    getLayerPopup,
    addCommonPopupProps,
    getWatershedPopup,
    loadPopupListeners,
    getRunoffConditionPopup,
    setPopupConditionBar,
    renderBmpFcsAssessmentHistory,
    _handleBmpFcsAssessmentHistoryUpdate,
    updateLastSiteVisit,
    showBmpFcsInventoryModal,
    getCustomLayerPopup,
    editAssessment,
    viewAssessment,
    deleteAssessment,
    handleAssessmentHistoryReadOnly,
  };
};

module.exports = PopupContent();

const Analytics = require("../general/analytics");
const ApiCalls = require("../apiCalls");
const Benchmark = require("../modals/benchmark");
const BmpFcsInventoryModalController = require("../bmpfcs/bmpFcsInventoryModalController");
const BmpFunctions = require("../modals/bmpFunctions");
const BmpObservation = require("../modals/bmpObservation");
const DryWeatherHistory = require("../illicitDischarge/dryWeatherHistory");
const IncidentHistory = require("../illicitDischarge/incidents/incidentHistory");
const IncidentInventoryModalController = require("../illicitDischarge/incidents/incidentInventoryModalController");
const IncidentInvestigationController = require("../illicitDischarge/incidentInvestigation/incidentInvestigationController");
const IndcomInventoryModalController = require("../indcom/indcomInventoryModalController");
const LayerFunctions = require("../layerFunctions");
const LidSelfInspectionHistory = require("../lid/lidSelfInspectionHistory");
const MainMap = require("./mainMap");
const MapFunctions = require("./mapFunctions");
const MapStyles = require("./mapStyles");
const Misc = require("../misc");
const OutfallInspectionHistory = require("../outfalls/outfallInspectionHistory");
const OutfallInventoryModalController = require("../outfalls/outfallInventoryModalController");
const ParcelAssessment = require("../lid/parcelAssessment");
const ParcelAssessmentHistory = require("../lid/parcelAssessmentHistory");
const PopupPhotos = require("./popupPhotos");
const RecordSweepModal = require("../muni/recordSweepModal");
const Session = require("../login/session");
const UserPermissions = require("../login/userPermissions");
const SpatialViewController = require("./spatialViewController");
const Tree = require("../tree");
const OutfallFactSheetController = require("../muni/outfallFactSheetController");
const FactSheet = require("../general/factSheet");
const FacilityFactSheetController = require("../indcom/facilityFactSheetController");
const NunjucksFilters = require("../general/nunjucksFilters");
const actions = require("../actions");
const StructuralBmpsPopupGeoServerLayer = require("../bmpFcsG2/structuralBmpsPopupGeoServerLayer");
const SamplingSiteInventoryModalController = require("../wq/samplingSiteInventoryModalController");
const SamplingSitePopup = require("../wq/samplingSitePopup");
