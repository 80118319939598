"use strict";

const EnforcementHistory = function (
  EnforcementController,
  { prepareReasonsFunction = null } = {},
) {
  const stringKey = `enforcement-history`;
  const tabHeader = "Enforcement History";
  let $page;
  const historyUpdates = {};
  const editLetterFormKey = "editing-letter-details";

  var _prepareReasons = function (historyItem) {
    return prepareReasonsFunction?.(historyItem) ?? [];
  };

  var loadListeners = function () {
    $page = $(`#assessment-modal #${stringKey}`);
    $page.on("click", ".letter-file-download", _downloadExistingLetterFile);
    $page.on("click", ".signed-letter-file-download", _downloadSignedLetterFile);
    $page.on("click", ".delete-button", showDeleteHistoryItemModal);
    $page.on("click", ".edit-history-letter", renderHistoryLetterEditing);
    _populateEscalationFindings();
  };

  var unloadListeners = function () {
    $page.off("click", ".letter-file-download", _downloadExistingLetterFile);
    $page.off("click", ".signed-letter-file-download", _downloadSignedLetterFile);
    $page.off("click", ".delete-button", showDeleteHistoryItemModal);
    $page.off("click", ".edit-history-letter", renderHistoryLetterEditing);
  };

  var getProps = function (data) {
    return {
      readOnly: data?.readOnly ?? false,
      isAdmin: Session.isAdmin(),
    };
  };

  var getStringKey = function () {
    return stringKey;
  };

  var getTabHeader = function () {
    return tabHeader;
  };

  var _populateEscalationFindings = function () {
    const history = EnforcementController.getLatestData().enforcementHistory;

    history?.forEach(function (historyItem) {
      const reasons = _prepareReasons(historyItem);
      const renderedReasons = nunjucks.render("modals/enforcement/historyReasons.njk", { reasons });
      $page
        .find(`.card[data-id='${historyItem.id}']`)
        .find(`.history-open-findings`)
        .html(renderedReasons);
    });
  };

  var _downloadExistingLetterFile = function (e) {
    const letterFileUuid = $(e.currentTarget).data("uuid");
    ApiCalls.downloadEnforcementLetterFile(letterFileUuid);
  };

  var _downloadSignedLetterFile = function (e) {
    const letterFileUuid = $(e.currentTarget).data("uuid");
    ApiCalls.downloadSignedLetterFile(letterFileUuid);
  };

  var showDeleteHistoryItemModal = function (e) {
    e.stopPropagation();
    const $parentCard = $(this).parents(".card");
    if ($parentCard.siblings(".card").length && !$parentCard.is(":last-child")) {
      const historyItem = _getHistoryItem($parentCard.find("[data-id]").data("id"));
      let confirmMessage = "Are you sure you want to delete this enforcement history item?";
      if (historyItem.escalationAction === "Resolved") {
        confirmMessage = confirmMessage + " This will re-open the enforcement.";
      }
      MessageModal.showConfirmWarningModal(
        confirmMessage,
        async function () {
          const id = $parentCard.find("[data-id]").data("id");
          const newData = await EnforcementController.deleteEscalation(id);
          EnforcementController.closeModal();
          if (newData.resolved) {
            EnforcementController.viewEnforcementAction(newData.id, newData, {
              activeTab: "enforcement-history",
            });
          } else {
            EnforcementController.editEnforcementAction(newData.id, newData, {
              activeTab: "enforcement-history",
            });
          }
        },
        "Cancel",
        "Delete history item",
      );
    } else {
      MessageModal.showConfirmWarningModal(
        null,
        EnforcementController.deleteAndCloseModal,
        "Cancel",
        "Delete Enforcement",
        "<b>Delete Enforcement</b><br><br>Deleting the initial enforcement issue will delete this entire enforcement and all of its history.",
      );
    }
  };

  var renderHistoryLetterEditing = function (e) {
    const $card = $(this).parents(".card").first();
    const escalationId = $card.data("id");
    const historyItem = _getHistoryItem(escalationId);

    const html = renderHistoryLetterModalContent(historyItem, escalationId);
    showHistoryLetterModal(html, escalationId);

    const $modal = $(`.${editLetterFormKey}`);
    initializeModalComponents($modal, editLetterFormKey, historyItem);
  };

  function renderHistoryLetterModalContent(historyItem, escalationId) {
    return nunjucks.render("modals/enforcement/modifyHistoryLetterInfo.njk", {
      historyItem,
      id: escalationId,
    });
  }

  function showHistoryLetterModal(html, escalationId) {
    MessageModal.renderMessage({
      title: "Enforcement Details",
      body: html,
      bodyIsHtml: true,
      modalClass: editLetterFormKey,
      buttons: [
        {
          text: "Cancel",
          type: "secondary",
          callback: () => Form.clearForm(editLetterFormKey),
          args: null,
        },
        {
          text: "Save",
          type: "primary",
          callback: renderHistoryLetterUpdate,
          args: escalationId,
          stayOpenOnClick: true,
        },
      ],
    });
  }

  function initializeModalComponents($modal, modalFormKey, historyItem) {
    Form.initializeAndLoadListeners($modal, modalFormKey);
    Form.initializeDatePickers($modal, modalFormKey);

    const dropzone = Form.initializeDropzone(modalFormKey, $modal, {
      newFiles: [],
      existingFiles: historyItem.signedLetter?.file || historyItem.signedLetterFile,
      maxNumberFiles: 1,
      readOnly: false,
      fileChangeCallback: null,
      downloadCallback: (fileUuid) => {
        ApiCalls.downloadSignedLetterFile(fileUuid);
      },
    });

    dropzone.setDownloadHandler(() => {
      ApiCalls.downloadSignedLetterFile(historyItem.signedLetter?.uuid || historyItem.signedLetter);
    });

    if (historyItem?.signReturnDate) {
      DateTimePicker.setDateOnElement(
        $modal.find("[name*=sign-return-date]").assertLength(1),
        historyItem?.signReturnDate,
      );
    }
  }

  var renderHistoryLetterUpdate = function (escalationId) {
    const $card = $(`.card[data-id='${escalationId}']`);

    MessageModal.showConfirmWarningModal(
      "Are you sure you want to save these enforcement detail updates?",
      async function () {
        const updates = Form.getDataFromForm(editLetterFormKey, false);
        if (Object.keys(updates).length === 0) {
          Form.clearForm(editLetterFormKey);
          $("#dynamic-warning-modal").modal("hide");
          return;
        }
        try {
          if (updates?.signedLetter) {
            const newFile = updates?.signedLetter.file?.[0];
            updates.signedLetter = {
              ...updates?.signedLetter,
              ...(newFile && {
                new: true,
                uuid: UUID.v4(),
                filename: newFile.name,
              }),
            };
          }
          const updatedHistoryItem = await ApiCalls.updateEnforcementHistory(
            EnforcementController.getFormKey(),
            {
              escalationId,
              updates,
            },
          );

          const html = nunjucks.render("modals/enforcement/existingHistoryLetterInfo.njk", {
            historyItem: updatedHistoryItem,
          });
          $card.find(".history-letter-info").html(html);
          historyUpdates[escalationId] = updatedHistoryItem;
          Form.clearForm(editLetterFormKey);
          $("#dynamic-warning-modal").modal("hide");
        } catch (error) {
          MessageModal.showSimpleWarningModal("Failed to update enforcement history");
        }
      },
      "Cancel",
      "Save",
      null,
      null,
      null,
      null,
      null,
      "#final-confirm-modal",
    );
  };

  var _getHistoryItem = function (escalationId) {
    const data = EnforcementController.getLatestData();
    const historyItem = data.enforcementHistory.find((item) => item.id === escalationId);

    return {
      ...historyItem,
      ...historyUpdates[historyItem.id],
    };
  };

  return {
    unloadListeners,
    loadListeners,
    getProps,
    getStringKey,
    getTabHeader,
  };
};

module.exports = EnforcementHistory;

const ApiCalls = require("../apiCalls");
const DateTimePicker = require("../general/dateTimePicker");
const Form = require("../general/form");
const Session = require("../login/session");
const MessageModal = require("../modals/messageModal");
const UUID = require("uuid");
