"use strict";

const TreeInitialDataRam = function () {
  const initDynamicData = function () {
    const DateTime = require("./dateTime");
    const FormConstants = require("./mapFunctions/formConstants");
    const ToDoConfig = require("./config/toDoConfig");
    const Tree = require("./tree");

    Tree.set("toDoFilters", ToDoConfig.getAllToDoDataViewConfigsByProperty("filters"));
    Tree.set(["asset", "basicInfo"], FormConstants.inventoryDefaultFields);
    Tree.set("waterYear", DateTime.getCurrentWaterYear());
  };

  const getStaticData = function () {
    return {
      spatialScale: null,
      tool: null, //@TODO subdomain to determine which tool, hardcoded for development
      activeGroup: {
        groupId: null,
        group: null,
        name: null,
        lat: null,
        lng: null,
        group_role: null,
      },

      user: {
        username: "",
        full_name: "",
        admin_type: "",
        email: "",
        trueGroupId: "",
        group: "",
        group_role: "",
        paying: "",
      },
      userPermissions: [],
      groupMenuSettings: [],
      observationTemp: {},

      trashReportingYear: null,
      annualReportingSettings: {},

      //report dates
      end_date: null,
      due_date: null,
      start_date: null,

      catchments: {
        isFetching: false,
        items: [],
      },

      // without filters applied
      fullInventory: {
        isFetching: false,
        items: [],
      },

      inventory: {
        isFetching: false,
        items: [],
      },

      photo_layer: {},
      bmp_photos: {},
      survey123_photos: {},
      collector_photos: {},
      obs_photos: {
        temps: [],
      },
      bench_photos: {
        temps: [],
      },
      bmpobs_photos: {
        temps: [],
      },
      parcelAssessment_photos: {
        temps: [],
      },

      blueDotLatLng: null,
      bbox: null,
      zoomLevel: null,
      mapCenter: null,

      clustered: { layer: "" },
      outfallInventoryState: {
        type: "NA",
        existing: false,
        outfallId: null,
        currentOutfallRotationAngle: "",
        currentOutfallLatLng: "",
        currentOutfallData: {},
      },
      facilityInventoryState: {
        type: "NA",
        existing: false,
        facilityIdentifier: null,
        currentFacilityLatLng: "",
        currentFacilityData: {},
      },
      samplingSiteInventoryState: {
        type: "NA",
        existing: false,
        siteIdentifier: null,
        currentSamplingSiteData: {},
      },

      layers: {
        streams: {
          isEnabled: false,
          isFetching: false,
          data: null,
          selected: "",
          selectedType: "",
          searchString: "",
        },
        flowRouting: {
          isEnabled: false,
          isFetching: false,
          data: null,
          selected: "",
          selectedType: "",
          searchString: "",
          listening: false,
          hasNewData: false,
          dataModal: [],
          isEnabledModal: false,
        },
        stormdrains: {
          isEnabled: false,
          isFetching: false,
          data: null,
          selected: "",
          selectedType: "",
          searchString: "",
          dataModal: [],
          isEnabledModal: false,
        },
        projects: {
          isEnabled: false,
          isFetching: false,
          data: null,
          selected: "",
          selectedType: "",
          searchString: "",
        },
        landuse: {
          isEnabled: false,
          isFetching: false,
          data: null,
          selected: "",
          selectedType: "",
          searchString: "",
          dataModal: [],
          isEnabledModal: false,
        },
        maintenanceZonesLegend: {
          isEnabled: false,
          isFetching: false,
          data: null,
          selected: "",
          selectedType: "",
          searchString: "",
          dataModal: [],
          isEnabledModal: false,
        },
        particulates: {
          isEnabled: false,
          isFetching: false,
          data: null,
          selected: "",
          selectedType: "",
          searchString: "",
        },
        runoff: {
          isEnabled: false,
          isFetching: false,
          data: null,
          selected: "",
          selectedType: "",
          searchString: "",
        },
        bmps: {
          isEnabled: false,
          isFetching: false,
          data: null,
          selected: "",
          selectedType: "",
          searchString: "",
          dataModal: [],
          isEnabledModal: false,
        },
        bounds: {
          isEnabled: false,
          isFetching: false,
          data: null,
          selected: "",
          selectedType: "",
          searchString: "",
        },
        survey123: {
          isEnabled: false,
          isFetching: false,
          catchList: [],
          data: null,
          selected: "",
          selectedType: "",
          searchString: "",
          hasNewData: false,
        },
        trashLines: {
          isEnabled: false,
          isFetching: false,
          catchList: [],
          data: null,
          selected: "",
          selectedType: "",
          searchString: "",
        },
        trashPoints: {
          isEnabled: false,
          isFetching: false,
          data: null,
          selected: "",
          selectedType: "",
          searchString: "",
        },
        priority: {
          isEnabled: false,
          isFetching: false,
          data: null,
          selected: "",
          selectedType: "",
          searchString: "",
          dataModal: [],
          isEnabledModal: false,
        },
        drainage: {
          isEnabled: false,
          isFetching: false,
          data: null,
          selected: "",
          selectedType: "",
          searchString: "",
          dataModal: [],
          isEnabledModal: false,
        },
        fullDrainageBmp: {
          isEnabled: false,
          isFetching: false,
          data: null,
          selected: "",
          selectedType: "",
          searchString: "",
          dataModal: [],
          isEnabledModal: false,
        },
        fullDrainageFcs: {
          isEnabled: false,
          isFetching: false,
          data: null,
          selected: "",
          selectedType: "",
          searchString: "",
          dataModal: [],
          isEnabledModal: false,
        },
        fcs: {
          isEnabled: false,
          isFetching: false,
          data: null,
          selectedId: null,
          selected: "",
          selectedType: "",
          searchString: "",
          dataModal: [],
          isEnabledModal: false,
        },
        constructionProject: {
          isEnabled: false,
          isFetching: false,
          data: null,
          selectedId: null,
          selected: "",
          selectedType: "",
          searchString: "",
        },
        constructionProjectArea: {
          isEnabled: false,
          isFetching: false,
          data: null,
          selectedId: null,
          selected: "",
          selectedType: "",
          searchString: "",
        },
        lidProject: {
          isEnabled: false,
          isFetching: false,
          data: null,
          selectedId: null,
          selected: "",
          selectedType: "",
          searchString: "",
        },
        lidProjectArea: {
          isEnabled: false,
          isFetching: false,
          data: null,
          selectedId: null,
          selected: "",
          selectedType: "",
          searchString: "",
        },
        lidProjectBmp: {
          isEnabled: false,
          isFetching: false,
          data: null,
          selectedId: null,
          selected: "",
          selectedType: "",
          searchString: "",
        },
        indcomFacilities: {
          isEnabled: false,
          isFetching: false,
          data: null,
          selectedId: null,
          selected: "",
          selectedType: "",
          searchString: "",
        },
        projectAssociatedBmp: {
          isEnabled: false,
          isFetching: false,
          data: null,
          dataModal: [],
          selectedId: null,
          selected: "",
          selectedType: "",
          searchString: "",
        },
        planProperties: {
          isEnabled: false,
          isFetching: false,
          data: null,
          dataModal: [],
          selectedId: null,
          selected: "",
          selectedType: "",
          searchString: "",
        },
        allProperties: {
          isEnabled: false,
          isFetching: false,
          data: null,
          dataModal: [],
          selectedId: null,
          selected: "",
          selectedType: "",
          searchString: "",
        },
        propertyBmp: {
          isEnabled: false,
          isFetching: false,
          data: null,
          dataModal: [],
          selectedId: null,
          selected: "",
          selectedType: "",
          searchString: "",
        },
        muniCatchBasin: {
          isEnabled: false,
          isEnabledModal: false,
          isFetching: false,
          data: null,
          dataModal: [],
          selectedId: null,
          selected: "",
          selectedType: "",
          searchString: "",
        },
        muniCatchBasinDrainageArea: {
          isEnabled: false,
          isEnabledModal: false,
          isFetching: false,
          data: null,
          dataModal: [],
          selectedId: null,
          selected: "",
          selectedType: "",
          searchString: "",
        },
        outfallsSimple: {
          isEnabled: false,
          isFetching: false,
          data: null,
          selected: "",
          selectedType: "",
          searchString: "",
          listening: false,
          hasNewData: false,
          dataModal: [],
          isEnabledModal: false,
        },
        outfalls: {
          isEnabled: false,
          isFetching: false,
          data: null,
          selected: "",
          selectedType: "",
          searchString: "",
          listening: false,
          hasNewData: false,
          dataModal: [],
          isEnabledModal: false,
        },
        incidents: {
          isEnabled: false,
          isFetching: false,
          data: null,
          selected: "",
          selectedType: "",
          searchString: "",
          listening: false,
          hasNewData: false,
          dataModal: [],
          isEnabledModal: false,
        },
        muniOutfalls: {
          isEnabled: false,
          isFetching: false,
          data: null,
          selected: "",
          selectedType: "",
          searchString: "",
          listening: false,
          hasNewData: false,
          dataModal: [],
          isEnabledModal: false,
        },
        catchBasinBulkClean: {
          isEnabled: false,
          isFetching: false,
          data: null,
          dataModal: [],
          selectedId: null,
          selected: "",
          selectedType: "",
          searchString: "",
        },
        scenarios: {
          isFetching: false,
          data: null,
        },
      },
      focusedInputName: null,
      baseMap: "Gray",
      geoServerFeatures: [],
      fromUpdate: null,
      assetManagementRadioMode: "attribution",
      assetManagementApiData: null,
      assetManagementApiDataSummaryTable: null,
      hasSaved: false,
      modalDefault: null,
      readOnlyModal: false,
      readOnlyModalMap: false,

      isDefaultFilter: false,
      filters: {
        search: "",
        statuses: [], // @TODO: investigate if we are sitll using this
        ownership: [
          "our-agency",
          "private",
          "partner",
          "unknown",
          "other",
          "ms4",
          "dot",
          "local-agency",
        ],
        inaccessible: false,
        types: [],
        catchments: [], //catch names only
        receivingWaters: [],
        dateFrom: null,
        dateTo: null,
        condition: [], //text conditions for raw data
        descend: false, //alphabetic order
        dataSort: "rw", //dropdown options  'ud' & 'uc'
        photos: false, //has photos
        priority: false, //filter by priority landuse
        searchString: "",
        scoreFrom: null,
        scoreTo: null,
      },
      dataView: "",

      spatialView: "ms4",
      availableDataSortLayers: [],
      asset: {
        inventory: {},
        observation: {
          obs_id: null,
          fcs_name: null,
          idBmp: null,
          group_id: null,
          date: null,
          personnel: null,
          clog_percent: null,
          clog_cleaned: null,
          screen_damaged: null,
          screen_repaired: null,
          full_percent: null,
          trash_removed: null,
          avg_trash_count: null,
          trash_volume: null,
          mobile_percent: null,
          notes: null,
          score: null,
          report_submitted: null,
          trash_counts: [],
          wt_trash_count: null,
          wt_volume: null,
          wt_mobile: null,
        },
        selected_catchments: [],
        selected_polygons: [],
        lastSavedBmpDrainarea: 0,
        update: false,
        tempScore: null, //temp store score until saved
        isCentralized: null,
        type_name: null,
        centralizedBmps: [],
        drawMethod: "drawing-tools",
        drawAcres: {
          acres: 0,
          pluAcres: 0,
        },
      },

      enforcementActionUpdates: {},

      currentBmpFcs: null,
      bmpFcsAssessmentHistory: null,

      todos: {
        fcs: {
          unfilteredData: undefined,
          data: undefined,
          filters: undefined,
          isFetching: false,
          todoIdSets: undefined,
          catchTodoCounts: undefined,
          totalTodoCounts: undefined,
          selectedSubject: undefined,
          highlightSubject: undefined,
        },
        bmp: {
          unfilteredData: undefined,
          data: undefined,
          filters: undefined,
          isFetching: false,
          todoIdSets: undefined,
          catchTodoCounts: undefined,
          totalTodoCounts: undefined,
          selectedSubject: undefined,
          highlightSubject: undefined,
        },
        "muni-catch-basin": {
          unfilteredData: undefined,
          data: undefined,
          filters: undefined,
          isFetching: false,
          todoIdSets: undefined,
          catchTodoCounts: undefined,
          totalTodoCounts: undefined,
          selectedSubject: undefined,
          highlightSubject: undefined,
        },
      },

      activeTab: null,

      selected: {
        receivingWater: "",
        catchment: "",
      },

      catchmentData: [], //filtered catchments with all data

      deleteBmpFcs: {
        idBmp: undefined,
        bmpName: undefined,
      },

      legendShowing: false,
      modalLegendShowing: false,
      modalMapSingleMarkerLastValidLatLng: null,

      //@TODO: Refactor - Data from bmpram SessionStorage
      bmpram: {
        infiltration: {},
        benchmarkData: [{}],
        bmp: null,
        depth: {},
        tempSaveDepths: [],
        tempDeleteDepthIds: [],
        tempSaveInfs: [],
        tempDeleteInfIds: [],
        type: null,
        soil: {},
        benchInf: null,
        benchmarkUpdate: false,
        bmpObsUpdate: false,
      },
      initialBmpObsState: {
        observation: {},
        depths: [],
        infs: [],
        runoffs: [],
        soil: {},
      },

      table: {
        expanded: {
          receivingWaters: [],
          catchments: [],
          maintenanceZones: [],
          highways: [],
          masterToggle: false,
        },
        dataSort: "rw",
      },

      factSheet: {
        open: false,
      },

      bulkSelect: {
        actionKey: null,
        selectedIds: [],
      },

      reloadFilter: true,

      toDoSpatialFilterData: null,

      popupData: {
        muniCatchBasin: {},
      },

      streetSweepingEsriLayer: "streetSweepingRoute",
      enabledEsriLayers: [],
      enabledGeoServerLayers: [],

      dataScenario: "baseline-runoff",
    };
  };

  return { getStaticData, initDynamicData };
};

module.exports = TreeInitialDataRam();
