"use strict";

function RouteConfig() {
  const get = function () {
    return {
      "2nform": {
        ":tool/:dataView": GeneralRoutes.toolDataViewRoute,
        ":tool/:dataView/to-do/:toDoSubject/prioritize": GeneralRoutes.toDoPrioritizeRoute,

        "asset/bmpFcs/:idbmp/routineInspection": BmpFcsRoutes.routineInspectionRoute,
        "asset/bmpFcs/:idbmp/routineInspection/:inspectionId": BmpFcsRoutes.routineInspectionRoute,
        "asset/catchBasin/:idbmp/routineInspection": CatchBasinRoutes.routineInspectionRoute,
        "asset/catchBasin/:idbmp/routineInspection/:inspectionId":
          CatchBasinRoutes.routineInspectionRoute,

        // Repair routes left for historical Esri inspections, do not use in new code
        "asset/bmpFcs/:idbmp/routineRepair": BmpFcsRoutes.routineInspectionRoute,
        "asset/bmpFcs/:idbmp/routineRepair/:inspectionId": BmpFcsRoutes.routineInspectionRoute,
        "asset/catchBasin/:idbmp/routineRepair": CatchBasinRoutes.routineInspectionRoute,
        "asset/catchBasin/:idbmp/routineRepair/:inspectionId":
          CatchBasinRoutes.routineInspectionRoute,

        "asset/outfall/:id/routineInspection": OutfallRoutes.routineInspectionRoute,
        "asset/outfall/:id/routineInspection/:inspectionId": OutfallRoutes.routineInspectionRoute,

        "asset/samplingSite/:id/inspection": SamplingSiteRoutes.inspectionRoute,
        "asset/samplingSite/:id/inspection/:inspectionId": SamplingSiteRoutes.inspectionRoute,

        // Repair routes left for historical Esri inspections, do not use in new code
        "asset/outfall/:id/routineRepair": OutfallRoutes.routineInspectionRoute,
        "asset/outfall/:id/routineRepair/:inspectionId": OutfallRoutes.routineInspectionRoute,

        "asset/outfall/:id/dryWeatherScreening": OutfallRoutes.routineInspectionDryWeatherRoute,
        "asset/outfall/:id/dryWeatherScreening/:inspectionId":
          OutfallRoutes.routineInspectionDryWeatherRoute,

        "lid/lid-project/data/fact-sheet/:id": ConstructionRoutes.lidProjectFactSheetRoute,
        "lid/lid-project/data/fact-sheet/:id#:highlightId":
          ConstructionRoutes.lidProjectFactSheetHighlightRoute,

        "construction/construction-project/data/fact-sheet/:id":
          ConstructionRoutes.projectFactSheetRoute,
        "construction/construction-project/data/fact-sheet/:id#:highlightId":
          ConstructionRoutes.projectFactSheetHighlightRoute,

        "projdelivery/construction-project-delivery/data/fact-sheet/:id":
          ConstructionRoutes.projectDeliveryFactSheetRoute,
      },
      esg: {
        "": EsgRoutes.homeRoute,
        "impact-accounting": EsgRoutes.stormwaterImpactAccountingRoute,
        "impact-accounting/stormwater-plans": EsgRoutes.plansRoute,
        "impact-accounting/stormwater-plans/:planSlug": EsgRoutes.planRoute,
        "impact-accounting/stormwater-plans/edit-info/:planSlug": EsgRoutes.planInfoRoute,
        "impact-accounting/stormwater-plans/:planSlug/impact": EsgRoutes.impactRoute,
        "impact-accounting/stormwater-plans/:planSlug/selected": EsgRoutes.selectedPropertiesRoute,
        "impact-accounting/stormwater-plans/:planSlug/selected/:propertySlug":
          EsgRoutes.propertyFactSheetRouteForPlanSelected,
        "impact-accounting/stormwater-plans/:planSlug/suitable": EsgRoutes.suitablePropertiesRoute,
        "impact-accounting/stormwater-plans/:planSlug/suitable/:propertySlug":
          EsgRoutes.propertyFactSheetRouteForPlanSuitable,
        portfolio: EsgRoutes.stormwaterOpportunityRoute,
        "portfolio/stormwater-plans/:planSlug": EsgRoutes.planRoute,
        "portfolio/properties": EsgRoutes.propertiesRoute,
        "portfolio/properties/:propertySlug": EsgRoutes.propertyFactSheetRoute,
        "portfolio/incentive-programs": EsgRoutes.incentiveProgramsRoute,
      },
      portal: {
        ":secret": PortalRoutes.goToFormRoute,
        ":secret/post-construction-response": PortalRoutes.lidSelfInspectionResponseRoute,
        ":secret/post-construction-response/confirm":
          PortalRoutes.lidSelfInspectionConfirmationRoute,
        ":secret/industrial-commercial-response":
          PortalRoutes.indcomFacilitySelfInspectionResponseRoute,
        ":secret/industrial-commercial-response/confirm":
          PortalRoutes.indcomFacilitySelfInspectionConfirmationRoute,
      },
    };
  };

  return { get };
}

module.exports = RouteConfig();

const BmpFcsRoutes = require("../routes/2nform/bmpFcsRoutes");
const CatchBasinRoutes = require("../routes/2nform/catchBasinRoutes");
const OutfallRoutes = require("../routes/2nform/outfallRoutes");
const GeneralRoutes = require("../routes/2nform/generalRoutes");
const EsgRoutes = require("../routes/esg/esgRoutes");
const PortalRoutes = require("../routes/portal/portalRoutes");
const SamplingSiteRoutes = require("../routes/2nform/samplingSiteRoutes");
const ConstructionRoutes = require("../routes/2nform/constructionRoutes");
