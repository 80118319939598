"use strict";

const EnforcementConstants = function () {
  const instructions = `<p><strong>Click "Submit Enforcement" to create a new enforcement record. Click "Save & Edit Later" to save this enforcement and submit it at a later time. Click "Cancel" to go back.</strong></p>`;
  const warningMessages = {
    issue: "Are you sure you want to issue this enforcement?" + instructions,
    "keep-open": "Are you sure you want to keep this enforcement open?" + instructions,
    escalate: "Are you sure you want to escalate this enforcement?" + instructions,
    resolve: "Are you sure you want to resolve this enforcement?" + instructions,
  };

  var getNewEnforcementDefaults = function (enforcementData) {
    enforcementData.escalationAction = "issue";
    enforcementData.issueDate = DateTime.getTodayIso();
    return enforcementData;
  };

  var getWarningMessageFromLevel = function (level) {
    return warningMessages[level] ?? "";
  };

  return { getNewEnforcementDefaults, getWarningMessageFromLevel };
};

module.exports = EnforcementConstants();

const DateTime = require("../dateTime");
