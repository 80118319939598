"use strict";

const WqMonitoringLocationGeoServerLayer = function () {
  const layerName = "wqMonitoringLocations";
  const layerKey = "wqMonitoringLocationsLayer";

  var createMarkerFunction = function (feature, latlng) {
    const properties = feature.properties;
    const assetType = properties.assetType;

    if (assetType === "samplingSite") {
      return SamplingSiteIcon.getSamplingSiteMarker(latlng);
    } else if (assetType === "outfall") {
      return OutfallsIcon.getSimpleOutfallsMarker(
        latlng,
        properties.rotationAzimuth,
        properties.priority,
      );
    }
  };

  var getLocationIconClass = function (properties) {
    const assetType = properties.assetType;

    if (assetType === "samplingSite") {
      return SamplingSiteIcon.getSamplingSiteIconClass();
    } else if (assetType === "outfall") {
      return OutfallsIcon.getOutfallsIconClass();
    }
  };

  var loadLayerListenersForMap = function (map, mapLayers, mapId) {
    const layer = new GeoServerMapLayer(layerName, layerKey, createMarkerFunction);
    GeoServerLayerFunctions.setLayerInstanceByMapId(layerName, mapId, layer);

    layer.loadToggledListenerForMap(map, mapLayers, mapId);
    layer.loadTreeUpdateListenersForMap(map, mapLayers, mapId);
  };

  var loadLayer = function (mapId = "main") {
    GeoServerLayerFunctions.loadLayerByMapId(layerName, mapId);
  };

  var invalidateLayerData = function (loadingScreen = true) {
    GeoServerLayerFunctions.invalidateLayerDataForMainMap(layerName, loadingScreen);
  };

  var organizeLayerData = function (layerData) {
    return layerData.map(function (data) {
      return getOrganizedProperties(data.properties, data.geometry);
    });
  };

  var getOrganizedProperties = function (data, geometry) {
    const properties = {
      assetType: data.asset_type,
      id: data.id,
      groupId: data.group_id,
      geometry: geometry,
      locationIdentifier: data.location_identifier,
      locationName: data.location_name,
      locationPhase: data.location_phase,
      locationType: data.location_type,

      address: data.address,
      city: data.city,
      state: data.state,
      zip: data.zip,
      maintainerType: data.maintainer_type,
      maintainedBy: data.maintainer_type,
      notes: data.notes,

      catchment_name: data.catchment_name,
      drains_to_rw: data.drains_to_rw,
      drains_to_c: data.drains_to_c,
      zone_names: data.zone_names,

      rotationAzimuth: data.rotation_azimuth,
      priority: data.priority,
    };

    properties.tableIcon = getLocationIconClass(properties);

    return properties;
  };

  var getLayerData = async function (onlineFiltering = false, loadingScreen = true) {
    const data = await GeoServerLayerFunctions.getFeaturesForLayer(
      layerName,
      onlineFiltering,
      loadingScreen,
    );
    return organizeLayerData(data);
  };

  return {
    loadLayerListenersForMap,
    loadLayer,
    invalidateLayerData,
    organizeLayerData,
    getLayerData,
  };
};

module.exports = WqMonitoringLocationGeoServerLayer();

const GeoServerLayerFunctions = require("../mapFunctions/geoServerLayerFunctions");
const GeoServerMapLayer = require("../mapFunctions/geoServerMapLayer");
const SamplingSiteIcon = require("./samplingSiteIcon");
const OutfallsIcon = require("../outfalls/outfallsIcon");
