"use strict";

const ConstructionProjectDeliveryDataListController = function () {
  var inited = false;
  var config = null;

  var init = function () {
    if (inited) {
      render();
    } else {
      inited = true;
      config = {
        prepareDataCallback: prepareConstructionProjects,
        template: "list/constructionProjectDeliveryDataList.njk",
        layerName: "constructionProject",
      };
      DataList.init(config);
      initialRender();
      DataList.loadDomListeners([{ target: ".open-fact-sheet", callback: openFactSheet }]);
    }
  };

  var initialRender = function () {
    DataList.setSorting("projectIdentifier", false);
    render();
  };

  var render = function () {
    DataList.render(config);
  };

  var prepareConstructionProjects = function (constructionProjects) {
    if (constructionProjects.length === 0) {
      return {
        rows: [],
        options: {},
      };
    }
    ConstructionProjectDataListController.addSurvey123Urls(constructionProjects);
    addIconClasses(constructionProjects);
    addProjectStageAndTreatmentTypeData(constructionProjects);
    sortConstructionProjectsBy(constructionProjects);
    return {
      rows: constructionProjects,
      options: {},
    };
  };

  var addIconClasses = function (constructionProjects) {
    constructionProjects.forEach((project) => {
      project.iconClass = ConstructionIcon.getConstructionProjectIconClass(project);
    });
  };

  var addProjectStageAndTreatmentTypeData = function (constructionProjects) {
    constructionProjects.forEach((project) => {
      project.stage = ProjectStageData.getDisplayStageData(project);
      project.treatmentType = project.treatmentTypes?.[0];
    });
  };

  var sortConstructionProjectsBy = function (constructionProjects) {
    const dateSorter = DateTime.dbDateStringComparator;
    const alphaSorter = SortUtilities.alphaSorter;
    const numberSorter = SortUtilities.numberSorter;
    const phaseSorter = ConstructionProjectDataListController.sortByConstructionPhase;

    var sortersDict = {
      projectIdentifier: alphaSorter,
      projectName: alphaSorter,
      streetAddress: alphaSorter,
      phase: phaseSorter,
      startDateIso: dateSorter,
      capacityAcreFeet: numberSorter,
      treatedImperviousAreaAcres: numberSorter,
      deliveryCost: numberSorter,
    };
    DataList.sortWithDict(constructionProjects, sortersDict);
  };

  var openFactSheet = function () {
    const projectId = DataList.getIdFromRow($(this));
    Tree.set(["factSheet", "previousDataSort"], Tree.get(["table", "dataSort"]));
    Filters.setDataSort("projectIdentifier");

    UrlRoutes.go("construction-project-delivery-fact-sheet", { id: projectId });
    DataList.hide();
    FactSheet.handleFilterFlow();
  };

  return {
    init,
  };
};

module.exports = ConstructionProjectDeliveryDataListController();

const ConstructionIcon = require("../construction/constructionIcon");
const ConstructionProjectDataListController = require("./constructionProjectDataListController");
const DataList = require("../mapFunctions/dataList");
const DateTime = require("../dateTime");
const Filters = require("../mapFunctions/filters");
const SortUtilities = require("../general/sortUtilities");
const ProjectStageData = require("./projectStageData");
const Tree = require("../tree");
const FactSheet = require("../general/factSheet");
const UrlRoutes = require("../routes/urlRoutes");
