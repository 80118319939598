"use strict";
const IndcomInventoryConstants = function () {
  var getResponsiblePartyMaintenanceOptions = function () {
    return FormSettings.getDropdownOptions("maintainer-type", { tool: "muni" });
  };

  var getConsequenceOfFailureOptions = function () {
    return FormSettings.getDropdownOptions("consequence-of-failure", { tool: "muni" });
  };

  var getStatesOptions = function () {
    return FormSettings.getDropdownOptions("states");
  };

  const riskFactorOptions = [
    {
      name: "High",
      value: "high",
    },
    {
      name: "Moderate",
      value: "moderate",
    },
    {
      name: "Low",
      value: "low",
    },
    {
      name: "Exempt",
      value: "exempt",
    },
  ];

  const responsiblePartyOptions = [
    { name: "Private", value: "private" },
    { name: "Municipal", value: "municipal" },
  ];

  const phaseOptions = [
    { name: "Open/Active", value: "open" },
    { name: "Closed/Out of Business", value: "closed" },
  ];

  const eswiftEntityTypeOptions = [
    {
      name: "Partner",
      value: "partner",
    },
    {
      name: "Private",
      value: "private",
    },
    {
      name: "Our Agency",
      value: "our-agency",
    },
    {
      name: "DOT",
      value: "dot",
    },
    {
      name: "MS4",
      value: "ms4",
    },
    {
      name: "Local Agency",
      value: "local-agency",
    },
    {
      name: "Other",
      value: "other",
    },
    {
      name: "Unknown",
      value: "unknown",
    },
  ];

  const datumOptions = [
    {
      name: "EGM 96",
      value: "egm96",
    },
    {
      name: "EGM 2008",
      value: "egm2008",
    },
    {
      name: "NAVD 1929",
      value: "navd1929",
    },
    {
      name: "NGVD 1988",
      value: "ngvd1988",
    },
    {
      name: "Other",
      value: "other",
    },
    {
      name: "WGS 84",
      value: "wgs84",
    },
    {
      name: "Unknown",
      value: "unknown",
    },
  ];

  const priorityOptions = [
    {
      name: "Yes",
      value: true,
    },
    {
      name: "No",
      value: false,
    },
  ];

  const consequenceOfFailureOptions = [
    {
      name: "Very High",
      value: "very-high",
    },
    {
      name: "High",
      value: "high",
    },
    {
      name: "Moderate",
      value: "moderate",
    },
    {
      name: "Low",
      value: "low",
    },
    {
      name: "Very Low",
      value: "very-low",
    },
    {
      name: "Unknown",
      value: "unknown",
    },
  ];

  const getRoleOptions = function () {
    return ToolSettings.getSetting("indcom", "contactRoleOptions");
  };

  const uploadLocationOptions = [
    {
      name: "File Upload",
      value: "upload",
    },
    {
      name: "File Location",
      value: "location",
    },
    {
      name: "File Link",
      value: "external-url",
    },
  ];

  const requiredFieldOptions = [
    {
      name: "Latitude & Longitude",
      value: "geom",
    },
    {
      name: "ID",
      value: "facilityIdentifier",
    },
    {
      name: "Responsible Party",
      value: "responsiblePartyManagement",
    },
    {
      name: "Phase",
      value: "facilityPhase",
    },
    {
      name: "Open Date",
      value: "openedDate",
    },
    {
      name: "Closed Date",
      value: "closedDate",
    },
    {
      name: "Inspection Frequency",
      value: "inspectionFrequencyMonths",
    },
    {
      name: "Business Owner Mailing Address",
      value: "contacts",
    },
  ];

  var getFileTypeOptions = function () {
    return ToolSettings.getSetting("indcom", "inventory", "fileTypes")
      .map(function (datum) {
        return {
          name: datum.name,
          displayName: datum.name,
          value: datum.value,
        };
      })
      .sort(SortUtilities.alphaSortFormOptions);
  };

  var getIndcomInventoryDefaults = function () {
    const defaults = {
      priority: false,
      routineSiteVisitFrequencyMonths: 12,
      existingFacilities: [],
      responsiblePartyManagement: "private",
      facilityPhase: "open",
      fogParticipant: null,
      isIndustrialGeneralPermit: null,
      has303dPollutant: null,
      adjacentToEsa: null,
      mobileBusiness: null,
      facilityFiles: [],
    };

    return defaults;
  };

  var getFacilityTypeOptions = function () {
    return FormSettings.getDropdownOptions("facility-type") || [];
  };

  return {
    consequenceOfFailureOptions,
    priorityOptions,
    phaseOptions,
    datumOptions,
    getResponsiblePartyMaintenanceOptions,
    getIndcomInventoryDefaults,
    getConsequenceOfFailureOptions,
    eswiftEntityTypeOptions,
    getStatesOptions,
    getFileTypeOptions,
    getFacilityTypeOptions,
    responsiblePartyOptions,
    riskFactorOptions,
    getRoleOptions,
    uploadLocationOptions,
    requiredFieldOptions,
  };
};
module.exports = IndcomInventoryConstants();

const SortUtilities = require("../general/sortUtilities");
const FormSettings = require("../settings/formSettings");
const ToolSettings = require("../settings/toolSettings");
