"use strict";

const OutfallRoutineMaintenanceMeasurements = function (Controller) {
  const stringKey = "measurements";
  const tabHeader = "Field Measurements";
  let $page;

  var setPage = function ($thisPage) {
    $page = $thisPage;
  };

  var loadListeners = function (data) {
    if (!$page) {
      $page = $("#assessment-modal #measurements");
    }

    if (FeatureFlag.enabled("sampling-site-inspection")) {
      $page.on(
        "2N:FormInput",
        `[name="conduct-field-measurements"]`,
        onConductFieldMeasurementsChange,
      );
    }

    $page.on("2N:FormInput", `.visual-observations select`, onVisualObservationSelectChange);
    $page.on(
      "2N:FormInput",
      `[name="new-field-measurement[measurement-parameter]"]`,
      renderNewUnits,
    );
    $page.on("click", ".save-btn", saveNewRecord);
    $page.on("click", ".delete-btn", deleteRecord);

    toggleVisualObservationOtherInput(
      $(`[name="observation-info[odor-description]"]`),
      data.observationInfo?.odorDescription,
    );
    toggleVisualObservationOtherInput(
      $(`[name="observation-info[color-description]"]`),
      data.observationInfo?.colorDescription,
    );
    toggleVisualObservationOtherInput(
      $(`[name="observation-info[floatables-description]"]`),
      data.observationInfo?.floatablesDescription,
    );
    if (
      FeatureFlag.enabled("sampling-site-inspection") &&
      WqMonitoringConstants.isWqMonitoringWorkflowAssetType(data?.assetType)
    ) {
      toggleConductFieldMeasurements(data?.conductFieldMeasurements);
    }
  };

  var unloadListeners = function () {
    $page.off(
      "2N:FormInput",
      `[name="conduct-field-measurements"]`,
      onConductFieldMeasurementsChange,
    );
    $page.off("2N:FormInput", `.visual-observations select`, onVisualObservationSelectChange);
    $page.off("2N:FormInput", `[name="new-field-measurement[measurement-parameter]"]`);
    $page.off("click", ".save-btn");
    $page.off("click", ".delete-btn");
  };

  var getProps = function () {
    return {
      flowEstimationDescriptionOptions: DryWeatherConstants.flowEstimationDescriptionOptions,
      flowEstimationRateUnitOptions: DryWeatherConstants.flowEstimationRateUnitOptions,
      odorDescriptionOptions: DryWeatherConstants.getOdorDescriptionOptions(),
      odorSeverityOptions: DryWeatherConstants.getOdorSeverityOptions(),
      colorDescriptionOptions: DryWeatherConstants.getColorDescriptionOptions(),
      colorSeverityOptions: DryWeatherConstants.getColorSeverityOptions(),
      turbidityDescriptionOptions: DryWeatherConstants.getTurbidityDescriptionOptions(),
      floatablesDescriptionOptions: DryWeatherConstants.getFloatablesDescriptionOptions(),
      floatablesSeverityOptions: DryWeatherConstants.floatablesSeverityOptions,
      measurementParameterOptions: DryWeatherConstants.getMeasurementParameterOptions(),
      measurementMethodOptions: DryWeatherConstants.getMeasurementMethodOptions(),
      measurementParameterUnitOptions: DryWeatherConstants.getMeasurementParameterUnitOptions(),
    };
  };

  var getStringKey = function () {
    return stringKey;
  };

  var getTabHeader = function () {
    return tabHeader;
  };

  var renderNewUnits = function (event, value) {
    const newDropdownValues = DryWeatherConstants.measurementParameterUnits[value];
    const newValue = ((newDropdownValues || [])[0] || {}).value; // newDropdownValues?[0]?.value
    const html = nunjucks.render("modals/outfallRoutineMaintenance/measurementUnits.njk", {
      dropdownOptions: newDropdownValues,
      defaultSelection: newValue,
    });

    $page.find(".units").html(html);
    updateParameterMaximumQuantity(value);
    updateUnitsInFormValues(newValue);
  };

  var updateUnitsInFormValues = function (newValue) {
    if (newValue) {
      Controller.setFormData(["newFieldMeasurement", "units"], newValue);
    } else {
      Controller.setFormData(["newFieldMeasurement", "units"], "");
    }
  };

  var saveNewRecord = function () {
    const measurementData = Controller.getFormData(["newFieldMeasurement"]) || {};
    if (
      measurementData.measurementParameter &&
      (measurementData.quantity || measurementData.quantity === 0)
    ) {
      const index = (Controller.getInspectionRenderData().measurements || []).length;
      const newRecord = {
        ...measurementData,
        id: Date.now(),
        method: measurementData.method || "grab-sample",
      };
      Controller.setFormData(["measurements", index], newRecord);
      insertNewMeasurementRecord(newRecord);
      resetMeasurementFields();
    } else {
      MessageModal.showSimpleWarningModal("Enter a value for all fields to save.");
    }
  };

  var updateParameterMaximumQuantity = function (value) {
    const newMaximum = value === "ph" ? 14 : 999999.99;
    const quantityField = $page.find(`[name="new-field-measurement[quantity]"]`)[0];
    const currentValue = Controller.getFormData(["newFieldMeasurement", "quantity"]);
    if (currentValue > newMaximum) {
      NumberInput.manuallySetInput(quantityField, newMaximum);
    }
    NumberInput.updateInputOptions(quantityField, { maximumValue: newMaximum });
  };

  var deleteRecord = function (e) {
    const existingMeasurements = Controller.getExistingInspectionData().measurements || [];
    const existingMeasurementsIds = existingMeasurements.map((measurement) => measurement.id);
    const idToDelete = parseInt($(e.currentTarget).parent(".measurement").attr("id"));
    if (existingMeasurementsIds.includes(idToDelete)) {
      deleteExistingRecord(idToDelete);
    } else {
      deleteNewRecord(idToDelete);
    }

    $(e.currentTarget).parent(".measurement").remove();
  };

  var deleteExistingRecord = function (idToDelete) {
    const measurements = Controller.getInspectionRenderData().measurements;
    const index = measurements.findIndex((measurement) => {
      return measurement.id === idToDelete;
    });
    Controller.setFormData(["measurements", index], {
      deleted: "true",
      id: idToDelete,
    });
  };

  var deleteNewRecord = function (idToDelete) {
    const measurements = Controller.getInspectionRenderData().measurements;
    const index = measurements.findIndex((measurement) => {
      return measurement?.id === idToDelete;
    });

    Controller.unsetFormData(["measurements", index]);
  };

  var resetMeasurementFields = function () {
    const resetValues = { method: "grab-sample" };
    const $parameter = $page.find(`[name="new-field-measurement[measurement-parameter]"]`);
    const dropdownOptions = nunjucks.render(
      "modals/outfallRoutineMaintenance/measurementUnits.njk",
    );

    $parameter.val("no-selection");

    $page.find(".units").html(dropdownOptions);

    $page.find(`[name="new-field-measurement[method]"]`).val(resetValues.method);
    NumberInput.manuallySetInput($page.find(`[name="new-field-measurement[quantity]"]`)[0], "");
    updateParameterMaximumQuantity();
    Controller.setFormData(["newFieldMeasurement"], resetValues);
  };

  var insertNewMeasurementRecord = function (newRecord) {
    const html = nunjucks.render("modals/outfallRoutineMaintenance/measurementRecord.njk", {
      record: newRecord,
      measurementParameterUnitOptions: DryWeatherConstants.getMeasurementParameterUnitOptions(),
    });
    $page.find(".measurement-records").prepend(html);
  };

  var onVisualObservationSelectChange = function (e, description) {
    toggleVisualObservationOtherInput($(this), description);
  };

  var toggleVisualObservationOtherInput = function ($select, description) {
    const showOtherInput = description === "other";
    $select.parent().find(".other-input").toggle(showOtherInput);
  };

  var onConductFieldMeasurementsChange = function (e, conductFieldMeasurements) {
    toggleConductFieldMeasurements(conductFieldMeasurements);
  };

  var toggleConductFieldMeasurements = function (conductFieldMeasurements) {
    $page.find(`.show-when-conduct-field-measurements`).toggle(conductFieldMeasurements);
  };

  return {
    loadListeners,
    unloadListeners,
    getStringKey,
    getTabHeader,
    getProps,
    setPage,
    renderNewUnits,
  };
};

module.exports = OutfallRoutineMaintenanceMeasurements;

const DryWeatherConstants = require("../../illicitDischarge/dryWeatherScreening/dryWeatherConstants");
const MessageModal = require("../../modals/messageModal");
const NumberInput = require("../../general/numberInput");
const FeatureFlag = require("../../settings/featureFlag");
const WqMonitoringConstants = require("../../wq/inspection/wqMonitoringConstants");
