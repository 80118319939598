"use strict";

const InspectionSummaryLetterModal = function () {
  const formKey = "inspection-summary-letter";
  var $modal;
  var props;
  var inspectionTypeOptions;
  var followUpTypeOptions;

  var loadDomListeners = function () {
    const $modalContent = $modal.find(".modal-content");

    $modalContent.on("click", ".cancel-button", _closeModalIfConfirmed);
    $modalContent.on("click", ".save-response-button", _saveResponseIfConfirmed);
    $modalContent.on("click", ".preview-button", _onPreviewLetter);
    $modalContent.on("2N:FormInput", checkEnableSendButton);
  };

  var _closeModalIfConfirmed = function () {
    if (Object.keys(Form.getDataFromForm(formKey, false)).length === 0) {
      close();
    } else {
      MessageModal.showConfirmWarningModal(
        null,
        close,
        "Go Back",
        "Close Without Saving",
        CommonModalFunctions.getCloseWithoutSavingPrompt(),
      );
    }
  };

  var _saveResponseIfConfirmed = function () {
    const returnMessage = "Go Back";
    const okMessage = "Generate";
    const emailsEnabled = Session.notificationEnabled("pdf-letters");
    const confirmMessage = `Clicking “${okMessage}” will save the information entered and generate a zip file that includes a maintenance notice PDF and related site visit report PDFs ${emailsEnabled ? "that will be emailed to you shortly" : "that will be available from the project inventory shortly"}. To proceed, click “${okMessage}”. To go back, click “${returnMessage}”.`;

    MessageModal.showConfirmWarningModal(null, _save, returnMessage, okMessage, confirmMessage);
  };

  var checkEnableSendButton = function () {
    const canUpdateResponse = _hasAllRequiredFields();
    $modal.find(".preview-button").toggleClass("disabled", !canUpdateResponse);
    $modal.find(".save-response-button").toggleClass("disabled", !canUpdateResponse);
  };

  var renderAndShowEdit = async function (summaryId, { readOnly = false } = {}) {
    const data = await ApiCalls.getLidInspectionSummary(summaryId);
    await _initRenderAndShow([data.project], { readOnly, sendData: data["send"] });
  };

  var renderAndShow = async function (projectIds, { readOnly = false } = {}) {
    const projectData = await ApiCalls.getConstructionProjectForLidInspectionSummary(projectIds);
    await _initRenderAndShow(projectData, { readOnly });
  };

  var _initRenderAndShow = async function (projectsData, { readOnly, sendData = null } = {}) {
    $modal = $("#update-response-modal");

    await setProps(projectsData, readOnly, sendData);
    render();
    initializeFields();
    setupForm(readOnly);

    Analytics.sendScreenView("modal", formKey);
  };

  var setupForm = function (readOnly) {
    checkEnableSendButton();

    if (readOnly) {
      Misc.toggleDisabledInputs($modal.find(".modal-body"), readOnly);
    }
  };

  var setProps = function (projectsData, readOnly, sendData) {
    for (const project of projectsData) {
      LetterCommunicationModal.addFirstContactNames(project);
    }

    _setInspectionTypeOptions(projectsData);
    _setFollowUpTypeOptions(projectsData);

    props = {
      readOnly,
      projectsData,
      projectContactIdOptions: getProjectContactIdOptions(projectsData[0].contacts),
      bulkProjectContactTypeOptions: [
        {
          value: "operator-maintainer",
          name: "O&M Contact(s)",
        },
        {
          value: "owner",
          name: "Property Owner(s)/LRP",
        },
      ],
      followUpTypeOptions,
      issuesOnlyOptions: [
        { name: "Only reports for sBMPs with maintenance needs", value: true },
        { name: "All reports", value: false },
      ],
      inspectionTypeOptions,
      send: sendData ?? _getDefaultSendData(),
    };
  };

  var _setFollowUpTypeOptions = function (projectsData) {
    if (
      projectsData.some((project) => project.bmpHasCompleteObservationWithIssue) ||
      projectsData.some((project) => project.bmpHasMuniInspectionWithIssue)
    ) {
      followUpTypeOptions = [
        {
          value: "site-visit",
          name: "Re-Inspection",
        },
        {
          value: "informal",
          name: "Maintenance Notice",
        },
      ];
    } else {
      followUpTypeOptions = [];
    }
  };

  var render = async function () {
    const html = nunjucks.render("modals/lid/inspectionSummaryLetterModal.njk", props);
    $modal.html(html);
    $modal.modal("show");
  };

  var initializeFields = function () {
    Form.initializeAndLoadListeners($modal, formKey);
    Form.manuallySetFormDataField(formKey, ["send"], props.send);
    loadDomListeners();
  };

  var _getUpdateData = function (clearData = false) {
    const updateData = Form.getDataFromForm(formKey, clearData);

    addProjectIds(updateData);

    return updateData;
  };

  var addProjectIds = function (updateData) {
    updateData.projectIds = props.projectsData.map(function (project) {
      return { id: project.id };
    });
  };

  var _save = async function () {
    await Form.getReadyToSavePromise(formKey);
    const updateData = _getUpdateData(true);
    await ApiCalls.generateLidInspectionSummaryLetter(updateData);

    close();
    LidProjectLayer.invalidateLayerData();
  };

  var close = function () {
    Form.clearForm(formKey);
    $modal.modal("hide");
    $modal.html("");
    Analytics.sendScreenView();
  };

  var _hasAllRequiredFields = function () {
    if (Form.isFormEmpty(formKey)) {
      return false;
    }

    const data = getCurrentData();
    const fields = ["send[inspectionType]", "send[issuesOnly]", "send[projectContactId]"];

    if (followUpTypeOptions.length) {
      fields.push("send[followUpType]");
      fields.push("send[responseDueDate]");
    }

    return fields.every((requiredField) => _inputNameIsSet(requiredField, data));
  };

  var _inputNameIsSet = function (inputName, data) {
    const pathParts = Form.getPathPartsFromName(inputName);
    data = Form.getDataAtPath(data, pathParts);

    return data !== null && data !== undefined && data !== "";
  };

  var _onPreviewLetter = async function () {
    previewLetter(props?.projectsData?.[0].id);
  };

  var previewLetter = async function (projectId) {
    InspectionPreview.preview(function () {
      const updateData = Form.getDataFromForm(formKey, false);
      updateData.projectId = projectId;
      return updateData;
    }, ApiCalls.previewLidInspectionSummaryLetter);
  };

  var getProjectContactIdOptions = function (contacts) {
    if (!contacts) {
      return [];
    }

    return contacts.map(function (contact) {
      return {
        value: contact.id,
        name: `${contact.name ?? "—"} (${contact.displayRole})`,
      };
    });
  };

  var getCurrentData = function () {
    return $.extend(true, {}, props, Form.getDataFromForm(formKey, false));
  };

  var _setInspectionTypeOptions = function (projectsData) {
    inspectionTypeOptions = [];

    if (projectsData.some((project) => project.bmpHasCompleteObservation)) {
      inspectionTypeOptions.push({
        value: "functional-assessment",
        name: "Functional Assessment",
      });
    }

    if (projectsData.some((project) => project.bmpHasMuniInspection)) {
      inspectionTypeOptions.push({
        value: "routine-inspection",
        name: "Routine Inspection",
      });
    }
  };

  var _getDefaultSendData = function () {
    const data = {
      issuesOnly: true,
    };

    if (inspectionTypeOptions.length === 1) {
      data.inspectionType = inspectionTypeOptions[0].value;
    }

    return data;
  };

  return {
    renderAndShow,
    renderAndShowEdit,
    close,
    render,
    _hasAllRequiredFields,
    _closeModalIfConfirmed,
    _save,
    _getUpdateData,
    _saveResponseIfConfirmed,
    getProjectContactIdOptions,
  };
};

module.exports = InspectionSummaryLetterModal();

const Analytics = require("../general/analytics");
const ApiCalls = require("../apiCalls");
const CommonModalFunctions = require("../modals/commonModalFunctions");
const Form = require("../general/form");
const LidProjectLayer = require("./lidProjectLayer");
const MessageModal = require("../modals/messageModal");
const Misc = require("../misc");
const InspectionPreview = require("../general/inspectionPreview");
const LetterCommunicationModal = require("./letterCommunicationModal");
const Session = require("../login/session");
