"use strict";

const OutfallPopup = function () {
  var getPopupHtml = function (data, isWqMonitoringLocationLayer = false) {
    const dataView = Tree.get("dataView");
    const inventoryAccess = UserPermissions.getPermission("can_create_outfall");
    const popupProperties = {
      diameterLabel:
        FormSettings.getLabelSettingsByFormKey("outfall-inventory")?.find(
          (label) => label.key === "outlet-diameter",
        )?.newLabel ?? null,
      inventoryAccess,
      featureProperties: data,
      readOnly: Session.isRegulator(),
      isOutfallDataView: OutfallFunctions.isOutfallDataView(dataView),
      isWqMonitoringDataView: dataView === "wq-monitoring",
      popupColor: OutfallsIcon.getIconColor(data.condition, data.outfallInspectionIsDue),
      showDeleteOutfallButton: true,
      showDetailsButton: Tree.get("tool") !== "outmon",
      permissions: UserPermissions.getAllPermissions(),
      isWqMonitoringLocationLayer,
    };

    PopupContent.addCommonPopupProps(popupProperties);

    if (FeatureFlag.enabled("sampling-site-inventory-modal") && Tree.get("tool") === "outmon") {
      return nunjucks.render("popups/wqOutfallPopup.njk", popupProperties);
    } else {
      return nunjucks.render("popups/outfallsPopup.njk", popupProperties);
    }
  };

  var onPopupOpenWithData = function (data, $element, invalidateLayerDataFunction) {
    var onPopupDelete = function () {
      deleteOutfall(data.id, data.outfallName, invalidateLayerDataFunction);
    };

    var onDownloadReport = async function () {
      const id = $(this).closest("tr").data("id");
      await ApiCalls.downloadOutfallInspectionPdf(id);
    };

    $element.off("click", ".popup-delete-button", onPopupDelete);
    $element.on("click", ".popup-delete-button", onPopupDelete);
    $element.off("click", ".download-report", onDownloadReport);
    $element.on("click", ".download-report", onDownloadReport);

    OutfallInspectionHistory.renderOutfallInspectionHistory(data.history, data.id);
    PopupPhotos.load("outfall", data.id);
  };

  var deleteOutfall = function (id, name, invalidateLayerDataFunction) {
    const popupCloseButn = $(".leaflet-popup-close-button");
    MessageModal.showConfirmWarningModal(
      `Delete Outfall ${name}? Click "Delete" to permanently delete the Outfall. Click "Cancel" to return.`,
      async function () {
        await ApiCalls.deleteOutfall(id);
        invalidateLayerDataFunction();
        _reloadAndClosePopup(popupCloseButn);
      },
      "Cancel",
      "Delete",
    );
    Analytics.sendPopupEvent("delete_outfall", "outfall");
  };

  var _reloadAndClosePopup = function (popupCloseButn) {
    if (popupCloseButn.length) {
      popupCloseButn[0].click();
    }
  };

  var onPopupOpen = async function (properties, layerName, isWqMonitoringLocationLayer = false) {
    const id = properties.id;
    const data = await ApiCalls.getOutfallPopup(id);
    const html = getPopupHtml(data, isWqMonitoringLocationLayer);
    const layerModule = LayerFunctions.getLayerModule(layerName);

    const $popup = $(".leaflet-popup-content");
    $popup.html(html);
    loadConditionBars(data);
    onPopupOpenWithData(data, $popup, layerModule.invalidateLayerData);
  };

  var loadConditionBars = function (data) {
    PopupContent.setPopupConditionBar(
      $(".tr-muni-outfall-score"),
      data.history,
      (datum) => datum.score,
    );
  };

  return {
    getPopupHtml,
    onPopupOpen,
    onPopupOpenWithData,
  };
};

module.exports = OutfallPopup();

const ApiCalls = require("../apiCalls");
const FormSettings = require("../settings/formSettings");
const LayerFunctions = require("../layerFunctions");
const MessageModal = require("../modals/messageModal");
const OutfallFunctions = require("./outfallFunctions");
const OutfallInspectionHistory = require("./outfallInspectionHistory");
const OutfallsIcon = require("./outfallsIcon");
const PopupPhotos = require("../mapFunctions/popupPhotos");
const Session = require("../login/session");
const UserPermissions = require("../login/userPermissions");
const Tree = require("../tree");
const Analytics = require("../general/analytics");
const PopupContent = require("../mapFunctions/popupContent");
const FeatureFlag = require("../settings/featureFlag");
