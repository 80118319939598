"use strict";

const SamplingSiteInspectionConstants = function () {
  var getNewInspectionDefaults = function (samplingSiteId, samplingSiteInfo = {}) {
    return {
      ...DryWeatherConstants.getNewDryWeatherScreeningDefaults(samplingSiteId, "sampling-site"),
      inspectionType: WqMonitoringConstants.getDefaultInspectionTypeByTool(),
      samplingSiteInfo: samplingSiteInfo,
      asset: samplingSiteInfo?.asset,
      maintenanceType: getMaintenanceType(samplingSiteInfo?.asset?.dryWeatherCondition),
    };
  };

  var getMaintenanceType = function (dryWeatherCondition) {
    return dryWeatherCondition === "open" ? "follow-up" : "routine";
  };

  var getResponsiblePartyMaintenanceOptions = function () {
    return FormSettings.getDropdownOptions("maintainer-type", { tool: "muni" });
  };

  return {
    getNewInspectionDefaults,
    getResponsiblePartyMaintenanceOptions,
  };
};

module.exports = SamplingSiteInspectionConstants();

const DryWeatherConstants = require("../../illicitDischarge/dryWeatherScreening/dryWeatherConstants");
const FormSettings = require("../../settings/formSettings");
const WqMonitoringConstants = require("./wqMonitoringConstants");
