/**
 * @public
 */

/**
 * @public
 */
class DereferenceStrategy {
  name;
  constructor({
    name
  }) {
    this.name = name;
  }
}
export default DereferenceStrategy;