"use strict";

const SamplingSiteFilters = function () {
  const searchDbFields = ["site_identifier", "address"];
  const searchFields = ["siteIdentifier", "address"];
  let mainSmartFilters;

  var filterResourceDataHandler = async function (unfilteredData, filters, loadingScreen) {
    if (onlineFilterIsSet(filters)) {
      try {
        return await ResourceFilters.filterOnline(
          unfilteredData,
          loadingScreen,
          SamplingSiteGeoServerLayer.getLayerData,
        );
      } catch (e) {
        ApiError.assertIs(e);
      }
    }

    return filterOffline(unfilteredData, filters);
  };

  var filterOffline = function (unfilteredData) {
    return mainSmartFilters ? mainSmartFilters.filter(unfilteredData) : unfilteredData;
  };

  var onlineFilterIsSet = function (filters) {
    if (filters.toDoFilters) {
      return ResourceFilters.toDoSpatialFiltersAreSet(filters.toDoFilters);
    } else {
      return ResourceFilters.spatialFiltersAreSet(filters) || isOnlineDateFilterSet(filters);
    }
  };

  var addGeoServerViewParams = function (params, filters) {};

  var isOnlineDateFilterSet = function (filters) {
    return isOnlineDateType(filters.dateType) && (filters.dateFrom || filters.dateTo);
  };

  var isOnlineDateType = function (dateType) {
    return ["routine", "repair", "clean"].includes(dateType);
  };

  var addGeoServerFilterQuery = function (queryStrings, filters) {
    if (filters.searchString) {
      queryStrings.push(
        GeoServerFilterFunctions.getSearchQuery(searchDbFields, filters.searchString),
      );
    }
    GeoServerFilterFunctions.addGeographicalFocusFiltersToQuery(filters, queryStrings);
  };

  var getProps = function () {
    const { urbanDrainageOptions, receivingWaterOptions, catchmentsOptions, zoneOptions } =
      Filters.getSpatialArrays();
    const subGroupProps = Progeny.getSubGroupFilterProps();

    return {
      zoneOptions,
      receivingWaterOptions,
      urbanDrainageOptions,
      catchmentsOptions,
      landOwnershipOptions: FilterConstants.responsiblePartyDeliveryOptionsCB,
      yesNoOptions: FilterConstants.yesNoOptions,
      conditionOptions: FilterConstants.conditionOptions,
      subGroupOptions: subGroupProps?.subGroupOptions,
      subGroupSelected: subGroupProps?.subGroupSelected,
    };
  };

  var setSmartFilters = function (newSmartFilters) {
    mainSmartFilters = newSmartFilters;
  };

  var getFilterConfigs = function () {
    return {
      maintainedBy: "maintainedBy",
      sitePhase: "sitePhase",
      siteType: "siteType",
      subGroups: "groupId",
      drainsToRw: "drains_to_rw",
      drainsToC: "drains_to_c",
      catchmentName: "catchment_name",
      zones: "zone_names",
      otherFilters: (datum, filters) => {
        return ResourceFilters.passSearchStringFilter(datum, filters, searchFields);
      },
    };
  };

  return {
    filterResourceDataHandler,
    getProps,
    isOnlineDateFilterSet,
    addGeoServerFilterQuery,
    addGeoServerViewParams,
    getFilterConfigs,
    setSmartFilters,
  };
};

module.exports = SamplingSiteFilters();

const ApiError = require("../errors/apiError");
const ResourceFilters = require("../filters/resourceFilters");
const GeoServerFilterFunctions = require("../mapFunctions/geoServerFilterFunctions");
const FilterConstants = require("../filterConstants");
const Filters = require("../mapFunctions/filters");
const Progeny = require("../login/progeny");
const SamplingSiteGeoServerLayer = require("./samplingSiteGeoServerLayer");
