"use strict";

const OutfallRoutineMaintenanceSample = function (Controller) {
  const stringKey = "sample";
  const tabHeader = FeatureFlag.enabled("sampling-site-inspection")
    ? "Sample Collection"
    : "Illicit Discharge";
  let $page;

  var setPage = function ($thisPage) {
    $page = $thisPage;
  };

  var loadListeners = function (data) {
    if (!$page) {
      $page = $(`#assessment-modal #${stringKey}`);
    }

    if (
      WqMonitoringConstants.isWqMonitoringWorkflowAssetType(data?.assetType) &&
      FeatureFlag.enabled("sampling-site-inspection")
    ) {
      $page.on("2N:FormInput", `[name="collect-samples"]`, onCollectSamplesChange);
    } else {
      $page.on("2N:FormInput", `[name="samples-collected"]`, toggleNewSampleControls);
      $page.on("2N:FormInput", `[name="illicit-discharge"]`, onIllicitDischargeChange);
      $page.on("2N:FormInput", `[name="follow-up-needed"]`, onFollowUpNeededChange);
      $page.on("2N:FormInput", `[name="close-out-investigation"]`, onCloseOutInvestigationChange);
    }

    $page.on("click", ".save-btn", _saveNewSample);
    $page.on("click", ".delete-btn", deleteExistingSample);

    if (data) {
      if (
        WqMonitoringConstants.isWqMonitoringWorkflowAssetType(data?.assetType) &&
        FeatureFlag.enabled("sampling-site-inspection")
      ) {
        toggleCollectSamples(data.collectSamples);
      } else {
        toggleIllicitDischarge(data.illicitDischarge);
        toggleFollowUpNeeded(data.followUpNeeded);
        toggleCloseOutInvestigation(data.closeOutInvestigation);
      }
    }
  };

  var unloadListeners = function () {
    $page.off("2N:FormInput", `[name="samples-collected"]`, toggleNewSampleControls);
    $page.off("2N:FormInput", `[name="collect-samples"]`, onCollectSamplesChange);
    $page.off("2N:FormInput", `[name="illicit-discharge"]`, onIllicitDischargeChange);
    $page.off("2N:FormInput", `[name="follow-up-needed"]`, onFollowUpNeededChange);
    $page.off("2N:FormInput", `[name="close-out-investigation"]`, onCloseOutInvestigationChange);
    $page.off("click", ".save-btn", _saveNewSample);
    $page.off("click", ".delete-btn", deleteExistingSample);
  };

  var getProps = function () {
    return {
      methodOptions: DryWeatherConstants.getSampleMethodOptions(),
      locationCollectedFromOptions: DryWeatherConstants.getLocationCollectedFromOptions(),
      closeOutReasonOptions: DryWeatherConstants.getCloseOutReasonOptions(),
      showSamplesField: !ToolSettings.getSetting("outfall", "hide-field-measurements"),
      followUpActionOptions: DryWeatherConstants.getFollowUpActionOptions(),
    };
  };

  var getStringKey = function () {
    return stringKey;
  };

  var getTabHeader = function () {
    return tabHeader;
  };

  var toggleNewSampleControls = function (e, toggle) {
    $(".new-sample, .sample-records").toggleClass("hidden", !toggle);
  };

  var _saveNewSample = function () {
    const newSample = Controller.getFormData(["newSample"]) || {};
    if (newSample.locationCollectedFrom && newSample.sampleId) {
      const sampleIdIsUnique = (Controller.getInspectionRenderData().samples || [])
        .filter((sample) => sample.deleted !== "true")
        .every((sample) => {
          return sample.sampleId !== newSample.sampleId;
        });
      if (!sampleIdIsUnique) {
        MessageModal.showSimpleWarningModal("Sample ID must be unique.");
        return;
      }

      const newRecord = {
        id: Date.now(),
        method: newSample.method || "grab-sample",
        locationCollectedFrom: newSample.locationCollectedFrom,
        sampleId: newSample.sampleId,
      };

      const totalSamples = Controller.getInspectionRenderData().samples || [];
      Controller.setFormData(["samples", totalSamples.length], newRecord);
      resetSampleFields();
      insertNewSampleRecordHtml(newRecord);
    } else {
      MessageModal.showSimpleWarningModal("Enter a value for all fields to save.");
    }
  };

  var deleteExistingSample = function (e) {
    const existingSamples = Controller.getExistingInspectionData().samples || [];
    const existingSampleIds = existingSamples.map((sample) => sample.id);
    const idToDelete = parseInt($(e.currentTarget).parent(".sample").attr("id"));
    if (existingSampleIds.includes(idToDelete)) {
      const existingIndex = existingSamples.findIndex((sample) => {
        return sample.id === idToDelete;
      });
      Controller.setFormData(["samples", existingIndex], {
        id: idToDelete,
        deleted: "true",
      });
    } else {
      const samples = Controller.getInspectionRenderData().samples;
      const newIndex = samples.findIndex((sample) => {
        return sample?.id === idToDelete;
      });
      Controller.unsetFormData(["samples", newIndex]);
    }
    $(e.currentTarget).parent(".sample").remove();
  };

  var onCollectSamplesChange = function (e, collectSamples) {
    toggleCollectSamples(collectSamples);
  };

  var toggleCollectSamples = function (collectSamples) {
    $page.find(`.show-when-collect-samples`).toggle(collectSamples);
  };

  var onIllicitDischargeChange = function (e, illicitDischarge) {
    toggleIllicitDischarge(illicitDischarge);
  };

  var toggleIllicitDischarge = function (illicitDischarge) {
    $page.find(`.illicit-discharge-show-when-discharge`).toggle(illicitDischarge);
  };

  var onFollowUpNeededChange = function (e, followUpNeeded) {
    toggleFollowUpNeeded(followUpNeeded);
  };

  var toggleFollowUpNeeded = function (followUpNeeded) {
    $page.find(`.follow-up-needed-show-when-follow-up`).toggle(followUpNeeded);
  };

  var onCloseOutInvestigationChange = function (e, closeOutInvestigation) {
    toggleCloseOutInvestigation(closeOutInvestigation);
  };

  var toggleCloseOutInvestigation = function (closeOutInvestigation) {
    $page.find(`.close-out-investigation-show-when-close-out`).toggle(closeOutInvestigation);
  };

  var insertNewSampleRecordHtml = function (newRecord) {
    const html = nunjucks.render("modals/outfallRoutineMaintenance/sampleRecord.njk", {
      record: newRecord,
    });
    $page.find(".sample-records").prepend(html);
  };

  var resetSampleFields = function () {
    const initialSampleFields = {
      method: "grab-sample",
    };
    $page.find(`[name="new-sample[method]"]`).val(initialSampleFields.method);
    $page.find(`[name="new-sample[location-collected-from]"]`).prop("checked", false);
    $page.find(`[name="new-sample[sample-id]"]`).val("");
    Controller.setFormData(["newSample"], initialSampleFields);
  };

  return {
    loadListeners,
    unloadListeners,
    getStringKey,
    getTabHeader,
    getProps,
    _saveNewSample,
    setPage,
  };
};

module.exports = OutfallRoutineMaintenanceSample;

const DryWeatherConstants = require("../../illicitDischarge/dryWeatherScreening/dryWeatherConstants");
const MessageModal = require("../../modals/messageModal");
const ToolSettings = require("../../settings/toolSettings");
const FeatureFlag = require("../../settings/featureFlag");
const WqMonitoringConstants = require("../../wq/inspection/wqMonitoringConstants");
