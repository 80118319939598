"use strict";

const ConstructionToDoController = function () {
  const clearFlaggedInspections = async function (inspectionIds) {
    try {
      await ToDoFunctions.showConfirmClearToDoListConfirm(inspectionIds.length, {
        selectedToDosName: "flagged inspection",
        confirmButton: "Clear Flagged Inspections",
      });
    } catch (e) {
      // User canceled message
      return;
    }

    await ApiCalls.constructionToDoClearFlaggedInspections(inspectionIds);
    ToDoListController.loadTodos();
  };

  const canShowBmpInstallationTodos = function () {
    return UserRoles.getConfig("showBmpInstallationTodos");
  };

  const canShowCompleteProjectTodos = function () {
    return UserRoles.getConfig("showCompleteProjectTodos");
  };

  const canShowProjectEnforcementActionsTodos = function () {
    return UserRoles.getConfig("showProjectEnforcementActionsTodos");
  };

  const canShowProjectInspectionTodos = function () {
    return UserRoles.getConfig("showProjectInspectionTodos");
  };

  const canShowFormalCommunicationTodos = function () {
    return (
      UserRoles.getConfig("showFormalCommunicationTodos") &&
      FeatureFlag.enabled("formal-communication")
    );
  };

  const canShowIndividualEnforcementsSection = function () {
    return (
      !ToolSettings.getSetting("construction", "sync-enforcements-within-project") &&
      UserRoles.getConfig("showIndividualEnforcementsSection")
    );
  };

  const loadToDoListeners = function ($container, { selectedTable }) {
    const onFlaggedInspectionsClear = function (e) {
      e.stopPropagation();
      const checkedRowIds = selectedTable
        .getAllSelectedRows()
        .toArray()
        .map((elm) => $(elm).data("relatedId"));
      clearFlaggedInspections(checkedRowIds);
    };

    $container.off("click", ".open-enforcement-action");
    $container.on("click", ".open-enforcement-action", openEnforcementActionModal);

    $container.off("click", ".open-project-complete-modal");
    $container.on("click", ".open-project-complete-modal", openProjectCompleteModal);

    $container.off("click", ".open-post-active-modal");
    $container.on("click", ".open-post-active-modal", openPostActiveModal);

    $container.off("click", ".open-formal-communication");
    $container.on("click", ".open-formal-communication", openFormalCommunicationModal);

    $container.off("click", ".js-clear-flagged-inspections", onFlaggedInspectionsClear);
    $container.on("click", ".js-clear-flagged-inspections", onFlaggedInspectionsClear);
  };

  var openFormalCommunicationModal = function (e) {
    const $row = $(e.currentTarget).parents("tr");
    const id = $row.data("id");
    const relatedId = $row.data("relatedId");
    const severity = $row.data("severity");
    const formalCommunicationType = $row.data("formalCommunicationType");

    FormalCommunicationModal.renderAndShow(id, relatedId, severity, formalCommunicationType);
  };

  var openPostActiveModal = function (e) {
    const $row = $(e.currentTarget).parents("tr");
    const id = $row.data("relatedId");
    PostActiveModal.renderAndShow(id);
  };

  var openProjectCompleteModal = function (e) {
    const $row = $(e.currentTarget).parents("tr");
    const id = $row.data("relatedId");
    ProjectCompleteModal.renderAndShow(id);
  };

  const openEnforcementActionModal = function (e) {
    const $row = $(e.currentTarget).parents("tr");
    const type = $row.data("relatedType");
    const id = $row.data("relatedId");
    const subject = $row.data("subject");
    if (type === "inspection") {
      if (
        subject === "enforcement-unresolved" &&
        ToolSettings.getSetting("construction", "use-issue-enforcements-modal")
      ) {
        IssueEnforcementsModal.open(id, null, () => ToDoListController.loadTodos(), type);
      } else {
        EnforcementActionModalController.openEnforcementActionModalForInspection(id, () =>
          ToDoListController.loadTodos(),
        );
      }
    } else if (type === "enforcement-action") {
      if (
        subject === "enforcement-unresolved" &&
        ToolSettings.getSetting("construction", "use-issue-enforcements-modal")
      ) {
        IssueEnforcementsModal.open(null, id, () => ToDoListController.loadTodos(), type);
      } else {
        EnforcementActionModalController.getAndRenderEnforcementAction(id, () =>
          ToDoListController.loadTodos(),
        );
      }
    } else {
      throw new Error(`Can't open enforcement action modal for related type ${type}`);
    }
  };

  const canEnforceProject = function () {
    return ToolSettings.getSetting("permissions", "can_create_enforcement");
  };

  const organizeOpenEnforcementTodos = function (data) {
    const result = [];
    for (const datum of data) {
      for (const enforcement of datum.additionalInfo) {
        TableDataFunctions.assignLidProjectIcon(datum, true);
        const issueDate = enforcement.issueDate
          ? DateTime.parseIsoDate(enforcement.issueDate)
          : null;

        if (!enforcement.currentEscalation) continue;

        result.push({
          id: datum.projectId,
          enforcementId: enforcement.id,
          tableIcon: ConstructionIcon.getConstructionProjectIconClass(datum.iconFields, true, true),
          projectIdentifier: datum.projectIdentifier,
          name: datum.projectName,
          streetAddress: datum.address,
          contact: datum.contact,
          displayEnforcementReason: _getDisplayEnforcementReason(enforcement.currentEscalation),
          displayLevel: Misc.getOptionNameByValue(
            FormSettings.getDropdownOptions("level", { tool: "indcom" }),
            enforcement.currentEscalation?.level,
          ),
          fine: enforcement.currentEscalation?.fine,
          issueDate: issueDate,
          displayIssueDate: issueDate ? DateTime.dateToGroupDisplayDate(issueDate) : issueDate,
          canIssueEnforcement: canEnforceProject(),
        });
      }
    }

    return result;
  };

  var addPermissionsToConstructionTodos = function (constructionTodos) {
    constructionTodos.forEach((todo) => {
      todo.canIssueEnforcement = canEnforceProject();
    });
  };

  const _getDisplayEnforcementReason = function (escalation) {
    const displayEnforcementReason = Misc.getOptionNameByValue(
      FormSettings.getDropdownOptions("enforcement-reason"),
      escalation.enforcementReason,
    );
    const count = escalation.reasonCount ? ` (${escalation.reasonCount})` : "";

    return displayEnforcementReason + count;
  };

  return {
    clearFlaggedInspections,
    canShowBmpInstallationTodos,
    canShowCompleteProjectTodos,
    canShowProjectEnforcementActionsTodos,
    canShowProjectInspectionTodos,
    canShowFormalCommunicationTodos,
    canShowIndividualEnforcementsSection,
    canEnforceProject,
    loadToDoListeners,
    organizeOpenEnforcementTodos,
    addPermissionsToConstructionTodos,
  };
};

module.exports = ConstructionToDoController();

const ApiCalls = require("../apiCalls");
const ToDoFunctions = require("../mapFunctions/toDoFunctions");
const ToDoListController = require("../mapFunctions/toDoListController");
const UserRoles = require("../login/userRoles");
const FeatureFlag = require("../settings/featureFlag");
const ToolSettings = require("../settings/toolSettings");
const EnforcementActionModalController = require("./enforcementActionModalController");
const IssueEnforcementsModal = require("./issueEnforcementsModal");
const ProjectCompleteModal = require("./projectCompleteModal");
const PostActiveModal = require("./postActiveModal");
const FormalCommunicationModal = require("./formalCommunicationModal");
const DateTime = require("../dateTime");
const TableDataFunctions = require("../general/tableDataFunctions");
const Misc = require("../misc");
const FormSettings = require("../settings/formSettings");
const ConstructionIcon = require("../construction/constructionIcon");
