"use strict";

const PostActiveModal = function () {
  const formKey = "post-active";
  var $modal;
  var inited = false;
  var props;

  var init = function () {
    $modal = $("#post-active-modal");
    loadDomListeners();
    inited = true;
  };

  var loadDomListeners = function () {
    $modal.off("click", ".save-button", _savePostActiveStatus);
    $modal.on("click", ".save-button", _savePostActiveStatus);

    $modal.off("click", ".set-post-active-button", setPostActiveButtonClick);
    $modal.on("click", ".set-post-active-button", setPostActiveButtonClick);

    $modal.off("click", ".cancel-button", _closeModalIfConfirmed);
    $modal.on("click", ".cancel-button", _closeModalIfConfirmed);

    $modal.off("click", ".open-project-contacts", openProjectContacts);
    $modal.on("click", ".open-project-contacts", openProjectContacts);

    $modal.off("2N:FormInput", _validateForm);
    $modal.on("2N:FormInput", _validateForm);

    $modal.off("change", ".excluded", _validateForm);
    $modal.on("change", ".excluded", _validateForm);

    ProjectStageData.loadStageDataListeners($modal, {
      formKey,
      isInventory: false,
      limitToPast: true,
    });
  };

  var _savePostActiveStatus = async function () {
    const projectUpdate = Form.getDataFromForm(formKey);
    if ($.isEmptyObject(projectUpdate)) {
      close();
      return;
    }
    $modal.find(".modal-footer .set-post-active-button").addClass("disabled");
    $modal.find(".modal-footer .save-button").addClass("disabled").text("Saving");

    return updateProject(projectUpdate);
  };

  var setPostActiveButtonClick = function () {
    MessageModal.showConfirmWarningModal(
      `This will update the phase for the project ${props.projectIdentifier} as well as the ${props.bmpCount} structural BMPs associated with it.`,
      _setPostActive,
      "Go Back",
      "Change Phase",
    );
  };

  var _setPostActive = async function () {
    $modal.find(".modal-footer .set-post-active-button").addClass("disabled").text("Completing");
    $modal.find(".modal-footer .save-button").addClass("disabled");

    var projectUpdate = Form.getDataFromForm(formKey);
    projectUpdate.phase = "post-active";
    return updateProject(projectUpdate);
  };

  var updateProject = async function (projectUpdate) {
    var projectId = props.id;

    var formData = Form.toFormData({
      constructionProjectId: projectId,
      constructionProjectUpdates: projectUpdate,
    });
    await ApiCalls.updateConstructionProjectAsPromise(formData);

    if (Tree.get("tool") === "construction") {
      ConstructionProjectGeoServerLayer.invalidateLayerData();
    } else if (Tree.get("tool") === "scenario") {
      ScenariosLayer.loadLayer();
    } else {
      ConstructionProjectLayer.loadLayer();
    }

    ToDoListController.loadTodos();
    close();
  };

  var openProjectContacts = function () {
    close();
    ProjectInventoryModalController.invokeProjectInventoryModal(props.id, "project-contacts");
  };

  var _closeModalIfConfirmed = function () {
    if (Object.keys(Form.getDataFromForm(formKey, false)).length === 0) {
      close();
    } else {
      MessageModal.showConfirmWarningModal(
        null,
        close,
        "Go Back",
        "Close Without Saving",
        CommonModalFunctions.getCloseWithoutSavingPrompt(),
      );
    }
  };

  var renderAndShow = async function (projectId) {
    if (!inited) init();
    const postActiveStatus = await ApiCalls.getPostActiveStatus(projectId);
    props = Object.assign({}, postActiveStatus);
    const renderPromise = render();
    Form.initializeAndLoadListeners($modal, formKey);
    initializeFields();
    Analytics.sendScreenView("modal", formKey);
    return renderPromise;
  };

  var render = async function () {
    const html = nunjucks.render("modals/projectInventory/postActiveModal.njk", props);
    $modal.html(html);
    return new Promise((resolve) => {
      $modal.one("shown.bs.modal", resolve);
      $modal.modal("show");
    });
  };

  var initializeFields = function () {
    Form.initializeDatePickers($modal, formKey);
    ProjectStageData.limitDateFieldToNow("certified", "stageData[certified][endDateIso]");
    ProjectStageData.updateAllStageDataDatePickers(props.stageData);
    _validateForm();
  };

  var _validateForm = function () {
    props.canSetPostActive = canSetPostActive();

    $modal
      .find(".set-post-active-button")
      .toggleClass("disabled", !props.canEditProject || !props.canSetPostActive);
    $modal
      .find(".save-button")
      .toggleClass("disabled", !props.canEditProject || props.canSetPostActive);
  };

  var canSetPostActive = function () {
    const endDatePopulated = !!(
      Form.getFormDataAtPath(formKey, ["stageData", "certified", "endDateIso"]) ??
      props["currentEndDateIso"]
    );
    const inspectionFrequencyCompleted = !!(
      Form.getFormDataAtPath(formKey, ["postActiveInspectionFrequency"]) ??
      props["postActiveInspectionFrequency"]
    );

    return endDatePopulated && inspectionFrequencyCompleted;
  };

  var close = function () {
    Form.clearForm(formKey);
    $modal.modal("hide");
    inited = false;
    Analytics.sendScreenView();
  };

  return {
    renderAndShow,
    close,
    render,
    _closeModalIfConfirmed,
    _validateForm,
    _savePostActiveStatus,
    _setPostActive,
  };
};

module.exports = PostActiveModal();

const Analytics = require("../general/analytics");
const ApiCalls = require("../apiCalls");
const ConstructionProjectGeoServerLayer = require("../constructionG2/constructionProjectGeoServerLayer");
const CommonModalFunctions = require("../modals/commonModalFunctions");
const Form = require("../general/form");
const MessageModal = require("../modals/messageModal");
const ProjectInventoryModalController = require("./projectInventoryModalController");
const ProjectStageData = require("./projectStageData");
const ToDoListController = require("../mapFunctions/toDoListController");
const Tree = require("../tree");
const ConstructionProjectLayer = require("../construction/constructionProjectLayer");
const ScenariosLayer = require("../scenario/scenariosLayer");
