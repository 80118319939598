"use strict";

const ConstructionProjectInsightDashboardController = function () {
  var $insightContainer;
  var inited = false;
  var controller = null;

  var reports = [];
  var reportingYears;

  var filters = {
    focusYear: null,
    comparisonYear: null,
    priority: null,
    inspectionType: null,
  };

  const filterOptions = {
    priorityOptions: [
      { name: "All", value: "all" },
      { name: "Yes", value: "true" },
      { name: "No", value: "false" },
      { name: "No Data", value: "null" },
    ],
    get inspectionTypeOptions() {
      let options;

      if (FeatureFlag.enabled("new-construction-inspections")) {
        options = structuredClone(NewProjectInspectionConstants.getInspectionTypeOptions());
      } else {
        options = structuredClone(ProjectInspectionConstants.getInspectionTypeOptions());
      }
      options.unshift({ name: "All", value: "all" });
      return options;
    },
  };

  var init = async function (reInitialize = false) {
    if (!inited || reInitialize) {
      await loadReportingYears();

      $insightContainer = $(".insight-container");
      filters.priority = "all";
      filters.inspectionType = "all";

      loadDomListeners();

      var widgetProjectCount = new InsightWidgetSimpleNumber({
        size: 1,
        title: "Total # of Active Projects",
        neutralTrendColor: true,
        dataDecimalPlaces: 0,
        getDataUnit: () => "",
        getDataNumber: (insightData, dataPath) => {
          return insightData[dataPath]["projectCount"];
        },
        getSubtitle: (insightData, dataPath) => {
          return getYearByDataPath(dataPath);
        },
        getHoverTitle: () => {
          return `Total # of Active Projects in ${filters.focusYear}`;
        },
        getHoverDescription: () => {
          return "The percent difference in total number of active construction projects between the comparison and focus years";
        },
      });

      var widgetCompletedProjectCount = new InsightWidgetSimpleNumber({
        size: 1,
        title: "Total # of Completed Projects",
        neutralTrendColor: true,
        dataDecimalPlaces: 0,
        getDataUnit: () => "",
        getDataNumber: (insightData, dataPath) => {
          return insightData[dataPath]["completedProjectCount"];
        },
        getSubtitle: (insightData, dataPath) => {
          return getYearByDataPath(dataPath);
        },
        getHoverTitle: () => {
          return `Total # of Completed Projects in ${filters.focusYear}`;
        },
        getHoverDescription: () => {
          return "The percent difference in the total number of projects completed during the year between the comparison and focus reporting years";
        },
      });

      var widgetInspectionCount = new InsightWidgetSimpleNumber({
        size: 1,
        title: "Total # of Inspections",
        neutralTrendColor: true,
        dataDecimalPlaces: 0,
        getDataUnit: () => "",
        getDataNumber: (insightData, dataPath) => {
          return insightData[dataPath]["inspectionCount"];
        },
        getSubtitle: (insightData, dataPath) => {
          return getYearByDataPath(dataPath);
        },
        getHoverTitle: () => {
          return `Total # of Inspections in ${filters.focusYear}`;
        },
        getHoverDescription: () => {
          return "The percent difference in total number of construction site inspections between the comparison and focus years";
        },
      });

      var widgetEnforcementCount = new InsightWidgetSimpleNumber({
        size: 1,
        title: "Total # of Enforcements",
        neutralTrendColor: false,
        invertTrendColor: true,
        dataDecimalPlaces: 0,
        getDataUnit: () => "",
        isGreyedOut: () => filters.inspectionType !== "all",
        getDataNumber: (insightData, dataPath) => {
          return insightData[dataPath]["enforcementActionCount"];
        },
        getSubtitle: (insightData, dataPath) => {
          return getYearByDataPath(dataPath);
        },
        getHoverTitle: () => {
          return `Total # of Enforcements in ${filters.focusYear}`;
        },
        getHoverDescription: () => {
          return "The percent difference in the total number of enforcements issued during the year between the comparison and focus reporting years";
        },
      });

      var widgetMonthlyInspectionCount = new InsightWidgetMonthlyCountChart({
        size: 4,
        sizeSm: 2,
        title: "Total # of Inspections by Month",
        neutralTrendColor: true,
        getDataArray: (insightData, dataPath) => {
          const monthlyData = insightData[dataPath]["monthlyInspectionCount"];
          InsightWidgetFunctions.handleMonthlyDataDisplay(monthlyData);
          return monthlyData;
        },
        getYearByDataPath,
        getHoverTitle: (hoverIndex) => {
          const monthIndex = hoverIndex + 1;

          return `${filters.focusYear} & ${filters.comparisonYear} Month ${monthIndex} <br/> Total # of Inspections`;
        },
        getHoverDescription: () => {
          return "The percent difference in total number of construction site inspections between the comparison and focus years";
        },
        getNotes: () => {
          const focusReport = reports.find(
            (report) => report.reportingYear === parseInt(filters.focusYear),
          );

          return `*${focusReport.displayDataRangeFirst} is the start of the focus reporting year. ${focusReport.displayDataRangeLast} is the end of the focus reporting year.`;
        },
      });

      var widgetTopObservationFindings = new InsightWidgetTopList({
        size: 2,
        title: "Top 5 Observation Findings",
        getDataArray: (insightData, dataPath) => {
          return insightData[dataPath]["topCitedInspectionCategoriesCount"];
        },
        getSubtitle: (insightData, dataPath) => {
          return getYearByDataPath(dataPath);
        },
        getHoverTitle: (insightData, hoverType) => {
          return `${hoverType} <br/>Observation Finding Ranking`;
        },
      });

      var widgetTopCitedProjects = new InsightWidgetTopList({
        size: 2,
        title: "Top 5 Most Frequently Cited Projects",
        getDataArray: (insightData, dataPath) => {
          return insightData[dataPath]["topAverageFindingPairsPerInspection"];
        },
        getSubtitle: (insightData, dataPath) => {
          return getYearByDataPath(dataPath);
        },
        getHoverTitle: (insightData, hoverType) => {
          return `${hoverType} <br/>Project Ranking`;
        },
        dataDecimalPlaces: 1,
      });

      var widgetEnforcementsByLevelChart = new InsightWidgetCountByTypeChart({
        size: 2,
        title: "# of Enforcements by Level",
        excludeZeros: true,
        orderByCount: false,
        neutralTrendColor: true,
        twoLineLabel: true,
        typeCountLimit: 3,
        getDataArray: (insightData, dataPath) => {
          return insightData[dataPath]["topEnforcementsByLevel"];
        },
        getYearByDataPath,
        isGreyedOut: () => filters.inspectionType !== "all",
        getSelectedType: () => "total",
        getHoverTitle: (insightData, hoverType) => {
          const displayName =
            insightData.focus.topEnforcementsByLevel.find((type) => type.key === hoverType)
              ?.displayName ??
            insightData.comparison.topEnforcementsByLevel.find((type) => type.key === hoverType)
              ?.displayName;
          return `${filters.focusYear} & ${filters.comparisonYear} <br/> ${displayName}`;
        },
        getHoverDescription: () => {
          return "The percent difference in the total number of enforcements during the year between the comparison and focus reporting years";
        },
      });

      var widgetAvgFindingOpenTime = new InsightWidgetSimpleNumber({
        size: 1,
        title: "Average Time Findings Open",
        getDataUnit: (insightData, dataPath) => {
          const hours = insightData[dataPath]["avgFindingOpenTime"];
          return InsightWidgetFunctions.convertTimeUnit(hours);
        },
        getDataNumber: (insightData, dataPath) => {
          const hours = insightData[dataPath]["avgFindingOpenTime"];
          return hours;
        },
        convertDataNumberFunction: InsightWidgetFunctions.convertTime,
        getSubtitle: (insightData, dataPath) => {
          return getYearByDataPath(dataPath);
        },
        getHoverTitle: () => {
          return `${filters.focusYear} & ${filters.comparisonYear} <br/> Average Time Findings Open`;
        },
        getHoverDescription: () => {
          return "The percent difference in average time findings are open between the comparison and focus reporting years";
        },
      });

      var config = {
        getInsightDataFunction: ApiCalls.getConstructionProjectInsight,
        widgets: FeatureFlag.enabled("construction-insight-filters")
          ? [
              widgetProjectCount,
              widgetInspectionCount,
              widgetMonthlyInspectionCount,
              widgetCompletedProjectCount,
              widgetAvgFindingOpenTime,
              widgetTopObservationFindings,
              widgetTopCitedProjects,
              widgetEnforcementCount,
              widgetEnforcementsByLevelChart,
            ]
          : [widgetProjectCount, widgetInspectionCount, widgetMonthlyInspectionCount],
        hasFilter: true,
        getFilterMenuHtmlFunction,
        saveFilterFunction,
        getMobileFilterButtonTextFunction,
      };

      controller = new InsightDashboardController(config);
      controller.init();
      inited = true;
      render();
    }
  };

  var loadDomListeners = function () {
    $insightContainer.off("change", ".year-dropdown", onYearChange);
    $insightContainer.on("change", ".year-dropdown", onYearChange);

    $insightContainer.off("change", `[name="priority"]`, onPriorityChange);
    $insightContainer.on("change", `[name="priority"]`, onPriorityChange);

    $insightContainer.off("change", `[name="inspectionType"]`, onInspectionTypeChange);
    $insightContainer.on("change", `[name="inspectionType"]`, onInspectionTypeChange);

    $insightContainer.off("click", ".count-by-type-chart-container .bar-group", onBarGroupClick);
    $insightContainer.on("click", ".count-by-type-chart-container .bar-group", onBarGroupClick);
  };

  var onYearChange = function () {
    if (this.name === "focus-year") {
      filters.focusYear = this.value;
    } else if (this.name === "comparison-year") {
      filters.comparisonYear = this.value;
    }
    render();
  };

  var onPriorityChange = function () {
    setPriority(this.value);
    render();
  };

  var setPriority = function (priority) {
    filters.priority = String(priority);
  };

  var onInspectionTypeChange = function () {
    filters.inspectionType = this.value;
    render();
  };

  var onBarGroupClick = function () {
    var $barGroup = $(this);
    var $performanceTierSelect = $insightContainer.find(`select[name="performanceTier"]`);

    if ($barGroup.hasClass("selected")) {
      $performanceTierSelect.val("total").change();
    } else {
      const performanceTier = $barGroup.data("type");
      if (performanceTier) {
        $performanceTierSelect.val(performanceTier).change();
      }
    }
  };

  var render = function () {
    if (Tree.get("dataView") !== "construction-project") {
      return;
    }

    controller.render(filters);
  };

  var loadReportingYears = async function () {
    reports = await ApiCalls.getAnnualReports("construction");
    reportingYears = reports
      .map((report) => report.reportingYear)
      .sort()
      .reverse();
    filters.focusYear = reportingYears[0];
    filters.comparisonYear = reportingYears[1] || reportingYears[0];
  };

  var getFilterMenuHtmlFunction = function () {
    return nunjucks.render("insight/constructionProjectInsightDashboardFilters.njk", {
      ...filterOptions,
      ...filters,
      yearOptions: reportingYears.map((year) => {
        return { name: year, value: year };
      }),
    });
  };

  var getMobileFilterButtonTextFunction = function () {
    const priorityDisplayName = filterOptions.priorityOptions.getObjectWith(
      "value",
      filters.priority,
    )?.name;
    const inspectionTypeDisplayName = filterOptions.inspectionTypeOptions.getObjectWith(
      "value",
      filters.inspectionType,
    )?.name;
    return `${filters.focusYear} & ${filters.comparisonYear}, ${priorityDisplayName}, ${inspectionTypeDisplayName}`;
  };

  var saveFilterFunction = function (formData) {
    if ("focusYear" in formData) {
      filters.focusYear = parseInt(formData["focusYear"]);
      $insightContainer.find(`select[name="focus-year"]`).val(filters.focusYear);
    }
    if ("comparisonYear" in formData) {
      filters.comparisonYear = parseInt(formData["comparisonYear"]);
      $insightContainer.find(`select[name="comparison-year"]`).val(filters.comparisonYear);
    }
    if ("priority" in formData) {
      setPriority(formData["priority"]);
      $insightContainer.find(`select[name="priority"]`).val(filters.priority);
    }
    if ("inspectionType" in formData) {
      filters.inspectionType = formData["inspectionType"];
      $insightContainer.find(`select[name="inspectionType"]`).val(filters.inspectionType);
    }

    render();
  };

  var getYearByDataPath = function (dataPath) {
    return dataPath === "focus" ? filters.focusYear : filters.comparisonYear;
  };

  return {
    init,
  };
};

module.exports = ConstructionProjectInsightDashboardController();

const ApiCalls = require("../apiCalls");
const InsightDashboardController = require("../general/widgets/insightDashboardController");
const InsightWidgetCountByTypeChart = require("../general/widgets/insightWidgetCountByTypeChart");
const InsightWidgetSimpleNumber = require("../general/widgets/insightWidgetSimpleNumber");
const InsightWidgetTopList = require("../general/widgets/insightWidgetTopList");
const InsightWidgetMonthlyCountChart = require("../general/widgets/insightWidgetMonthlyCountChart");
const InsightWidgetFunctions = require("../general/widgets/insightWidgetFunctions");
const Tree = require("../tree");
const ProjectInspectionConstants = require("./inspections/projectInspectionConstants");
const FeatureFlag = require("../settings/featureFlag");
const NewProjectInspectionConstants = require("./newInspections/newProjectInspectionConstants");
