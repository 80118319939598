"use strict";

const BaseEnforcementController = function (
  formKey,
  htmlPath,
  headerTemplate,
  getTabModules,
  newEnforcementApiFunction,
  getEnforcementApiFunction,
  createEnforcementApiFunction,
  updateEnforcementApiFunction,
  getAssetNameFunction,
  getAssetIdFunction,
  { afterHideFunction, beforeSaveFunction } = {},
) {
  let $modal;
  let tabs;
  let data;

  var loadListeners = function () {
    Form.initializeAndLoadListeners($modal, formKey);
    FormSettings.customizeForm($modal, formKey);
  };

  var unloadListeners = function () {};

  var loadNewEnforcement = async function (id, options = {}) {
    const newData = await newEnforcementApiFunction(id);
    const defaultData = EnforcementConstants.getNewEnforcementDefaults(newData);

    if (options?.flaggedForEnforcement) {
      defaultData.flaggedForEnforcement = true;
      defaultData.flaggedForEnforcementInspectionId = options.flaggedForEnforcementInspectionId;
    }

    showEnforcementModal(defaultData, true);
  };

  var viewEnforcementAction = async function (id, enforcementData = null, config = {}) {
    enforcementData ||= await getEnforcementApiFunction(id);
    showEnforcementModal(
      { ...enforcementData, escalationAction: null, readOnly: true },
      false,
      config,
    );
  };

  var editEnforcementAction = async function (id, enforcementData = null, config = {}) {
    enforcementData ||= await getEnforcementApiFunction(id);
    showEnforcementModal(
      {
        ...enforcementData,
        issueDate: enforcementData?.issueDate || DateTime.getNowIso(),
      },
      false,
      config,
    );
  };

  var showEnforcementModal = function (newData, newEnforcement = false, config = {}) {
    const { EnforcementAction, EnforcementLetterManagement, EnforcementHistory } = getTabModules();

    data = newData;

    const historyTab = data?.enforcementHistory?.length ? [EnforcementHistory] : [];

    tabs = newEnforcement
      ? [EnforcementAction, EnforcementLetterManagement]
      : data?.readOnly
        ? [...historyTab, EnforcementLetterManagement]
        : [EnforcementAction, ...historyTab, EnforcementLetterManagement];

    const renderData = mapTabProps(tabs, data);
    $modal = TabbedModal.showTabbedModal(formKey, renderData, tabs, htmlPath, config);

    loadListeners();
    setAdditionalModalHandlers();
    updateLetterFilesList();
    TabbedModal.setHeaderText(getHeaderHtml(renderData));
    if (data?.readOnly) {
      TabbedModal.hideSaveButton();
    }
  };

  var getHeaderHtml = function (data) {
    return nunjucks.render(headerTemplate, data);
  };

  var mapTabProps = function (tabs, data) {
    const tabProps = tabs.reduce((props, tab) => {
      return $.extend(true, props, tab.getProps(data));
    }, {});
    return {
      ...data,
      ...tabProps,
    };
  };

  var setAdditionalModalHandlers = function () {
    TabbedModal.setCancelHandler(() => {
      unloadListeners();
    });
    TabbedModal.setSaveHandler(saveButtonClick, false);
    TabbedModal.setFormHasUpdatesHandler(formHasUpdates);
  };

  var saveButtonClick = function () {
    return new Promise((resolve) => {
      const fields = EnforcementValidator.getMissingRequiredFields(getAllData());
      _saveOrWarnInvalid(fields);
      resolve(false);
    });
  };

  var _saveOrWarnInvalid = function (fields) {
    showSaveWarningModal(fields);
    highlightInvalidFields(fields);
  };

  var highlightInvalidFields = function (fields) {
    const fieldNames = fields.map((field) => field.fieldName);
    CommonModalFunctions.highlightFormFields($modal, fieldNames);
  };

  var showSaveWarningModal = function (fields) {
    const messages = fields.map((field) => field.message);
    const uniqueMessages = new Set(messages);

    const incompleteFields = {
      entityName: getAssetNameFunction(data),
      entityKind: "enforcement",
      incompleteFieldNames: [...uniqueMessages],
    };

    const buttonCallbacks = {
      cancelCallback: null,
      saveCallback: saveAndCloseModal,
      submitCallback: function () {
        setFormField("locked", true);
        saveAndCloseModal();
      },
    };

    SaveIncompleteModal.showWarnSaveSubmitModal(
      incompleteFields,
      buttonCallbacks,
      getWarningMessageFromLevel(),
    );
  };

  var getWarningMessageFromLevel = function () {
    const escalationAction = getLatestData()?.escalationAction || "issue";
    return EnforcementConstants.getWarningMessageFromLevel(escalationAction);
  };

  var saveAndCloseModal = async function () {
    let uploadingData;

    try {
      await Form.getReadyToSavePromise(formKey);
      const dataToUpload = getDataToUpload();
      uploadingData = await sendToApi(dataToUpload);
      closeModal();
    } catch (e) {
      return UploadValidator.reportError(e);
    }

    return uploadingData;
  };

  var setFormField = function (path, value) {
    path = Array.isArray(path) ? path : [path];
    Form.manuallySetFormDataField(formKey, path, value);
  };

  var unsetFormField = function (path) {
    path = Array.isArray(path) ? path : [path];
    Form.manuallyUnsetField(formKey, path);
    return Form.getDataFromForm(formKey, false);
  };

  var getDataToUpload = function () {
    const dataToUpload = _getUpdateData(Form.getDataFromForm(formKey, false));
    beforeSaveFunction?.(dataToUpload.updates);

    return dataToUpload;
  };

  var sendToApi = async function (dataToUpload) {
    if (dataToUpload?.updates?.id) {
      dataToUpload.enforcementId = dataToUpload?.updates?.id;
      await updateEnforcementApiFunction(Form.toFormData(dataToUpload));
    } else {
      await createEnforcementApiFunction(Form.toFormData(dataToUpload));
    }

    return dataToUpload;
  };

  var getExistingData = function () {
    return data;
  };

  var getLatestData = function () {
    return $.extend(true, {}, data, Form.getDataFromForm(formKey, false));
  };

  var _getUpdateData = function (dataUpdates) {
    let dataToUpload = $.extend(true, {}, dataUpdates);
    dataToUpload = mergeDefaultsIntoUpdates(dataToUpload);

    delete dataToUpload.constants;

    return {
      ...getAssetIdFunction(data),
      updates: dataToUpload,
    };
  };

  var getAllData = function () {
    const formData = Form.getDataFromForm(formKey, false);
    return $.extend(true, {}, data, formData);
  };

  var mergeDefaultsIntoUpdates = function (dataToUpload) {
    const result = $.extend({}, data, dataToUpload);
    return result;
  };

  var getFormKey = function () {
    return formKey;
  };

  var formHasUpdates = function () {
    return !Form.isFormEmpty(formKey);
  };

  var updateLetterFilesList = function () {
    const letterFilesHtml = getLetterFilesHtml();
    $modal.find("[name=enforcement-letter]").replaceWith(letterFilesHtml);
  };

  var getLetterFilesHtml = function () {
    const { EnforcementLetterManagement } = getTabModules();
    return nunjucks.render("modals/enforcement/_enforcementLetterOptions.njk", {
      enforcementLetter: getLatestData()?.enforcementLetter,
      props: { enforcementLetterOptions: EnforcementLetterManagement.getLetterFilesList() },
    });
  };

  var deleteAndCloseModal = async function () {
    await ApiCalls.deleteEnforcement(data.id, formKey);
    closeModal();
  };

  var deleteEscalation = async function (escalationId) {
    return await ApiCalls.deleteEscalation(escalationId, formKey);
  };

  var closeModal = function () {
    TabbedModal.hide();
    afterHideFunction?.();
  };

  return {
    viewEnforcementAction,
    editEnforcementAction,
    loadNewEnforcement,
    showEnforcementModal,
    getFormKey,
    getExistingData,
    getLatestData,
    setFormField,
    unsetFormField,
    getDataToUpload,
    getLetterFilesHtml,
    updateLetterFilesList,
    deleteAndCloseModal,
    deleteEscalation,
    closeModal,
  };
};

module.exports = BaseEnforcementController;

const DateTime = require("../dateTime");
const Form = require("../general/form");
const TabbedModal = require("../general/tabbedModal");
const UploadValidator = require("../general/uploadValidator");
const CommonModalFunctions = require("../modals/commonModalFunctions");
const SaveIncompleteModal = require("../modals/saveIncompleteModal");
const FormSettings = require("../settings/formSettings");
const EnforcementConstants = require("./enforcementConstants");
const EnforcementValidator = require("./enforcementValidator");
const ApiCalls = require("../apiCalls");
