"use strict";

const ToDoListController = function () {
  let preparedToDoData;
  let dataView;

  var init = async function () {
    dataView = Tree.get("dataView");
    if (!DataViewFunctions.getUserEnabledTabs(dataView, "list").includes("todo")) return;
    loadTreeListeners();
    const alreadyLoaded = loadTodosIfNecessary();
    if (alreadyLoaded) {
      await _renderToDoList(Tree.get("todos", dataView, "unfilteredData"));
    }
    ToDoList.loadDomListeners([{ target: ".open-fact-sheet", callback: openFactSheet }]);
  };

  var loadTreeListeners = function () {
    Tree.select(["todos", dataView, "unfilteredData"]).off("update", onUnfilteredDataUpdate);
    Tree.select(["todos", dataView, "unfilteredData"]).on("update", onUnfilteredDataUpdate);

    Tree.watch({
      activeTab: ["activeTab"],
      dataView: ["dataView"],
      navToggleSelection: ["navToggleSelection"],
    }).off("update", onViewUpdate);
    Tree.watch({
      activeTab: ["activeTab"],
      dataView: ["dataView"],
      navToggleSelection: ["navToggleSelection"],
    }).on("update", onViewUpdate);
  };

  var onUnfilteredDataUpdate = function (e) {
    if (e.data.currentData) {
      updateToDoList(e.data);
    }
  };

  var onViewUpdate = function (e) {
    const data = e.target.get();
    if (data.navToggleSelection === "list" && data.activeTab === "todo") {
      loadTodosIfNecessary();
    }
  };

  var updateToDoList = async function (todosUpdateData) {
    if (todosUpdateData.previousData) {
      const preparedPreviousData = await prepareData(todosUpdateData.previousData);
      preparedToDoData = await prepareData(todosUpdateData.currentData);

      ToDoList.renderUpdate(
        sortPreparedData(preparedPreviousData),
        sortPreparedData(preparedToDoData),
      );
    } else {
      await _renderToDoList(todosUpdateData.currentData);
    }
  };

  var sortPreparedData = function (todos) {
    for (const subject in todos) {
      const sort = ToDoList.getSorting(subject);
      SortUtilities.sortRows(sort.key, sort.reversed, todos[subject], getSortersDict(subject));
    }

    return todos;
  };

  var prepareData = async function (todos) {
    todos = Misc.shallowCloneArrayObjects(todos);

    if ("bmp" === dataView) {
      return await prepareBmpTodosData(todos);
    } else if ("fcs" === dataView) {
      return await prepareFcsTodosData(todos);
    } else if (dataView === "construction-project") {
      return await prepareConstructionTodoData(todos);
    } else {
      return await prepareTodoData(todos);
    }
  };

  var addAlternativeSubjects = function (todos) {
    todos.forEach((todo) => {
      todo.alternativeSubjects = "alternativeSubjects" in todo ? todo.alternativeSubjects : [];
    });
  };

  var addSurvey123LinksToInstallationInspectionTodos = function (installationInspectionTodos) {
    installationInspectionTodos.forEach((installationTodo) => {
      installationTodo.addInstallationUrl = Survey123UrlBuilder.getBmpInstallationInspectionUrl(
        installationTodo.inspectionPrefillFields,
      );
    });
  };

  var addSurvey123LinksToInspectionTodos = async function (inspectionTodos) {
    for (const inspectionTodo of inspectionTodos) {
      if (inspectionTodo.subject === "inspection-due" || inspectionTodo.actionText === "Inspect") {
        await ConstructionProjectPopup.addInspectionButtonProps(inspectionTodo, inspectionTodo);
      }
    }
  };

  var addIconClassesToConstructionTodos = function (constructionTodos) {
    return constructionTodos.map((todo) => {
      return {
        ...todo,
        iconClass: ConstructionIcon.getConstructionProjectIconClass(todo.iconFields, true, true),
        bmpIconClass:
          todo.subject === "installation-inspection"
            ? BmpFcsIcon.getTableIcon(
                todo.bmpIconFields.score,
                todo.bmpIconFields.centralized,
                todo.bmpIconFields.isFcs,
                todo.bmpIconFields.isDue,
                todo.bmpIconFields.phase,
                todo.bmpIconFields.color,
              )
            : "",
      };
    });
  };

  var _renderToDoList = async function (todos) {
    preparedToDoData = await prepareData(todos);
    ToDoList.render(sortPreparedData(preparedToDoData));
  };

  var rerenderToDoList = async function () {
    ToDoList.render(sortPreparedData(preparedToDoData));
  };

  var prepareConstructionTodoData = async function (todos) {
    ConstructionToDoController.addPermissionsToConstructionTodos(todos);
    const groupedTodos = Misc.groupBy(addIconClassesToConstructionTodos(todos), "subject");
    await addSurvey123LinksToInspectionTodos(groupedTodos["inspection-due"] || []);
    await addSurvey123LinksToInspectionTodos(groupedTodos["complete-project"] || []);
    addSurvey123LinksToInstallationInspectionTodos(groupedTodos["installation-inspection"] || []);
    if (groupedTodos["open-enforcement"]?.length) {
      groupedTodos["open-enforcement"] = ConstructionToDoController.organizeOpenEnforcementTodos(
        groupedTodos["open-enforcement"],
      );
    }

    for (let apiSubject in groupedTodos) {
      const subject = ToDoFunctions.getToDoSubjectByApiSubjectName(apiSubject);
      if (subject) {
        groupedTodos[subject] = groupedTodos[apiSubject];
        delete groupedTodos[apiSubject];
      }
    }

    return groupedTodos;
  };

  var prepareTodoData = async function (todos) {
    const enabledSubjects = DataViewFunctions.getUserEnabledToDoSubjects(dataView);
    const result = {};

    for (const subject of enabledSubjects) {
      const subjectConfig = ToDoConfig.getToDoSubjectConfig([subject]);
      result[subject] = await prepareTodoSubject(todos, subject, subjectConfig);
    }

    return result;
  };

  var prepareTodoSubject = function (todos, subject, subjectConfig) {
    let subjectTodos = filterTodoSubject(todos, subject);

    if (subjectConfig.prepareDataCallback) {
      subjectTodos = subjectConfig.prepareDataCallback(subjectTodos);
    }

    return TableDataFunctions.prepareData(subjectTodos, Tree.get("dataView"), true);
  };

  var prepareBmpTodosData = async function (todos) {
    todos = filterOutAnnualInputsSettingsTodos(todos);
    addAlternativeSubjects(todos);
    BmpFcsToDoController.addBmpFcsRequiredFieldStrings(todos);
    addPropertiesByTodoAssetType(todos);
    addScoreColorClasses(todos);
    roundScores(todos);

    const routineSiteVisitBmp = filterTodoSubject(todos, "routineSiteVisitBmp");
    await addSurvey123LinksToInspectionTodos(routineSiteVisitBmp);

    const routineRepairBmp = filterTodoSubject(todos, "routineRepairBmp");
    BmpFcsToDoController.addRepairConditions(routineRepairBmp);
    await addSurvey123LinksToInspectionTodos(routineRepairBmp);

    const maintenanceNeededTodos = filterTodoSubject(todos, "maintenanceNeeded");

    const assessmentDueTodos = filterTodoSubject(todos, "assessmentDue");
    addAssessmentDueDateColorClass(assessmentDueTodos);
    addAssessmentType(assessmentDueTodos);

    const inventoryIncompleteTodos = filterTodoSubject(todos, "inventoryIncomplete");
    inventoryIncompleteTodos.forEach((todo) => {
      todo.numMissingRequiredFields = todo.requiredFields.length;
    });
    addBmpIconClassesToTodos(todos);

    return {
      routineSiteVisitBmp,
      maintenanceNeeded: maintenanceNeededTodos,
      assessmentDue: assessmentDueTodos,
      inventoryIncomplete: inventoryIncompleteTodos,
      routineRepairBmp,
    };
  };

  var prepareFcsTodosData = async function (todos) {
    todos = filterOutAnnualInputsSettingsTodos(todos);
    addAlternativeSubjects(todos);
    BmpFcsToDoController.addBmpFcsRequiredFieldStrings(todos);
    addPropertiesByTodoAssetType(todos);
    addScoreColorClasses(todos);
    roundScores(todos);

    const maintenanceNeededTodos = filterTodoSubject(todos, "maintenanceNeeded");

    const assessmentDueTodos = filterTodoSubject(todos, "assessmentDue");
    addAssessmentDueDateColorClass(assessmentDueTodos);
    addAssessmentType(assessmentDueTodos);

    const inventoryIncompleteTodos = filterTodoSubject(todos, "inventoryIncomplete");
    inventoryIncompleteTodos.forEach((todo) => {
      todo.numMissingRequiredFields = todo.requiredFields.length;
    });
    addFcsIconClassesToTodos(todos);

    return {
      maintenanceNeeded: maintenanceNeededTodos,
      assessmentDue: assessmentDueTodos,
      inventoryIncomplete: inventoryIncompleteTodos,
    };
  };

  var getSortersDict = function (subject) {
    const getSortersDictCallback = ToDoConfig.getToDoSubjectConfig(
      [subject],
      false,
    )?.getSortersDictCallback;

    if (getSortersDictCallback) {
      return getSortersDictCallback();
    }

    return getLegacySortsDict();
  };

  var getLegacySortsDict = function () {
    const dbDateStringSorter = DateTime.dbDateStringComparator;
    const dateSorter = DateTime.dateComparator;
    const numberSorter = SortUtilities.numberSorter;
    const alphaSorter = SortUtilities.alphaSorter;
    const booleanSorter = SortUtilities.booleanSorter;

    return {
      address: alphaSorter,
      streetAddress: alphaSorter,
      assessmentType: alphaSorter,
      bmpId: alphaSorter,
      bmpName: alphaSorter,
      "catchments.name": alphaSorter,
      category: alphaSorter,
      contact: alphaSorter,
      contractor: alphaSorter,
      correction: alphaSorter,
      currentEscalationLevel: alphaSorter,
      displayEnforcementReason: alphaSorter,
      displayLevel: alphaSorter,
      displayTypes: alphaSorter,
      due_date: dbDateStringSorter,
      dueDate: dbDateStringSorter,
      dueDateObj: dateSorter,
      dueDateIso: dbDateStringSorter,
      escalationStatus: alphaSorter,
      facilityName: alphaSorter,
      fine: numberSorter,
      followUpIsoDate: dbDateStringSorter,
      "highways.highwayName": alphaSorter,
      highPriorityYesNo: alphaSorter,
      highPriority: alphaSorter,
      illicitFlowMaterialActivityDisplayName: alphaSorter,
      incidentTypeDisplayName: alphaSorter,
      incompleteTask: alphaSorter,
      inProximityTo303dWaterbody: booleanSorter,
      cof: SortUtilities.cofSorter,
      inspectionType: alphaSorter,
      inspectionDate: dbDateStringSorter,
      issueDate: dateSorter,
      isPastDue: alphaSorter,
      issueType: alphaSorter,
      name: alphaSorter,
      ownerName: alphaSorter,
      lastLidNoticeProjectContactName: alphaSorter,
      lastInspectionDate: dbDateStringSorter,
      needsReview: booleanSorter,
      numMissingRequiredFields: numberSorter,
      obs_date: dbDateStringSorter,
      outfallName: alphaSorter,
      ownership: alphaSorter,
      priority: SortUtilities.cofSorter,
      projectIdentifier: alphaSorter,
      projectName: alphaSorter,
      requestedIsoDate: dbDateStringSorter,
      requestingAffiliationDisplayName: alphaSorter,
      requestingTypeDisplayName: alphaSorter,
      score: numberSorter,
      severity: alphaSorter,
      status: alphaSorter,
      zone: alphaSorter,
    };
  };

  var loadTodosIfNecessary = function (filters = null, onlyUnfiltered = true) {
    dataView = Tree.get("dataView");

    if (Tree.get("todos", dataView, "isFetching")) {
      return false;
    }
    if (!Tree.get("todos", dataView, "unfilteredData")) {
      loadTodos(filters, onlyUnfiltered);
      return false;
    }
    return true;
  };

  var loadTodos = function (filters = null, onlyUnfiltered = true) {
    dataView = Tree.get("dataView");

    if (Tree.get("todos", dataView, "isFetching")) {
      return null;
    }

    return Actions.loadTodos(dataView, filters, onlyUnfiltered);
  };

  var filterOutAnnualInputsSettingsTodos = function (todos) {
    return todos.filter((todo) => todo.subject !== "annual reporting settings");
  };

  var openIncompleteInventoryModalForBmp = function (idBmp) {
    BmpFcsInventoryModalController.invokeBmpInventoryModal(idBmp);
  };

  var addPropertiesByTodoAssetType = function (todos) {
    var scoreProp = ToDoFunctions.getTodoPropertyNameByAssetType("score");
    var daysRemaningProp = ToDoFunctions.getTodoPropertyNameByAssetType("days_remaining");
    var obsDateProp = ToDoFunctions.getTodoPropertyNameByAssetType("obs_date");
    var dueDateProp = ToDoFunctions.getTodoPropertyNameByAssetType("due_date");

    todos.forEach((todo) => {
      todo.score = todo[scoreProp];
      todo.days_remaining = todo[daysRemaningProp];
      todo.obs_date = todo[obsDateProp];
      todo.noObsDate = todo.obs_date === null;
      todo.due_date = todo[dueDateProp];
    });
  };

  var addBmpIconClassesToTodos = function (todos) {
    todos.forEach((todo) => {
      todo.tableIcon = MapStyles.getBmpTodoIconClasses(todo);
    });
  };

  var addFcsIconClassesToTodos = function (todos) {
    todos.forEach((todo) => {
      todo.tableIcon = MapStyles.getFcsTodoIconClasses(todo);
    });
  };

  var addScoreColorClasses = function (todos) {
    todos.forEach((todo) => {
      if (todo.score === undefined || todo.score === null) {
        todo.scoreBold = false;
      } else {
        todo.scoreColor = MapStyles.getConditionColor(+todo.score);
        todo.scoreBold = true;
      }
    });
  };

  var roundScores = function (todos) {
    todos.forEach((todo) => {
      if (todo.score !== null && todo.score !== undefined) {
        todo.scoreRounded = (+todo.score).toLocaleString(undefined, {
          minimumFractionDigits: 1,
          maximumFractionDigits: 1,
        });
      }
    });
  };

  var addAssessmentDueDateColorClass = function (todos) {
    todos.forEach((todo) => {
      if (todo.days_remaining === undefined) return;
      if (todo.days_remaining === null || +todo.days_remaining < 1) {
        todo.assessmentDueDateColorClass = "red";
      } else {
        todo.assessmentDueDateColorClass = "";
      }
    });
  };

  var filterTodoSubject = function (todos, subject) {
    return todos.filter((todo) => todo.toDoSubjects.includes(subject));
  };

  var addAssessmentType = function (todos) {
    var dataView = Tree.get("dataView");
    if (dataView === "fcs") {
      addAssessmentTypeForFcsTodos(todos);
    } else if (dataView === "bmp") {
      addAssessmentTypeForBmpTodos(todos);
    }
  };

  var addAssessmentTypeForFcsTodos = function (todos) {
    todos.forEach((todo) => {
      if (todo.subject === "assessment due") {
        todo.assessmentType = "FCS Assessment";
        todo.assessmentTypeKey = "fcs";
      }
    });
  };

  var addAssessmentTypeForBmpTodos = function (todos) {
    todos.forEach((todo) => {
      if (todo.subject === "assessment due") {
        todo.skipBenchmark = BmpFunctions.getSkipBenchmark(todo);
        if ((todo.currentBenchmarkId && todo.currentBenchmarkComplete) || todo.skipBenchmark) {
          todo.assessmentType = "Assessment";
          todo.assessmentTypeKey = "bmp";
        } else {
          todo.assessmentType = "Benchmark";
          todo.assessmentTypeKey = "benchmark";
        }
      }
    });
  };

  var openFactSheet = function () {
    const id = ToDoList.getIdFromRow($(this));
    openFactSheetTo(id);
  };

  var openFactSheetTo = function (id, highlightId = null) {
    $("#details-button-container").addClass("desktop-only");
    ToDoConfig.getToDoDataViewConfig([dataView, "genericFactSheet"]).initFactSheet(id, highlightId);
  };

  return {
    init,
    loadTodos,
    rerenderToDoList,
    openIncompleteInventoryModalForBmp,
    openFactSheetTo,
    loadTodosIfNecessary,
    _renderToDoList,
  };
};

module.exports = ToDoListController();

const Actions = require("../actions");
const BmpFcsIcon = require("../bmpfcs/bmpFcsIcon");
const BmpFcsInventoryModalController = require("../bmpfcs/bmpFcsInventoryModalController");
const BmpFcsToDoController = require("../bmpfcs/bmpFcsToDoController");
const BmpFunctions = require("../modals/bmpFunctions");
const ConstructionIcon = require("../construction/constructionIcon");
const ConstructionProjectPopup = require("../construction/constructionProjectPopup");
const ConstructionToDoController = require("../construction/constructionToDoController");
const DataViewFunctions = require("../dataViewFunctions");
const DateTime = require("../dateTime");
const SortUtilities = require("../general/sortUtilities");
const MapStyles = require("./mapStyles");
const Misc = require("../misc");
const Survey123UrlBuilder = require("../construction/survey123UrlBuilder");
const TableDataFunctions = require("../general/tableDataFunctions");
const ToDoFunctions = require("./toDoFunctions");
const ToDoList = require("./toDoList");
const Tree = require("../tree");
const ToDoConfig = require("../config/toDoConfig");
