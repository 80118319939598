"use strict";

const LidEnforcementController = require("./lidEnforcementController");
const BaseEnforcementAction = require("../../enforcement/baseEnforcementAction");

const EnforcementAction = function () {
  var getPropsFunction = function (data) {
    const failingBmpAssessmentsOptions = _getfailingBmpAssessmentsOptions(data);
    const openIssuesOptions = _getOpenIssuesOptions(data);

    return {
      levelOptions: FormSettings.getDropdownOptions("level", { tool: "indcom", sort: false }),
      enforcementReasonOptions: getEnforcementReasonOptions(data),
      openIssuesOptions,
      failingBmpAssessmentsOptions,
      openIssues: data.openIssues,
      failingBmpAssessments: data.failingBmpAssessments,
    };
  };

  var getEnforcementReasonOptions = function (data) {
    const options = FormSettings.getDropdownOptions("enforcement-reason", { tool: "lid" });

    return options.filter((option) => {
      return !(
        (option.value === "open-issues" && !data?.openIssuesOptions?.length) ||
        (option.value === "failed-sbmp-assessment" && !data?.failingBmpAssessmentsOptions?.length)
      );
    });
  };

  var _getfailingBmpAssessmentsOptions = function (data) {
    const options = data?.failingBmpAssessmentsOptions?.map((observation) => {
      return {
        name: observation.display,
        value: observation.observationId,
      };
    });

    return { "": options };
  };

  var _getOpenIssuesOptions = function (data) {
    const openIssues = data?.openIssuesOptions ?? [];
    const bmps = {};

    openIssues.forEach((bmp) => {
      const bmpDisplay = _getBmpDisplay(bmp);
      bmps[bmpDisplay] = bmp.issues.map((issue) => {
        return {
          name: getOpenIssueDisplay(issue),
          value: issue.id,
        };
      });
    });

    return bmps;
  };

  var _getBmpDisplay = function (bmp) {
    return `${bmp.bmpId} — ${bmp.bmpTypeName}`;
  };

  var getOpenIssueDisplay = function (issue, forHistory = false) {
    const tasks = ToolSettings.getSetting("constants", "maintenanceTasks");
    let displayName = tasks[issue.key]?.displayName ?? "";
    if (forHistory) {
      displayName = _getBmpDisplay(issue.bmp) + " — " + displayName;
    }
    return displayName + (issue.resolved ? " (resolved)" : "");
  };

  const base = BaseEnforcementAction(LidEnforcementController, { getPropsFunction });

  return {
    ...base,
    getEnforcementReasonOptions,
    getOpenIssueDisplay,
  };
};

module.exports = EnforcementAction();

const FormSettings = require("../../settings/formSettings");
const ToolSettings = require("../../settings/toolSettings");
