"use strict";

const IndcomFacilityPopup = function () {
  var getPopupHtml = function (data) {
    var properties = {
      ...data,
      headerColorClass: getBackgroundColorClass(data),
    };
    properties.permissions = UserPermissions.getAllPermissions();
    return PopupContent.getLayerPopup(properties, "indcomFacilities");
  };

  var getIndcomFacilityPopupData = async function (id) {
    const popupData = await ApiCalls.getFacilityPopupData(id);
    return popupData;
  };

  var getBackgroundColorClass = function (data, isTodo = false) {
    let color = "grey";

    if (Tree.get(["filters", "conditionPhaseToggle"]) === "phase" && !isTodo) {
      if (data.facilityPhase === "closed") {
        color = "black";
      } else {
        color = "white";
      }
    } else {
      if (data.indcomFacilityInspection?.isDue) {
        color = "blue";
      } else if (data.hasInspections) {
        if (data.hasOpenFindings) {
          color = "red";
        } else {
          color = "green";
        }
      } else {
        color = "grey";
      }
    }

    return `background-${color}`;
  };

  var onPopupOpenWithData = function (data, $element, invalidateLayerDataFunction) {
    var onPopupDelete = function () {
      Analytics.sendPopupEvent("delete_facility", "indcom");
      deleteFacility(data.id, data.facilityName, invalidateLayerDataFunction);
    };

    $element.off("click", ".popup-delete-button", onPopupDelete);
    $element.on("click", ".popup-delete-button", onPopupDelete);
  };

  var deleteFacility = function (id, name, invalidateLayerDataFunction) {
    MessageModal.showConfirmWarningModal(
      `Delete Facility ${name}? Click "Delete" to permanently delete the Facility. Click "Cancel" to return.`,
      async function () {
        await ApiCalls.deleteFacilityFactSheet(id);
        invalidateLayerDataFunction();
        closePopup();
      },
      "Cancel",
      "Delete",
    );
  };

  var closePopup = function () {
    const closeButton = $(".leaflet-popup-close-button");
    if (closeButton.length > 0) {
      closeButton[0].click();
    }
  };

  var loadPopupPhotosAndFiles = function (id) {
    PopupPhotos.load("indcomFacilities", id);
  };

  var loadFacilityHistory = function (historyData) {
    FacilityHistory.renderFacilityHistory(historyData);
  };

  var onPopupOpen = async function (properties) {
    const id = properties.id;
    var data = await getIndcomFacilityPopupData(id);
    const html = getPopupHtml(data);
    const $popup = $(".leaflet-popup-content");
    $popup.html(html);
    var layerName = "indcomFacilities";
    const layerModule = LayerFunctions.getLayerModule(layerName);
    loadFacilityHistory(data.history);
    renderBarGraph(data?.inspectionsForBarGraph);
    onPopupOpenWithData(data, $popup, layerModule.invalidateLayerData);
    loadPopupPhotosAndFiles(id);
  };

  var renderBarGraph = function (arrayForBarGraph) {
    PopupContent.setPopupConditionBar($(`.tr-indcome-score`), arrayForBarGraph, function (datum) {
      return datum.numericScore;
    });
  };

  return {
    getPopupHtml,
    onPopupOpen,
    getIndcomFacilityPopupData,
    getBackgroundColorClass,
    renderBarGraph,
  };
};

module.exports = IndcomFacilityPopup();

const ApiCalls = require("../apiCalls");
const FacilityHistory = require("./facilityHistory");
const Tree = require("../tree");
const PopupContent = require("../mapFunctions/popupContent");
const LayerFunctions = require("../layerFunctions");
const MessageModal = require("../modals/messageModal");
const PopupPhotos = require("../mapFunctions/popupPhotos");
const UserPermissions = require("../login/userPermissions");
const Analytics = require("../general/analytics");
