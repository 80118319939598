"use strict";

const ApiKeyManagement = function () {
  const key = "api-key-management";
  const option = "API Keys";
  const iconClass = "key";
  let $page;
  let $createKeyModal;
  let props = [];

  function loadListeners() {
    $page = SettingsModalController.getParentPage();

    $page.off("click", `input[name="active-toggle"]`, toggleActive);
    $page.on("click", `input[name="active-toggle"]`, toggleActive);

    $page.off("click", ".delete-button", deleteKeyConfirm);
    $page.on("click", ".delete-button", deleteKeyConfirm);

    $page.off("click", ".new-key-button", showCreateKeyModal);
    $page.on("click", ".new-key-button", showCreateKeyModal);
  }

  function loadCreateKeyListeners() {
    $createKeyModal = $("#create-key-modal");

    $createKeyModal.off("click", ".key_copy", copy);
    $createKeyModal.on("click", ".key_copy", copy);

    $createKeyModal.off("click", ".download-key", downloadKey);
    $createKeyModal.on("click", ".download-key", downloadKey);

    $createKeyModal.off("click", ".close-button", hideCreateKeyModal);
    $createKeyModal.on("click", ".close-button", hideCreateKeyModal);
  }

  async function createKey() {
    const createdKeys = await ApiCalls.createApiKey();
    if (createdKeys.error) {
      MessageModal.showWarningModal(createdKeys.error.message);
      return;
    }
    return createdKeys;
  }
  async function showCreateKeyModal() {
    const createdKey = await createKey();
    if (!createdKey) {
      return;
    }
    const $createKeyModal = $("#create-key-modal");
    $createKeyModal.html(
      nunjucks.render("modals/settingsModal/apiKeyManagementCreateKey.njk", {
        name: createdKey.accessKey.substring(0, 5),
        accessKey: createdKey.accessKey,
        secretKey: createdKey.secretKey,
      }),
    );
    loadCreateKeyListeners();
    $createKeyModal.modal("show");
  }

  function copy() {
    navigator.clipboard.writeText(this.parentElement.innerText.trim());

    $(this).next().removeClass("hidden");
  }

  function downloadKey() {
    const name = $createKeyModal.find("input[name=name]").val();
    const accessKey = $createKeyModal.find(".access_key")[0].innerText.trim();
    const secretKey = $createKeyModal.find(".secret_key")[0].innerText.trim();
    const filename = `${name}-2n_key.json`;
    const text = `{\n"access_key": "${accessKey}",\n"secret_key": "${secretKey}"\n}`;
    const blob = new Blob([text], { type: "application/json" });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = filename;
    a.click();
    URL.revokeObjectURL(url);
  }

  var toggleActive = async function () {
    const enabled = !!this.checked;
    const accessKey = this.value;
    await ApiCalls.updateApiKey(accessKey, !enabled);
    $(this).closest("tr").toggleClass("enabled", enabled);
  };

  var setName = async function () {
    const accessKey = $createKeyModal.find(".access_key")[0].innerText.trim();
    const name = $createKeyModal.find("input[name=name]").val();
    await ApiCalls.updateApiKey(accessKey, null, name);
  };

  var deleteKeyConfirm = async function () {
    const name = $(this.parentElement).find(".name-col")[0].innerHTML;
    const accessKey = $(this.parentElement).find(".key-col")[0].innerHTML;
    MessageModal.showConfirmWarningModal(
      null,
      deleteKey,
      "Cancel",
      "Delete",
      `<p>Are you sure you want to delete access key?</br><strong>Name:</strong> ${name}</br> <strong>Key: </strong>${accessKey}</p>`,
      () => {},
      "Delete API Key",
      "modal-sm",
      accessKey,
    );
  };

  var deleteKey = async function (accessKey) {
    await ApiCalls.deleteApiKey(accessKey);
    rerenderKeyList(true);
  };

  async function getProps() {
    const apiKeys = await ApiCalls.getApiKeys();
    props = { apiKeys };
    return props;
  }

  function getMenuKey() {
    return key;
  }

  function getMenuOption() {
    return option;
  }

  function getMenuIcon() {
    return iconClass;
  }

  async function rerenderKeyList({ refresh = true } = {}) {
    if (refresh) {
      await getProps();
      const existingProps = SettingsModalController.getExistingProps();
      existingProps.apiKeys = props.apiKeys;
      SettingsModalController.updateExistingProps(props);
    }
    $("#keys-tbody").html(
      nunjucks.render("modals/settingsModal/apiKeyManagementDatum.njk", {
        props: {
          apiKeys: props.apiKeys,
        },
      }),
    );
  }

  async function hideCreateKeyModal(refresh = false) {
    await setName();
    $("#create-key-modal").modal("hide");
    if (refresh) {
      rerenderKeyList(true);
    }
  }

  function onShow() {
    return;
  }

  function onClose() {
    hideCreateKeyModal();
  }

  return {
    getProps,
    getMenuKey,
    getMenuOption,
    getMenuIcon,
    onClose,
    onShow,
    loadListeners,
  };
};

module.exports = ApiKeyManagement();

const ApiCalls = require("../../apiCalls");
const SettingsModalController = require("../settingsModalController");
const MessageModal = require("../../modals/messageModal");
