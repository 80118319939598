"use strict";

const OutfallFunctions = function () {
  var isOutfallDataView = function (dataView) {
    const outfallDataViews = ["outfalls", "muni-outfalls"];
    return outfallDataViews.includes(dataView);
  };

  var invalidateCurrentOutfallLayerWithoutLoadingScreen = function () {
    return invalidateOutfallLayerDataByDataView(undefined, false);
  };

  var invalidateOutfallLayerDataByDataView = function (
    dataView = Tree.get("dataView"),
    loadingScreen = true,
  ) {
    const dataViewConfig = DataViewFunctions.getDataViewConfig(dataView);

    if (dataView === "dry-weather") {
      DryWeatherFunctions.invalidateAllDryWeatherLayersData();
    } else if (isOutfallDataView(dataView)) {
      LayerFunctions.getLayerModule(dataViewConfig.defaultLayers[0]).invalidateLayerData(
        loadingScreen,
      );
    } else {
      LayerFunctions.getLayerModule("outfallsSimple").invalidateLayerData();
    }

    if (dataView === "wq-monitoring") {
      WqMonitoringLocationGeoServerLayer.invalidateLayerData();
    }
  };

  return {
    isOutfallDataView,
    invalidateOutfallLayerDataByDataView,
    invalidateCurrentOutfallLayerWithoutLoadingScreen,
  };
};

module.exports = OutfallFunctions();

const DataViewFunctions = require("../dataViewFunctions");
const DryWeatherFunctions = require("../illicitDischarge/dryWeatherFunctions");
const LayerFunctions = require("../layerFunctions");
const Tree = require("../tree");
const WqMonitoringLocationGeoServerLayer = require("../wq/wqMonitoringLocationGeoServerLayer");
