"use strict";

const InventoryModal = function () {
  let inited = false;
  let $inventoryModal;
  let $modalMap;
  let modalPages = [];
  let activePage;
  let saveable;
  let hideInventoryOnClose = true;
  let excludeMap = false;

  var loadInventoryModalListeners = function () {
    $inventoryModal.off("click", ".add-new-next-button");
    $inventoryModal.on("click", ".add-new-next-button", setNextPageActive);

    $inventoryModal.off("click", ".add-new-back-button");
    $inventoryModal.on("click", ".add-new-back-button", setPreviousPageActive);

    $inventoryModal.off("click", ".steps li:not(.active)");
    $inventoryModal.on("click", ".steps li:not(.active)", _setTabPageActive);

    $inventoryModal.off("click", ".add-new-cancel-button");
    $inventoryModal.on("click", ".add-new-cancel-button", cancelAndClose);

    $inventoryModal.off("click", ".add-new-save-button");
    $inventoryModal.on("click", ".add-new-save-button", saveAndClose);
  };

  var init = function () {
    if (inited) return;
    renderInventoryModal();
    loadInventoryModalListeners();

    initializeSaveability();
    inited = true;
  };

  var initializeSaveability = function () {
    saveable = {
      ready: false,
      allowed: false,
    };
  };

  var showModal = async function (inputPages, startingPage, options, handlers) {
    excludeMap = options && options.hasOwnProperty("excludeMap") && options.excludeMap;
    init();
    resetInventorySteps();
    setupInventorySteps(inputPages);
    setHandlers(handlers);
    if (!excludeMap) {
      ModalMap.initializeModalMap();
    }
    show();
    let renderPromise = null;
    if (inputPages.length > 0) {
      if (startingPage) {
        renderPromise = setActivePageByKey(startingPage, options);
      } else {
        renderPromise = setActivePageByIndex(0, options);
      }
    }
    return renderPromise;
  };

  var setHandlers = function (handlers) {
    if (!handlers) return;
    if (handlers.saveAndCloseHandler) {
      saveAndCloseHandler = handlers.saveAndCloseHandler;
    }
    if (handlers.cancelHandler) {
      cancelHandler = handlers.cancelHandler;
    }
    if (handlers.formHasUpdatesHandler) {
      formHasUpdates = handlers.formHasUpdatesHandler;
    }
    if (handlers.onPageChangeHandler) {
      onPageChange = handlers.onPageChangeHandler;
    }
  };

  var renderInventoryModal = function () {
    $inventoryModal = $("#inventory-modal");
    $inventoryModal.html(
      nunjucks.render("modals/inventory/inventoryModal.njk", {
        scenarios: Tree.get("dataView") === "scenarios",
      }),
    );
    $modalMap = $inventoryModal.find("#modalMap");
    $modalMap.hide();

    resetModalTitle();
  };

  var removePage = function (pages, pageToRemove) {
    const index = pages.findIndex((page) => page === pageToRemove);
    if (index !== -1) {
      pages.splice(index, 1);
    }
    return pages;
  };

  var setupInventorySteps = function (inputPages) {
    modalPages = inputPages;
    renderInventorySteps();
  };

  var removeInventoryStep = function (removePage) {
    const index = modalPages.findIndex((page) => page === removePage);
    if (index !== -1) {
      modalPages.splice(index, 1);
      resetInventorySteps();
      renderInventorySteps();
      showNavigationButtons();
    }
  };

  var insertInventoryStep = function (position, page) {
    const index = modalPages.findIndex((existingPage) => existingPage === page);
    if (index === -1) {
      modalPages.splice(position - 1, 0, page);
      $inventoryModal.find(".steps").empty();
      renderInventorySteps();
      showNavigationButtons();
    }
  };

  var renderInventorySteps = function () {
    const $headerSteps = $inventoryModal.find(".steps");
    const currentlyActivePage = getActivePageStringKey();

    modalPages.forEach((pageModule, index) => {
      const stringKey = pageModule.getStringKey();
      const pageHeaderInfoStr =
        stringKey === "bulk-select-page" ? "" : pageModule.getHeaderInformation();

      $(`<li data-string-key="${stringKey}">
           <a>${pageHeaderInfoStr}</a>
         </li>`)
        .toggleClass("active", stringKey === currentlyActivePage)
        .appendTo($headerSteps);
    });
  };

  var resetInventorySteps = function () {
    const $headerSteps = $inventoryModal.find(".steps");
    $headerSteps.empty();
  };

  var setModalTitle = function (title, data = null, titleId = "default") {
    const hasTitleData = data !== null;
    var $title = getModalTitleById(titleId);

    if ($title.length === 0) {
      addModalTitle(titleId);
      $title = getModalTitleById(titleId);
    }

    $title.toggleClass("data-title", hasTitleData);

    $title.html(title);

    if (hasTitleData) {
      $title.append(`<span class="data">${data}</span>`);
      $title.attr("title", data);
    } else {
      $title.removeAttr("title");
    }
  };

  var getModalTitleById = function (titleId) {
    return $inventoryModal?.find(`.modal-title-group [data-id="${titleId}"]`);
  };

  var addModalTitle = function (titleId) {
    $inventoryModal
      .find(`.modal-title-group`)
      .append(`<h4 class="modal-title" data-id="${titleId}"></h4>`);
  };

  var resetModalTitle = function () {
    $inventoryModal?.find(".modal-title-group").empty();
    setModalTitle("");
  };

  var setModalDynamicContent = function (html) {
    const $dynamicContent = $inventoryModal.assertLength(1).find(".modal-dynamic-content");
    $dynamicContent.html(html);

    const formKey = getOpenInventory();
    FormSettings.customizeForm($dynamicContent, formKey);

    return $dynamicContent;
  };

  var setActivePageByIndex = async function (pageIndex, renderOptions) {
    if (!assertPageIndexExists(pageIndex)) return;
    return setActivePage(
      modalPages[pageIndex],
      pageIndex === 0,
      pageIndex === modalPages.length - 1,
      renderOptions,
    );
  };

  var setActivePage = async function (pageModule, isFirst, isLast, renderOptions) {
    Analytics.sendScreenView("inventory", pageModule.getStringKey());
    _addDataListAnalytics(
      renderOptions?.actionKey ? renderOptions?.actionKey : renderOptions?.existingLocation,
    );
    const $steps = $inventoryModal.find(".steps");
    const stringKey = pageModule.getStringKey();
    $steps.find("li").removeClass("active");
    $steps.find(`[data-string-key=${stringKey}]`).addClass("active");
    toggleBackButton(!isFirst);
    toggleNextButton(!isLast);
    activePage = pageModule;
    await pageModule.render(renderOptions);
    $("#inventory-modal").attr("data-page", stringKey);
    onPageChange();
  };

  var _addDataListAnalytics = function (actionDecider) {
    const currentDataView = Tree.get("dataView");
    const currentView = Tree.get("navToggleSelection");

    if (currentView === "list" && actionDecider === false) {
      Analytics.sendDataListEvent(
        "add_new_asset",
        currentDataView
          .replace("construction-project", "construction")
          .replace("construction-delivery", "project-delivery"),
      );
    } else if (currentView === "list" && actionDecider === "catchBasinBulkClean") {
      Analytics.sendDataListEvent("bulk_clean", currentDataView);
    }
  };

  var toggleBackButton = function (toggleBool) {
    $inventoryModal.find(".add-new-back-button").toggle(toggleBool);
  };

  var toggleNextButton = function (toggleBool) {
    $inventoryModal.find(".add-new-next-button").toggle(toggleBool);
  };

  var setActivePageByKey = async function (pageKey, renderOptions) {
    const pageIndex = getPageIndex(pageKey);
    return setActivePageByIndex(pageIndex, renderOptions).catch((error) => {
      console.error(error);
    });
  };

  var getPageIndex = function (pageKey) {
    if (!assertPageWithKeyExists(pageKey)) return;
    return modalPages
      .map((pageModule) => {
        return pageModule.getStringKey();
      })
      .indexOf(pageKey);
  };

  var assertPageIndexExists = function (pageIndex, errorIfDoesNotExist = true) {
    if (isNaN(pageIndex) && errorIfDoesNotExist) {
      console.error(`Can't get page with index ${pageIndex}.`);
    }
    if (modalPages[pageIndex] === undefined) {
      if (errorIfDoesNotExist) {
        console.error(`Inventory modal page with index ${pageIndex} does not exist.`);
      }
      return false;
    }
    return true;
  };

  var assertPageWithKeyExists = function (pageKey) {
    if (typeof pageKey !== "string") {
      console.error(`pageKey must be a string, was ${typeof pageKey}.`);
    }
    if (!modalPages.some((page) => page.getStringKey() === pageKey)) {
      console.error(`Inventory modal page with string key ${pageKey} does not exist.`);
      return false;
    }
    return true;
  };

  var isBmpFcsInventoryModalFormat = function () {
    const assetPathTools = ["bmpram", "trashram", "bmp-fcs-inventory"];
    return assetPathTools.includes(getOpenInventory());
  };

  var activePageReadyToChange = async function () {
    const isValid = await activePage.validate();

    return isValid && activePage.cleanUp();
  };

  var setNextPageActive = async function () {
    const nextPageIndex = getPageIndex(activePage.getStringKey()) + 1;

    if (!assertPageIndexExists(nextPageIndex, false)) {
      return;
    }

    const ready = await activePageReadyToChange();
    if (ready) {
      return setActivePageByIndex(nextPageIndex, { existingLocation: true });
    }
  };

  var setPreviousPageActive = async function () {
    const ready = await activePageReadyToChange();
    if (ready) {
      const activePageIndex = getPageIndex(activePage.getStringKey());
      return setActivePageByIndex(activePageIndex - 1, { existingLocation: true });
    }
  };

  var _setTabPageActive = async function () {
    const ready = await activePageReadyToChange();
    if (ready) {
      const stringKey = $(this).closest("li").data("stringKey");
      return setActivePageByKey(stringKey, { existingLocation: true });
    }
  };

  var cancelAndClose = function () {
    var onCancelFn = () => {
      activePage.cleanUp();
      if (hideInventoryOnClose) {
        cancelHandler();
        hide();
        loadLayerIfNecessary();
      } else {
        cancelHandler();
      }
    };
    if (!formHasUpdates()) {
      onCancelFn();
      return;
    }
    MessageModal.showConfirmWarningModal(
      null,
      onCancelFn,
      "Go Back",
      "Close Without Saving",
      CommonModalFunctions.getCloseWithoutSavingPrompt(),
    );
  };

  var loadLayerIfNecessary = function () {
    const dataView = Tree.get("dataView");
    const currentTab = Tree.get("activeTab");
    const isPopupOpen = $(".leaflet-popup-close-button")?.length >= 1;

    if (dataView === "lid-project" && currentTab === "todo" && isPopupOpen) {
      ProjectInventoryModalController.reopenPopupForUpdatedData(dataView);
    }
    if (dataView === "lid-project" && currentTab === "todo" && !isPopupOpen) {
      LidProjectLayer.loadLayer("main");
    }
  };

  var formHasUpdates = function () {
    throw new Error("formHasUpdates was called before being configured.");
  };

  var saveAndCloseHandler = async function () {
    throw new Error("Save and close handler was called before being configured.");
  };

  var cancelHandler = function () {
    throw new Error("Cancel handler was called before being configured.");
  };

  var onPageChange = function () {
    return;
  };

  var saveAndClose = async function () {
    const isValid = await activePage.validate();
    if (isValid) {
      if (formHasUpdates()) {
        disableAllButtons();
        setSaveButtonTextToSaving();
      }

      return Misc.assertPromise(saveAndCloseHandler())
        .then((hideModal = true) => {
          if (hideModal) {
            hide();
          } else {
            restoreAllButtons();
          }
        })
        .catch((err) => {
          throw err;
        });
    }
  };

  var getAllData = function (stringKey) {
    const assetAdditionalUpdates = Tree.get("assetAdditionalUpdates", stringKey) || {};
    const asset = Tree.get("asset", stringKey) || {};
    const formData = Form.getDataFromForm(stringKey, false) || {};
    return $.extend(true, {}, asset, assetAdditionalUpdates, formData);
  };

  var getLatestData = function (base, path) {
    let formUpdate = null;
    if (Form.isInitialized(base)) {
      formUpdate = Form.getFormDataAtPath(base, path);
    }
    const returnItem =
      formUpdate ??
      Tree.get(["assetAdditionalUpdates", base].concat(path)) ??
      Tree.get(["asset", base].concat(path));

    if ($.isArray(returnItem)) {
      return $.extend(true, [], returnItem);
    } else if (returnItem === null) {
      return null;
    } else if (typeof returnItem === "object") {
      return $.extend(true, {}, returnItem);
    }
    return returnItem;
  };

  var setSaveButtonTextToSaving = function () {
    $inventoryModal.find(".add-new-save-button").text("Saving...");
  };

  var restoreSaveButton = function () {
    Misc.toggleDisabled($inventoryModal.find(".add-new-save-button"), false);
    $inventoryModal.find(".add-new-save-button").show();
    $inventoryModal.find(".add-new-save-button").text("Save & Close");
  };

  var disableSaveButton = function () {
    Misc.toggleDisabled($inventoryModal.find(".add-new-save-button"), true);
  };

  var disableCancelButton = function () {
    Misc.toggleDisabled($inventoryModal.find(".add-new-cancel-button"), true);
  };

  var restoreCancelButton = function () {
    Misc.toggleDisabled($inventoryModal.find(".add-new-cancel-button"), false);
    $inventoryModal.find(".add-new-cancel-button").show();
  };

  var disableNavigationButtons = function () {
    Misc.toggleDisabled($inventoryModal.find(".add-new-back-button"), true);
    Misc.toggleDisabled($inventoryModal.find(".add-new-next-button"), true);
    $inventoryModal.find(".steps li").toggleClass("disabled", true);
  };

  var restoreNavigationButtons = function () {
    Misc.toggleDisabled($inventoryModal.find(".add-new-back-button"), false);
    Misc.toggleDisabled($inventoryModal.find(".add-new-next-button"), false);
    $inventoryModal.find(".steps li").toggleClass("disabled", false);
    showNavigationButtons();

    // Can't (".steps li").show() because they might be
    // intentionally hidden on smaller screens.
  };

  var showNavigationButtons = function () {
    if (!isFirstPageActive()) {
      $inventoryModal.find(".add-new-back-button").show();
    }
    if (!isLastPageActive()) {
      $inventoryModal.find(".add-new-next-button").show();
    }
  };

  var restoreAllButtons = function () {
    restoreButtonsToActive();
    restoreSaveButton();
  };

  var restoreButtonsToActive = function () {
    restoreCancelButton();
    restoreNavigationButtons();
  };

  var disableAllButtons = function () {
    disableSaveButton();
    disableNavigationButtons();
    disableCancelButton();
  };

  var show = function () {
    $inventoryModal.modal("show");
  };

  var cleanUpPage = function () {
    activePage.cleanUp();
  };

  var hide = function () {
    cleanUpPage();
    Tree.unset("assetAdditionalUpdates");
    Tree.unset("asset");
    if (!excludeMap) {
      ModalMap.resetModalMap();
    }
    restoreButtonsToActive();
    restoreSaveButton();
    initializeSaveability();
    setOpenInventory(null);
    $inventoryModal.modal("hide");
    AddBmpsTable.clearAddBmpsTable();
    Analytics.sendScreenView();
  };

  var readySaveState = function (state) {
    saveable.ready = state;
    saveable.ready && saveable.allowed ? restoreSaveButton() : disableSaveButton();
  };

  var allowedSaveState = function (state) {
    saveable.allowed = state;
    saveable.ready && saveable.allowed ? restoreSaveButton() : disableSaveButton();
  };

  var getActivePageStringKey = function () {
    if (activePage) {
      return activePage.getStringKey();
    }
    return null;
  };

  var toggleUploadHint = function (toggle) {
    $(".upload-hint").toggle(toggle);
  };

  var isFirstPageActive = function () {
    return $inventoryModal.find(".steps li").first().hasClass("active");
  };

  var isLastPageActive = function () {
    return $inventoryModal.find(".steps li").last().hasClass("active");
  };

  var setOpenInventory = function (inventoryKey) {
    Tree.set(["asset", "openInventory"], inventoryKey);
  };

  var getOpenInventory = function () {
    const openInventory = Tree.get("asset", "openInventory");
    return openInventory ? openInventory : Tree.get("tool");
  };

  var setHideInventoryOnClose = function (hide) {
    hideInventoryOnClose = hide;
  };

  var newInventory = function () {
    if (!$("#modalMap").is(":visible") && $(this).data("action") === "add-new") {
      var tool = Tree.get("tool");
      var dataView = Tree.get("dataView");
      if (
        tool === "bmpram" ||
        tool === "trashram" ||
        dataView === "muni-catch-basin" ||
        dataView === "muni-bmp" ||
        dataView === "lid-bmp"
      ) {
        BmpFcsInventoryModalController.invokeBmpInventoryModal(null);
      } else if (tool === "construction" || tool === "lid" || tool === "projdelivery") {
        return ProjectInventoryModalController.invokeProjectInventoryModal();
      } else if (OutfallFunctions.isOutfallDataView(dataView)) {
        return OutfallInventoryModalController.invokeOutfallInventoryModal(null, {});
      } else if (dataView === "incidents") {
        return IncidentInventoryModalController.invokeIncidentInventoryModal(null, {});
      } else if (dataView === "activities") {
        return ActivityInventoryModalController.invokeActivityInventoryModal(null, {});
      } else if (dataView === "indcom-facilities") {
        return IndcomInventoryModalController.invokeIndcomInventoryModal(null, {});
      } else if (dataView === "sampling-site") {
        return SamplingSiteInventoryModalController.invokeSamplingSiteInventoryModal(null, {});
      } else {
        throw Error(`Unknown tool ${tool} for inventory.`);
      }
    }
  };

  var getModal = function () {
    return $inventoryModal;
  };

  return {
    showModal,
    setModalTitle,
    resetModalTitle,
    setActivePageByKey,
    setModalDynamicContent,
    setSaveButtonTextToSaving,
    restoreSaveButton,
    disableSaveButton,
    restoreAllButtons,
    restoreButtonsToActive,
    disableAllButtons,
    disableNavigationButtons,
    restoreNavigationButtons,
    insertInventoryStep,
    getAllData,
    getLatestData,
    isBmpFcsInventoryModalFormat,
    readySaveState,
    allowedSaveState,
    saveAndClose,
    setNextPageActive,
    setPreviousPageActive,
    _setTabPageActive,
    cleanUpPage,
    hide,
    cancelAndClose,
    getActivePageStringKey,
    toggleUploadHint,
    removeInventoryStep,
    setOpenInventory,
    getOpenInventory,
    setHideInventoryOnClose,
    newInventory,
    removePage,
    getModal,
    _addDataListAnalytics,
  };
};

module.exports = InventoryModal();

const ActivityInventoryModalController = require("../peo/activityInventoryModalController");
const AddBmpsTable = require("../mapFunctions/addBmpsTable");
const Analytics = require("../general/analytics");
const BmpFcsInventoryModalController = require("../bmpfcs/bmpFcsInventoryModalController");
const CommonModalFunctions = require("../modals/commonModalFunctions");
const Form = require("../general/form");
const Misc = require("../misc");
const MessageModal = require("../modals/messageModal");
const ModalMap = require("../mapFunctions/modalMap");
const OutfallFunctions = require("../outfalls/outfallFunctions");
const OutfallInventoryModalController = require("../outfalls/outfallInventoryModalController");
const ProjectInventoryModalController = require("../construction/projectInventoryModalController");
const Tree = require("../tree");
const IncidentInventoryModalController = require("../illicitDischarge/incidents/incidentInventoryModalController");
const FormSettings = require("../settings/formSettings");
const LidProjectLayer = require("../lid/lidProjectLayer");
const IndcomInventoryModalController = require("../indcom/indcomInventoryModalController");
const SamplingSiteInventoryModalController = require("../wq/samplingSiteInventoryModalController");
