"use strict";

const OutfallInventorySetLocation = function () {
  const stringKey = "outfall-set-location";
  const headerInformation = "Location";
  let $page;
  let spatialOrg;

  var loadSelectLocationListeners = function () {
    $page.on("change", "[name=latitude], [name=longitude]", updateLatLngFromInput);
    $page.on("input", ":input.form-control", changeSetLocationInput);
    $page.on("change", `[name="use-manual-apn"]`, onUseManualApnChange);
    Form.initializeAndLoadListeners($page, "outfall-inventory", { isMultiPart: true });
  };

  var removeListeners = function () {
    $page.off("change", "[name=latitude], [name=longitude]", updateLatLngFromInput);
    $page.off("input", ":input.form-control", changeSetLocationInput);
    $page.off("change", `[name="use-manual-apn"]`, onUseManualApnChange);
  };

  var changeSetLocationInput = function () {
    const changedField = $(this).attr("name");
    if (changedField === "outfall-name") {
      setOutfallId($(this).val());
    }
  };

  var onUseManualApnChange = function () {
    const showManualApnInput =
      OutfallInventoryModalController.getLatestOutfallInventoryData("useManualApn") === true;
    $page.find(`input[name="parcel-apn"]`).toggleClass("hidden", showManualApnInput);
    $page.find(`input[name="manual-apn"]`).toggleClass("hidden", !showManualApnInput);
  };

  var setOutfallId = function (newName) {
    if (newName === OutfallInventoryModalController.getExistingData("outfallName")) {
      restoreButtonsIfHasAllRequiredInputs();
      OutfallInventoryModalController.unsetFormDataField("outfallName");
    } else if (newName === "") {
      InventoryModal.allowedSaveState(false);
      OutfallInventoryModalController.unsetFormDataField("outfallName");
      InventoryModal.disableSaveButton();
      InventoryModal.disableNavigationButtons();
    } else {
      OutfallInventoryModalController.setFormDataField("outfallName", newName);
      restoreButtonsIfHasAllRequiredInputs();
    }
  };

  var getStringKey = function () {
    return stringKey;
  };

  var getHeaderInformation = function () {
    return headerInformation;
  };

  var render = async function (options) {
    await renderPageHtml();
    setValidLatLongInTree();
    ModalMap.removeMarkerLayer();
    showLocationSelectionMap(options);
    loadSelectLocationListeners();
    disableUnavailableElements();
  };
  var disableUnavailableElements = function () {
    const outfallName =
      OutfallInventoryModalController.getLatestOutfallInventoryData("outfallName");
    Misc.toggleDisabled($("#inventory-modal input[name='outfall-name']"), !outfallName);

    if (outfallName) {
      restoreButtonsIfHasAllRequiredInputs();
    } else {
      InventoryModal.allowedSaveState(false);
      InventoryModal.disableNavigationButtons();
    }
  };

  var checkCreateNewOutfallMarker = function () {
    var id = false;
    var isNewMarker = !id;
    return isNewMarker;
  };

  var showLocationSelectionMap = function (options) {
    const configuration = {
      checkNewMarkerHandler: checkCreateNewOutfallMarker,
      newExistingMarkerHandler: OutfallInventoryModalController.loadExistingOutfallMarker,
      locationDataUpdateHandler: updateSetLocationFooter,
      badLocationUpdateHandler: resetLatLngToLastValidValue,
      createMarkerHandler: createOutfallMarker,
    };

    LocationSelectionMap.configureLocationModalMapHandlers(configuration);
    LocationSelectionMap.showLocationSelectionMap(options?.existingLocation);

    setLocationMapText();
  };

  var createOutfallMarker = function (latlng) {
    const rotazi = parseInt(
      OutfallInventoryModalController.getLatestOutfallInventoryData("rotationAzimuth"),
    );
    return ModalMap.createSingleRotatingOutFallMarker(latlng, rotazi, true);
  };

  var updateSetLocationFooter = async function (locationInfo, latlng) {
    const promise = updateLocationInfo(locationInfo);
    updateLatLngFields(latlng);

    const $outfallName = $page.find("input[name='outfall-name']");
    if ($outfallName.attr("disabled")) {
      Misc.toggleDisabled($outfallName, false);
      $outfallName.focus();
    }

    return promise;
  };

  var getRegionName = function (string) {
    return string ? string.substring(string.indexOf(" ") + 1) : "—";
  };

  var updateLocationInfo = function (locationInfo) {
    locationInfo = parseLocationInfo(locationInfo);

    OutfallInventoryModalController.setFormDataField("locationInfo", locationInfo);
    setInputField("maintenance-zone", locationInfo.maintenanceZone, true);
    setInputField("maintenanceSection", locationInfo.maintenanceSection, true);
    setInputField("parcel-apn", locationInfo.parcelApn, true);
    setInputField("catchment-id", locationInfo.catchmentName, true);
    setInputField("receiving-water-name", locationInfo.receivingWaterName, true);
    setInputField("address", locationInfo.address, true);
    setInputField("city", locationInfo.groupCityName, true);
    setInputField("zip", locationInfo.zip, true);
    setInputField("state", locationInfo.state, true);
    setInputField("highwayAssignment", locationInfo.highways, true);
    setInputField("region", locationInfo.regionName, true);
    setInputField("patrol", locationInfo.patrol, true);
  };

  var updateLatLngFields = function (latlng) {
    const latInput = $page.find(`input[name="latitude"]`)[0];
    const longInput = $page.find(`input[name="longitude"]`)[0];

    if (latInput && longInput) {
      NumberInput.manuallySetInput(latInput, latlng.lat);
      NumberInput.manuallySetInput(longInput, latlng.lng);
    }

    OutfallInventoryModalController.setFormDataField("latitude", latlng.lat);
    OutfallInventoryModalController.setFormDataField("longitude", latlng.lng);
    restoreButtonsIfHasAllRequiredInputs();
  };

  var setInputField = function (field, value, updateForm) {
    $page.find(`input[name=${field}]`).val(value);
    if (updateForm && value !== undefined) {
      OutfallInventoryModalController.setFormDataField(field, value);
    }
  };

  var setLocationMapText = function () {
    if (ModalMap.modalMapHasLayer("singleMarkerLayer")) {
      ModalMap.showLocationMessage("Click map or drag marker to change location");
    } else {
      ModalMap.showLocationMessage("Click map to set location");
    }
  };

  var parseLocationInfo = function (locationInfo) {
    return {
      regionName: getRegionName(locationInfo?.gname),
      highways: locationInfo.highway_name,
      catchmentGid: locationInfo.gid,
      catchmentName: locationInfo.catchid,
      receivingWaterName: locationInfo.drains_to_rw,
      landuseType: locationInfo.combo,
      groupName: locationInfo.gname,
      groupId: locationInfo.group_id,
      groupCityName: locationInfo.city,
      address: locationInfo.address,
      zip: locationInfo.zip,
      state: locationInfo.state,
      parcelApn: locationInfo.parcelid,
      maintenanceZone: locationInfo.maintenanceZone,
      maintenanceSection: locationInfo.maintenance_section,
      patrol: locationInfo.patrol,
    };
  };

  var getProps = function () {
    const renderProps = OutfallInventoryModalController.getInventoryForRender();

    const sortLayer = Tree.get("availableDataSortLayers");
    if (sortLayer[0] === "maintenanceZones") {
      spatialOrg = "ZONE";
    } else if (sortLayer[0] === "catchments") {
      spatialOrg = "CATCHMENT";
    } else if (sortLayer[0] === "highways") {
      spatialOrg = "HIGHWAY";
    }
    const installDate =
      OutfallInventoryModalController.getLatestOutfallInventoryData("installDate");

    return {
      ...renderProps,
      locationInfo: parseLocationInfo(renderProps.locationInfo ?? []),
      phaseOptions: outfallInventoryConstants.phaseOptions,
      installDate,
      props: { spatialOrg },
    };
  };

  var setValidLatLongInTree = function () {
    const lat = OutfallInventoryModalController.getLatestOutfallInventoryData("latitude");
    const lng = OutfallInventoryModalController.getLatestOutfallInventoryData("longitude");
    if (lat && lng) {
      Tree.set("modalMapSingleMarkerLastValidLatLng", { lat, lng });
    }
  };

  var renderPageHtml = async function () {
    const props = getProps();
    const html = nunjucks.render("modals/outfallInventory/outfallInventorySetLocation.njk", props);
    OutfallInventoryModalController.renderPageContent(html);
    $page = $(".modal-dynamic-content");
  };

  var updateLatLngFromInput = function () {
    if ($page.find(`input[name="latitude"]`).val() && $page.find(`input[name="longitude"]`).val()) {
      const e = {
        latlng: {
          lat: $page.find(`input[name="latitude"]`).val(),
          lng: $page.find(`input[name="longitude"]`).val(),
        },
      };
      LocationSelectionMap.locationSelectionMapClick(e);

      restoreButtonsIfHasAllRequiredInputs();
    } else {
      InventoryModal.allowedSaveState(false);
      InventoryModal.disableNavigationButtons();
    }
  };

  var hasAllRequiredInputs = function () {
    const latitude = $page.find(`input[name="latitude"]`).val();
    const longitude = $page.find(`input[name="longitude"]`).val();
    const outfallName = $page.find(`input[name="outfall-name"]`).val();

    return latitude && longitude && outfallName;
  };

  var restoreButtonsIfHasAllRequiredInputs = function () {
    if (hasAllRequiredInputs()) {
      InventoryModal.allowedSaveState(true);
      InventoryModal.restoreNavigationButtons();
    }
  };

  var resetLatLngToLastValidValue = function (latlng) {
    NumberInput.manuallySetInput($page.find(`input[name="latitude"]`)[0], latlng.lat);
    NumberInput.manuallySetInput($page.find(`input[name="longitude"]`)[0], latlng.lng);
  };

  var cleanUp = function () {
    removeListeners();
    LocationSelectionMap.hideLocationSelectionMap();
    $page.empty();
    return true;
  };

  var validateOutfallId = async function () {
    const alreadyUsedId = Tree.get(["outfallInventoryState", "currentOutfallData", "outfallName"]);
    const currentIdState =
      Tree.get("outfallInventoryState", "outfallId") ||
      OutfallInventoryModalController.getLatestOutfallInventoryData("outfallName");

    if (alreadyUsedId === currentIdState) {
      return true;
    } else {
      const groupId = Tree.get("activeGroup", "groupId");
      const isUnique = await ApiCalls.checkUniqueOutfallName(groupId, currentIdState);
      if (!isUnique[0].unique) {
        MessageModal.showSimpleWarningModal(
          "The ID you entered already exists in our records! Please choose a different name or contact <a href='mailto:customersuccess@2ndnaturewater.com'>customersuccess@2ndnaturewater.com</a> for further assistance.",
          true,
        );
      }
      return isUnique[0].unique;
    }
  };

  var validate = async function () {
    return validateOutfallId().catch((err) => {
      throw err;
    });
  };

  return {
    getStringKey,
    getHeaderInformation,
    setOutfallId,
    render,
    renderPageHtml,
    setInputField,
    cleanUp,
    validate,
    validateOutfallId,
    updateSetLocationFooter,
    loadSelectLocationListeners,
  };
};

module.exports = OutfallInventorySetLocation();

const Form = require("../general/form");
const InventoryModal = require("../general/inventoryModal");
const LocationSelectionMap = require("../mapFunctions/locationSelectionMap");
const Misc = require("../misc");
const ModalMap = require("../mapFunctions/modalMap");
const NumberInput = require("../general/numberInput");
const Tree = require("../tree");
const OutfallInventoryModalController = require("../outfalls/outfallInventoryModalController");
const MessageModal = require("../modals/messageModal");
const ApiCalls = require("../apiCalls");
const outfallInventoryConstants = require("./outfallInventoryConstants");
