"use strict";

const SaveIncompleteModal = function () {
  const cancelText = "Cancel";
  const saveAndCloseText = "Save & Close";
  const saveAndEditText = "Save & Edit Later";

  var showConfirmSaveIncompleteModal = function (
    entityName,
    entityKind,
    incompleteFieldNames,
    saveCallback,
    cancelCallback = () => {},
    showHelpCenterText = false,
  ) {
    MessageModal.showConfirmWarningModal(
      null,
      saveCallback,
      cancelText,
      saveAndCloseText,
      getIncompleteFieldsBodyText(entityName, entityKind, incompleteFieldNames) +
        "</p>" +
        getWarnSaveButtonHelpText(showHelpCenterText),
      cancelCallback,
    );
  };

  var showWarnSaveIncompleteModal = function (
    entityName,
    entityKind,
    incompleteFieldNames,
    additionalText,
  ) {
    MessageModal.showSimpleWarningModal(
      getIncompleteFieldsBodyText(entityName, entityKind, incompleteFieldNames, additionalText),
      true,
    );
  };

  var showWarnSaveSubmitModal = function (
    incompleteFields = {
      entityName: null,
      entityKind: null,
      incompleteFieldNames: null,
    },
    buttonCallbacks = {
      cancelCallback: null,
      saveCallback: null,
      submitCallback: null,
    },
    customSuccessMessage = null,
  ) {
    const buttons = getWarnSaveSubmitButtons(incompleteFields, buttonCallbacks);
    var bodyText = "";

    if (incompleteFields.incompleteFieldNames.length !== 0) {
      bodyText += getIncompleteFieldsBodyText(
        incompleteFields.entityName,
        incompleteFields.entityKind,
        incompleteFields.incompleteFieldNames,
      );
      bodyText += `
        <p>
          <strong>Click “${saveAndEditText}” to save this ${incompleteFields.entityKind} and complete it at a later time. Click “${cancelText}” to go back.</strong>
        </p>
      `;
      bodyText += `
        <p>
          <strong>You cannot submit the ${incompleteFields.entityKind} until all required fields are completed.</strong>
        </p>
      `;
    } else if (customSuccessMessage) {
      bodyText = `<p>${customSuccessMessage}</p>`;
    } else {
      bodyText += `
        <p>
          All required fields are completed! You are ready to submit the ${incompleteFields.entityKind} for <strong>${incompleteFields.entityName}</strong>.
        </p>
      `;
      bodyText += `
        <p>
          <strong>Click “${getSubmitButtonText(incompleteFields.entityKind)}” to lock this ${
            incompleteFields.entityKind
          } and generate a PDF report${Session.notificationEnabled("pdf-letters") ? " that will be emailed to you shortly" : ""}. Click “${saveAndEditText}” to save this ${
            incompleteFields.entityKind
          } and submit it at a later time. Click “${cancelText}” to go back.</strong>
        </p>
      `;
    }

    MessageModal.showCustomWarningModal(bodyText, buttons);
  };

  var getIncompleteFieldsBodyText = function (
    entityName,
    entityKind,
    incompleteFieldNames,
    additionalText,
  ) {
    const isAreNumberIncomplete = Misc.pluralIsAre(incompleteFieldNames.length > 1);
    const incompleteNames = Misc.arrayToPrettyString(incompleteFieldNames);
    const formattedEntityName = `<strong>${entityName}</strong>`;
    const wording =
      Tree.get("tool") === "scenario"
        ? `needed to ensure ${formattedEntityName} is analysis ready:`
        : `incomplete for ${formattedEntityName}:`;

    const noSelectionEqualsUnknown = incompleteFieldNames.includes("sBMP/FCS type")
      ? `<p>If no Type is selected, it will be saved as Unknown.</p>`
      : ``;

    var text = `
    <p>
      The following ${entityKind} field${isAreNumberIncomplete} ${wording}
    </p>
    <p>
      ${incompleteNames}
    </p>
    ${noSelectionEqualsUnknown}
  `;

    if (additionalText) {
      text += `
      <p>${additionalText}</p>
      `;
    }

    return text;
  };

  var getWarnSaveSubmitButtons = function (incompleteFields, buttonCallbacks) {
    const buttons = [
      {
        text: cancelText,
        type: "secondary",
        callback: buttonCallbacks.cancelCallback,
      },
      {
        text: saveAndEditText,
        type: "primary",
        callback: buttonCallbacks.saveCallback,
      },
      {
        text: getSubmitButtonText(incompleteFields.entityKind),
        type: "primary-submit",
        callback: function () {
          MessageModal.showFinalConfirmModal(
            incompleteFields.entityKind,
            incompleteFields.entityName,
            buttonCallbacks.submitCallback,
          );
        },
        stayOpenOnClick: true,
        disabled: incompleteFields.incompleteFieldNames.length > 0,
      },
    ];

    return buttons;
  };

  var getSubmitButtonText = function (entityKind) {
    const submitKind = NunjucksFilters.capFirstLetters(entityKind);
    return `Submit ${submitKind}`;
  };

  var getWarnSaveButtonHelpText = function (showHelpCenterText = false) {
    var text = "";

    if (showHelpCenterText) {
      text += `
        <p>
          Visit the <a class="navbar-help-center" href="https://kb.2ndnaturewater.com/_hcms/mem/login?redirect_url=https://kb.2ndnaturewater.com/knowledge" target="_blank">Help Center</a> for more guidance.
        </p>
      `;
    }

    text += `
      <p>
        <strong>Click "${cancelText}" to enter required information or "${saveAndCloseText}" to save and complete later.</strong>
      </p>
    `;

    return text;
  };

  return {
    showConfirmSaveIncompleteModal,
    showWarnSaveIncompleteModal,
    showWarnSaveSubmitModal,
    getIncompleteFieldsBodyText,
  };
};

module.exports = SaveIncompleteModal();

const MessageModal = require("./messageModal");
const Misc = require("../misc");
const NunjucksFilters = require("../general/nunjucksFilters");
const Tree = require("../tree");
const Session = require("../login/session");
