"use strict";

const ProjectFactSheetController = function () {
  var getConfig = function () {
    const ConstructionIcon = require("./constructionIcon");
    const SortUtilities = require("../general/sortUtilities");
    const alphaSorter = SortUtilities.alphaSorter;
    return {
      routeName: "construction-project-fact-sheet",
      template: "factSheet/construction/constructionProjectFactSheet.njk",
      dataSort: "projectIdentifier",
      polygonColumnName: "projectAreaGeom",
      pointColumnName: "geom",
      iconFunction: ConstructionIcon.getConstructionProjectIconMarker,
      closeUrl: "/ram/construction/construction-project",
      getDataFunction: getData,
      prepareDataFunction: prepareData,
      onCloseFunction: onClose,
      afterOpenFunction: afterOpen,
      downloadSiteMapFunction: downloadSiteMap,
      dataSorting: {
        history: { key: "date", reversed: true },
      },
      sortersDict: {
        displayType: alphaSorter,
        bmpId: alphaSorter,
        date: alphaSorter,
        result: alphaSorter,
      },
      tableTemplates: {
        history: "factSheet/construction/constructionHistoryRows.njk",
      },
      apiAssetName: "construction-projects",
    };
  };

  var getData = async function (id) {
    try {
      return await ApiCalls.getConstructionProjectForFactSheet(id);
    } catch (e) {
      ApiError.assertIs(e);
      if (e.status === 404) {
        return e;
      }
    }

    return await ResourceController.get("postConstructionProjectG2").getById(id);
  };

  var prepareData = function (data) {
    const display = (options, value) => options.find((option) => option.value === value)?.name;

    const phaseOptions = ToolSettings.getSetting("filterConstants", "phases");
    const ownershipOptions = ProjectInventoryConstants.ownershipOptions;
    const projectTypeOptions = ToolSettings.getDomainOptions("project_type");
    const developmentTypeOptions = ToolSettings.getDomainOptions("development_type");

    data.displayPhase = display(phaseOptions, data.phase);
    data.phaseColor = data.phase ? MapStyles.getHexColor(data.phase) : "#ffffff";
    data.phaseTextColor = Misc.isDarkColor(data.phaseColor) ? "#fff" : "#000";
    data.displayImplementer = display(ownershipOptions, data.implementerType);
    data.displayMaintainer = display(ownershipOptions, data.maintainerType);
    data.displayLandOwner = display(ownershipOptions, data.landOwnership);
    data.displayProjectType = display(projectTypeOptions, data.projectType);
    data.displayDevelopmentType = display(developmentTypeOptions, data.developmentType);

    data.permitsAndPlans = FactSheet.addAbsoluteUrlsToFiles(data.permitsAndPlans);

    data.showGenerateSummaryButton = LidProjectDataListController.showGenerateSummaryButton(data);

    data.readOnlyTopLevel = Session.isRegulator();
    PopupContent.handleAssessmentHistoryReadOnly(data.history, data.bmps);
    FactSheetNew.addDisplayDates(data.history);
    data.siteCharacteristicsSettings = ToolSettings.getSetting("lid", "siteCharacteristics");
    data.dataView = Tree.get("dataView");
  };

  var open = async function (id, options = {}) {
    FactSheetNew.open(id, getConfig(), null, options);
  };

  var afterOpen = function () {
    loadListeners();
    _handleMessages();
  };

  var downloadSiteMap = async function (id) {
    Analytics.sendDataListEvent("download_sitemap");

    const preview = new PreviewDownloader();
    const data = await ApiCalls.downloadProjectSiteMap(id);
    preview.openUrl(data.url);
  };

  const _handleMessages = function () {
    const channel = `construction-project.${FactSheetNew.getId()}`;

    Messages.subscribe({
      channel,
      event: "update",
      callback: (data) => reload(data.project),
    });
  };

  var reload = function (data) {
    const allData = FactSheetNew.getData();
    if (allData?.id && data?.id === allData?.id) {
      FactSheetNew.reload({
        ...allData,
        ...data,
      });
    }
  };

  var loadListeners = function () {
    $("#fact-sheet-container")
      .off("click", ".edit-button", _clickEditButton)
      .on("click", ".edit-button", _clickEditButton)
      .off("click", ".map-button", _openMapPopup)
      .on("click", ".map-button", _openMapPopup)
      .off("click", ".download-button", _downloadPhotos)
      .on("click", ".download-button", _downloadPhotos)
      .off("click", ".delete-button", _displayDeleteProjectModal)
      .on("click", ".delete-button", _displayDeleteProjectModal)
      .off("click", ".edit-description", _clickEditDescription)
      .on("click", ".edit-description", _clickEditDescription)
      .off("click", "#edit-area", _clickEditArea)
      .on("click", "#edit-area", _clickEditArea)
      .off("click", "#edit-permit-info", _clickEditPermitInfo)
      .on("click", "#edit-permit-info", _clickEditPermitInfo)
      .off("click", "#add-asset", _clickAddAsset)
      .on("click", "#add-asset", _clickAddAsset)
      .off("click", ".edit-sbmp-button", _clickEditSbmp)
      .on("click", ".edit-sbmp-button", _clickEditSbmp)
      .off("click", "#add-contact, .edit-contact", _clickAddContact)
      .on("click", "#add-contact, .edit-contact", _clickAddContact)
      .off("click", "#add-file, .edit-file", _clickAddFile)
      .on("click", "#add-file, .edit-file", _clickAddFile)
      .off("click", ".download-file", _downloadFile)
      .on("click", ".download-file", _downloadFile)
      .off("click", ".show-enforcement-action", _showEnforcementAction)
      .on("click", ".show-enforcement-action", _showEnforcementAction)
      .off("click", ".js-delete-construction-project-inspection", _deleteProjectInspection)
      .on("click", ".js-delete-construction-project-inspection", _deleteProjectInspection)
      .off("click", ".download-inspection", _downloadConstructionInspectionReport)
      .on("click", ".download-inspection", _downloadConstructionInspectionReport)
      .off("click", ".download-report", MuniBmpPopup.onDownloadReport)
      .on("click", ".download-report", MuniBmpPopup.onDownloadReport)
      .off("click", ".download-bmp-report", StructuralBmpsPopupGeoServerLayer.onPopupDownload)
      .on("click", ".download-bmp-report", StructuralBmpsPopupGeoServerLayer.onPopupDownload)
      .off("click", ".js-edit-bmp-assessment", _editBmpAssessmentClick)
      .on("click", ".js-edit-bmp-assessment", _editBmpAssessmentClick)
      .off("click", ".js-view-bmp-assessment", _viewBmpAssessmentClick)
      .on("click", ".js-view-bmp-assessment", _viewBmpAssessmentClick)
      .off("click", ".js-delete-bmp-assessment", _deleteBmpAssessmentClick)
      .on("click", ".js-delete-bmp-assessment", _deleteBmpAssessmentClick)
      .off("click", ".js-edit-assessment", _editAssessmentClick)
      .on("click", ".js-edit-assessment", _editAssessmentClick)
      .off("click", ".js-view-assessment", _viewAssessmentClick)
      .on("click", ".js-view-assessment", _viewAssessmentClick)
      .off("click", ".js-delete-assessment", _deleteAssessmentClick)
      .on("click", ".js-delete-assessment", _deleteAssessmentClick)
      .off("click", ".js-inspection-letter", _inspectionLetterClick)
      .on("click", ".js-inspection-letter", _inspectionLetterClick)
      .off("click", ".download-inspection-report", _downloadLidInspectionReport)
      .on("click", ".download-inspection-report", _downloadLidInspectionReport)
      .off("click", ".download-response-report", _downloadResponseReport)
      .on("click", ".download-response-report", _downloadResponseReport)
      .off("click", ".download-summary-report", _downloadSummaryReport)
      .on("click", ".download-summary-report", _downloadSummaryReport)
      .off("click", ".js-view-summary", _viewSummaryClick)
      .on("click", ".js-view-summary", _viewSummaryClick)
      .off("click", ".js-delete-summary", _deleteSummaryClick)
      .on("click", ".js-delete-summary", _deleteSummaryClick)
      .on("click", ".download-summary-report", _downloadSummaryReport)
      .off("click", ".issue-lid-enforcement", _issueLidEnforcement)
      .on("click", ".issue-lid-enforcement", _issueLidEnforcement);
  };

  var _editBmpAssessmentClick = function () {
    const data = FactSheetNew.$getRow($(this)).data();
    _addBmpToTreeForAssessment(data);
    const readOnly = false;
    BmpFunctions.editHistory(data, readOnly);
  };

  var _viewBmpAssessmentClick = function () {
    const data = FactSheetNew.$getRow($(this)).data();
    _addBmpToTreeForAssessment(data);
    const readOnly = true;
    BmpFunctions.editHistory(data, readOnly);
  };

  var _addBmpToTreeForAssessment = function (data) {
    Tree.set(["currentBmpFcs", "system_access"], data.system_access);
  };

  var _deleteBmpAssessmentClick = function () {
    const $row = FactSheetNew.$getRow($(this));
    const data = $row.data();

    BmpFunctions.confirmDeleteHistory($row, data);
  };

  var _editAssessmentClick = function () {
    const data = FactSheetNew.$getRow($(this)).data();
    const readOnly = false;
    LidSelfInspectionHistory.editHistory(data, readOnly);
  };

  var _viewAssessmentClick = function () {
    const data = FactSheetNew.$getRow($(this)).data();
    const readOnly = true;
    LidSelfInspectionHistory.editHistory(data, readOnly);
  };

  var _deleteAssessmentClick = function () {
    const $row = FactSheetNew.$getRow($(this));
    const data = $row.data();
    LidSelfInspectionHistory.confirmDeleteHistory($row, data);
  };

  var _deleteSummaryClick = function () {
    const $row = FactSheetNew.$getRow($(this));

    MessageModal.showDeleteRecordModal(async function () {
      await ApiCalls.deleteLidInspectionSummary($row.data("id"));
      $row.remove();
    });
  };

  var _inspectionLetterClick = function () {
    InspectionSummaryLetterModal.renderAndShow([FactSheetNew.getId()]);
  };

  var _viewSummaryClick = function () {
    InspectionSummaryLetterModal.renderAndShowEdit(FactSheetNew.$getRow($(this)).data("id"), {
      readOnly: true,
    });
  };

  var _downloadLidInspectionReport = function () {
    const selfInspectionId = FactSheetNew.$getRow($(this)).data("id");
    ApiCalls.downloadLidSelfInspectionPdf(selfInspectionId);
  };

  var _downloadConstructionInspectionReport = function () {
    const inspectionId = FactSheetNew.$getRow($(this)).data("id");
    const inspectionType = FactSheetNew.$getRow($(this)).data("type");
    ApiCalls.downloadConstructionInspectionReport(inspectionId, null, inspectionType);
  };

  var _downloadSummaryReport = function () {
    const summaryId = FactSheetNew.$getRow($(this)).data("id");
    ApiCalls.downloadLidSummaryPdf(summaryId);
  };

  var _issueLidEnforcement = function () {
    const id = FactSheetNew.getId();
    LidEnforcementController.loadNewEnforcement(id);
  };

  var _downloadResponseReport = function () {
    const selfInspectionId = FactSheetNew.$getRow($(this)).data("id");
    ApiCalls.downloadLidResponsePdf(selfInspectionId);
  };

  var _openModalToPage = function (page) {
    ProjectInventoryModalController.invokeProjectInventoryModal(FactSheetNew.getId(), page);
  };

  var _clickEditButton = function () {
    _openModalToPage();
  };

  var _openMapPopup = function () {
    FactSheetNew.close();
    MapFunctions.openPopupById(Number(FactSheetNew.getId()));
    Analytics.sendDataListEvent("address_to_map", "construction");
  };

  var _downloadPhotos = function () {
    ApiCalls.downloadProjectPhotos(FactSheetNew.getId());
  };

  var _displayDeleteProjectModal = function () {
    const id = FactSheetNew.getId();

    MessageModal.showThreeButtonConfirmWarningModal(
      `Delete Project? Click "Delete Project" to permanently delete the project and all associated inspections, photos and files. Click "Delete Project & Assets" to delete the project and all associated stormwater assets. Click "Cancel" to return.`,
      async function () {
        await ApiCalls.deleteProjectFactSheet(id);
      },
      async function () {
        await ApiCalls.deleteProjectFactSheet(id, true);
      },
      "Cancel",
      "Delete Project",
      "Delete Project & Assets",
    );
  };

  var _clickEditDescription = function () {
    _openModalToPage("construction-general-info");
  };

  var _clickEditArea = function () {
    _openModalToPage("construction-area-selection");
  };

  var _clickEditPermitInfo = function () {
    _openModalToPage("construction-permit-info");
  };

  var _clickAddAsset = function () {
    _openModalToPage("project-add-bmps");
  };

  var _clickEditSbmp = function () {
    const id = $(this).data("id");
    BmpFcsInventoryModalController.invokeBmpInventoryModal(id, {
      mode: "bmpram",
    });
  };

  var _clickAddContact = function () {
    _openModalToPage("project-contacts");
  };

  var _clickAddFile = function () {
    _openModalToPage("project-files");
  };

  var _downloadFile = function (e) {
    const permitPlanId = $(e.currentTarget).parents(`[data-category=permit]`).data("id-permit");
    ApiCalls.downloadPermitPlanFile(permitPlanId);
  };

  var _showEnforcementAction = function () {
    EnforcementActionModalController.getAndRenderEnforcementAction(
      FactSheetNew.$getRow($(this)).data("id"),
    );
  };

  var _deleteProjectInspection = function () {
    const inspectionId = FactSheetNew.$getRow($(this)).data("id");
    MessageModal.showDeleteRecordModal(async function () {
      await ApiCalls.deleteConstructionProjectInspection(inspectionId);
    });
  };

  var onClose = function () {
    const channel = `construction-project.${FactSheetNew.getId()}`;
    Messages.unsubscribe(channel);
  };

  return {
    getConfig,
    open,
    routeName: getConfig().routeName,
    reload,
    loadListeners,
  };
};

module.exports = ProjectFactSheetController();

const ApiCalls = require("../apiCalls");
const ApiError = require("../errors/apiError");
const ResourceController = require("../offline/resourceController");
const FactSheetNew = require("../general/factSheetNew");
const MapStyles = require("../mapFunctions/mapStyles");
const Misc = require("../misc");
const ToolSettings = require("../settings/toolSettings");
const ProjectInventoryConstants = require("../construction/projectInventoryConstants");
const ProjectInventoryModalController = require("../construction/projectInventoryModalController");
const Messages = require("../messages");
const BmpFcsInventoryModalController = require("../bmpfcs/bmpFcsInventoryModalController");
const EnforcementActionModalController = require("../construction/enforcementActionModalController");
const PopupContent = require("../mapFunctions/popupContent");
const MessageModal = require("../modals/messageModal");
const Session = require("../login/session");
const FactSheet = require("../general/factSheet");
const BmpFunctions = require("../modals/bmpFunctions");
const MuniBmpPopup = require("../muni/bmp/muniBmpPopup");
const Tree = require("../tree");
const LidSelfInspectionHistory = require("../lid/lidSelfInspectionHistory");
const StructuralBmpsPopupGeoServerLayer = require("../bmpFcsG2/structuralBmpsPopupGeoServerLayer");
const MapFunctions = require("../mapFunctions/mapFunctions");
const Analytics = require("../general/analytics");
const InspectionSummaryLetterModal = require("../lid/inspectionSummaryLetterModal");
const LidProjectDataListController = require("../lid/lidProjectDataListController");
const PreviewDownloader = require("../files/previewDownloader");
const LidEnforcementController = require("../lid/lidEnforcement/lidEnforcementController");
