"use strict";

const Login = function () {
  var initialize = function () {
    _addListeners();
  };

  var _addListeners = function () {
    $("#loginDiv").on("submit", "form", _login);
    $("#tool-nav").on("click", "#sign-in-container a", showLoginModal);
    $("#loginDiv").on("click", ".loginOptions", showForgotPassword);
    $("#forgotDiv").on("click", ".backDiv", returnToLogin).on("submit", "form", _forgotPassword);
    $("#loginDiv").on("change", '[name="username"]', onUsernameChange);
  };

  var selectAlias = async function (e) {
    const aliasId = $(e.currentTarget).data("id");

    if (!BrowserWarning.confirmCanClose("switch accounts")) {
      return;
    }

    SingleSignOn.selectAlias({ aliasId: aliasId });
  };

  var updatePassword = function () {
    var email = $("#emailPU").val();

    var $oldPassword = $("#passwordPU");
    var oldPassword = $oldPassword.val();

    var $newPassword = $("#newPasswordPU");
    var newPassword = $newPassword.val();

    var $verifyNewPassword = $("#verifyNewPasswordPU");
    var verifyNewPassword = $verifyNewPassword.val();

    var $errorMessage = $(".errorMessage");

    if (verifyNewPassword !== newPassword) {
      $errorMessage.text("New passwords do not match.");
      $verifyNewPassword.val("");
      $newPassword.val("").select();
      return;
    }

    SingleSignOn.updatePassword(email, oldPassword, newPassword, function (newTokenData) {
      if (!newTokenData.error) {
        Session.initSession(newTokenData);
        $errorMessage.text("Your password has been updated.");
      } else {
        $errorMessage.text(newTokenData.error);
        $verifyNewPassword.val("");
        $newPassword.val("").select();
      }
    });
  };

  var _login = async function (e) {
    var username = $("#username").val();
    var password = $("#password").val();

    // Do not submit form
    e.preventDefault();

    if (_getSsoLogin() !== null) {
      await samlLogin(username);
    } else {
      SingleSignOn.login(username, password, function (data, statusCode) {
        if (data) {
          Session.initSession(data);

          if (isSsoPage()) {
            SingleSignOn.ssoLogin(data);
          } else {
            UrlRoutes.navigateToHomePage();
          }
        } else {
          _showErrorMessage(
            statusCode,
            "The email or password is incorrect. Please check your login credentials.",
          );
        }
      });
    }
  };

  var samlLogin = async function (username) {
    const response = await SingleSignOn.samlLogin(username);
    if (response) {
      _showErrorMessage(response.xhrResponse.status, response.xhrResponse.responseText);
    }
  };

  var isSsoPage = function () {
    return window.location.href.includes("sso.html");
  };

  var _showErrorMessage = function (statusCode, defaultMessage) {
    const msg = getErrorMessage(statusCode, defaultMessage);
    $(".errorMessage").html(msg);
  };

  var getErrorMessage = function (statusCode, defaultMessage, actionName = "login") {
    var msg = defaultMessage;
    if (statusCode === 429) {
      msg = `Too many ${actionName} attempts. Please wait a minute and try again.`;
    } else if (statusCode === 500) {
      msg =
        "Server error. Please try again later or or contact us at customersuccess@2ndnaturewater.com for assistance.";
    }
    return msg;
  };

  var logout = function () {
    if (!BrowserWarning.confirmCanClose("sign out")) {
      return;
    }
    Session.clear();
    SingleSignOn.logout(UrlRoutes.navigateToHomePage);
    UserResource.clearAllData();
  };

  const showLoginModal = (options = {}) => {
    options = $.extend({ show: true, keyboard: false }, options);
    const $login = $("#loginModal");
    const samlLoginPlaceholder = _getSsoLogin();

    if (samlLoginPlaceholder !== null) {
      $login
        .find("#login")
        .html(nunjucks.render("home/sso-login-form.njk", { email: samlLoginPlaceholder }));
    }

    $login
      .one("shown.bs.modal", function () {
        $("#username").trigger("select");
      })
      .modal(options);

    if (samlLoginPlaceholder !== null && samlLoginPlaceholder !== "") {
      samlLogin(samlLoginPlaceholder);
    }
  };

  /*
    @return: null   if should not do an SSO login.
             string if should do an SSO login using the string as the default login. Note the string may be empty if no default is available.
  */
  const _getSsoLogin = function () {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get("samlLogin");
  };

  var showForgotPassword = function () {
    $("#forgotDiv").css("display", "block");
    $("#loginDiv").css("display", "none");
    $("#pUsername").trigger("select");
  };

  var _forgotPassword = function () {
    var email = $("#pUsername").val();
    ApiCalls.sendPassword(
      email,
      function () {
        $("#forgotComment").html("<p>A reset link has been emailed to " + email + "</p>");
      },
      function (xhr) {
        const message = getErrorMessage(xhr.status, xhr.responseText, "reset");
        $("#forgotComment").html(`<p>${message}</p>`);
      },
    );
    return false;
  };

  var returnToLogin = function () {
    $("#forgotDiv").css("display", "none");
    $("#loginDiv").css("display", "block");
  };

  var initializeResetPassword = function (site = "2nform") {
    const code = location.hash.slice(1);
    ApiCalls.queryActivationCode(code, function (results) {
      $(".reset-password").html(
        nunjucks.render("userSettings/reset.njk", { ...results, site: site }),
      );
      const submitFunction = results?.activation ? activateUser : resetPassword;
      $("#formWrapper").on("submit", "form", submitFunction);
    });
  };

  var resetPassword = function () {
    var password = $("#password").val();
    var passwordConfirm = $("#password-confirm").val();
    if (password !== passwordConfirm) {
      $(".errorMessage").text("Passwords don't match!");
      return;
    }

    var code = location.hash.slice(1);

    ApiCalls.newPassword(code, password, passwordUpdateCallback, updateErrorMessage);
    return false;
  };

  var activateUser = function () {
    var fullName = $("#full-name").val();
    var password = $("#password").val();
    var passwordConfirm = $("#password-confirm").val();
    if (password !== passwordConfirm) {
      $(".errorMessage").text("Passwords don't match!");
      return;
    }

    var code = location.hash.slice(1);

    ApiCalls.activateUser(code, fullName, password, passwordUpdateCallback, updateErrorMessage);
    return false;
  };

  var passwordUpdateCallback = function () {
    var signInPage = "./";
    if (location.href.includes("/ram/")) {
      signInPage = "/ram/home/index.html";
    }
    location.href = signInPage;
  };

  var updateErrorMessage = function (xhr) {
    $(".errorMessage").text(xhr.responseText);
  };

  var checkAgreements = function (data) {
    const userAgreementKey = "user_agreement_signed";
    const userAgreementSigned = getSessionItem(userAgreementKey) || data.context[userAgreementKey];

    const saasAgreementKey = "is_saas_signed";
    const saasAgreementSigned = getSessionItem(saasAgreementKey) || data.context[saasAgreementKey];

    if (data.context.is_2n_admin) {
      return;
    }

    startUnsignedAgreements(
      data.context.user_role === "admin",
      saasAgreementSigned,
      userAgreementSigned,
    );
  };

  var getSessionItem = function (key) {
    return JSON.parse(sessionStorage.getItem(key));
  };

  var startUnsignedAgreements = function (isAdmin, saasAgreementSigned, userAgreementSigned) {
    if (isAdmin && !saasAgreementSigned) {
      SaasAgreementModal.startSaasAgreement();
    } else {
      if (!saasAgreementSigned) {
        window.alert(
          "Your account is locked until an admin logs in and accepts the SaaS agreement. You will now be logged out.",
        );
        logout();
        return;
      }

      if (!userAgreementSigned) {
        UserAgreementModal.startUserAgreement();
      }
    }
  };

  var setSessionUserAgreementTrue = function () {
    sessionStorage.setItem("user_agreement_signed", true);
  };

  var setSessionSaasAgreementTrue = function () {
    sessionStorage.setItem("is_saas_signed", true);
  };

  var onUsernameChange = function (e) {
    const username = $(e.currentTarget).val() ?? "";
    const $ssoButton = $(".sso-login-button");

    const url = getUrlFromAbsolutePath($ssoButton.attr("href"));
    if (url === null) {
      return;
    }
    url.searchParams.set("samlLogin", username);

    $ssoButton.attr("href", url);
  };

  var getUrlFromAbsolutePath = function (maybeAbsoluteUrl) {
    if (!maybeAbsoluteUrl) {
      return null;
    }

    if (maybeAbsoluteUrl.startsWith("/")) {
      maybeAbsoluteUrl = Config.get().origin + maybeAbsoluteUrl;
    }

    return new URL(maybeAbsoluteUrl);
  };

  return {
    initialize,
    selectAlias,
    updatePassword,
    logout,
    showLoginModal,
    showForgotPassword,
    _forgotPassword,
    returnToLogin,
    initializeResetPassword,
    checkAgreements,
    setSessionUserAgreementTrue,
    setSessionSaasAgreementTrue,
    _getSsoLogin,
    _showErrorMessage,
    _login,
  };
};
module.exports = Login();

const ApiCalls = require("../apiCalls");
const BrowserWarning = require("../general/browserWarning");
const SaasAgreementModal = require("../modals/saasAgreementModal");
const Session = require("./session");
const SingleSignOn = require("./sso");
const UrlRoutes = require("../routes/urlRoutes");
const UserAgreementModal = require("../modals/userAgreementModal");
const Config = require("../config");
const UserResource = require("../offline/userResource");
