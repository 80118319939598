"use strict";

const PostConstructionProjectG2Filters = function () {
  const searchFields = ["projectIdentifier", "projectName", "streetAddress"];
  const searchDbFields = ["project_identifier", "street_address"];
  let mainSmartFilters;

  var filterResourceDataHandler = async function (unfilteredData, filters, loadingScreen) {
    if (onlineFilterIsSet(filters)) {
      try {
        return await ResourceFilters.filterOnline(
          unfilteredData,
          loadingScreen,
          PostConstructionProjectGeoServerLayer.getLayerData,
        );
      } catch (e) {
        ApiError.assertIs(e);
      }
    }

    return filterOffline(unfilteredData, filters);
  };

  var onlineFilterIsSet = function (filters) {
    if (filters.toDoFilters) {
      return ResourceFilters.toDoSpatialFiltersAreSet(filters.toDoFilters);
    } else {
      return ResourceFilters.spatialFiltersAreSet(filters) || isOnlineDateFilterSet(filters);
    }
  };

  var isOnlineDateFilterSet = function (filters) {
    return isOnlineDateType(filters.dateType) && (filters.dateFrom || filters.dateTo);
  };

  var isOnlineDateType = function (dateType) {
    return ["routine", "repair", "clean"].includes(dateType);
  };

  var filterOffline = function (unfilteredData) {
    return mainSmartFilters ? mainSmartFilters.filter(unfilteredData) : unfilteredData;
  };

  var passFeatureFlaggedCompletedDateFilter = function (datum, filters) {
    return ResourceFilters.passIsoDateFilter(
      datum.projectEndDate,
      filters.completed?.from,
      filters.completed?.to,
    );
  };

  var addGeoServerFilterQuery = function (queryStrings, filters) {
    if (Tree.get("dataView") === "lid-runoff") {
      const defaultPhases = ["completed", "certified"];
      queryStrings.push(GeoServerFilterFunctions.getInArrayQuery("project_phase", defaultPhases));
    } else {
      if (filters.status?.length !== undefined) {
        const phases = getFilterPhases(filters);
        queryStrings.push(GeoServerFilterFunctions.getInArrayQuery("project_phase", phases));
      }

      GeoServerFilterFunctions.addFilterToGeoServerQuery(
        queryStrings,
        filters,
        "lidOwnership",
        "implementer_type",
      );

      GeoServerFilterFunctions.addFilterToGeoServerQuery(
        queryStrings,
        filters,
        "maintainerType",
        "maintainer_type",
      );

      GeoServerFilterFunctions.addFilterToGeoServerQuery(
        queryStrings,
        filters,
        "performanceTier",
        "highest_tier_number",
      );

      if (
        filters.alternativeCompliance &&
        !FilterSummary.areAllCheckboxesChecked("alternativeCompliance")
      ) {
        queryStrings = GeoServerFilterFunctions.yesNoNullGeoServerFilter(
          filters,
          "alternativeCompliance",
          queryStrings,
          "alternative_compliance",
        );
      }

      if (
        filters.initialMaintenanceNotice &&
        !FilterSummary.areAllCheckboxesChecked("initialMaintenanceNotice")
      ) {
        queryStrings = GeoServerFilterFunctions.yesNoNullGeoServerFilter(
          filters,
          "initialMaintenanceNotice",
          queryStrings,
          "initial_maintenance_notice",
        );
      }

      if (
        filters.initialRoutineInspection &&
        !FilterSummary.areAllCheckboxesChecked("initialRoutineInspection")
      ) {
        queryStrings = GeoServerFilterFunctions.yesNoNullGeoServerFilter(
          filters,
          "initialRoutineInspection",
          queryStrings,
          "initial_routine_inspection",
        );
      }

      if (filters.openEnforcements && !FilterSummary.areAllCheckboxesChecked("openEnforcements")) {
        queryStrings = GeoServerFilterFunctions.yesNoNullGeoServerFilter(
          filters,
          "openEnforcements",
          queryStrings,
          "open_enforcements",
        );
      }

      if (filters.searchString) {
        queryStrings.push(
          GeoServerFilterFunctions.getSearchQuery(searchDbFields, filters.searchString),
        );
      }
      GeoServerFilterFunctions.addGeographicalFocusFiltersToQuery(filters, queryStrings, [
        { key: "drainsToRw", field: "drains_to_rw" },
        { key: "drainsToC", field: "drains_to_c" },
        { key: "catchmentName", field: "catchment_name" },
      ]);

      if (filters.dateTypeLidProject === "completed") {
        GeoServerFilterFunctions.addDateQuery(
          queryStrings,
          "project_end_date",
          filters.completed?.from,
          filters.completed?.to,
        );
      }
    }
  };

  var getFilterPhases = function (filters) {
    let phases = filters.status;

    if (filters.conditionPhaseToggle === "condition") {
      phases = phases.filter((phase) => ["completed", "certified"].includes(phase));
    }

    return phases;
  };

  function getProps() {
    const { urbanDrainageOptions, receivingWaterOptions, catchmentsOptions, highwayOptions } =
      Filters.getSpatialArrays();

    const performanceTierOptions = [
      { name: "No Criteria", value: "?" },
      { name: "Exempt", value: "0" },
      { name: "Site Design", value: "1" },
      { name: "Water Quality Treatment", value: "2" },
      { name: "Runoff Retention", value: "3" },
      { name: "Peak Management", value: "4" },
    ];

    const lidOwnershipOptions = [
      {
        name: "Our Agency",
        value: "our-agency",
      },
      {
        name: "Partner",
        value: "partner",
      },
      {
        name: "Private",
        value: "private",
      },
      {
        name: "DOT",
        value: "dot",
      },
      {
        name: "MS4",
        value: "ms4",
      },
      {
        name: "Local Agency",
        value: "local-agency",
      },
      {
        name: "Other",
        value: "other",
      },
      {
        name: "Unknown",
        value: "unknown",
      },
      {
        name: "No Data",
        value: "null",
      },
    ];

    const yesNoOptions = [
      { name: "Yes", value: true },
      { name: "No", value: false },
      { name: "No Data", value: "null" },
    ];

    const subGroupProps = Progeny.getSubGroupFilterProps();

    return {
      urbanDrainageOptions,
      receivingWaterOptions,
      catchmentsOptions,
      highwayOptions,
      phaseOptions: ToolSettings.getSetting("filterConstants", "phases"),
      performanceTierOptions: performanceTierOptions,
      lidOwnershipOptions: lidOwnershipOptions,
      yesNoOptions: yesNoOptions,
      subGroupOptions: subGroupProps?.subGroupOptions,
      subGroupSelected: subGroupProps?.subGroupSelected,
    };
  }

  var setSmartFilters = function (newSmartFilters) {
    mainSmartFilters = newSmartFilters;
  };

  var getFilterConfigs = function () {
    return {
      status: "phase",
      lidOwnership: "implementerType",
      maintainerType: "maintainerType",
      performanceTier: "performanceTier",
      alternativeCompliance: "alternativeCompliance",
      initialMaintenanceNotice: "initialMaintenanceNotice",
      initialRoutineInspection: "initialRoutineInspection",
      drainsToRw: "drains_to_rw",
      drainsToC: "drains_to_c",
      catchmentName: "catchment_name",
      highwayName: "highway_name",
      subGroups: "groupId",
      openEnforcements: "openEnforcements",

      otherFilters: (datum, filters) => {
        return (
          ResourceFilters.passSearchStringFilter(datum, filters, searchFields) &&
          passFeatureFlaggedCompletedDateFilter(datum, filters)
        );
      },
    };
  };

  return {
    filterResourceDataHandler,
    addGeoServerFilterQuery,
    passFeatureFlaggedCompletedDateFilter,
    getProps,
    getFilterConfigs,
    setSmartFilters,
  };
};

module.exports = PostConstructionProjectG2Filters();
const ApiError = require("../errors/apiError");
const FilterSummary = require("../filters/filterSummary");
const ResourceFilters = require("../filters/resourceFilters");
const GeoServerFilterFunctions = require("../mapFunctions/geoServerFilterFunctions");
const Tree = require("../tree");
const PostConstructionProjectGeoServerLayer = require("./postConstructionProjectGeoServerLayer");
const Filters = require("../mapFunctions/filters");
const ToolSettings = require("../settings/toolSettings");
const Progeny = require("../login/progeny");
