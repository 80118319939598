"use strict";

var SubmitConfig = function () {
  var getProgramImpact = function () {
    return [
      {
        dataProgram: "trashProgram",
        dataName: "trash group",
        displayName: "Trash Reduction",
        disabled: false,
        dataView: "trashView",
      },
      {
        dataProgram: "runoffProgram",
        dataName: "stormwater group",
        displayName: "Runoff Reduction",
        disabled: false,
        dataView: "runoffView",
      },
      {
        dataProgram: "particulateProgram",
        dataName: "stormwater group",
        displayName: "Particulate Reduction",
        disabled: false,
        dataView: "particulatesView",
      },
      {
        dataProgram: "runoffRatioProgram",
        dataName: "stormwater group",
        displayName: "Runoff Ratio Reduction",
        disabled: false,
        dataView: "runoffRatioView",
      },
    ];
  };

  var handleDataViewLinkDisable = function (dataView) {
    const selectedYear = Tree.get("yearDropdownSelection");
    if (dataView === "assetManagementView") {
      return selectedYear === 2021 ? false : true;
    }
  };

  var handleStreetSweepingDisable = function () {
    const selectedYear = Tree.get("yearDropdownSelection");
    return selectedYear >= 2023 ? false : true;
  };

  var getProgramCompliance = function () {
    var programCompliance = [
      {
        dataProgram: "watershedProgram",
        dataName: "stormwater group",
        dataView: "watershedView",
        displayName: "Watershed Characterization",
        disabled: false,
        linkSummaryEnabled: false,
        defaultStatus: "Multiple layers available",
        defaultHidden: true,
      },
      {
        dataProgram: "monitoringProgram",
        dataName: "water quality",
        dataView: null,
        displayName: "Water Quality Monitoring",
        disabled: false,
        linkSummaryEnabled: true,
        linkSummaryOnly: true,
      },
      {
        dataProgram: "assetProgram",
        dataName: "asset management",
        dataView: "assetManagementView",
        displayName: "Asset Lifecycle Optimization",
        disabled: false,
        linkSummaryEnabled: true,
        linkSummaryOnly: handleDataViewLinkDisable("assetManagementView"),
        displayTextOnly: Progeny.activeGroupIsRegulator(),
      },
      {
        dataProgram: "bmpProgram",
        dataName: "stormwater group",
        dataView: "sbmpView",
        displayName: "Structural BMP Performance",
        disabled: false,
      },
      {
        dataProgram: "fcsProgram",
        dataName: "trash group",
        dataView: "fcsView",
        displayName: "Public and Private Trash Full Capture Systems",
        disabled: false,
      },
      {
        dataProgram: "facilityProgram",
        dataName: "muni facility",
        dataView: "muniFacilityView",
        displayName: "Municipal Maintenance - Facilities",
        disabled: false,
        linkSummaryEnabled: false,
      },
      {
        dataProgram: "outfallProgram",
        dataName: "muni outfall",
        dataView: "outfallView",
        displayName: "Municipal Maintenance - Outfalls",
        disabled: false,
        linkSummaryEnabled: false,
      },
      {
        dataProgram: "muniBmpProgram",
        dataName: "muni bmp",
        dataView: "muniBmpView",
        displayName: "Municipal Maintenance - Structural BMPs",
        disabled: false,
        linkSummaryEnabled: false,
      },
      {
        dataProgram: "muniCatchBasinProgram",
        dataName: "muni catch basin",
        dataView: "muniCatchBasinView",
        displayName: "Municipal Maintenance - Catch Basins",
        disabled: false,
      },
      {
        dataProgram: "streetSweepingProgram",
        dataName: "stormwater group",
        dataView: "streetSweepingView",
        displayName: "Municipal Maintenance - Street Sweeping",
        disabled: handleStreetSweepingDisable(),
        linkSummaryEnabled: false,
        linkSummaryOnly: false,
        displayTextOnly: Progeny.activeGroupIsRegulator(),
      },
      {
        dataProgram: "iddeProgram",
        dataName: "idde",
        dataView: "iddeView",
        displayName: "Illicit Discharge Detection and Elimination",
        disabled: false,
        linkSummaryEnabled: false,
      },
      {
        dataProgram: "indcomProgram",
        dataName: "indcom",
        dataView: "indCommView",
        displayName: "Commercial and Industrial Facilities",
        disabled: false,
        linkSummaryEnabled: false,
        displayTextOnly: Progeny.activeGroupIsRegulator(),
      },
      {
        dataProgram: "constructionProgram",
        dataName: "construction",
        dataView: "constructionView",
        displayName: "Construction Site Management",
        disabled:
          FeatureFlag.enabled("new-construction-inspections") &&
          !FeatureFlag.enabled("annual-report-new-construction"),
      },
      {
        dataProgram: "lidProgram",
        dataName: "lid",
        dataView: "lidView",
        displayName: "Post-Construction Stormwater Management",
        disabled: false,
      },
      {
        dataProgram: "publicProgram",
        dataName: "public",
        dataView: "publicView",
        displayName: "Public Education and Outreach",
        disabled: false,
        linkSummaryEnabled: false,
        displayTextOnly: Progeny.activeGroupIsRegulator(),
      },
    ];

    return programCompliance;
  };

  var getAll = function () {
    const programImpact = getProgramImpact();
    const programCompliance = getProgramCompliance();

    programImpact.forEach((program) => (program.programType = "impact"));
    programCompliance.forEach((program) => (program.programType = "compliance"));

    return programImpact.concat(programCompliance);
  };

  return {
    getProgramImpact,
    getProgramCompliance,
    getAll,
    handleStreetSweepingDisable,
  };
};

module.exports = SubmitConfig();

const Progeny = require("../../login/progeny");
const FeatureFlag = require("../../settings/featureFlag");
const Tree = require("../../tree");
