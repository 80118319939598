"use strict";

const TableDataFunctions = function () {
  const outsideBoundaryCatchmentId = "outside boundary";

  var matchTableArray = function (
    name,
    layerData,
    layer = "catchments",
    sortingProperty = "catchid",
  ) {
    var filterArray = [];
    var layerArray = [];
    for (var i = 0; i < layerData.length; i++) {
      if (layerData[i].catchid.includes(name.toUpperCase())) {
        filterArray.push(layerData[i][sortingProperty]);
        layerArray.push(layerData[i]);
      }
    }
    Tree.set(["catchmentData"], layerArray); //catch array with data
    Tree.set(["filters", layer], filterArray);
    if (!layerArray.length) {
      $("#bottomFloatingInputsTable").hide();
    }
  };

  var prepareData = function (data, dataView = Tree.get("dataView"), isTodo = false) {
    if (dataView === "bmp") {
      LegacyBmpFcs.prepareTotalAreaData(data);
      LegacyBmpFcs.addNaIfBmpStatusIsNull(data);
      assignBmpTableIcons(data);
    } else if (dataView === "fcs") {
      LegacyBmpFcs.prepareTotalAreaData(data);
      LegacyBmpFcs.addNaIfBmpStatusIsNull(data);
      assignFcsTableIcons(data);
    } else if (
      dataView === "construction-project" ||
      dataView === "construction-project-delivery"
    ) {
      assignConstructionProjectIcons(data);
    } else if (dataView === "lid-project" || dataView === "lid-runoff") {
      assignLidProjectIcons(data, isTodo);
    } else if (dataView === "indcom-facilities") {
      // Icon already assigned in IndcomFacilityGeoServerLayer
    } else if (dataView === "muni-catch-basin") {
      assignMuniCatchBasinIcons(data);
    } else if (dataView === "muni-bmp" || dataView === "lid-bmp") {
      assignMuniBmpIcons(data);
    } else if (dataView === "muni-culvert") {
      assignMuniIcons(data);
    } else if (dataView === "muni-open-channel") {
      assignMuniIcons(data);
    } else if (dataView === "muni-manhole") {
      assignMuniIcons(data);
    } else if (dataView === "muni-cleanout") {
      assignMuniIcons(data);
    } else if (dataView === "muni-pipes") {
      assignMuniIcons(data);
    } else if (dataView === "muni-lift-stations") {
      assignMuniIcons(data);
    } else if (dataView === "dry-weather") {
      assignMonitoringLocationTableIcons(data);
    } else if (OutfallFunctions.isOutfallDataView(dataView)) {
      assignOutfallTableIcons(data);
    } else if (dataView === "incidents") {
      assignIncidentTableIcons(data);
    } else if (dataView === "asset-management" || dataView === "activities") {
      // no icons
    } else if (dataView === "scenarios") {
      assignScenarioTableIcons(data);

      // ESG
    } else if (dataView === "properties" || dataView === "muniCatchBasinView") {
      // no icons
    } else if (dataView === "sampling-site") {
      // Icon already assigned in SamplingSiteGeoServerLayer
    } else if (dataView === "wq-monitoring") {
      // Icon already assigned in WqMonitoringLocationGeoServerLayer
    } else {
      console.info(`No table icon assigned for data view ${dataView}.`);
    }
    return data;
  };

  var organizeData = function (layerData, preparedData) {
    var dataSort = Tree.get(["table", "dataSort"]);
    var descend = Tree.get(["filters", "descend"]);

    if (SpatialViewController.isCatchmentOrDrainageView()) {
      // @TODO: merge with isFlatList logic to sort by dataSort instead of dataView
      sortByDataViewInCatchmentView(preparedData, dataSort, descend);
      return preparedData;
    } else if (isFlatList(dataSort)) {
      sortByDataSort(preparedData, descend);
      return preparedData;
    } else {
      return sortAndGroupData(layerData, preparedData);
    }
  };

  var sortAndGroupData = function (catchments, data) {
    var sortDescending, dataSort;

    sortDescending = Tree.get("filters", "descend");
    dataSort = Tree.get("table", "dataSort");

    var groupedData = groupDataIntoDataSortLayer(catchments, data);

    if (dataSort === "uc") {
      niceSort(groupedData, "catchid", sortDescending);
      return groupedData;
    }

    if (DataSortFunctions.getCurrentDataSortProperty("isSpatialGrouping")) {
      groupedData = groupLayerDataIntoSpatialGrouping(groupedData, dataSort);
      sortGroupedData(groupedData, sortDescending);
      return groupedData;
    } else if (DataSortFunctions.getCurrentDataSortProperty("grouping") !== null) {
      groupedData = groupLayerData(groupedData);
      groupLayerDataForSecondaryGroupingSort(groupedData, sortDescending);
      sortGroupedData(groupedData, sortDescending);
      return groupedData;
    } else {
      const sortingProperty = DataSortFunctions.getCurrentDataSortProperty("sortingProperty");
      niceSort(groupedData, sortingProperty, sortDescending);
      return groupedData;
    }
  };

  var groupLayerDataForSecondaryGroupingSort = function (groupedData, sortDescending) {
    const secondaryGroupingSort =
      DataSortFunctions.getCurrentDataSortProperty("secondaryGroupingSort");
    if (secondaryGroupingSort) {
      const topLevelLayer = DataSortFunctions.getCurrentDataSortProperty("topLevelLayer");
      groupedData.forEach((groupedDatum) => {
        if (groupedDatum?.[topLevelLayer]?.length) {
          const secondaryGroupedData = groupLayerData(
            groupedDatum[topLevelLayer],
            secondaryGroupingSort,
          );
          sortGroupedData(secondaryGroupedData, sortDescending, secondaryGroupingSort);
          groupedDatum[secondaryGroupingSort] = secondaryGroupedData;
        }
      });
    }
  };

  var sortGroupedData = function (groupedData, sortDescending, dataSort) {
    const groupingLayer = DataSortFunctions.getCurrentDataSortProperty("topLevelLayer", dataSort);
    const sortingProperty = DataSortFunctions.getCurrentDataSortProperty(
      "sortingProperty",
      dataSort,
    );
    groupedData.forEach(function (group) {
      if (group) {
        niceSort(group[groupingLayer], sortingProperty, sortDescending);
      }
    });
  };

  var groupLayerData = function (layerData, dataSort = null) {
    const groupProperty = DataSortFunctions.getCurrentDataSortProperty("grouping", dataSort);
    const groupingLayer = DataSortFunctions.getCurrentDataSortProperty("topLevelLayer", dataSort);

    const groupedData = CleanData.groupByProp(layerData, groupProperty, groupingLayer);
    groupedData.forEach((group) => {
      if (group) {
        group.expanded = Table.isGroupExpanded(groupProperty, group[groupProperty]);
        group.selected = Table.isGroupSelected(groupProperty, group[groupProperty]);
      }
    });
    return groupedData;
  };

  var groupLayerDataIntoSpatialGrouping = function (layerData, dataSort = null) {
    const groupingProperty = DataSortFunctions.getCurrentDataSortProperty("grouping", dataSort);
    const topLevelLayer = DataSortFunctions.getCurrentDataSortProperty("topLevelLayer", dataSort);
    const dataGrouping = DataSortFunctions.getCurrentDataSortProperty("dataGrouping", dataSort);

    const spatialGroupings = Tree.get("spatialGroupings");

    if (dataSort in spatialGroupings) {
      const groupings = spatialGroupings[dataSort];

      groupings.forEach((grouping) => {
        if (grouping[topLevelLayer]?.length) {
          grouping.groupedItems = grouping[topLevelLayer];
          grouping.groupedItems.forEach((groupedItem) => {
            const matchedItem = layerData.find(
              (layerDataItem) => layerDataItem[dataGrouping] === groupedItem[dataGrouping],
            );
            if (matchedItem) {
              groupedItem.data = matchedItem.data;
              groupedItem.expanded = matchedItem.expanded;
              groupedItem.selected = matchedItem.selected;
            }
          });
        }
      });

      groupings.forEach((group) => {
        if (group) {
          group.expanded = Table.isGroupExpanded(groupingProperty, group.groupingName);
          group.selected = Table.isGroupSelected(groupingProperty, group.groupingName);
        }
      });

      return groupings;
    }

    return [];
  };

  var isFlatList = function (dataSort) {
    return DataSortFunctions.getCurrentDataSortProperty("isFlatList");
  };

  var sortByDataSort = function (data, descend) {
    const sortProperty = DataSortFunctions.getCurrentDataSortProperty("sortProperty");

    if (sortProperty === "status") {
      sortByBMPColor(data, descend);
    } else if (sortProperty === "type") {
      sortByBMPType(data, descend);
    } else if (sortProperty === "performanceTiers") {
      sortByLidPerformanceTiers(data, descend);
    } else if (sortProperty === "projectStatus") {
      sortByLidStatus(data, descend);
    } else if (sortProperty === undefined) {
      throw Error(`No sort property defined for data view ${Tree.get("table", "dataView")}.`);
    } else {
      niceSort(data, sortProperty, descend);
    }
  };

  var sortByDataViewInCatchmentView = function (data, dataSort, descend) {
    const dataViewToProperty = {
      "lid-runoff": "apn",
    };
    var dataView = Tree.get(["dataView"]);
    var propertyName = dataViewToProperty[dataView];

    niceSort(data, propertyName, descend);
  };

  var groupDataIntoDataSortLayer = function (dataSortLayerData, data) {
    const dataGrouping = DataSortFunctions.getCurrentDataSortProperty("dataGrouping");
    const topLevelLayer = DataSortFunctions.getCurrentDataSortProperty("topLevelLayer");
    const isGisDataView = DataViewFunctions.getCurrentDataViewProperty("isGisDataView");

    const dataSortLayerDataMap = isGisDataView
      ? getGisDataViewDataSortLayerDataMap(data, dataGrouping, topLevelLayer)
      : getDataSortLayerDataMap(data, dataGrouping, topLevelLayer);

    dataSortLayerData.forEach(function (layer) {
      layer.data = dataSortLayerDataMap[layer[dataGrouping]] || [];
      layer.expanded = Table.isPropertyExpanded(topLevelLayer, layer[dataGrouping]);
      layer.selected = Table.isPropertySelected(topLevelLayer, layer[dataGrouping]);
    });
    return dataSortLayerData;
  };

  var getDataSortLayerDataMap = function (data, dataGrouping, topLevelLayer) {
    var dataSortLayerDataMap = {};
    data.forEach((datum) => {
      let dataGroupingKeys = [];
      if (datum[topLevelLayer]) {
        if (datum[topLevelLayer]["formattedZoneId"]) {
          dataGroupingKeys = datum[topLevelLayer]["formattedZoneId"]
            .split(", ")
            .map((item) => parseInt(item));
        } else {
          dataGroupingKeys = [datum[topLevelLayer][dataGrouping]];
        }
      } else {
        dataGroupingKeys = [datum[dataGrouping]];
      }

      dataGroupingKeys.forEach((key) => {
        if (!dataSortLayerDataMap[key]) {
          dataSortLayerDataMap[key] = [];
        }
        dataSortLayerDataMap[key].push(datum);
      });
    });

    return dataSortLayerDataMap;
  };

  var getGisDataViewDataSortLayerDataMap = function (data, dataGrouping, topLevelLayer) {
    var dataSortLayerDataMap = {};
    var selectedKey = Tree.get("table", "expanded", topLevelLayer)?.[0] ?? null;

    dataSortLayerDataMap[selectedKey] = data;

    return dataSortLayerDataMap;
  };

  var assignConstructionProjectIcons = function (constructionProjects) {
    constructionProjects.forEach((project) => {
      project.tableIcon = ConstructionIcon.getConstructionProjectIconClass(project, false);
    });
  };

  var assignLidProjectIcons = function (lidProjects, isTodo) {
    lidProjects.forEach((project) => assignLidProjectIcon(project, isTodo));
  };

  var assignLidProjectIcon = function (project, isTodo) {
    const iconClass = LidProjectStyles.getNewLidProjectIconClass(project, isTodo);
    const fontColor = LidProjectStyles.getLidPerformanceFontColor(project, isTodo);
    project.tableIcon ||= iconClass + " g2-lid-table-icon-" + fontColor;
    project.tierNumber ||= LidIcon.getTierNumberFromG2Data(project);
  };

  var assignBmpTableIcons = function (assets) {
    assets.forEach(function (bmpFcs) {
      if (Tree.get("layers", "structuralBmps", "isEnabled")) {
        bmpFcs.tableIcon = StructuralBmpsGeoServerLayer.getTableIcon(bmpFcs);
      } else {
        bmpFcs.tableIcon = MapStyles.getBmpTodoIconClasses(bmpFcs);
      }
    });
  };

  var assignFcsTableIcons = function (assets) {
    assets.forEach(function (bmpFcs) {
      bmpFcs.tableIcon = MapStyles.getFcsTodoIconClasses(bmpFcs);
    });
  };

  var assignMuniCatchBasinIcons = function (data) {
    data.forEach(function (asset) {
      const theIcon = MuniCatchBasinIcon.getIconClass(
        asset.score,
        asset.routineMaintenanceDue,
        asset.isCatchBasinHighPriority,
      );
      asset.tableIcon = theIcon;
    });
  };

  var assignMuniBmpIcons = function (data) {
    data.forEach(function (asset) {
      asset.iconInfo = MuniBmpIcon.getIconInfo(
        asset.bmpTypeObj.isCentralized,
        asset.condition,
        asset.isDue,
        asset.bmpTypeObj.abbreviation,
      );
    });
  };

  var assignMuniIcons = function (data) {
    data.forEach(function (asset) {
      asset.tableIcon = asset.icon.class;
    });
  };

  var assignOutfallTableIcons = function (outfalls) {
    outfalls.forEach(function (outfall) {
      outfall.tableIcon = OutfallsIcon.getOutfallsIconClass(
        outfall.priority,
        outfall.condition,
        outfall.outfallInspectionIsDue,
      );
      outfall.tableIcon += _addExtraClassToStyleIconSizesOnTable("outfall");
    });
  };

  var assignIncidentTableIcons = function (incidents) {
    incidents.forEach(function (incident) {
      incident.tableIcon = IncidentsIcon.getIncidentsIconClass(incident.incidentStatus);
    });
  };

  var assignScenarioTableIcons = function (data) {
    data.forEach((bmp) => {
      bmp.iconClass = BmpFcsIcon.getTableIcon(null, bmp.centralized, bmp.fcs, null, bmp.phase);
    });
  };

  var assignMonitoringLocationTableIcons = function (monitoringLocations) {
    monitoringLocations.forEach(function (monitoringLocation) {
      monitoringLocation.tableIcon = DryWeatherIcon.getDryWeatherClass(monitoringLocation);
      monitoringLocation.tableIcon += _addExtraClassToStyleIconSizesOnTable(
        monitoringLocation.assetType,
      );
    });
  };

  var addOutliers = function (layerItems, data) {
    const topLevelLayer = DataSortFunctions.getCurrentDataSortProperty("topLevelLayer");
    if (getOutlierByLayer(topLevelLayer)) {
      if (topLevelLayer === "catchments" && getLegacyCatchmentId(data[0] ?? {})) {
        addLegacyOutlierCatchment(layerItems, data);
      } else {
        addOutlier(topLevelLayer, layerItems, data);
      }
    }
  };

  var getOutlierByLayer = function (topLevelLayer) {
    const layerToOutlierMap = {
      maintenanceZones: {
        gid: null,
        maintenanceZoneName: "Outside Maintenance Zones",
        acres: 0,
      },
      highways: {
        gid: null,
        highwayName: "Outside Highways",
      },
      catchments: {
        gid: null,
        catchid: outsideBoundaryCatchmentId,
        acres: 0,
        drains_to_rw: "Other",
        drains_to_c: "Other",
      },
    };

    return layerToOutlierMap[topLevelLayer];
  };

  var addOutlier = function (topLevelLayer, layerItems, data) {
    const outlier = getOutlierByLayer(topLevelLayer);
    let hasOutliers;

    if (DataViewFunctions.getCurrentDataViewProperty("isGisDataView")) {
      hasOutliers = true;
    } else {
      hasOutliers = standardizedOutliers(topLevelLayer, data);
    }

    if (hasOutliers) {
      layerItems.push(outlier);
    }
  };

  var standardizedOutliers = function (topLevelLayer, data) {
    let hasOutliers = false;

    for (const datum of data) {
      if (!datum[topLevelLayer]) {
        datum[topLevelLayer] = { gid: null };
      }

      if (datum[topLevelLayer].gid === null) {
        hasOutliers = true;
      }
    }

    return hasOutliers;
  };

  var getLegacyCatchmentId = function (datum) {
    return datum.catchmentId || datum.catchid;
  };

  var addLegacyOutlierCatchment = function (catchArray, data) {
    if (data.some((datum) => getLegacyCatchmentId(datum) === outsideBoundaryCatchmentId)) {
      catchArray.push(getOutlierByLayer("catchments"));
    }
  };

  var niceSort = function (items, property, descending) {
    items.sort(function (a, b) {
      var aProp = a[property];
      var bProp = b[property];

      var noAProp = !aProp || aProp === "—";
      var noBProp = !bProp || bProp === "—";

      var descendingMultiplier = getSortMultiplier(descending);
      if (noBProp && noAProp) {
        return 0;
      } else if (noAProp) {
        return descendingMultiplier * -1;
      } else if (noBProp) {
        return descendingMultiplier * 1;
      }

      var sorter = null;
      if (bProp === true || bProp === false || aProp === true || aProp === false) {
        sorter = booleanSorter;
      } else if (typeof aProp === "number" || typeof bProp === "number") {
        sorter = numberSorter;
      } else {
        sorter = stringSorter;
      }

      return sorter(aProp, bProp, descending);
    });
  };

  var booleanSorter = function (bool1, bool2, descending) {
    var descendingMultiplier = getSortMultiplier(descending);
    var result = bool1 === bool2 ? 0 : bool1 ? 1 : -1;
    return descendingMultiplier * result;
  };

  var numberSorter = function (num1, num2, descending) {
    var descendingMultiplier = getSortMultiplier(descending);
    var result = num1 - num2;
    return descendingMultiplier * result;
  };

  var stringSorter = function (str1, str2, descending) {
    str1 = convertUnderscoresHyphensToWhitespace(str1);
    str2 = convertUnderscoresHyphensToWhitespace(str2);
    var descendingMultiplier = getSortMultiplier(descending);
    return (
      descendingMultiplier *
      str1.localeCompare(str2, undefined, {
        numeric: true,
        sensitivity: "base",
      })
    );
  };

  var convertUnderscoresHyphensToWhitespace = function (str) {
    return str.replace(/_|\/|-/g, " ");
  };

  var getSortMultiplier = function (descending) {
    if (descending) {
      return -1;
    } else {
      return 1;
    }
  };

  var sortByLidPerformanceTiers = function (data, descend) {
    data.sort(function (a, b) {
      if (descend) {
        return getLidProjectTierSortNumber(b, descend) - getLidProjectTierSortNumber(a, descend);
      } else {
        return getLidProjectTierSortNumber(a, descend) - getLidProjectTierSortNumber(b, descend);
      }
    });
  };

  var getLidProjectTierSortNumber = function (data, descend) {
    var tier = LidIcon.getLidProjectTierNumber(data);

    if (tier === -1) {
      tier = descend ? Infinity : -Infinity;
    } else if (tier === "?") {
      tier = -1;
    }

    return tier;
  };

  var sortByLidStatus = function (data, descend) {
    data.sort(function (a, b) {
      if (descend) {
        return (
          ConstructionProjectDataListController.getPhaseOrder(b.phase) -
          ConstructionProjectDataListController.getPhaseOrder(a.phase)
        );
      } else {
        return (
          ConstructionProjectDataListController.getPhaseOrder(a.phase) -
          ConstructionProjectDataListController.getPhaseOrder(b.phase)
        );
      }
    });
  };

  var sortByBMPType = function (inventory, alphaDescend) {
    var sortOrder = getBmpColorSortOrder();
    const multiplier = getSortMultiplier(alphaDescend);

    inventory.sort(function (a, b) {
      if (a.bmp_type_abbr > b.bmp_type_abbr) return 1 * multiplier;
      if (a.bmp_type_abbr < b.bmp_type_abbr) return -1 * multiplier;

      if (sortOrder.indexOf(a.color) > sortOrder.indexOf(b.color)) return 1 * multiplier;
      if (sortOrder.indexOf(a.color) < sortOrder.indexOf(b.color)) return -1 * multiplier;

      if (a.bmpName.toLowerCase() > b.bmpName.toLowerCase()) return 1 * multiplier;
      if (a.bmpName.toLowerCase() < b.bmpName.toLowerCase()) return -1 * multiplier;
    });
  };

  var sortByBMPColor = function (inventory, alphaDescend) {
    const sortOrder = getBmpColorSortOrder();
    const multiplier = getSortMultiplier(alphaDescend);

    inventory.sort(function (a, b) {
      if (sortOrder.indexOf(a.color) > sortOrder.indexOf(b.color)) return 1 * multiplier;
      if (sortOrder.indexOf(a.color) < sortOrder.indexOf(b.color)) return -1 * multiplier;

      if (a.bmp_type_abbr > b.bmp_type_abbr) return 1 * multiplier;
      if (a.bmp_type_abbr < b.bmp_type_abbr) return -1 * multiplier;

      if (a.bmpName.toLowerCase() > b.bmpName.toLowerCase()) return 1 * multiplier;
      if (a.bmpName.toLowerCase() < b.bmpName.toLowerCase()) return -1 * multiplier;
    });
  };

  var _addExtraClassToStyleIconSizesOnTable = function (assetType) {
    const currentDataView = Tree.get("dataView");
    if (["bmpFcs", "manhole"].includes(assetType)) {
      return " shrink";
    }
    return ` ${currentDataView}-${assetType}-icon`;
  };

  var getBmpColorSortOrder = function () {
    return [
      "certified",
      "completed",
      "construction",
      "planned",
      "planning",
      "green",
      "yellow",
      "orange",
      "red",
      "shelved",
      "rejected",
    ];
  };

  return {
    niceSort,
    prepareData,
    organizeData,
    matchTableArray,
    sortByLidPerformanceTiers,
    getLidProjectTierSortNumber,
    sortByLidStatus,
    assignOutfallTableIcons,
    addOutliers,
    numberSorter,
    _addExtraClassToStyleIconSizesOnTable,
    assignLidProjectIcon,
  };
};

module.exports = TableDataFunctions();

const BmpFcsIcon = require("../bmpfcs/bmpFcsIcon");
const CleanData = require("../mapFunctions/cleanData");
const ConstructionIcon = require("../construction/constructionIcon");
const DataSortFunctions = require("../dataSortFunctions");
const DataViewFunctions = require("../dataViewFunctions");
const DryWeatherIcon = require("../illicitDischarge/dryWeatherIcon");
const IncidentsIcon = require("../illicitDischarge/incidents/incidentsIcon");
const LegacyBmpFcs = require("../bmpfcs/legacyBmpFcs");
const LidIcon = require("../lid/lidIcon");
const LidProjectStyles = require("../lid/lidProjectStyles");
const MapStyles = require("../mapFunctions/mapStyles");
const MuniBmpIcon = require("../muni/bmp/muniBmpIcon");
const MuniCatchBasinIcon = require("../muni/muniCatchBasinIcon");
const OutfallFunctions = require("../outfalls/outfallFunctions");
const OutfallsIcon = require("../outfalls/outfallsIcon");
const SpatialViewController = require("../mapFunctions/spatialViewController");
const Table = require("../mapFunctions/table");
const Tree = require("../tree");
const StructuralBmpsGeoServerLayer = require("../bmpFcsG2/structuralBmpsGeoServerLayer");
const ConstructionProjectDataListController = require("../construction/constructionProjectDataListController");
