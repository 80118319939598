"use strict";

const DryWeatherConstants = function () {
  // General Info
  const getSubmergedOptions = () => ToolSettings.getDomainOptions("submerged_option");

  // Field Measurements
  const flowEstimationDescriptionOptions = [
    {
      name: "Trickle",
      value: "trickle",
    },
    {
      name: "Moderate",
      value: "moderate",
    },
    {
      name: "Substantial",
      value: "substantial",
    },
  ];

  const flowEstimationRateUnitOptions = [
    {
      name: "",
      value: "",
    },
    {
      name: "cfs",
      value: "cfs",
    },
    {
      name: "gpm",
      value: "gpm",
    },
  ];

  var getOdorDescriptionOptions = function () {
    return FormSettings.getDropdownOptions("odor-description");
  };

  var getFollowUpActionOptions = function () {
    return FormSettings.getDropdownOptions("follow-up-action", { tool: "idde" });
  };

  const getOdorSeverityOptions = () => ToolSettings.getDomainOptions("odor_severity");

  var getColorDescriptionOptions = function () {
    return FormSettings.getDropdownOptions("color-description");
  };

  const getColorSeverityOptions = () => ToolSettings.getDomainOptions("color_severity");

  var getTurbidityDescriptionOptions = function () {
    return FormSettings.getDropdownOptions("turbidity-description");
  };

  var getFloatablesDescriptionOptions = function () {
    return FormSettings.getDropdownOptions("floatables-description");
  };

  const floatablesSeverityOptions = [
    {
      name: "Few/slight",
      value: "few-slight",
    },
    {
      name: "Some - origin unclear",
      value: "some-origin-unclear",
    },
    {
      name: "Some - origin clear",
      value: "some-origin-clear",
    },
  ];

  const getMeasurementParameterOptions = () =>
    ToolSettings.getDomainOptions("measurement_parameter");

  const measurementParameterUnits = {
    temperature: [
      {
        name: "°C",
        value: "c",
      },
      {
        name: "°F",
        value: "f",
      },
    ],
    conductivity: [
      {
        name: "mS/cm",
        value: "ms/cm",
      },
      {
        name: "uS/cm",
        value: "us/cm",
      },
    ],
    turbidity: [
      {
        name: "NTU",
        value: "ntu",
      },
      {
        name: "FNU",
        value: "fnu",
      },
    ],
    detergent: [
      {
        name: "mg/L",
        value: "mg/l",
      },
    ],
    ammonia: [
      {
        name: "mg/L",
        value: "mg/l",
      },
    ],
    do: [
      {
        name: "mg/L",
        value: "mg/l",
      },
    ],
    chlorine: [
      {
        name: "mg/L",
        value: "mg/l",
      },
    ],
    "e-coli": [
      {
        name: "MPN/100 mL",
        value: "mpn/100-ml",
      },
      {
        name: "CFU/100 mL",
        value: "cfu/100-ml",
      },
    ],
    salinity: [
      {
        name: "PPT",
        value: "ppt",
      },
      {
        name: "PSU",
        value: "psu",
      },
    ],
  };

  const getMeasurementParameterUnitOptions = function () {
    var options = [];

    for (const key in measurementParameterUnits) {
      options = options.concat(measurementParameterUnits[key]);
    }

    return options;
  };

  const getMeasurementMethodOptions = () =>
    ToolSettings.getDomainOptions("measurement_parameter_method");

  const getSampleMethodOptions = () => ToolSettings.getDomainOptions("sample_method");

  const getLocationCollectedFromOptions = () =>
    ToolSettings.getDomainOptions("sample_location_collected_from");

  const getCloseOutReasonOptions = () =>
    FormSettings.getDropdownOptions("close-out-reason", { tool: "idde" });

  var getBasicDryWeatherDefaults = function (assetId, assetType, activeMaintenanceIssues) {
    const data = {
      assetId,
      assetType,
      fieldPersonnel: Session.loadContext().full_name,
      date: DateTime.getNowIso(),
      outfallSubmergedInWater: "no",
      flowPresent: false,
      standingWaterPresent: false,
      samplesCollected: false,
      conductFieldMeasurements: FeatureFlag.enabled("sampling-site-inspection") ? true : null,
      collectSamples: FeatureFlag.enabled("sampling-site-inspection") ? false : null,
      illicitDischarge: false,
      followUpNeeded: false,
      closeOutInvestigation: false,
      assetAccessible:
        RoutineMaintenanceConstants.getAssetAccessibleDefault(activeMaintenanceIssues),
      accessIssue: RoutineMaintenanceConstants.getAccessIssueDefault(activeMaintenanceIssues),
    };

    return data;
  };

  var getObservationInfoDefaults = function () {
    return {
      observationInfo: {
        flowEstimationRateUnit: "cfs",
        odorDescription: "none",
        colorDescription: "clear",
        turbidityDescription: "clear",
        floatablesDescription: "none",
      },
    };
  };

  var getNewDryWeatherScreeningDefaults = function (id, assetType, activeMaintenanceIssues) {
    return {
      ...getBasicDryWeatherDefaults(id, assetType, activeMaintenanceIssues),
      ...getObservationInfoDefaults(),
    };
  };

  var getAccessIssueOptions = function () {
    return FormSettings.getDropdownOptions("access-issue", { tool: "muni" });
  };

  return {
    getSubmergedOptions,
    getAccessIssueOptions,
    getNewDryWeatherScreeningDefaults,
    flowEstimationDescriptionOptions,
    flowEstimationRateUnitOptions,
    getOdorSeverityOptions,
    getColorSeverityOptions,
    floatablesSeverityOptions,
    getSampleMethodOptions,
    getLocationCollectedFromOptions,
    getCloseOutReasonOptions,
    getMeasurementParameterOptions,
    measurementParameterUnits,
    getMeasurementMethodOptions,
    getOdorDescriptionOptions,
    getColorDescriptionOptions,
    getFloatablesDescriptionOptions,
    getTurbidityDescriptionOptions,
    getFollowUpActionOptions,
    getMeasurementParameterUnitOptions,
  };
};

module.exports = DryWeatherConstants();

const DateTime = require("../../dateTime");
const FormSettings = require("../../settings/formSettings");
const Session = require("../../login/session");
const RoutineMaintenanceConstants = require("../../bmpfcs/routineMaintenanceConstants");
const ToolSettings = require("../../settings/toolSettings");
const FeatureFlag = require("../../settings/featureFlag");
