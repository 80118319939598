"use strict";

const ProjectDeliveryPhaseModal = function () {
  const $modal = $("#project-phase-modal");
  const formKey = "project-phase";
  var inited = false;
  var props;

  var init = function () {
    loadDomListeners();
    inited = true;
  };

  var loadDomListeners = function () {
    $modal.off("click", ".save-button", saveButtonClick);
    $modal.on("click", ".save-button", saveButtonClick);
    $modal.off("click", ".cancel-button", _closeModalIfConfirmed);
    $modal.on("click", ".cancel-button", _closeModalIfConfirmed);
    $modal.off("2N:FormInput", `[name="phase"]`, updatePhase);
    $modal.on("2N:FormInput", `[name="phase"]`, updatePhase);
    $modal.off("2N:FormInput", "[name^='stageData']", validateForm);
    $modal.on("2N:FormInput", "[name^='stageData']", validateForm);
    ProjectStageData.loadStageDataListeners($modal, {
      formKey,
      isInventory: false,
      limitToPast: true,
    });
  };

  var updatePhase = function () {
    props.phase = $(this).val();

    const formData = Form.getDataFromForm(formKey, false);
    if (formData.stageData) {
      $.extend(true, props.stageData, formData.stageData);
    }

    const stage = ProjectStageData.getStageByPhase(props.phase);
    if (!props.stageData[stage]) {
      props.stageData[stage] = {};
    }
    props.stageData[stage].stage = stage;

    renderForm();
  };

  var saveButtonClick = function () {
    MessageModal.showConfirmWarningModal(
      `This will update the phase for the project ${props.projectIdentifier} as well as the ${props.bmpCount} structural BMPs associated with it.`,
      saveProjectPhase,
      "Go Back",
      "Change Phase",
    );
  };

  var saveProjectPhase = async function () {
    var data = Form.getDataFromForm(formKey);
    var projectId = props.id;

    if ($.isEmptyObject(data)) {
      close();
    } else {
      var formData = Form.toFormData({
        constructionProjectId: projectId,
        constructionProjectUpdates: data,
      });
      $modal.find(".modal-footer .save-btn").addClass("disabled").text("Saving");

      await ApiCalls.updateConstructionProject(formData);
      if (Tree.get("tool") === "scenario") {
        ScenariosLayer.loadLayer();
      } else {
        ConstructionProjectLayer.loadLayer();
      }

      close();
    }
  };

  var _closeModalIfConfirmed = function () {
    if (Object.keys(Form.getDataFromForm(formKey, false)).length === 0) {
      close();
    } else {
      MessageModal.showConfirmWarningModal(
        null,
        close,
        "Go Back",
        "Close Without Saving",
        CommonModalFunctions.getCloseWithoutSavingPrompt(),
      );
    }
  };

  var renderAndShow = function (project) {
    if (!inited) init();
    Form.initializeAndLoadListeners($modal, formKey);
    props = Object.assign({}, project);
    const html = nunjucks.render("modals/projectInventory/projectDeliveryPhaseModal.njk", props);
    $modal.html(html);
    $modal.modal("show");
    renderForm(props);
    Analytics.sendScreenView("modal", formKey);
  };

  var renderForm = function () {
    props.projectPhaseOptions = ProjectInventoryConstants.projectPhaseOptions;
    props.rejectionReasonOptions = ProjectInventoryConstants.rejectionReasonOptions;
    props.stageData = ProjectStageData.getRenderStageData(props.stageData);
    const html = nunjucks.render("modals/projectInventory/projectDeliveryPhaseForm.njk", props);
    $("#project-phase-form").html(html);
    initializeFields();
  };

  var initializeFields = function () {
    Form.initializeAutoNumericFields($modal);
    Form.initializeDatePickers($modal, formKey);
    ProjectStageData.updateAllStageDataDatePickers(props.stageData);
    validateForm();
  };

  var validateForm = function () {
    var isValid = true;

    for (const element of $modal.find("[name^='stageData']")) {
      if (!$(element).val()) {
        isValid = false;
        break;
      }
    }

    Misc.toggleDisabled($modal.find(".save-button"), !isValid);
  };

  var close = function () {
    Form.clearForm(formKey);
    $modal.modal("hide");
    inited = false;
    Analytics.sendScreenView();
  };

  return {
    renderAndShow,
    close,
    _closeModalIfConfirmed,
  };
};

module.exports = ProjectDeliveryPhaseModal();

const ApiCalls = require("../apiCalls");
const Tree = require("../tree");
const MessageModal = require("../modals/messageModal");
const ProjectInventoryConstants = require("./projectInventoryConstants");
const CommonModalFunctions = require("../modals/commonModalFunctions");
const Form = require("../general/form");
const ProjectStageData = require("./projectStageData");
const Misc = require("../misc");
const Analytics = require("../general/analytics");
const ScenariosLayer = require("../scenario/scenariosLayer");
const ConstructionProjectLayer = require("../construction/constructionProjectLayer");
