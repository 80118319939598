"use strict";

const WqMonitoringGeoServerLayerFilters = function () {
  const searchDbFields = ["location_identifier"];
  const searchFields = ["locationIdentifier"];

  var filterResourceDataHandler = async function (unfilteredData, filters, loadingScreen) {
    if (onlineFilterIsSet(filters)) {
      try {
        return await ResourceFilters.filterOnline(
          unfilteredData,
          loadingScreen,
          WqMonitoringLocationGeoServerLayer.getLayerData,
        );
      } catch (e) {
        ApiError.assertIs(e);
      }
    }

    return filterOffline(unfilteredData, filters);
  };

  var filterOffline = function (unfilteredData, filters) {
    return unfilteredData.filter((datum) => {
      return ResourceFilters.passSearchStringFilter(datum, filters, searchFields);
    });
  };

  var onlineFilterIsSet = function (filters) {
    return ResourceFilters.spatialFiltersAreSet(filters);
  };

  var addGeoServerViewParams = function (params, filters) {};

  var addGeoServerFilterQuery = function (queryStrings, filters) {
    if (filters.searchString) {
      queryStrings.push(
        GeoServerFilterFunctions.getSearchQuery(searchDbFields, filters.searchString),
      );
    }
  };

  return {
    filterResourceDataHandler,
    addGeoServerFilterQuery,
    addGeoServerViewParams,
  };
};

module.exports = WqMonitoringGeoServerLayerFilters();

const ApiError = require("../errors/apiError");
const ResourceFilters = require("../filters/resourceFilters");
const GeoServerFilterFunctions = require("../mapFunctions/geoServerFilterFunctions");
const WqMonitoringLocationGeoServerLayer = require("./wqMonitoringLocationGeoServerLayer");
