"use strict";

const RoutineMaintenanceFormGeneral = function () {
  var assetAccessibleOnlyTabs;

  var loadListeners = function ($page, data, newAssetAccessibleOnlyTabs, Controller = null) {
    assetAccessibleOnlyTabs = newAssetAccessibleOnlyTabs;

    $page.on("2N:FormInput", `[name="asset-accessible"]`, (e, isAccessible) =>
      toggleAssetAccessible($page, isAccessible),
    );

    if (Controller) {
      $page.on("2N:FormInput", `[name="flow-present"]`, () => flowRadioToggle(Controller));
      $page.on("2N:FormInput", `[name="standing-water-present"]`, () =>
        flowRadioToggle(Controller),
      );
      $page.on("2N:FormInput", "[name='monitoring-location-id']", (e, setMonitoringLocationId) =>
        setMonitoringLocationResult($page, setMonitoringLocationId, data),
      );

      setMonitoringLocationResult($page, data.monitoringLocationId);
    }

    toggleAssetAccessible($page, data.assetAccessible);
  };

  var loadListenersWqMonitoringWorkflow = function ($page, data, Controller = null) {
    $page.on("2N:FormInput", `[name="inspection-type"]`, (e, inspectionType) =>
      toggleInspectionType($page, inspectionType),
    );
    toggleInspectionType($page, data.inspectionType);

    if (Controller) {
      $page.on("2N:FormInput", "[name='date'], [name='last-precipitation-date']", () =>
        updateDaysSinceLastRainfall($page, Controller),
      );
      updateDaysSinceLastRainfall($page, Controller);
    }
  };

  var unloadListeners = function ($page) {
    $page.off("2N:FormInput", `[name="asset-accessible"]`);
    $page.off("2N:FormInput", `[name="flow-present"]`);
    $page.off("2N:FormInput", `[name="standing-water-present"]`);
    $page.off("2N:FormInput", "[name='monitoring-location-id']");
    $page.off("2N:FormInput", `[name="inspection-type"]`);
    $page.off(
      "2N:FormInput",
      "[name='date'], [name='last-precipitation-date']",
      updateDaysSinceLastRainfall,
    );
  };

  var toggleInspectionType = function ($element, inspectionType) {
    const $modal = $element.closest(".modal-content");
    const showMeasurementSampleTabs = ["wq-monitoring", "idde-screening"].includes(inspectionType);
    TabbedModal.toggleTab("measurements", showMeasurementSampleTabs);
    TabbedModal.toggleTab("sample", showMeasurementSampleTabs);

    const showIllicitDischargeTab = inspectionType === "idde-screening";
    TabbedModal.toggleTab("illicit-discharge", showIllicitDischargeTab);

    if (inspectionType === "routine-inspection") {
      $modal
        .find(`[name="inspect-structural-condition-maintenance"][value="true"]`)
        .prop("checked", true)
        .trigger("input");
      $modal.find(`[name="inspect-structural-condition-maintenance"]`).prop("disabled", true);
    } else {
      $modal.find(`[name="inspect-structural-condition-maintenance"]`).prop("disabled", false);
    }
  };

  var toggleAssetAccessible = function ($element, setIsAccessible = null) {
    const $modal = $element.closest(".modal-content");
    let isAccessible = setIsAccessible;

    if (isAccessible === null) {
      isAccessible = $modal.find(`[name="asset-accessible"]:checked`).val() === "true";
    } else {
      $modal.find(`[name="asset-accessible"][value="${isAccessible}"]`).prop("checked", true);
    }

    _toggleAssetAccessibleSections($modal, isAccessible);

    if (assetAccessibleOnlyTabs?.length) {
      TabbedModal.toggleExclusivelyShowTabs(
        !isAccessible,
        "showHardAssets",
        assetAccessibleOnlyTabs,
      );
    }
  };

  var _toggleAssetAccessibleSections = function ($modal, isAccessible) {
    const hasCheckedNewIssues = $modal.find(`[name$="[issue]"][value="true"]:checked`).length > 0;
    const hasNoAccessIssue = $modal.find(`[data-issue-key="asset-accessible"]`).length > 0;

    $modal
      .find(`[name="asset-accessible"]`)
      .prop("disabled", hasCheckedNewIssues || hasNoAccessIssue);
    $modal.find(`[data-issue-key="asset-accessible"]`).prop("disabled", hasCheckedNewIssues);

    if (hasNoAccessIssue) {
      $modal.find(`[name="access-issue"]`).prop("disabled", true);
    }

    $modal.find(`.asset-accessible-hide-when-accessible`).toggle(!isAccessible);

    if (
      FeatureFlag.enabled("sampling-site-inspection") &&
      $modal.find(`.show-when-accessible-and-inspect`).length > 0
    ) {
      OutfallRoutineMaintenanceCondition.toggleInspectConditionFields(isAccessible);
    } else {
      $modal.find(`.asset-accessible-show-when-accessible`).toggle(isAccessible);
    }
  };

  var addProps = function (props, data) {
    props.accessIssueOptions = DryWeatherConstants.getAccessIssueOptions();
    props.submergedOptions = DryWeatherConstants.getSubmergedOptions();
    props.precipTypeOptions = OutfallInspectionConstants.getPrecipitationTypeOptions();
  };

  var getTabName = function () {
    return "General Info";
  };

  var flowRadioToggle = function (Controller) {
    const standingWater = Controller.getFormData(["standingWaterPresent"]);
    const flowPresent = Controller.getFormData(["flowPresent"]);
    const showMeasurementsSamples = standingWater === true || flowPresent === true;

    DryWeatherController.toggleFlowTabs(showMeasurementsSamples);
  };

  var setMonitoringLocationResult = function ($page, setMonitoringLocationId, data = {}) {
    const relatedMonitoringLocations = data.asset?.relatedMonitoringLocations;

    setMonitoringLocationId = parseInt(setMonitoringLocationId);

    if (isNaN(setMonitoringLocationId)) {
      return;
    }

    const locationResult =
      relatedMonitoringLocations?.find((location) => location.value === setMonitoringLocationId)
        ?.displayCondition ?? "—";

    $page.find(".monitoring-location-id-result").text(locationResult);
  };

  var updateDaysSinceLastRainfall = function ($page, Controller) {
    const date = Controller.getInspectionRenderData().date;
    const lastRainfallDate = Controller.getInspectionRenderData().lastPrecipitationDate;

    const daysSinceLastRainfall = getDaysSinceLastRainfall(date, lastRainfallDate);

    $page.find(".days-since-last-rainfall").text(daysSinceLastRainfall);
  };

  var getDaysSinceLastRainfall = function (startTime, lastRainfallDate) {
    if (!lastRainfallDate || !startTime) {
      return null;
    }

    lastRainfallDate = DateTime.parseIsoDate(lastRainfallDate);
    startTime = DateTime.parseIsoDate(startTime);

    return DateTime.getDaysDifferenceNumber(startTime, lastRainfallDate);
  };

  return {
    loadListeners,
    loadListenersWqMonitoringWorkflow,
    unloadListeners,
    addProps,
    getTabName,
    toggleAssetAccessible,
    flowRadioToggle,
  };
};

module.exports = RoutineMaintenanceFormGeneral();

const DateTime = require("../dateTime");
const DryWeatherConstants = require("../illicitDischarge/dryWeatherScreening/dryWeatherConstants");
const OutfallInspectionConstants = require("../outfalls/outfallInspectionConstants");
const TabbedModal = require("./tabbedModal");
const DryWeatherController = require("../illicitDischarge/dryWeatherScreening/dryWeatherController");
const OutfallRoutineMaintenanceCondition = require("../outfalls/routineMaintenance/outfallRoutineMaintenanceCondition");
const FeatureFlag = require("../settings/featureFlag");
