"use strict";

const WqMonitoringResult = function () {
  var getWqMonitoringResultData = function (assetName, data) {
    const messageColor = "grey";
    const message = data.samples?.length
      ? `Samples were collected at ${assetName}.`
      : `Samples were not collected at ${assetName}.`;

    return {
      messageColor,
      message,
    };
  };

  return {
    getWqMonitoringResultData,
  };
};

module.exports = WqMonitoringResult();
