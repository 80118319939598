"use strict";

const RoadOmPage = function () {
  const stringKey = "road-om";
  let $page;

  var loadListeners = function (data) {
    $page = $(`#assessment-modal #${stringKey}`);

    _renderSpatialSelection();
    $page.on("2N:FormInput", `[name="spatialSelect"]`, _onSpatialSelect);
    $page.on("click", `[name="addSpatial"]`, _onAddSpatialClick);
    $page.on("click", `.delete-button`, _onDeleteButtonClick);
  };

  var unloadListeners = function () {
    $page.off("2N:FormInput", `[name="spatialSelect"]`, _onSpatialSelect);
    $page.off("click", `[name="addSpatial"]`, _onAddSpatialClick);
    $page.off("click", `.delete-button`, _onDeleteButtonClick);
  };

  var getProps = function (data) {
    return {};
  };

  var getStringKey = function () {
    return stringKey;
  };

  var getTabHeader = function () {
    return null;
  };

  var _onSpatialSelect = function (e, value, path, formState) {
    const selected = formState.spatialSelect ?? [];

    $page.find(`[name="addSpatial"]`).prop("disabled", selected.length === 0);
  };

  var _onAddSpatialClick = function () {
    const data = RoadOmModalController.getUpdateData();
    const selectedOptions = _getSpatialSelectedOptions(data);
    const index = _getNextSpatialConditionIndex(data);

    const html = nunjucks.render("modals/muni/roads/roadOmSpatialCondition.njk", {
      index,
      header: _getCardHeader(selectedOptions),
      sweeperTypeSelectOptions: RoadBulkOmOptions.sweeperTypeSelectOptions,
      curbAccessibilitySelectOptions: RecordSweepConstants.curbAccessibilitySelectOptions,
      debrisCollectedUnitOptions: RecordSweepConstants.debrisCollectedUnitOptions,
      sweeperFrequencySelectOptions: RoadBulkOmOptions.sweeperFrequencySelectOptions,
      averagePciSelectOptions: RoadBulkOmOptions.averagePciSelectOptions,
      priorityOptions: RoadBulkOmOptions.priorityOptions,
    });
    $page.find("#js-road-om-spatial-selection").after(html);
    CollapsingCard.initializeCards($page);

    RoadOmModalController.manuallySetFormDataField(["spatialSelect"], null);
    _setNewSpatialSectionData(index, selectedOptions);
    _renderSpatialSelection();
  };

  var _getSpatialSelectedOptions = function (data) {
    const spatialSelectValues = Session.isNewDataDrivenRoadConditionGroup()
      ? data.spatialSelect.map((value) => String(value))
      : data.spatialSelect;
    const spatialData = RoadOmModalController.getNormalizedSpatialData();
    if (!Array.isArray(spatialSelectValues)) {
      return spatialData.dataOptions;
    } else {
      return spatialData.dataOptions.filter((data) => spatialSelectValues.includes(data.value));
    }
  };

  var _getCardHeader = function (selectedOptions) {
    return selectedOptions.map((data) => data.name).join(", ");
  };

  var _getNextSpatialConditionIndex = function (data) {
    const spatialCondition = data.spatialCondition ?? [];
    return spatialCondition.length;
  };

  var _onDeleteButtonClick = function (data) {
    const $deleteButton = $(this);
    const index = $deleteButton.data("id");
    $deleteButton.closest(".card").remove();
    RoadOmModalController.manuallySetFormDataField(["spatialCondition", index], null);
    _renderSpatialSelection();
  };

  var _setNewSpatialSectionData = function (index, selectedOptions) {
    RoadOmModalController.manuallySetFormDataField(["spatialCondition", index], {
      spatialIds: selectedOptions.map((option) => option.value),
    });
  };

  var _renderSpatialSelection = function () {
    const data = RoadOmModalController.getUpdateData();
    const spatialData = RoadOmModalController.getNormalizedSpatialData();

    const html = nunjucks.render("modals/muni/roads/roadOmSpatialSelection.njk", {
      displayName: spatialData.displayName,
      dataOptions: _getSpatialSelectionOptions(data),
      spatialSelect: data.spatialSelect,
    });
    $page.find("#js-road-om-spatial-selection").html(html);

    MultipleSelect.init();
  };

  var _getSpatialSelectionOptions = function (data) {
    const usedSpatialIds = _getUsedSpatialIdSet(data);
    const spatialData = RoadOmModalController.getNormalizedSpatialData();

    return spatialData.dataOptions.filter((option) => !usedSpatialIds.has(option.value));
  };

  var _getUsedSpatialIdSet = function (data) {
    const spatialConditions = data.spatialCondition ?? [];
    const usedSpatialIds = new Set();

    for (const data of spatialConditions) {
      for (const id of data?.spatialIds ?? []) {
        usedSpatialIds.add(id);
      }
    }

    return usedSpatialIds;
  };

  return {
    loadListeners,
    unloadListeners,
    getStringKey,
    getTabHeader,
    getProps,
    _onSpatialSelect,
  };
};

module.exports = RoadOmPage();

const MultipleSelect = require("../../general/multipleSelect");
const RoadOmModalController = require("./roadOmModalController");
const RoadBulkOmOptions = require("../../bulkSelect/roadBulkOmOptions");
const RecordSweepConstants = require("../recordSweepConstants");
const CollapsingCard = require("../../general/collapsingCard");
const Session = require("../../login/session");
