"use strict";

const MultipleSelect = function () {
  let $parent;

  const init = function (parent = "body") {
    $parent = $(parent);
    _loadListeners();
    renderSelectedItems();
  };

  const _loadListeners = function () {
    $parent.off("click", _closeSearch).on("click", _closeSearch);
    $(".multiple-select")
      .off("click", ".glyphicon-search, .selected-items", _expand)
      .on("click", ".glyphicon-search, .selected-items", _expand)
      .off("input", ".select-container input", _onInputSearch)
      .on("input", ".select-container input", _onInputSearch)
      .off("click", ".clear-search", _onClickClearSearch)
      .on("click", ".clear-search", _onClickClearSearch)
      .off("input", ".dropdown input", _onChangeCheckbox)
      .on("input", ".dropdown input", _onChangeCheckbox)
      .off("click", ".selected-items .remove", _onClickRemoveItem)
      .on("click", ".selected-items .remove", _onClickRemoveItem)
      .off("click", ".select-all", _onClickSelectAll)
      .on("click", ".select-all", _onClickSelectAll)
      .off("click", ".only", _onClickOnly)
      .on("click", ".only", _onClickOnly);
  };

  const _closeSearch = function (e) {
    const $target = $(e.target);
    if (
      !$target.closest(".multiple-select").length &&
      !$target.hasClass("remove") &&
      !$(".multiple-select.waiting-for-result").length
    ) {
      _collapseAll($target);
    }
  };

  const _expand = function (e) {
    if ($(e.target).hasClass("remove")) return;
    const $multipleSelect = $(this).closest(".multiple-select");
    if ($multipleSelect.hasClass("waiting-for-result")) return;
    _collapseAll();
    $multipleSelect.addClass("searching");
    _focusSearch($multipleSelect);
  };

  const _focusSearch = function ($multipleSelect) {
    $multipleSelect.find(".search-container input").focus();
  };

  const getFiltersToTally = function () {
    return $(".multiple-select")
      .filter(function () {
        const $multipleSelect = $(this);
        return (
          $multipleSelect.find(".dropdown input:not(:checked)").length &&
          !$multipleSelect.hasClass("searching")
        );
      })
      .map(function () {
        return $(this).data("name");
      })
      .get();
  };

  const addTallyToOptions = function (tally) {
    for (const filterKey in tally) {
      const $multipleSelect = $(`.multiple-select[data-name="${filterKey}"]`);
      for (const option in tally[filterKey]) {
        const count = tally[filterKey][option];
        const $checkBoxContainer = $multipleSelect.find(
          `.checkbox-container[data-option="${option}"]`,
        );
        $checkBoxContainer.toggleClass("hidden", count === 0);
        if (count === 0) continue;

        $checkBoxContainer.removeClass("hidden");
        const $input = $checkBoxContainer.find("input");
        const display = `${$input.data("name")} (${count})`;
        $input.siblings("span").text(display);
      }
    }
  };

  const _onInputSearch = function () {
    const searchString = $(this).val();
    const $checkbox = $(this).closest(".multiple-select").find(".checkbox-container");
    FilterDom.filterByText(searchString, $checkbox);
  };

  const _onClickClearSearch = function () {
    const $checkbox = $(this).closest(".multiple-select").find(".checkbox-container");
    FilterDom.filterByText("", $checkbox);
    $(this).closest(".select-container").find("input").val("").focus();
  };

  const _collapseAll = function () {
    $(".multiple-select").removeClass("searching");
  };

  const _onChangeCheckbox = function () {
    updateDropdown($(this));
  };

  const updateDropdown = function ($input) {
    const $multipleSelect = $input.first().closest(".multiple-select");

    const $checked = $multipleSelect.find(
      ".dropdown .checkbox-container:not(.hidden) input:checked:not(:disabled)",
    );

    if (!_allowNoSelection($multipleSelect) && !$checked.length) {
      $multipleSelect.find(".dropdown input:not(:disabled)").prop("checked", true);
    }

    renderSelectedItems($multipleSelect);
    _focusSearch($multipleSelect);
  };

  const _allowNoSelection = function ($multipleSelect) {
    return $multipleSelect.data("allow-no-selection") === true;
  };

  const _onClickRemoveItem = function () {
    const $selectedItem = $(this).closest(".selected-item");
    const $checkbox = $selectedItem
      .closest(".multiple-select")
      .find(`.dropdown input[value="${$selectedItem.data("value")}"]`);
    $checkbox.prop("checked", false).trigger("input");
  };

  const _onClickSelectAll = function () {
    const $checkboxes = $(this).closest(".multiple-select").find(".dropdown input:not(:disabled)");
    $checkboxes.prop("checked", true);
    $checkboxes.first().trigger("input");
  };

  const _onClickOnly = function (e) {
    e.preventDefault();

    const $checkbox = $(this).closest(".checkbox-container").find("input:not(:disabled)");
    const $multipleSelect = $checkbox.closest(".multiple-select");
    const $checkboxes = $multipleSelect.find(".dropdown input:not(:disabled)");
    $checkboxes.prop("checked", false);
    $checkbox.prop("checked", true);
    $checkbox.trigger("input");
  };

  const renderSelectedItemsForFilters = function (filters) {
    filters.forEach((filter) => {
      renderSelectedItems($(`.multiple-select[data-name="${filter}"]`));
    });
  };

  const renderSelectedItems = function ($multipleSelects = $(".multiple-select")) {
    $multipleSelects.each(function () {
      const $multipleSelect = $(this);
      const placeholder = $multipleSelect.data("placeholder");
      const $selectedItems = $multipleSelect.find(".selected-items");
      const $allCheckboxes = $multipleSelect.find(".dropdown input");
      const $checkedCheckboxes = $allCheckboxes.filter(":checked");
      const allChecked = $checkedCheckboxes.length === $allCheckboxes.length;
      const selectedItems = [];

      if (_allowNoSelection($multipleSelect) || !allChecked) {
        _pushAllCheckboxes(selectedItems, $checkedCheckboxes);
      }

      $selectedItems.html(
        nunjucks.render(getSelectedItemsTemplate($multipleSelect), {
          selectedItems,
          placeholder,
          allChecked,
        }),
      );
    });
  };

  const getSelectedItemsTemplate = function ($multipleSelect) {
    if ($multipleSelect.data("countSelected")) {
      return "macros/macroTemplates/multipleSelectCountSelectedItems.njk";
    } else {
      return "macros/macroTemplates/multipleSelectSelectedItems.njk";
    }
  };

  const _pushAllCheckboxes = function (selectedItems, $checkedCheckboxes) {
    $checkedCheckboxes.each(function () {
      const $checkbox = $(this);
      const name = $checkbox.data("name");
      const value = $checkbox.val();
      selectedItems.push({ name, value });
    });
  };

  const hideNoData = function (data) {
    const $hideMe = $("[data-hide-if-no-data-key]");
    if (!$hideMe.length) return false;
    $hideMe.each(function () {
      const $this = $(this);
      const key = $this.data("hide-if-no-data-key");
      $this.toggleClass("hidden", _dataIsAllNullsAtKey(data, key));
    });
    return true;
  };

  const _dataIsAllNullsAtKey = function (data, key) {
    const isValueValid = (val) =>
      val != null && val !== "" && !(Array.isArray(val) && val.length === 0);

    return !data.some((item) =>
      Array.isArray(item[key]) ? item[key].some(isValueValid) : isValueValid(item[key]),
    );
  };

  const getData = function (all = false) {
    const data = {};
    $(".multiple-select").each(function () {
      const $multipleSelect = $(this);
      if (!$multipleSelect.find(".dropdown input").length) return;
      const name = $multipleSelect.find(".dropdown input:first").attr("name") || "";
      const $checkboxes = $multipleSelect.find(`.dropdown input${all ? "" : ":checked"}`);
      const values = [];
      $checkboxes.each(function () {
        values.push($(this).val());
      });
      data[name] = values;
    });
    return data;
  };

  const setData = function (data) {
    for (const name in data) {
      const $multipleSelect = $(`.multiple-select[data-name="${name}"]`);
      const $checkboxes = $multipleSelect.find(".dropdown input");
      const values = data[name];
      $checkboxes.each(function () {
        const $checkbox = $(this);
        const value = $checkbox.val();
        $checkbox.prop("checked", values.includes(value));
      });
    }
    renderSelectedItems();
  };

  return {
    init,
    getData,
    setData,
    hideNoData,
    getFiltersToTally,
    addTallyToOptions,
    renderSelectedItems,
    renderSelectedItemsForFilters,
    updateDropdown,
    _dataIsAllNullsAtKey,
  };
};

module.exports = MultipleSelect();

const FilterDom = require("./filterDom");
