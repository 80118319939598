"use strict";

const BmpFcsInventoryConstants = function () {
  const phaseOptions = [
    {
      name: "Planning",
      value: "planning",
    },
    {
      name: "Design",
      value: "planned",
    },
    {
      name: "Construction",
      value: "construction",
    },
    {
      name: "Post Active",
      value: "post-active",
    },
    {
      name: "Completed",
      value: "completed",
    },
    {
      name: "Certified",
      value: "certified",
    },
    {
      name: "Rejected",
      value: "rejected",
    },
    {
      name: "Shelved",
      value: "shelved",
    },
  ];

  const phaseOptionsCB = [
    {
      name: "Planning",
      value: "planning",
    },
    {
      name: "Design",
      value: "planned",
    },
    {
      name: "Construction",
      value: "construction",
    },

    {
      name: "Completed",
      value: "completed",
    },
    {
      name: "Certified",
      value: "certified",
    },
    {
      name: "Rejected",
      value: "rejected",
    },
    {
      name: "Shelved",
      value: "shelved",
    },
    {
      name: "Abandoned",
      value: "abandoned",
    },
    {
      name: "Removed",
      value: "removed",
    },
    {
      name: "Unknown",
      value: "unknown",
    },
    {
      name: "No Data",
      value: "null",
    },
  ];

  const yesNoNull = [
    {
      name: "Yes",
      value: true,
    },
    {
      name: "No",
      value: false,
    },
    {
      name: "No Data",
      value: "null",
    },
  ];

  const ownershipOptions = [
    {
      name: "Public",
      value: "public",
    },
    {
      name: "Private",
      value: "private",
    },
    {
      name: "Partner",
      value: "partner",
    },
  ];

  const bmpTypeOptions = [
    {
      name: "Select Type",
      value: 16,
    },
    {
      name: "(BF) Bed Filter",
      value: 6,
    },
    {
      name: "(BI) Biofiltration",
      value: 8,
    },
    {
      name: "(BR) Bioretention",
      value: 13,
    },
    {
      name: "(BS) Bioswale",
      value: 15,
    },
    {
      name: "(CT) Cistern",
      value: 22,
    },
    {
      name: "(DT) Detention Basin",
      value: 14,
    },
    {
      name: "(DB) Dry Basin",
      value: 1,
    },
    {
      name: "(FD) Filtration Device",
      value: 17,
    },
    {
      name: "(GR) Green Roof",
      value: 20,
    },
    {
      name: "(IB) Infiltration Basin",
      value: 3,
    },
    {
      name: "(IF) Infiltration Feature",
      value: 9,
    },
    {
      name: "(MF) Media Filter",
      value: 5,
    },
    {
      name: "(PP) Pervious Pavement",
      value: 10,
    },
    {
      name: "(RN) Rain Barrel",
      value: 21,
    },
    {
      name: "(RB) Retention Basin",
      value: 19,
    },
    {
      name: "(ST) Sediment Trap",
      value: 11,
    },
    {
      name: "(SB) Settling Basin",
      value: 7,
    },
    {
      name: "(TT) Trash Trap",
      value: 18,
    },
    {
      name: "(TV) Treatment Vault",
      value: 4,
    },
    {
      name: "(WB) Wet Basin",
      value: 2,
    },
    {
      name: "(?) Unknown",
      value: 16,
    },
  ];

  const bmpTypeOptionsNoAbbreviations = [
    {
      name: "Select type",
      value: 16,
    },
    {
      name: "Bed Filter",
      value: 6,
    },
    {
      name: "Biofiltration",
      value: 8,
    },
    {
      name: "Bioretention",
      value: 13,
    },
    {
      name: "Bioswale",
      value: 15,
    },
    {
      name: "Cistern",
      value: 22,
    },
    {
      name: "Detention Basin",
      value: 14,
    },

    {
      name: "Dry Basin",
      value: 1,
    },
    {
      name: "Filtration Device",
      value: 17,
    },
    {
      name: "Green Roof",
      value: 20,
    },
    {
      name: "Infiltration Basin",
      value: 3,
    },
    {
      name: "Infiltration Feature",
      value: 9,
    },
    {
      name: "Media Filter",
      value: 5,
    },
    {
      name: "Pervious Pavement",
      value: 10,
    },
    {
      name: "Rain Barrel",
      value: 21,
    },
    {
      name: "Retention Basin",
      value: 19,
    },
    {
      name: "Sediment Trap",
      value: 11,
    },
    {
      name: "Settling Basin",
      value: 7,
    },

    {
      name: "Treatment Vault",
      value: 4,
    },
    {
      name: "Wet Basin",
      value: 2,
    },
    {
      name: "Trash Trap",
      value: 18,
    },
    {
      name: "Unknown",
      value: 16,
    },
    {
      name: "No Data",
      value: "null",
    },
  ];

  const bmpTypeOptionsNoAbbreviationsWithNullAndUnknown = [
    {
      name: "Bed Filter",
      value: "6",
    },
    {
      name: "Biofiltration",
      value: "8",
    },
    {
      name: "Bioretention",
      value: "13",
    },
    {
      name: "Bioswale",
      value: "15",
    },
    {
      name: "Cistern",
      value: "22",
    },
    {
      name: "Detention Basin",
      value: "14",
    },

    {
      name: "Dry Basin",
      value: "1",
    },
    {
      name: "Filtration Device",
      value: "17",
    },
    {
      name: "Green Roof",
      value: "20",
    },
    {
      name: "Infiltration Basin",
      value: "3",
    },
    {
      name: "Infiltration Feature",
      value: "9",
    },
    {
      name: "Media Filter",
      value: "5",
    },
    {
      name: "Pervious Pavement",
      value: "10",
    },
    {
      name: "Rain Barrel",
      value: "21",
    },
    {
      name: "Retention Basin",
      value: "19",
    },
    {
      name: "Sediment Trap",
      value: "11",
    },
    {
      name: "Settling Basin",
      value: "7",
    },

    {
      name: "Treatment Vault",
      value: "4",
    },
    {
      name: "Wet Basin",
      value: "2",
    },
    {
      name: "Unknown",
      value: "16",
    },
    {
      name: "No Data",
      value: "null",
    },
  ];

  const fcsTypeOptionsNoAbbreviations = [
    {
      name: "Unknown",
      value: 16,
    },
    {
      name: "Trash Trap",
      value: 18,
    },
    {
      name: "Drop Inlet",
      value: 12,
    },
    {
      name: "Bed filter",
      value: 6,
    },
    {
      name: "Biofiltration",
      value: 8,
    },
    {
      name: "Bioretention",
      value: 13,
    },

    {
      name: "Detention basin",
      value: 14,
    },

    {
      name: "Dry basin",
      value: 1,
    },
    {
      name: "Filtration device",
      value: 17,
    },

    {
      name: "Infiltration basin",
      value: 3,
    },
    {
      name: "Infiltration feature",
      value: 9,
    },
    {
      name: "Media filter",
      value: 5,
    },

    {
      name: "Sediment trap",
      value: 11,
    },
    {
      name: "Settling basin",
      value: 7,
    },

    {
      name: "Treatment vault",
      value: 4,
    },
    {
      name: "No Data",
      value: "null",
    },
  ];

  const catchBasinPriorityOptions = [
    {
      name: "Very High",
      value: "very-high",
    },
    {
      name: "High",
      value: "high",
    },
    {
      name: "Moderate",
      value: "moderate",
    },
    {
      name: "Low",
      value: "low",
    },
    {
      name: "Very Low",
      value: "very-low",
    },
  ];

  const cofOptions = [
    {
      name: "Very High",
      value: "very-high",
    },
    {
      name: "High",
      value: "high",
    },
    {
      name: "Moderate",
      value: "moderate",
    },
    {
      name: "Low",
      value: "low",
    },
    {
      name: "Very Low",
      value: "very-low",
    },
  ];
  const cofOptionsFlagged = [
    {
      name: "Very High",
      value: "very-high",
    },
    {
      name: "High",
      value: "high",
    },
    {
      name: "Moderate",
      value: "moderate",
    },
    {
      name: "Low",
      value: "low",
    },
    {
      name: "Very Low",
      value: "very-low",
    },
    {
      name: "Unknown",
      value: "unknown",
    },
    {
      name: "No Data",
      value: "null",
    },
  ];
  const datumOptions = [
    {
      name: "EGM 96",
      value: "egm96",
    },
    {
      name: "EGM 2008",
      value: "egm2008",
    },
    {
      name: "NAVD 1929",
      value: "navd1929",
    },
    {
      name: "NGVD 1988",
      value: "ngvd1988",
    },
    {
      name: "Other",
      value: "other",
    },
    {
      name: "WGS 84",
      value: "wgs84",
    },
    {
      name: "Unknown",
      value: "unknown",
    },
  ];

  const unitOptions = [
    {
      name: "ft",
      value: "feet",
    },
    {
      name: "in",
      value: "inches",
    },
  ];

  const propertyTypeOptions = [
    {
      name: "Partner",
      value: 2,
      responsiblePartyMaintenance: "partner",
    },
    {
      name: "Private",
      value: 1,
      responsiblePartyMaintenance: "private",
    },
    {
      name: "Our Agency",
      value: 0,
      responsiblePartyMaintenance: "our-agency",
    },
    {
      name: "DOT",
      value: 3,
      responsiblePartyMaintenance: "dot",
    },
    {
      name: "MS4",
      value: 4,
      responsiblePartyMaintenance: "ms4",
    },
    {
      name: "Local Agency",
      value: 5,
      responsiblePartyMaintenance: "local-agency",
    },
    {
      name: "Other",
      value: 6,
      responsiblePartyMaintenance: "other",
    },
    {
      name: "Unknown",
      value: 7,
      responsiblePartyMaintenance: "unknown",
    },
  ];

  const systemAccessOptions = [
    {
      name: "Open Air",
      value: true,
    },
    {
      name: "Closed Space",
      value: false,
    },
  ];

  const volumeUnitOptions = [
    {
      name: "cu-ft",
      value: "cubicFeet",
    },
    {
      name: "ac-ft",
      value: "acreFeet",
    },
    {
      name: "gallons",
      value: "gallons",
    },
  ];

  const lengthUnitOptions = [
    {
      name: "ft",
      value: "feet",
    },
    {
      name: "in",
      value: "inches",
    },
    {
      name: "m",
      value: "meters",
    },
    {
      name: "cm",
      value: "centimeters",
    },
  ];

  const areaUnitOptions = [
    {
      name: "sq-ft",
      value: "squareFeet",
    },
    {
      name: "ac",
      value: "acres",
    },
  ];

  const wetPoolCapacityUnitOptions = [
    {
      name: "cu-ft",
      value: "cubicFeet",
    },
    {
      name: "ac-ft",
      value: "acreFeet",
    },
  ];

  const multiSystemOptions = [
    {
      name: "Multi-benefit System",
      value: true,
    },
    {
      name: "Trash Treatment Device",
      value: false,
    },
  ];

  const volumeBasedOptions = [
    {
      name: "Volume-based Retention",
      value: true,
    },
    {
      name: "Flow-based Treatment",
      value: false,
    },
  ];

  const treatmentTrainOptions = [
    {
      name: "⟶",
      value: "1",
    },
    {
      name: "⟵",
      value: "2",
    },
  ];

  const nonTreatmentTrainsTypes = [22];

  const treatedStormTypeOptions = [
    {
      name: "24-hour Percentile",
      value: "24-hr-percentile",
    },
    {
      name: "24-hour Depth",
      value: "24-hr-depth",
    },
  ];

  const treatedStormPercentileOptions = [
    {
      name: "85th",
      value: "85th",
    },
    {
      name: "95th",
      value: "95th",
    },
  ];

  const responsiblePartyDeliveryOptions = [
    {
      name: "Partner",
      value: "partner",
    },
    {
      name: "Private",
      value: "private",
    },
    {
      name: "Our Agency",
      value: "our-agency",
    },
    {
      name: "DOT",
      value: "dot",
    },
    {
      name: "MS4",
      value: "ms4",
    },
    {
      name: "Local Agency",
      value: "local-agency",
    },
    {
      name: "Other",
      value: "other",
    },
    {
      name: "Unknown",
      value: "unknown",
    },

    {
      name: "No Data",
      value: "null",
    },
  ];

  const catchBasinTypeOptions = [
    {
      name: "Catch Basin",
      value: 11,
    },
    {
      name: "Catch Basin",
      value: 12,
    },
  ];

  const catchBasinTypeOptionsProperName = [
    {
      name: "Drop Inlet",
      value: 12,
    },
    {
      name: "Sediment Trap",
      value: 11,
    },
  ];
  const catchBasinBasicFields = [];
  const bmpBasicFields = [
    "inlets",
    "outlets",
    "visible-screen",
    "system-access",
    "treated-storm-type",
    "treated-storm-percentile",
    "treated-storm-depths-inches",
  ];

  const materialOptions = [
    {
      name: "Aluminum",
      value: "aluminum",
    },
    {
      name: "Amended Soil",
      value: "amended-soil",
    },
    {
      name: "Asphalt",
      value: "asphalt",
    },
    {
      name: "Biochar",
      value: "biochar",
    },
    {
      name: "Bioretention Soil",
      value: "bioretention-soil",
    },
    {
      name: "Brick",
      value: "brick",
    },
    {
      name: "Cast Iron",
      value: "cast-iron",
    },
    {
      name: "Concrete",
      value: "concrete",
    },
    {
      name: "Copper",
      value: "copper",
    },
    {
      name: "Corrugated Metal",
      value: "corrugated-metal",
    },
    {
      name: "Cured in Place",
      value: "cured-in-place",
    },
    {
      name: "Ductile Iron",
      value: "ductile-iron",
    },
    {
      name: "Grass",
      value: "grass",
    },
    {
      name: "Gravel",
      value: "gravel",
    },
    {
      name: "HDPE",
      value: "hdpe",
    },
    {
      name: "Iron-Enhanced Filter",
      value: "iron-enhanced-filter",
    },
    {
      name: "Mulch",
      value: "mulch",
    },
    {
      name: "Native Soil",
      value: "native-soil",
    },
    {
      name: "Other",
      value: "other",
    },
    {
      name: "Pavers",
      value: "pavers",
    },
    {
      name: "Permeable Pavement",
      value: "permeable-pavement",
    },
    {
      name: "PVC",
      value: "pvc",
    },
    {
      name: "Reinforced Concrete",
      value: "reinforced-concrete",
    },
    {
      name: "Riprap",
      value: "riprap",
    },
    {
      name: "Rock",
      value: "rock",
    },
    {
      name: "Sand Compost Mix",
      value: "sand-compost-mix",
    },
    {
      name: "Sand Filter",
      value: "sand-filter",
    },
    {
      name: "Spent Lime",
      value: "spent-lime",
    },
    {
      name: "Steel",
      value: "steel",
    },
    {
      name: "Truss Pipe",
      value: "truss-pipe",
    },
    {
      name: "Unknown",
      value: "unknown",
    },
    {
      name: "Vitrified Clay",
      value: "vitrified-clay",
    },
    {
      name: "Wood",
      value: "wood",
    },
  ];

  var shapeOptions = [
    {
      name: "Circular",
      value: "circular",
    },
    {
      name: "Other",
      value: "other",
    },
    {
      name: "Rectangle",
      value: "rectangle",
    },
    {
      name: "Square",
      value: "square",
    },
    {
      name: "Trapezoid",
      value: "trapezoid",
    },
    {
      name: "Unknown",
      value: "unknown",
    },
  ];

  const inletTypeOptions = [
    {
      name: "Apron inlet",
      value: "apron-inlet",
    },
    {
      name: "Combination",
      value: "combination",
    },
    {
      name: "Curb-Opening inlet",
      value: "curb-opening-inlet",
    },
    {
      name: "Deck drain (scupper) inlet",
      value: "scupper-inlet",
    },
    {
      name: "Flanking inlet",
      value: "flanking-inlet",
    },
    {
      name: "Grate inlet",
      value: "grate-inlet",
    },
    {
      name: "Pipe",
      value: "pipe",
    },
    {
      name: "Slotted inlet / Trench drain",
      value: "slotted-inlet",
    },
    {
      name: "Unknown",
      value: "unknown",
    },
  ];

  const depthUnitOptions = [
    {
      name: "ft",
      value: "feet",
    },
    {
      name: "in",
      value: "inches",
    },
  ];

  const responsiblePartyMaintenanceOptions = [
    {
      name: "Partner",
      value: "partner",
    },
    {
      name: "Private",
      value: "private",
    },
    {
      name: "Our Agency",
      value: "our-agency",
    },
    {
      name: "DOT",
      value: "dot",
    },
    {
      name: "MS4",
      value: "ms4",
    },
    {
      name: "Local Agency",
      value: "local-agency",
    },
    {
      name: "Other",
      value: "other",
    },
    {
      name: "Unknown",
      value: "unknown",
    },
  ];

  const bmpFcsInventoryFieldLabels = {
    planned: "Planned",
    drainage_selection: "Drainage selection",
    bmp_type: "sBMP/FCS type",
    inspect_freq: "Trash FCS inspection frequency",
    bmp_inspect_freq: "Functional assessment frequency",
    bmp_ptype: "Property type",
    multi_system: "FCS grouping",
    treats_storm: "1YR-1HR storm",
    volume_based: "FCS function",
    system_access: "System access",
    traps_particles: "Small particle trapping",
    state_approved: "State approval",
    bmp_manufacturer: "Manufacturer",
    bmp_namemodel: "Model name",
    bmp_creation: "Installation date",
    planFiles: "O&M plan",
    designFiles: "Design plan",
    visible_screen: "Screen visibility",
    screen_count: "Screen count",
    bmp_imparea: "Impervious area",
    bmp_inlets: "Number of inlets",
    bmp_outlets: "Number of outlets",
    bmp_wqcap: "Treatment capacity",
    bmp_footprint: "Footprint",
    bmp_irate: "Infiltration rate",
    bmp_drawdown: "Drawdown time",
    bmp_wet: "Wet pool capacity",
    bmp_trate: "Treatment rate",
    inv_stwat: "Ports to observe standing water",
    inv_sedacc: "Ports to observe material accumulation",
    maint_interval: "Maintenance interval",
    bmp_flow: "Low flow bypass",
    diversion: "Dry weather diversion",
    drainsout: "Capture & reuse",
    flowLength: "Total flow path length",
    phase: "Asset phase",
    treated_storm_type: "Design storm type",
    treated_storm_percentile: "Treated storm",
    treated_storm_depths_inches: "Treated storm depths",
    inlet_type: "Catch basin inlet type",
    total_depth: "Total depth",
    cleanout: "Cleanout",
    sump: "Sump present",
    footprint_material: "Footprint material",
    wall_material: "Wall material",
    shape: "Shape",
    length: "Length",
    width: "Width",
    bmp_diam: "Diameter",
  };

  const cBmpTelrRequiredFields = ["flow-length", "drainage"];

  var getTelrRequiredFieldsByType = function (type) {
    switch (parseInt(type)) {
      case 4:
      case 5:
      case 6:
        return ["treatment-capacity", "footprint", "treatment-rate"];
      case 14:
        return ["treatment-capacity", "footprint", "drawdown-time"];
      case 1:
        return ["treatment-capacity", "footprint", "infiltration-rate", "drawdown-time"];
      case 3:
        return ["treatment-capacity", "footprint", "infiltration-rate"];
      case 19:
        return ["treatment-capacity", "footprint", "wet-pool-capacity"];
      case 2:
        return ["treatment-capacity", "footprint", "wet-pool-capacity", "drawdown-time"];
      case 22:
        return ["treatment-capacity", "footprint"];
      default:
        return [];
    }
  };

  var getAllMaintenanceTasks = function () {
    const allMaintenanceTasks = [
      {
        key: "access",
        aliases: ["access-inlet", "access-outlet"],
      },
      {
        key: "active-erosion",
        aliases: ["active-erosion-inlet", "active-erosion-outlet"],
        selectNoOptionMessage: "Description",
        selectOptions: [
          {
            name: "<10%",
            value: "less-than-10",
          },
          {
            name: "10-25%",
            value: "10-25",
          },
          {
            name: "26-50%",
            value: "26-50",
          },
          {
            name: ">50%",
            value: "greater-than-50",
          },
        ],
      },
      {
        key: "biological-growth",
        selectNoOptionMessage: "Description",
        selectOptions: [
          {
            name: "Mosquito Larvae",
            value: "mosquito-larvae",
          },
          {
            name: "Algae",
            value: "algae",
          },
          {
            name: "Other",
            value: "other",
          },
        ],
      },
      {
        key: "bioretention-filter-maintenance",
        numberPlaceholder: "#",
        autoNumericTypeClass: "positive-float-tenth",
        selectOptions: [
          { name: "ac", value: "acres" },
          { name: "sq-yds", value: "square-yards" },
          { name: "sq-meters", value: "square-meters" },
        ],
        numberUnit: "square-yards",
      },
      {
        key: "fcs-device",
        fcsOnly: true,
      },
      {
        key: "medallion-label",
      },
      {
        key: "debris-clogging",
        aliases: ["debris-clogging-inlet", "debris-clogging-outlet"],
        numberPlaceholder: "#",
        autoNumericTypeClass: "positive-float-tenth",
        selectOptions: lengthUnitOptions,
        numberUnit: "inches",
        correctionOptions: ["cleaned", "further-review"],
        levelOptions: ["clean"],
      },
      {
        name: "Debris/Clogging",
        key: "clogging",
        autoNumericTypeClass: "percentage",
        numberPlaceholder: "%",
      },
      {
        key: "dewinterize-thaw",
      },
      {
        key: "sediment-debris-removal",
      },
      {
        key: "stabilization",
      },
      {
        key: "mowing-trimming",
        numberPlaceholder: "#",
        autoNumericTypeClass: "positive-float-tenth",
        selectOptions: [
          { name: "ac", value: "acres" },
          { name: "sq-yds", value: "square-yards" },
          { name: "sq-meters", value: "square-meters" },
        ],
        numberUnit: "acres",
      },
      {
        key: "piping-evidence",
      },
      {
        key: "planting-maintenance",
        numberPlaceholder: "#",
        autoNumericTypeClass: "positive-float-tenth",
        selectOptions: [
          { name: "ac", value: "acres" },
          { name: "sq-yds", value: "square-yards" },
          { name: "sq-meters", value: "square-meters" },
        ],
        numberUnit: "square-yards",
      },
      {
        key: "stains",
        selectNoOptionMessage: "Description",
        selectOptions: [
          {
            name: "Oily",
            value: "oily",
          },
          {
            name: "Flow Line",
            value: "flow-line",
          },
          {
            name: "Paint",
            value: "paint",
          },
          {
            name: "Course Particulate",
            value: "course-particulate",
          },
          {
            name: "Fine Particulate",
            value: "fine-particulate",
          },
          {
            name: "Other",
            value: "other",
          },
        ],
      },
      {
        key: "damage",
        aliases: ["damage-inlet", "damage-outlet"],
        selectNoOptionMessage: "Description",
        selectOptions: [
          {
            name: "Spalling/Cracking/Chipping",
            value: "spalling-cracking-chipping",
          },
          {
            name: "Peeling Paint",
            value: "peeling-paint",
          },
          {
            name: "Corrosion",
            value: "corrosion",
          },
          {
            name: "Other",
            value: "other",
          },
        ],
      },

      {
        key: "branch-pruning",
        numberPlaceholder: "# trees",
        autoNumericTypeClass: "positive-float-tenth",
      },

      {
        key: "trench-cleaning",
        numberPlaceholder: "#",
        autoNumericTypeClass: "positive-float-tenth",
        selectOptions: [
          { name: "ft", value: "feet" },
          { name: "yds", value: "yards" },
          { name: "meters", value: "meters" },
        ],
        numberUnit: "feet",
      },
      {
        key: "vegetation-growth",
        selectNoOptionMessage: "Description",
        selectOptions: [
          {
            name: "None",
            value: "none",
          },
          {
            name: "Less than 10%",
            value: "less-than-10",
          },
          {
            name: "10-25%",
            value: "10-25",
          },
          {
            name: "26-50%",
            value: "26-50",
          },
          {
            name: "Greater than 50%",
            value: "greater-than-50",
          },
        ],
      },
      {
        key: "asset-accessible",
      },
    ];

    return ToolSettings.addNameToOptions("constants", "maintenanceTasks", allMaintenanceTasks, {
      optionKey: "key",
    });
  };

  var findMaintenanceTask = function (key) {
    const foundTask = getAllMaintenanceTasks().find(function (task) {
      return task.key === key || task.aliases?.includes(key);
    });
    return $.extend(true, {}, foundTask);
  };

  var getDefaultMaintenanceTasks = function (bmpType, fcs) {
    bmpType = parseInt(bmpType);

    const enabledTasks = RoutineMaintenanceConfig.getConfiguredMaintenanceTasks(`bmp-${bmpType}`);

    return getAllMaintenanceTasks()
      .filter(function (task) {
        if (!enabledTasks.includes(task.key)) {
          return false;
        }

        return task.fcsOnly === true ? fcs : true;
      })
      .map(function (task) {
        delete task.fcsOnly;

        return task;
      });
  };

  var getNewBmpFcsInventoryDefaults = function (mode) {
    const today = DateTime.getTodayIso();
    const tool = ToolFunctions.getActiveTool();

    const defaults = {
      installationDate: today,
      phase: "completed",
      associatedProject: null,
      bmp: tool !== "trashram",
      fcs: tool === "trashram",
      bmpType: mode === "muni" ? 12 : 16,
      property: propertyTypeOptions[2].value,
      // Same as property (2 = our-agency)
      responsiblePartyMaintenance: propertyTypeOptions[2].responsiblePartyMaintenance,
      visibleScreen: false,
      systemAccess: true,
      lowFlow: false,
      diversion: false,
      drainsout: false,
      drawMethod: "drawing-tools",
      areaPolygons: [],
      possibleAssociatedProjects: [],
      additional: [],
      summary: [],
      plan: [],
      design: [],
      treatedStormType: "24-hr-percentile",
      treatedStormPercentile: "85th",
      maintenanceObservationStructuralCondition: true,
      maintenanceObservationStructuralConditionInlet: false,
      maintenanceObservationStructuralConditionOutlet: false,
    };

    return defaults;
  };

  var getDefaultMaintenanceTasksInTypes = function (bmpTypesArray) {
    const tasksMap = getDefaultMaintenanceTasksMap(bmpTypesArray);

    return [...tasksMap.values()].sort((a, b) => SortUtilities.alphaSorter(a.name, b.name));
  };

  var getDefaultMaintenanceTasksMap = function (bmpTypesArray) {
    const tasksMap = new Map();

    for (const bmpType of bmpTypesArray) {
      for (const task of getDefaultMaintenanceTasks(bmpType, true)) {
        tasksMap.set(task.key, task);
      }
    }

    return tasksMap;
  };

  return {
    getNewBmpFcsInventoryDefaults,
    phaseOptions,
    bmpTypeOptions,
    bmpTypeOptionsNoAbbreviations,
    systemAccessOptions,
    propertyTypeOptions,
    catchBasinPriorityOptions,
    cofOptions,
    datumOptions,
    volumeUnitOptions,
    lengthUnitOptions,
    areaUnitOptions,
    wetPoolCapacityUnitOptions,
    multiSystemOptions,
    volumeBasedOptions,
    treatmentTrainOptions,
    treatedStormTypeOptions,
    treatedStormPercentileOptions,
    responsiblePartyDeliveryOptions,
    catchBasinTypeOptions,
    catchBasinBasicFields,
    nonTreatmentTrainsTypes,
    bmpBasicFields,
    inletTypeOptions,
    depthUnitOptions,
    unitOptions,
    ownershipOptions,
    phaseOptionsCB,
    shapeOptions,
    materialOptions,
    responsiblePartyMaintenanceOptions,
    getDefaultMaintenanceTasks,
    cofOptionsFlagged,
    getAllMaintenanceTasks,
    findMaintenanceTask,
    bmpTypeOptionsNoAbbreviationsWithNullAndUnknown,
    bmpFcsInventoryFieldLabels,
    cBmpTelrRequiredFields,
    yesNoNull,
    getTelrRequiredFieldsByType,
    getDefaultMaintenanceTasksInTypes,
    catchBasinTypeOptionsProperName,
    fcsTypeOptionsNoAbbreviations,
  };
};

module.exports = BmpFcsInventoryConstants();

const DateTime = require("../dateTime");
const SortUtilities = require("../general/sortUtilities");
const RoutineMaintenanceConfig = require("../config/routineMaintenanceConfig");
const ToolFunctions = require("../toolFunctions");
const ToolSettings = require("../settings/toolSettings");
