"use strict";

const Tooltip = require("../tooltip");

var Table = function () {
  var layers = null;
  var el = $("#floatingInputsTable");
  var tbl = el.find("#bottomFloatingInputsTable");
  let sideBarCollapsed = false;

  var loadListeners = function (mapLayers) {
    $(window).off("resize", handleEsgSideBarExpand).on("resize", handleEsgSideBarExpand);

    // list item events
    layers = mapLayers;

    el.off();
    $(".sort-button").off();
    $(".search-input input").off();

    el.on("mouseover", ".table-datum", datumMouseover);

    el.on("click", ".li-data", catchmentClick);
    Misc.bindDoubleClickListeners(el, ".li-catch", catchmentClick, catchmentDoubleClick);
    el.on("click", function (e) {
      const $target = $(e.target);
      if (
        $target.hasClass("ms4-return-button") ||
        $target.closest("li").hasClass("ms4-return-button")
      ) {
        return;
      }

      var openPopups = $(".leaflet-popup-close-button");
      if (openPopups.length) {
        openPopups[0].click();
      }
    });
    $(".collapse-button, .expand-side-bar-button")
      .off("click", toggleSideBar)
      .on("click", toggleSideBar);
    el.on("mouseover", ".hover-catchment-highlight", catchmentHoverMouseover);
    el.on("mouseout", ".hover-catchment-highlight", catchmentHoverMouseout);

    // list header events
    el.on("click", ".catchment-table-header h3", headerClick);

    // previous observation arrow
    el.on("click", ".obsIconRow", prevObsClick);

    //bmp listeners
    el.on("click", ".table-datum", tableDatumClick);

    $(".search-input input").val("");
    $(".search-input input").on("input", Misc.debounceDelay(onSearchInput, 500));

    Tree.select("selected").on("update", function () {
      updateTitle();
    });

    Tree.watch({
      activeTab: ["activeTab"],
      navToggleSelection: ["navToggleSelection"],
    }).on("update", function (e) {
      const target = e.target.get();
      const activeTab = target.activeTab;
      const navToggleSelection = target.navToggleSelection;
      if (
        PageFunctions.getTabProperty(activeTab, "noPanels") ||
        (activeTab === "todo" && navToggleSelection === "list")
      ) {
        el.hide();
      } else {
        el.show();
        MapFunctions.setMapHeight();
      }
    });

    Tree.select("table", "dataSort").on("update", resetExpanded);

    el.on("show.bs.collapse", handleTableGroupExpanded);
    el.on("hide.bs.collapse", handleTableGroupCollapsed);

    Tree.watch({
      dataSort: ["table", "dataSort"],
      dataView: ["dataView"],
      spatialView: ["spatialView"],
    }).on("update", function (e) {
      render();
    });

    Tree.select("layers", "bmps", "data").on("update", function (e) {
      if (Tree.get("dataView") === "bmp" && !Filters.spatialFilterIsSet()) {
        render();
      }
    });

    Tree.select("layers", "fcs", "data").on("update", function (e) {
      if (Tree.get("dataView") === "fcs" && !Filters.spatialFilterIsSet()) {
        render();
      }
    });

    Tree.select("layers", "constructionProject", "data").on("update", function (e) {
      const dataView = Tree.get("dataView");
      if (
        (dataView === "construction-project" || dataView === "construction-project-delivery") &&
        !Filters.spatialFilterIsSet()
      ) {
        render();
      }
    });

    Tree.select("layers", "lidProject", "data").on("update", function (e) {
      if (Tree.get("dataView") === "lid-project" && !Filters.spatialFilterIsSet()) {
        render();
      }
    });

    Tree.select("layers", "runoffCondition", "data").on("update", function (e) {
      if (Tree.get("dataView") === "lid-runoff") {
        render();
      }
    });
  };

  const toggleSideBar = function () {
    sideBarCollapsed = !sideBarCollapsed;
    handleSideBarToggle();
  };

  const handleSideBarToggle = function () {
    $("#details-button-container").toggleClass("hidden", sideBarCollapsed);
    $(".filter-summary-container")
      .toggleClass("with-side-bar", !sideBarCollapsed)
      .css("margin-left", sideBarCollapsed ? "56px" : "5px");
    if (Tree.get("tool") === "esg") {
      const map = Map.getMap();
      $("#collapsed-side-table").toggleClass("hidden", !sideBarCollapsed || !SideBar.hasSideBar());
      $("#esg-all-properties, #esg-plan-properties, #esg-incentive-programs")
        .toggleClass("with-side-bar", !sideBarCollapsed)
        .css("margin-left", sideBarCollapsed ? "25px" : "0px");
      map?.invalidateSize();
    } else {
      $("#collapsed-side-table").toggleClass("hidden", !sideBarCollapsed);
      $(".map-title").css("left", sideBarCollapsed ? "65px" : "22%");
      $(".data-list").toggleClass("collapsed-side-table", sideBarCollapsed);
    }
  };

  const handleEsgSideBarExpand = function () {
    if (window.innerWidth < 992) {
      expandSideBar();
    }
  };

  const expandSideBar = function (expand = true) {
    sideBarCollapsed = !expand;
    handleSideBarToggle();
  };

  var handleTableGroupExpanded = function (e) {
    var $expandedElement = $(e.target);
    saveExpandedToTree($expandedElement);
  };

  var handleTableGroupCollapsed = function (e) {
    var $collapsedElement = $(e.target);
    saveCollapsedToTree($collapsedElement);
  };

  var saveExpandedToTree = function ($expandedElement) {
    const isLargeView = DataViewFunctions.getCurrentDataViewProperty("isGisDataView");
    const expandedInfo = _getExpandedTreeInfo($expandedElement);

    if (expandedInfo) {
      if (isLargeView) {
        expandedInfo.primarySelect.set([expandedInfo.id]);
        expandedInfo?.secondarySelect?.set([]);
      } else {
        expandedInfo.primarySelect.push(expandedInfo.id);
      }
    }
  };

  var saveCollapsedToTree = function ($collapsedElement) {
    const expandedInfo = _getExpandedTreeInfo($collapsedElement);

    if (expandedInfo) {
      expandedInfo.primarySelect.apply((expandedList) => {
        return expandedList.filter((expandedId) => expandedId !== expandedInfo.id);
      });
    }
  };

  function naturalCompare(a, b) {
    var re = /(\d+)|(\D+)/g;
    var aChunks = String(a).toLowerCase().match(re);
    var bChunks = String(b).toLowerCase().match(re);

    var len = Math.min(aChunks?.length, bChunks?.length);
    for (var i = 0; i < len; i++) {
      var aChunk = aChunks[i];
      var bChunk = bChunks[i];

      if (aChunk !== bChunk) {
        var aNumber = parseInt(aChunk, 10);
        var bNumber = parseInt(bChunk, 10);

        if (isNaN(aNumber) || isNaN(bNumber)) {
          return aChunk.localeCompare(bChunk);
        } else {
          return aNumber - bNumber;
        }
      }
    }

    return aChunks?.length - bChunks?.length;
  }

  var getUnique = function (data, key, valueKey) {
    const seen = new Set();
    const unique = data?.reduce((result, item) => {
      if (item && item[key] !== null && item[key] !== undefined && !seen.has(item[key])) {
        seen.add(item[key]);
        result.push({
          name: item[key],
          value:
            valueKey && item[valueKey] !== null && item[valueKey] !== undefined
              ? item[valueKey]
              : item[key],
        });
      }
      return result;
    }, []);
    if (unique) {
      unique?.sort((a, b) => naturalCompare(a.name, b.name));
      unique.push({
        name: "Other",
        value: "null",
      });
    }
    return unique;
  };

  var _getExpandedTreeInfo = function ($expandedElement) {
    let id;
    let primarySelect;
    let secondarySelect;

    if ($expandedElement.hasClass("catch-list")) {
      id = $expandedElement
        .closest(".drains-to-parent-div")
        .find(`[data-prop="drains_to_rw"], [data-prop="drains_to_c"]`)
        .data("id");
      primarySelect = Tree.select(["table", "expanded", "receivingWaters"]);
      secondarySelect = Tree.select(["table", "expanded", "catchments"]);
    } else if ($expandedElement.hasClass("rw-list")) {
      id = $expandedElement
        .closest(".drains-to-parent-div")
        .find(`[data-prop="watershed"]`)
        .data("id");
      primarySelect = Tree.select(["table", "expanded", "watersheds"]);
      secondarySelect = Tree.select(["table", "expanded", "catchments"]);
    } else if ($expandedElement.hasClass("grouped-item-list")) {
      id = $expandedElement
        .closest(".drains-to-parent-div")
        .find(`[data-prop="spatialGrouping"]`)
        .data("id");
      const topLevelLayer = DataSortFunctions.getCurrentDataSortProperty("topLevelLayer");

      primarySelect = Tree.select(["table", "expanded", "spatialGroupings"]);
      secondarySelect = Tree.select(["table", "expanded", topLevelLayer]);
    } else {
      const prop = DataSortFunctions.getCurrentDataSortProperty("dataGrouping");
      const layer = DataSortFunctions.getCurrentDataSortProperty("topLevelLayer");

      id = $expandedElement.closest(".catch-parent-div").find(`[data-prop='${prop}']`).data("id");

      if (layer) {
        primarySelect = Tree.select(["table", "expanded", layer]);
      }
    }

    if (id === "") {
      id = null;
    }

    if (id === undefined || !primarySelect) {
      console.info("Unable to save expanded table info to the tree.");
      return null;
    }

    return {
      id,
      primarySelect,
      secondarySelect,
    };
  };

  var onSearchInput = function () {
    Tree.set(["selected", "catchments"], "");
    Tree.set("catchmentData", []);
    const dataView = Tree.get("dataView");

    var searchString = $(this).val().trim().toUpperCase();
    Tree.set(["filters", "searchString"], searchString);
    if (searchString.length) {
      if (ToolFunctions.getToolDataViewSearchByPropertyEnabled(dataView)) {
        Tree.set(["filters", "search"], searchString);
      } else {
        var catchArray = Tree.get("catchments", "items");
        TableDataFunctions.matchTableArray(searchString, catchArray);
      }
    } else {
      // if search input is empty and not in catchment view, reset filter
      if (!SpatialViewController.isCatchmentOrDrainageView()) {
        Tree.set(["filters", "catchments"], []);
        Tree.set(["catchmentData"], []);
        $(".search-input input").val("");
        Tree.set(["filters", "search"], "");
        $("#bottomFloatingInputsTable").show();
      }
    }
    render();
  };

  var unloadListeners = function () {
    el.off();
  };

  var setDataColTooltips = function () {
    Tooltip.setTooltip({
      selector: ".data-col[data-highlight='total-acres']",
      text: "Drainage Area (acres)",
      width: 150,
    });
    Tooltip.setTooltip({
      selector: ".data-col[data-highlight='cof']",
      text: "Consequence of Failure",
      width: 150,
    });
    Tooltip.setTooltip({
      selector: ".data-col[data-highlight='plu-acres']",
      text: "Treated PLU (acres)",
      width: 150,
    });
    Tooltip.setTooltip({
      selector: ".data-col[data-highlight='imp-acres']",
      text: "Impervious Area (acres)",
      width: 150,
    });
    Tooltip.setTooltip({
      selector: "[data-type='bmp'] .data-col.table-icon",
      text: "sBMP Type and Status",
      width: 150,
    });
    Tooltip.setTooltip({
      selector: "[data-type='scenario-bmp'] .data-col.table-icon",
      text: "sBMP Type and Phase",
      width: 150,
    });
    Tooltip.setTooltip({
      selector: "[data-type='fcs'] .data-col.table-icon",
      text: "FCS Type and Status",
      width: 150,
    });
    Tooltip.setTooltip({
      selector: "[data-type='construction-project'] .data-col.table-icon",
      text: "Project Status",
      width: 150,
    });
    Tooltip.setTooltip({
      selector: ".data-col[data-highlight='priority']",
      text: "Priority",
      width: 150,
    });

    Tooltip.setTooltip({
      selector: "[data-type='lid-project'] .data-col.table-icon",
      text: "Status and Performance Tier",
      width: 150,
    });
    Tooltip.setTooltip({
      selector: "[data-type='outfalls'] .data-col.table-icon",
      text: "Outfall Priority",
      width: 150,
    });
    Tooltip.setTooltip({
      selector: ".data-col[data-highlight='rank']",
      text: "Rank",
      width: 50,
    });
  };

  // If the layer uses MapLayer or GeoServerMapLayer, you don't have to set a tree listener for it here

  var setTableDataWatchers = function (availableLayersArray = Tree.get("availableDataSortLayers")) {
    availableLayersArray.forEach(function (layer) {
      Tree.select([layer, "data"]).release();
      Tree.select([layer, "data"]).on("update", function (e) {
        if (!Filters.spatialFilterIsSet()) {
          render();
        }
      });
    });
  };

  var render = function () {
    var data;
    var layer = DataSortFunctions.getCurrentDataSortProperty("topLevelLayer");
    var isFlatList = DataSortFunctions.getCurrentDataSortProperty("isFlatList");
    const isGisDataView = DataViewFunctions.getCurrentDataViewProperty("isGisDataView");

    if (isFlatList) {
      return renderFlatList();
    }

    var layerItems = Tree.select(layer, "items").deepClone();
    if (!layerItems?.length && !DataSortFunctions.getCurrentDataSortProperty("isFlatList")) {
      return;
    }
    data = _getTableDataByView();
    if (data) {
      if (!SpatialViewController.isCatchmentOrDrainageView()) {
        TableDataFunctions.addOutliers(layerItems, data);
        data = TableDataFunctions.prepareData(data);
        Actions.setItemCount(data.length);
      }
      data =
        isGisDataView && !Filters.spatialFilterIsSet()
          ? TableDataFunctions.organizeData(layerItems, [])
          : TableDataFunctions.organizeData(layerItems, data);
      ToDoFunctions.prepareToDoSpatialFilterData(data);
      renderPreparedData(data);
    }

    MapFunctions.setMapHeight();
    scrollToSelected();
  };

  var scrollToSelected = function () {
    const selectedEl = $(".catch-parent-div.selected").get(0);
    if (selectedEl) {
      selectedEl.scrollIntoView();
    }
  };

  var renderFlatList = function () {
    let data = _getTableDataByView();
    data = TableDataFunctions.prepareData(data);
    Actions.setItemCount(data.length);
    data = DataList.limitToMaxListSize(data);
    data = TableDataFunctions.organizeData([], data);

    renderPreparedData(data);
    renderBackButtonIfFactSheet();
    SideBar.highlightRow();
    MapFunctions.setMapHeight();
  };

  var renderBackButtonIfFactSheet = function () {
    if (Tree.get("factSheet", "open")) {
      $(".ms4-return-button").removeClass("hidden");
    }
  };

  var _getTableDataByView = function () {
    let defaultLayer = DataViewFunctions.getCurrentDataViewProperty("defaultLayers")?.[0];
    // @TODO:  Handle speical lid-runoff logic with config
    defaultLayer = defaultLayer === "runoffCondition" ? "postConstructionProjectG2" : defaultLayer;
    let treeData = defaultLayer ? (Tree.get("layers", defaultLayer, "data") ?? []) : [];

    if (!Array.isArray(treeData) && treeData?.type === "FeatureCollection") {
      treeData = treeData.features;
    }

    return Misc.shallowCloneArrayObjects(treeData);
  };

  var renderPreparedData = function (preparedData) {
    var dataSort, dataView, html;
    dataSort = Tree.get("table", "dataSort");
    dataView = Tree.get("dataView");

    if (SpatialViewController.isCatchmentOrDrainageView()) {
      if (dataView === "lid-runoff") {
        html = nunjucks.render("table/catchmentView/catchmentLidRunoffs.njk", {
          data: preparedData,
        });
      }
    } else {
      const customDisplayProperty =
        DataSortFunctions.getCurrentDataSortProperty("customDisplayProperty");
      const masterToggleExpanded = Tree.get("table", "expanded", "masterToggle");

      if (dataView === "roads" && Session.isNewDataDrivenRoadConditionGroup()) {
        html = nunjucks.render("table/sweepingNetworks.njk", {
          data: preparedData,
        });
      } else if (["monitoringDrainages", "assessmentAreas", "jurisdictions"].includes(dataSort)) {
        html = nunjucks.render("table/spatialGroupings.njk", {
          groupings: preparedData,
          dataView: dataView,
          masterToggleExpanded: masterToggleExpanded,
        });
      } else if (dataSort === "watersheds") {
        html = nunjucks.render("table/watersheds.njk", {
          watersheds: preparedData,
          dataView: dataView,
          masterToggleExpanded: masterToggleExpanded,
        });
      } else if (dataSort === "rw") {
        html = nunjucks.render("table/recWaters.njk", {
          receivingWaters: preparedData,
          dataView: dataView,
          masterToggleExpanded: masterToggleExpanded,
        });
      } else if (dataSort === "ud") {
        html = nunjucks.render("table/urbanDrainages.njk", {
          drainages: preparedData,
          dataView: dataView,
          masterToggleExpanded: masterToggleExpanded,
        });
      } else if (dataSort === "uc") {
        html = nunjucks.render("table/catchments.njk", {
          catchments: preparedData,
          dataView: dataView,
        });
      } else if (dataSort === "maintenanceZones" || dataSort === "highways") {
        html = nunjucks.render("table/dataSortTable.njk", {
          topLevelGroupings: preparedData,
          dataView: dataView,
          masterToggleExpanded: masterToggleExpanded,
          dataSortInfo: DataSortFunctions.getCurrentDataSortConfig(),
          spatialFilterIsSet: Filters.spatialFilterIsSet(),
        });
      } else if (["fcs", "bmp"].includes(dataView)) {
        html = nunjucks.render("table/fcssBmps.njk", {
          data: preparedData,
          customDisplayProperty,
          is_fcs: dataView === "fcs",
          is_bmp: dataView === "bmp",
        });
      } else if (
        dataView === "construction-project" ||
        dataView === "construction-project-delivery"
      ) {
        html = nunjucks.render("table/constructionProjects.njk", {
          factSheetOpen: Tree.get(["factSheet", "open"]),
          data: preparedData,
          customDisplayProperty,
        });
      } else if (dataView === "lid-project") {
        html = nunjucks.render("table/lidProjects.njk", {
          data: preparedData,
          customDisplayProperty,
        });
      } else if (dataView === "muni-catch-basin") {
        html = nunjucks.render("table/muniCatchBasinId.njk", {
          factSheetOpen: Tree.get(["factSheet", "open"]),
          data: preparedData,
          customDisplayProperty,
        });
      } else if (dataView === "muni-bmp" || dataView === "lid-bmp") {
        html = nunjucks.render("table/muniBmpId.njk", {
          data: preparedData,
          customDisplayProperty,
        });
      } else if (dataView === "muni-manhole") {
        html = nunjucks.render("table/muniManholeId.njk", {
          data: preparedData,
          customDisplayProperty,
        });
      } else if (dataView === "muni-lift-stations") {
        html = nunjucks.render("table/muniLiftStationId.njk", {
          data: preparedData,
          customDisplayProperty,
        });
      } else if (dataView === "muni-cleanout") {
        html = nunjucks.render("table/muniCleanoutId.njk", {
          data: preparedData,
          customDisplayProperty,
        });
      } else if (dataView === "muni-pipes") {
        html = nunjucks.render("table/muniPipesId.njk", {
          data: preparedData,
          customDisplayProperty,
        });
      } else if (dataView === "muni-culvert") {
        html = nunjucks.render("table/muniCulvertId.njk", {
          data: preparedData,
          customDisplayProperty,
        });
      } else if (dataView === "muni-open-channel") {
        html = nunjucks.render("table/muniOpenChannelId.njk", {
          data: preparedData,
          customDisplayProperty,
        });
      } else if (dataView === "dry-weather") {
        html = nunjucks.render("table/monitoringLocationId.njk", {
          data: preparedData,
          customDisplayProperty,
        });
      } else if (OutfallFunctions.isOutfallDataView(dataView)) {
        html = nunjucks.render("table/outfallsId.njk", {
          factSheetOpen: Tree.get(["factSheet", "open"]),
          data: preparedData,
          customDisplayProperty,
        });
      } else if (dataView === "incidents") {
        html = nunjucks.render("table/incidentsId.njk", {
          data: preparedData,
          customDisplayProperty,
        });
      } else if (dataView === "activities") {
        html = nunjucks.render("table/activityName.njk", {
          data: preparedData,
          customDisplayProperty,
        });
      } else if (dataView === "indcom-facilities") {
        html = nunjucks.render("table/facilityName.njk", {
          factSheetOpen: Tree.get(["factSheet", "open"]),
          data: preparedData,
          customDisplayProperty,
        });
      } else if (dataView === "scenarios") {
        html = nunjucks.render("table/scenarioAssetId.njk", {
          data: preparedData,
          customDisplayProperty,
        });
      } else if (dataView === "asset-management") {
        html = nunjucks.render("table/assetManagementId.njk", {
          data: preparedData,
          customDisplayProperty,
        });

        // ESG
      } else if (dataView === "properties") {
        const theDataSorting = DataSortFunctions.getCurrentDataSortProperty("topLevelLayer");
        const thePrimaryDisplay =
          ToolSettings.getSetting("group")?.["settings"]?.["displaySettings"]?.["property"];
        const theTemplate =
          theDataSorting == "propertyName" ||
          (theDataSorting == "rank" && thePrimaryDisplay == "propertyName")
            ? "table/esgPropertyName.njk"
            : "table/esgPropertyIdentifier.njk";
        html = nunjucks.render(theTemplate, {
          data: preparedData,
        });
      } else if (dataView === "sampling-site") {
        html = nunjucks.render("table/samplingSiteId.njk", {
          data: preparedData,
          customDisplayProperty,
        });
      } else if (dataView === "wq-monitoring") {
        html = nunjucks.render("table/wqMonitoringLocationId.njk", {
          data: preparedData,
          customDisplayProperty,
        });
      } else {
        console.warn(`No data view specific table defined for ${dataView}`);
      }
    }
    tbl.html(html);
    $(".floating-inputs").show();
  };

  // -----  DOM event handlers  -----
  var catchmentHoverMouseover = function (e) {
    const topLevelLayer = DataSortFunctions.getCurrentDataSortProperty("topLevelLayer");

    if (topLevelLayer && layers[`${topLevelLayer}Layer`] && !Session.isMobileParentView()) {
      const dataProp = $(this).data("prop");

      if (dataProp === "spatialGrouping") {
        const $drainsToParentDiv = $(this).closest(".drains-to-parent-div");
        const itemNamesInSpatialGrouping =
          getGroupedItemNamesInSpatialGrouping($drainsToParentDiv) || [];
        const sortingProperty = DataSortFunctions.getCurrentDataSortProperty("sortingProperty");
        const keys = itemNamesInSpatialGrouping.reduce((acc, curr) => ((acc[curr] = ""), acc), {});
        layers[`${topLevelLayer}Layer`].highlightMultiple(sortingProperty, keys);
      } else {
        layers[`${topLevelLayer}Layer`].highlight(dataProp, $(this).data("id"));
      }
    }
  };

  var catchmentHoverMouseout = function (e) {
    const topLevelLayer = DataSortFunctions.getCurrentDataSortProperty("topLevelLayer");
    if (topLevelLayer && layers[`${topLevelLayer}Layer`] && !Session.isMobileParentView()) {
      layers[`${topLevelLayer}Layer`].unhighlightAll();
    }
  };

  var datumMouseover = function (e) {
    const $datumName = $(this).find(".table-datum-name");
    handleTooltipForOverflowingName($datumName);
  };

  var handleTooltipForOverflowingName = function ($datumName) {
    var isOverflowing = $datumName[0].scrollWidth > $datumName.innerWidth();
    if (isOverflowing) {
      $datumName.prop("title", $datumName.text());
    }
  };

  var catchmentClick = function (e) {
    Filters.resetSpatialFilters();
    const $element = $(this);
    const receivingWater = $element.data()?.rw;
    const $catchParentDiv = $element.parents(".catch-parent-div");
    const dataView = Tree.get("dataView");

    Tree.set(["selected", "rwDrainsTo"], receivingWater);

    removeMapHoverHighlight();

    const $expandButton = $catchParentDiv.find(".catch-expand");
    if (expandButtonIsTransitioning($expandButton)) {
      return;
    }

    if (!$element.find(".expand-arrow").is(":visible")) {
      Mobile.collapseAll("hide");
    }

    const isOpen = $element.children().hasClass("open") || $element.hasClass("open");
    if (isOpen) {
      if (
        $catchParentDiv.hasClass("active") ||
        $catchParentDiv.hasClass("selected") ||
        $element.children().hasClass("open")
      ) {
        closeTableCatchment($element);
      } else {
        openTableCatchment($element, e);
      }
    } else {
      selectTableCatchment($element);
    }

    if (dataView === "va") {
      if (!this.contains(e.target)) return; // stops propagation, only open list if it was clicked on

      $(e.target.children).toggleClass("open", !isOpen);
      $(e.target).toggleClass("open", !isOpen);
    }
  };

  var catchmentDoubleClick = function (e) {
    const $element = $(this);

    if (Tree.get("dataView") === "lid-runoff" && $element.hasClass("li-catch")) {
      const gid = $element.data().id;
      const rw = $element.data().rw;

      const catchName = $element.find(".catch-title").text();

      selectTableCatchment($element);

      SpatialViewController.goToView("catchment", catchName, rw, gid);
    }
  };

  var closeTableCatchment = function ($element) {
    const searchString = $(".search-input input").val();
    const $catchParentDiv = $element.parents(".catch-parent-div");
    const layer = DataSortFunctions.getCurrentDataSortProperty("topLevelLayer");
    const sortingProperty = DataSortFunctions.getCurrentDataSortProperty("sortingProperty");

    $element.children().removeClass("open");
    $element.removeClass("open");
    $catchParentDiv.removeClass("active");
    $catchParentDiv.removeClass("selected");

    Tree.set(["selected", layer], "");

    if (searchString.length) {
      var layerArray = Tree.get(layer, "items");
      TableDataFunctions.matchTableArray(searchString, layerArray, layer, sortingProperty);
    } else {
      Tree.set(["filters", layer], []);
    }
  };

  var openTableCatchment = function ($element, e) {
    const itemName = [$element.find(".catch-title").text()];
    const layer = DataSortFunctions.getCurrentDataSortProperty("topLevelLayer");

    $($element.data("target")).collapse("show");
    openTargetDivIfClosed($element);

    e.stopPropagation(); //prevent data-toggle
    resetTableHighlight();

    Tree.set(["filters", layer], itemName);
    Tree.set(["selected", "catchments"], itemName);

    $element.closest(".catch-parent-div").addClass("selected");
  };

  var selectTableCatchment = function ($element) {
    const $catchParentDiv = $element.parents(".catch-parent-div");
    var itemName, layer;

    if ($element.data("networkName")) {
      itemName = [$element.data("networkName").toString()];
    } else {
      itemName = [$element.find(".catch-title").text()];
    }
    layer = DataSortFunctions.getCurrentDataSortProperty("topLevelLayer");

    resetTableHighlight();

    Tree.set(["filters", layer], itemName);
    Tree.set(["selected", "catchments"], itemName);

    if (layer !== "catchments") {
      Tree.set(["selected", layer], $element.data("id"));
    }

    $catchParentDiv.addClass("active");
    $catchParentDiv.addClass("selected");
    $element.children().addClass("open");
    $element.addClass("open");

    openTargetDivIfClosed($element);
  };

  var openTargetDivIfClosed = function ($element) {
    const $target = $element.closest(".catch-parent-div").find($element.data("target"));
    if ($target.hasClass("collapse") && !$target.hasClass("in")) {
      $target.collapse("show");
    }
  };

  var expandButtonIsTransitioning = function ($expandButton) {
    const targetSelector = $expandButton.data("target");
    return $(targetSelector).hasClass("collapsing");
  };

  var headerClick = function (e) {
    if (isCatchListCollapsing($(this).closest(".drains-to-parent-div"))) return;

    var data = $(e.target).data();
    data = data.prop && data.id ? data : $(e.currentTarget).data();

    if (data.id == "chevron-all") {
      data = $(e.target).parent().data();
    }

    Filters.resetSpatialTreeSelected();

    if ($(this).hasClass("master-toggle")) {
      toggleTable();
      Filters.resetSpatialFilters();
      resetTableHighlight();
      Tree.set(["selected", "urbanDrainage"], null);
    } else {
      data["selectedRW"] = Tree.get("selected", "receivingWater");
      const $drainsToParentDiv = $(e.target).closest(".drains-to-parent-div");
      $drainsToParentDiv.addClass("active selected");
      if ($(e.target.children).hasClass("open") || $(e.target).hasClass("open")) {
        if ($drainsToParentDiv.hasClass("selected")) {
          //if expanded & selected, close & deselect
          $(e.target.children).removeClass("open");
          $(e.target).removeClass("open");
          $drainsToParentDiv.removeClass("active selected");

          var selected = $(".drains-to-parent-div.selected").length;
          if (!selected) {
            Filters.resetSpatialFilters();
          }

          Tree.set(["selected", "urbanDrainage"], null);
        } else {
          //re-select expanded header
          e.stopPropagation(); //prevent data-toggle
          Filters.resetSpatialFilters();
          resetTableHighlight();
          setTreeDataForSelcted(data, $drainsToParentDiv);
          $drainsToParentDiv.addClass("active selected");
        }
      } else {
        //expand & select collapsed header
        Filters.resetSpatialFilters();
        resetTableHighlight();
        setTreeDataForSelcted(data, $drainsToParentDiv);
        $drainsToParentDiv.addClass("active selected");
        $(e.target.children).addClass("open");
        $(e.target).addClass("open");
      }
    }
  };

  var setTreeDataForSelcted = function (data, $drainsToParentDiv) {
    if (data.prop === "spatialGrouping") {
      const itemNamesInSpatialGrouping = getGroupedItemNamesInSpatialGrouping($drainsToParentDiv);
      const topLevelLayer = DataSortFunctions.getCurrentDataSortProperty("topLevelLayer");

      Tree.set(["filters", topLevelLayer], itemNamesInSpatialGrouping);
      Tree.set(["filters", "spatialGroupingId"], data.spatialGroupingId);
      Tree.set(["selected", "spatialGrouping"], data.id);
    } else if (data.prop === "watershed") {
      const receivingWatersInWatershed = getReceivingWatersInWatershed($drainsToParentDiv);
      Tree.set(["filters", "receivingWaters"], receivingWatersInWatershed);
      Tree.set(["selected", "watershed"], data.id);
    } else {
      Tree.set(["filters", "receivingWaters"], [data.id]);
      Tree.set(["selected", "receivingWater"], data.id);
    }
    if (data.rw) {
      Tree.set(["selected", "rwDrainsTo"], data.rw);
    }
  };

  var getReceivingWatersInWatershed = function ($drainsToParentDiv) {
    const receivingWatersInWatershed = [];
    $drainsToParentDiv
      .find("> .inputs-table")
      .find(`[data-prop="drains_to_rw"]`)
      .each(function () {
        const receivingWaterId = $(this).data("id");
        if (receivingWaterId) {
          receivingWatersInWatershed.push(receivingWaterId);
        }
      });

    return receivingWatersInWatershed;
  };

  var getGroupedItemNamesInSpatialGrouping = function ($drainsToParentDiv) {
    const itemsInSpatialGrouping = [];
    $drainsToParentDiv
      .find("> .inputs-table")
      .find(`li`)
      .each(function () {
        const groupedItemName = $(this).attr("data-grouped-item-name");
        if (groupedItemName) {
          itemsInSpatialGrouping.push(groupedItemName);
        }
      });

    return itemsInSpatialGrouping;
  };

  var resetTableHighlight = function () {
    $(".drains-to-parent-div").removeClass("selected");
    $(".catchments-only h3").removeClass("selected");
    $(".catchment-header li").removeClass("selected");
    $(".catch-parent-div").removeClass("selected");
    $("#bottomFloatingInputsTable").find(".map-hover").removeClass("map-hover");
    removeMapHoverHighlight();
  };

  var toggleTable = function (forceExpand = null) {
    const shouldExpand =
      forceExpand !== null ? forceExpand : !Tree.get(["table", "expanded", "masterToggle"]);
    if (Session.isUlar() || Session.isVentura()) {
      return;
    }

    if (shouldExpand) {
      expandAllTableSections();
    } else {
      collapseAllTableSections();
    }
  };

  var expandAllTableSections = function () {
    // iOS has issues when animating large collapsing containers
    tbl.toggleTransitions(false);

    tbl.find(".drains-to-parent-div").find(".table-group.open").removeClass("open");
    tbl.find(".drains-to-parent-div").addClass("active");
    tbl.find(".catch-parent-div").addClass("active");

    tbl.find(".collapse").collapse("show");
    tbl.find(".expand-arrow").addClass("open");
    Tree.set(["table", "expanded", "masterToggle"], true);

    tbl.toggleTransitions(true);
  };

  var collapseAllTableSections = function () {
    tbl.toggleTransitions(false);

    tbl.find(".drains-to-parent-div").removeClass("active");
    tbl.find(".catch-parent-div").removeClass("active");
    tbl.find(".in.collapse").collapse("hide");
    tbl.find(".expand-arrow").removeClass("open");
    Tree.set(["table", "expanded", "masterToggle"], false);

    tbl.toggleTransitions(true);
  };

  var prevObsClick = function (e) {
    const item = $(e.target);
    var isAlreadyOpen = false;
    if ($(e.target.firstChild).hasClass("open") || item.hasClass("open")) {
      isAlreadyOpen = true;
    }
    if (isAlreadyOpen) {
      $(e.target.children).removeClass("open");
      $(e.target).removeClass("open");
    } else {
      $(e.target.children).addClass("open");
      $(e.target).addClass("open");
    }
  };

  var tableDatumClick = function (e) {
    const $item = $(this);
    const data = $item.data();

    if (data.type === "read-only") {
      return;
    }

    const waitForMarkerToFetch = updateTableSelection($item, e);
    const navToggleSelection = Tree.get("navToggleSelection");

    if (navToggleSelection === "map") {
      MapFunctions.openPopupById(data.id, waitForMarkerToFetch);
    } else if (navToggleSelection === "list") {
      DataList.scrollToId(data.id);
    }

    removeMapHoverHighlight();
    $item.addClass("map-hover");
  };

  var updateTableSelection = function ($item, e) {
    let waitForMarkerToFetch = false;

    if ($item.data("type") === "todo") {
      const todoSubject = $item.closest(".todo-subject-parent-div").data("subject");
      Tree.set(["todos", Tree.get("dataView"), "highlightSubject"], todoSubject);

      if (
        $(".todo-subject-parent-div.selected").length &&
        !$item.closest(".todo-subject-parent-div").hasClass("selected")
      ) {
        ToDoMapTable.reselectExpandedHeader.call(
          $item.closest(".todo-subject-parent-div").find(".todo-subject-header h3"),
          e,
        );
        waitForMarkerToFetch = true;
      }
    } else {
      Tree.set(["todos", Tree.get("dataView"), "highlightSubject"], undefined);

      // If an FCS outside of spatial filtered data is clicked, reselect the containing catchment of that fcs
      if (
        ($(".drains-to-parent-div.selected").length || $(".catch-parent-div.selected").length) &&
        !$item.closest(".catch-parent-div").hasClass("selected")
      ) {
        const $catchParentDiv = $item.closest(".catch-parent-div");
        const liData = $catchParentDiv.find(".li-data");
        const liToSelect = liData.length
          ? liData
          : $catchParentDiv.find(".catchment-header .li-catch");
        catchmentClick.call(liToSelect, e);
        waitForMarkerToFetch = true;
      }
    }

    return waitForMarkerToFetch;
  };

  var updateTitle = function () {
    const drainsToText = MapFunctions.getSpatialTitle();
    Tree.set("mapTitle", drainsToText);
  };

  var isPropertyExpanded = function (grouping, name) {
    return Tree.get("table", "expanded", grouping)?.includes(name) ?? false;
  };

  var isPropertySelected = function (grouping, name) {
    return Tree.get("selected", grouping) === name;
  };

  var isGroupExpanded = function (grouping, drainsToName) {
    if (grouping === "spatialGrouping") {
      return Tree.get("table", "expanded", "spatialGroupings")?.includes(drainsToName);
    } else if (grouping === "watershed") {
      return Tree.get("table", "expanded", "watersheds")?.includes(drainsToName);
    } else {
      return Tree.get("table", "expanded", "receivingWaters")?.includes(drainsToName);
    }
  };

  var isCatchmentExpanded = function (catchmentGid) {
    return Tree.get("table", "expanded", "catchments").includes(catchmentGid);
  };

  var isGroupSelected = function (grouping, drainsToName) {
    if (grouping === "spatialGrouping") {
      return Tree.get("selected", "spatialGrouping") === drainsToName;
    } else if (grouping === "watershed") {
      return Tree.get("selected", "watershed") === drainsToName;
    } else {
      return Tree.get("selected", "receivingWater") === drainsToName;
    }
  };

  var isCatchmentSelected = function (catchmentGid) {
    return Tree.get("selected", "catchments") === catchmentGid;
  };

  var resetExpanded = function () {
    Tree.set(["table", "expanded", "spatialGroupings"], []);
    Tree.set(["table", "expanded", "receivingWaters"], []);
    Tree.set(["table", "expanded", "watersheds"], []);
    Tree.set(["table", "expanded", "catchments"], []);
    Tree.set(["table", "expanded", "masterToggle"], false);
  };

  var isCatchListCollapsing = function ($element) {
    return $element.find(".catch-list").hasClass("collapsing");
  };

  var expandTableAndScroll = function (id) {
    if (Number.isNaN(parseInt(id))) {
      throw new Error(`ID ${id} passed into expandTableAndScroll must be an integer.`);
    }

    const activeTab = Tree.get("activeTab");
    const [bmpFcsLayer, bmpFcsDrainageLayer] = BmpFcsFunctions.getActiveBmpFcsLayer();

    scrollHighlightTable(id, activeTab === "todo");

    if (bmpFcsLayer) {
      Layers.setDrainageHighlight(id, bmpFcsLayer, bmpFcsDrainageLayer);
    }
  };

  var scrollHighlightTable = function (id, todo = false) {
    var $tbl;

    if (todo) {
      $tbl = $("#todo-map-table-container .todo-map-table");
      ToDoMapTable.expandTodoHeaderContainsIdbmp(id);
    } else {
      $tbl = $("#bottomFloatingInputsTable");
      toggleTable(true);
    }

    var $tableItem = $tbl.find('[data-id="' + id + '"]');

    if ($tableItem) {
      scrollTo($tbl, $tableItem);
      highlightInTable($tableItem);
    }
  };

  var scrollTo = function ($container, $target) {
    if ($target.offset()) {
      $container.animate(
        {
          scrollTop:
            $target.offset().top -
            $container.offset().top +
            $container.scrollTop() -
            $container.innerHeight() / 2 +
            $target.outerHeight() / 2,
        },
        {
          duration: 250,
        },
      );
    }
  };

  var highlightInTable = function ($target) {
    removeMapHoverHighlight();
    $target.addClass("map-hover");
  };

  var removeMapHoverHighlight = function () {
    $(".table-data-list").find(".map-hover").removeClass("map-hover");
  };

  return {
    render,
    renderPreparedData,
    loadListeners,
    unloadListeners,
    setDataColTooltips,
    resetTableHighlight,
    isPropertyExpanded,
    isPropertySelected,
    isGroupExpanded,
    isCatchmentExpanded,
    isGroupSelected,
    isCatchmentSelected,
    expandTableAndScroll,
    removeMapHoverHighlight,
    scrollHighlightTable,
    scrollTo,
    setTableDataWatchers,
    getReceivingWatersInWatershed,
    _getExpandedTreeInfo,
    _getTableDataByView,
    collapseAllTableSections,
    highlightInTable,
    expandSideBar,
    handleSideBarToggle,
    getUnique,
    renderBackButtonIfFactSheet,
  };
};

module.exports = Table();

const Actions = require("../actions");
const BmpFcsFunctions = require("../bmpfcs/bmpFcsFunctions");
const DataList = require("./dataList");
const DataSortFunctions = require("../dataSortFunctions");
const DataViewFunctions = require("../dataViewFunctions");
const Filters = require("./filters");
const Layers = require("./layers");
const MapFunctions = require("./mapFunctions");
const Misc = require("../misc");
const Mobile = require("../mobile");
const OutfallFunctions = require("../outfalls/outfallFunctions");
const Session = require("../login/session");
const SpatialViewController = require("./spatialViewController");
const TableDataFunctions = require("../general/tableDataFunctions");
const ToDoFunctions = require("./toDoFunctions");
const ToDoMapTable = require("./toDoMapTable");
const ToolFunctions = require("../toolFunctions");
const ToolSettings = require("../settings/toolSettings");
const Tree = require("../tree");
const Map = require("../esg/map");
const SideBar = require("../esg/sideBar");
const PageFunctions = require("../pageFunctions");
