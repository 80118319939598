"use strict";

const MessageModal = function () {
  const defaultConfirmText = "Okay";
  const defaultReturnText = "Return";
  const defaultWarnText = "Warning";
  const defaultModalContainerSelector = "#dynamic-warning-modal";

  var showWarningModal = function (message, title, isHtml = false) {
    const $warningModal = $("#warning-modal");
    const $textElement = $warningModal.find(".modal-body > p");
    const $titleElement = $warningModal.find(".modal-title");

    if (isHtml) {
      $textElement.html(message);
    } else {
      $textElement.text(message);
    }

    $titleElement.text(title);

    $warningModal.modal("show");

    $warningModal.off();
    return new Promise(function (resolve) {
      $warningModal.on("click", "button", function () {
        $warningModal.modal("hide");
        resolve();
      });
    });
  };

  var showSimpleWarningModal = function (warningMessage, isHtml = false) {
    return showWarningModal(warningMessage, defaultWarnText, isHtml);
  };

  var showReturnWarningModal = function (warningMessage, isHtml = false, returnMessage = "Return") {
    renderMessage({
      title: defaultWarnText,
      body: warningMessage,
      bodyIsHtml: isHtml,
      buttons: [
        {
          text: returnMessage,
          type: "primary",
        },
      ],
    });
  };

  var showFileSizeWarning = function (filename) {
    showSimpleWarningModal(
      `${filename} is too large to upload.  Please keep the file size under 8MB.`,
    );
  };

  var showConfirmWarningModal = function (
    message = null,
    confirmCallback,
    returnMessage = defaultReturnText,
    okMessage = defaultConfirmText,
    messageHtml = null,
    cancelCallback = null,
    modalTitle = defaultWarnText,
    classes = "",
    confirmCallbackArgs = null,
    containerModalSelector = defaultModalContainerSelector,
  ) {
    renderMessage(
      {
        title: modalTitle,
        body: messageHtml || message,
        bodyIsHtml: messageHtml !== null,
        modalClass: classes,
        buttons: [
          {
            text: returnMessage,
            type: "secondary",
            callback: cancelCallback,
          },
          {
            text: okMessage,
            type: "primary",
            callback: confirmCallback,
            args: confirmCallbackArgs,
          },
        ],
      },
      $(containerModalSelector),
    );
  };

  var showConfirmWarningModalAsPromise = function (
    messageHtml,
    {
      returnMessage = defaultReturnText,
      okMessage = defaultConfirmText,
      modalTitle = defaultWarnText,
    } = {},
  ) {
    return new Promise(function (resolve, reject) {
      showConfirmWarningModal(
        null,
        resolve,
        returnMessage,
        okMessage,
        messageHtml,
        reject,
        modalTitle,
      );
    });
  };

  var showThreeButtonConfirmWarningModal = function (
    message = null,
    firstConfirmCallback,
    secondConfirmCallback,
    cancelBtnMessage,
    firstDeleteBtnMessage,
    secondDeleteBtnMessage,
    messageHtml = null,
    cancelCallback = null,
    modalTitle = defaultWarnText,
  ) {
    renderMessage({
      title: modalTitle,
      body: messageHtml || message,
      bodyIsHtml: messageHtml !== null,
      buttons: [
        {
          text: cancelBtnMessage,
          type: "secondary",
          callback: cancelCallback,
        },
        {
          text: firstDeleteBtnMessage,
          type: "primary",
          callback: firstConfirmCallback,
        },
        {
          text: secondDeleteBtnMessage,
          type: "primary",
          callback: secondConfirmCallback,
        },
      ],
    });
  };

  var showCustomWarningModal = function (incompleteFieldsBodyText, buttons) {
    renderMessage({
      title: "Warning",
      body: incompleteFieldsBodyText,
      bodyIsHtml: true,
      buttons: buttons,
    });
  };

  var renderMessage = function (
    message = {
      title: "",
      body: "",
      bodyIsHtml: true,
      modalClass: "",
      buttons: [
        {
          text: "",
          type: "secondary",
          callback: null,
          args: null,
        },
        {
          text: "",
          type: "primary",
          callback: null,
          args: null,
        },
      ],
    },
    $containerModal = $(defaultModalContainerSelector),
  ) {
    const modalContent = nunjucks.render("dynamicModals/message.njk", message);
    $containerModal.html(modalContent);
    $containerModal.modal("show");
    $containerModal.off();
    $containerModal.on("click", "button:not(.dropdown-toggle)", function () {
      const index = $(this).data("index");
      const buttonData = message.buttons[index];

      if (buttonData?.callback) {
        buttonData.callback(buttonData.args);
      }
      if (buttonData?.stayOpenOnClick !== true) {
        $containerModal.modal("hide");
      }
    });
  };

  var showWelcomeModal = function (welcomeMessage, okMessage = defaultConfirmText) {
    renderMessage({
      title: "Welcome",
      body: welcomeMessage,
      bodyIsHtml: true,
      buttons: [
        {
          text: okMessage,
          type: "primary",
        },
      ],
    });
  };

  var showDeleteContactModal = function (fullName, callback) {
    showConfirmWarningModal(
      `You have selected to delete contact '${fullName}'. To permanently delete this contact from your 2NFORM data, click Delete. To keep the record, click Cancel.`,
      callback,
      "Cancel",
      "Delete",
    );
  };

  var showDeleteUserModal = function (fullName, callback) {
    showConfirmWarningModal(
      `You have selected to delete user '${fullName}'. To permanently delete this user from your 2NFORM data, click Delete. To keep the record, click Cancel.`,
      callback,
      "Cancel",
      "Delete",
    );
  };

  var showDeleteFileModal = function (fileDisplay, callback) {
    showConfirmWarningModal(
      `You have selected to delete this ${fileDisplay}. To permanently delete this file from your 2NFORM data, click Delete. To keep the record, click Cancel.`,
      callback,
      "Cancel",
      "Delete",
    );
  };

  var showDeleteRecordModal = function (deleteCallback, deleteMessageOptions = {}) {
    showConfirmWarningModal(
      _getDeleteMessage(deleteMessageOptions),
      deleteCallback,
      "Cancel",
      "Delete",
    );
  };

  var _getDeleteMessage = function ({ pdf = false } = {}) {
    let message = "";

    if (pdf) {
      message = "This will permanently delete the record as well as any associated PDF reports.";
    } else {
      message = "Delete record?";
    }

    message += ` Click "Delete" to permanently delete record or "Cancel" to return.`;
    return message;
  };

  var showNoticeMessage = function (body, okCallback) {
    renderMessage({
      title: "Notice",
      body: body,
      bodyIsHtml: false,
      buttons: [
        {
          text: "OK",
          type: "primary",
          callback: okCallback,
        },
      ],
    });
  };

  var showYesNoMessage = function (title, body) {
    return new Promise(function (resolve) {
      renderMessage({
        title: title,
        body: body,
        bodyIsHtml: false,
        buttons: [
          {
            text: "Yes",
            type: "primary",
            callback: () => resolve("yes"),
          },
          {
            text: "No",
            type: "secondary",
            callback: () => resolve("no"),
          },
        ],
      });
    });
  };

  var showFinalConfirmModal = function (entityKind, entityName, submitCallback) {
    const $finalConfirmModal = $("#final-confirm-modal");
    const messageTitle = `Warning - Submit ${entityKind}`;
    var messageBody = `
      <p>You will submit the ${entityKind} for: <br />
        <strong>${entityName}</strong>
      </p>
      <p>After you submit, the file will be read-only. Are you sure you want to submit now?</p>
    `;
    if (navigator.onLine === false) {
      messageBody +=
        "You are in Offline Mode. The inspection will be synced once you reconnect to the internet.";
    }
    const message = {
      title: messageTitle,
      body: messageBody,
      bodyIsHtml: true,
      buttons: [
        {
          text: "Don't Submit",
          type: "secondary",
          callback: function () {
            $finalConfirmModal.modal("hide");
          },
        },
        {
          text: `Submit ${entityKind}`,
          type: "primary-submit",
          callback: function () {
            submitCallback();
            $(defaultModalContainerSelector).modal("hide");
          },
        },
      ],
    };
    renderMessage(message, $finalConfirmModal);
  };

  /*
    Only buttons that have passed text are rendered, so it's easy to have zero, one, or two buttons.
    The returned promise is resolved with `true` if the primary button was pressed and `false` for the secondary button.
  */
  var showCustomMessage = function (
    title,
    body,
    { primaryButtonText = null, secondaryButtonText = null } = {},
  ) {
    return new Promise(function (resolve) {
      const buttons = [];

      if (primaryButtonText !== null) {
        buttons.push({
          text: primaryButtonText,
          type: "primary",
          callback: () => resolve(true),
        });
      }

      if (secondaryButtonText !== null) {
        buttons.push({
          text: secondaryButtonText,
          type: "secondary",
          callback: () => resolve(false),
        });
      }

      return renderMessage({
        title,
        body,
        bodyIsHtml: true,
        buttons,
      });
    });
  };

  return {
    showWarningModal,
    showSimpleWarningModal,
    showReturnWarningModal,
    showFileSizeWarning,
    showConfirmWarningModal,
    showConfirmWarningModalAsPromise,
    showWelcomeModal,
    showDeleteContactModal,
    showDeleteUserModal,
    showDeleteFileModal,
    renderMessage,
    showCustomWarningModal,
    showFinalConfirmModal,
    showNoticeMessage,
    showYesNoMessage,
    showThreeButtonConfirmWarningModal,
    showDeleteRecordModal,
    showCustomMessage,
    _getDeleteMessage,
  };
};

module.exports = MessageModal();
